/* eslint-disable import/no-named-default */
/* eslint-disable import/no-cycle */
// Alerts
import ErrorAlert from "./Alerts/ErrorAlert";

// Buttons
// Control Buttons & Control Bar
import ControlBar from "../../ui-lib/ControlBar";
import Checkbox from "../../ui-lib/Inputs/Checkbox";
// Badges
import PeerMutedBadge from "./Badges/PeerMuted";
import CameraButton from "./Buttons/Encoder/Camera/Camera";
import JoinBroadcastButton from "./Buttons/Encoder/JoinBroadcast/JoinBroadcast";
import MicrophoneButton from "./Buttons/Encoder/Microphone/Microphone";
import ScreenCaptureButton from "./Buttons/Encoder/ScreenCapture/ScreenCapture";
// import ModularSendCallRequestButton from "./Buttons/Encoder/SendCallRequest";
import SettingsButton from "./Buttons/Encoder/Settings/Settings";
import TestMicButton from "./Buttons/Encoder/TestMic/TestMic";
import PlayerAudioButton from "./Buttons/Player/Audio/Audio";
import PlayerBitrateButton from "./Buttons/Player/Bitrate/Bitrate";
import PlayerGetSoundButton from "./Buttons/Player/GetSound/GetSound";
import PlayerNewWindowButton from "./Buttons/Player/NewWindow/NewWindow";
import PlayerPlayButton from "./Buttons/Player/Play/Play";
import RecordDeleteButton from "./Buttons/Recorder/Delete/Delete";
import RecordPauseButton from "./Buttons/Recorder/Pause/Pause";
import RecordButton from "./Buttons/Recorder/Record/Record";
import RecordSaveButton from "./Buttons/Recorder/Save/Save";
import RecordStartButton from "./Buttons/Recorder/Start/Start";
import { default as FullscreenButton, default as PlayerFullscreenButton } from "./Buttons/Shared/Fullscreen";
import CallControl from "./Cam2Cam/CallControl";
import DurationLabel from "./DurationLabel";
// Inputs
import EncoderAspectRatioSelect from "./Inputs/Encoder/AspectRatioSelect";
import EncoderAudioDeviceSelect from "./Inputs/Encoder/AudioDeviceSelect";
import EncoderEchoCancellationCheckbox from "./Inputs/Encoder/EchoCancellationCheckbox";
import EncoderNoiseSuppressionCheckbox from "./Inputs/Encoder/NoiseSuppressionCheckbox";
import EncoderResolutionSelect from "./Inputs/Encoder/ResolutionSelect";
import EncoderVideoDeviceSelect from "./Inputs/Encoder/VideoDeviceSelect";
import PlayerQualitySettings from "./Inputs/Player/QualitySettings";
import PlayerVolumeRange from "./Inputs/Player/VolumeRange";
import MediaContainer from "./MediaContainer/MediaContainer";
import PlayerOverlayButton from "./PlayerOverlayButton/PlayerOverlayButton";
import Recorder from "./Recorder/Recorder";
import CallRequestSidebar from "./SlideSidebar/CallRequestSidebar";
import SettingsSidebar, { StatelessSettingsSidebar } from "./SlideSidebar/SettingsSidebar";
import VideoCall from "./SlideSidebar/VideoCall";
import EncoderVideo from "./Videos/EncoderVideo";
import PlayerVideo from "./Videos/PlayerVideo";
// Multistream
import {
  MultistreamCarouselSlide,
  MultistreamPlayer,
  MultistreamPlayersGrid,
  MultistreamCarousel,
} from "./Multistream";

export {
  JoinBroadcastButton,
  CallControl,
  CallRequestSidebar,
  CameraButton,
  FullscreenButton,
  MicrophoneButton,
  ScreenCaptureButton,
  // ModularSendCallRequestButton,
  SettingsButton,
  TestMicButton,
  PlayerAudioButton,
  PlayerBitrateButton,
  PlayerFullscreenButton,
  PlayerGetSoundButton,
  PlayerNewWindowButton,
  PlayerPlayButton,
  PlayerQualitySettings,
  PlayerOverlayButton,
  Recorder,
  RecordButton,
  RecordPauseButton,
  RecordStartButton,
  RecordDeleteButton,
  RecordSaveButton,
  ControlBar,
  DurationLabel,
  EncoderAspectRatioSelect,
  EncoderAudioDeviceSelect,
  EncoderEchoCancellationCheckbox,
  EncoderNoiseSuppressionCheckbox,
  EncoderResolutionSelect,
  EncoderVideoDeviceSelect,
  ErrorAlert,
  PlayerVolumeRange,
  Checkbox,
  MediaContainer,
  SettingsSidebar,
  VideoCall,
  EncoderVideo,
  PlayerVideo,
  PeerMutedBadge,
  StatelessSettingsSidebar,
  MultistreamCarouselSlide,
  MultistreamPlayer,
  MultistreamPlayersGrid,
  MultistreamCarousel,
};
