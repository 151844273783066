// it disabled because `object` is the only type which can be used as non-scalar type
/* eslint-disable @typescript-eslint/ban-types */

import { device } from "../../../api/adapter";

export const idKey = Symbol("id");
export const childrenKey = Symbol("children");
export const canceledKey = Symbol("canceled");
export const cancelPromiseKey = Symbol("cancelPromise");
export const cancelFuncKey = Symbol("cancelFunc");
let lastId = 1;
/**
 * Context value
 */
export type Context<T extends object> = { readonly [K in keyof T]: T[K] } & {
  [idKey]: number;
  [childrenKey]: Array<Context<object>>;
  [canceledKey]: boolean;
  [cancelPromiseKey]: Promise<string>;
  [cancelFuncKey]: (reason: string) => void;
};

/**
 * Untyped Context
 */
export type AnyContext = Context<object>;

export const NullContext: Context<object> = {
  [idKey]: 0,
  [childrenKey]: [],
  [canceledKey]: false,
  [cancelPromiseKey]: Promise.resolve("null context"),
  [cancelFuncKey]: () => undefined,
};

/**
 * Creates new context with specified values.
 * If `parent` is passed then the context will inherit all parents values
 * Make sure you always pass new context to another function even
 * if it doesn't have any new values.
 *
 * Example:
 * ```
 * function bar(ctx: AnyContext) {
 *   // a new context with the same values
 *   console.log(ctx.contextKey);
 * }
 *
 * function foo(ctx: AnyContext) {
 *   // do something with ctx
 *   bar(context(ctx));
 * }
 *
 * foo(context({contextKey: "value"}));
 * ```
 */
export function context<T extends object, P extends object>(
  values: T,
  parent = NullContext as Context<P>,
): Context<T & P> {
  const par = parent === NullContext ? ({} as Context<P>) : parent;

  const ctx: Context<T & P> = {
    ...par,
    ...values,
    [idKey]: lastId++,
    [childrenKey]: [],
    [canceledKey]: false,
    [cancelFuncKey]: () => undefined,
  };

  ctx[cancelPromiseKey] = new Promise<string>((r) => {
    ctx[cancelFuncKey] = r;
  }).then((reason) => {
    ctx[canceledKey] = true;
    return reason;
  });

  if (par[childrenKey] != null) {
    par[childrenKey].push(ctx);

    if (par[canceledKey]) {
      ctx[cancelFuncKey]("parent context is canceled");
    }
  }

  return ctx;
}

export function walk(ctx: AnyContext, out: AnyContext[] = [], remove = false): AnyContext[] {
  for (const child of ctx[childrenKey]) {
    walk(child, out, remove);
  }

  if (remove) {
    // remove it to avoid a leak
    ctx[childrenKey].splice(0, ctx[childrenKey].length);
  }

  out.push(ctx);
  return out;
}

/**
 * Cancel the passed context and all children contexts
 * If `reason` is provided then it will be passed to the cancel promise
 * @See `onceCanceled`
 */
export function cancel(ctx: AnyContext, reason = "not specified"): void {
  if (ctx[canceledKey]) {
    return;
  }

  const children = walk(ctx, [], true);

  for (const child of children) {
    device.setTimeout(() => {
      child[cancelFuncKey](reason);
      child[canceledKey] = true;
    }, 0);
  }
}

/**
 * Returns a promise which resolves once the context is canceled
 * Resolve argument is a cancellation reason
 * @See `cancel`
 */
export function onceCanceled(ctx: AnyContext): Promise<string> {
  return ctx[cancelPromiseKey];
}
