import { mediaController } from "@video/video-client-core";
import { observer } from "mobx-react-lite";
import React, { createRef, ReactElement, Ref, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { EncoderUiContext } from "../../../../context";
import { useStyles } from "../../../../styling";
import { mergeStylesObjects } from "../../../../styling/utils";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

const ModularVideoDeviceSelect = observer(({ classes = {}, ...props }: Partial<SelectProps>) => {
  const { label } = props;
  const componentName = "<VideoDeviceSelect/>";

  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  const ctx = useContext<EncoderUiState | null>(EncoderUiContext);

  useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

  const selectRef: Ref<HTMLSelectElement> = createRef();

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected video device.
   */
  const selectCamera = (ev: React.ChangeEvent<HTMLSelectElement>): void => {
    ctx.mediaStreamController.resolution = null;
    ctx.mediaStreamController.videoDeviceId = ev.target.value;
  };

  // Use global Error handler (to trigger ErrorBoundary).
  const handleInputChange = useUIEventError(selectCamera, componentName);

  return (
    <Select
      classes={mergedStyles}
      ref={selectRef}
      onChange={handleInputChange}
      value={ctx.mediaStreamController.videoDeviceId ?? ""}
      label={label ?? "Camera"}
      {...props}
    >
      <option disabled value="">
        Select a camera
      </option>
      {mediaController.videoDevices().map((item, key) => (
        <option key={item.deviceId} value={item.deviceId} className={mergedClasses.options}>
          {item.label ?? `Camera ${key + 1}`}
        </option>
      ))}
      ;
    </Select>
  );
});

function VideoDeviceSelectWithErrorBoundary({ classes = {}, ...props }: Partial<SelectProps>): ReactElement {
  const mergedClasses = useStyles({ source: classes, target: {} }, "select");
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} disabled classes={mergedStyles} label="Video device unavailable">
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? "Video Devices Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularVideoDeviceSelect classes={classes} {...props} />
    </ErrorBoundary>
  );
}

export default VideoDeviceSelectWithErrorBoundary;
