import type { LoggerCore } from "@video/log-client";
import { extractAggregates, isSerializableObject, Json } from "@video/log-node";
import { comparer, computed, makeObservable, observable, reaction } from "mobx";
import { MediaStream } from "../../api/adapter/features/media-stream";
import { SourceProvider } from "../../api/common";
import { ManifestFormats } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import { BitrateSwitchingEvents, BitrateSwitchingFeature } from "../../api/player/features/bitrate-switching";
import { ConsumerEvents, ConsumerFeature } from "../../api/player/features/consumer";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { MutedAutoplayFeature } from "../../api/player/features/muted-autoplay";
import { createError } from "../errors";
import { ErrorCode } from "../errors-deprecated";
import { MediasoupSource } from "../mediasoup-source";
import { Peer } from "../peer";
import { supportsMediasoupWebrtc } from "../utils/browser-support/browser";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer, CorePlayerOptions } from "./core";
import { getBitrateLayersFromQualities } from "./helper";

interface MediasoupPlayerEvents extends PlayerEvents, ConsumerEvents, BitrateSwitchingEvents {}

export type MediasoupPlayerOptions = CorePlayerOptions & { mutedAutoplayFallback?: boolean };

export class MediasoupPlayer
  extends CorePlayer<MediasoupPlayerOptions, MediaStream, MediasoupPlayerEvents>
  implements ConsumerFeature, BitrateSwitchingFeature, MutedAutoplayFeature
{
  static readonly displayName = "MediasoupPlayer";

  get displayName(): string {
    return MediasoupPlayer.displayName;
  }

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsMediasoupWebrtc("Mediasoup Player", logger);
  }

  async isSupported(): Promise<boolean> {
    return MediasoupPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "webrtc";
  }

  get format(): keyof ManifestFormats {
    return MediasoupPlayer.format;
  }

  preferredSource: boolean | null = null;

  hasAudio = false;

  hasVideo = false;

  isManifestPlayer = false;

  private readonly playerOptions: MediasoupPlayerOptions;

  protected get implementedFeatures(): PlayerFeature[] {
    if (this.isManifestPlayer) {
      return [PlayerFeature.CONSUMER, PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
    }
    return [PlayerFeature.CONSUMER, PlayerFeature.BITRATE_SWITCHING];
  }

  constructor(ctx: VcContext, provider: SourceProvider<MediaStream>, options: MediasoupPlayerOptions) {
    super(ctx, provider, options);

    makeObservable(this, {
      hasAudio: observable,
      hasVideo: observable,

      consumerAudioEnabled: computed,
      consumerVideoEnabled: computed,
      noConsumerAudioAndVideo: computed,
    });

    this.playerOptions = options;

    if (this.playerOptions.mutedAutoplayFallback) {
      this.isManifestPlayer = true;
    }

    this.addInnerDisposer(
      reaction(
        () => this.availableQualities,
        (qty) => {
          this.emit("layers", getBitrateLayersFromQualities(qty));
        },
        {
          equals: comparer.structural,
        },
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.currentQuality,
        (qty) => {
          this.emit("activeLayer", qty?.layer ?? null);
          if (this.provider instanceof MediasoupSource && this.provider.peer instanceof Peer && qty?.layer != null) {
            try {
              this.provider.peer.setPreferredEncoding(qty.layer);
            } catch (err) {
              this.emitError(
                createError(
                  ErrorCode.UnableSwitchQuality,
                  "Failed to change quality for webrtc consumer after currentQuality is changed",
                  {
                    player: this.displayName,
                    format: this.format,
                    preferredLevel: qty.level,
                  },
                ),
              );
            }
          }
        },
        {
          equals: comparer.structural,
        },
      ),
    );

    if (this.provider instanceof MediasoupSource) {
      this.provider.on("availableQualities", this.handleAvailableQualities);
      this.provider.on("accessDenied", this.handleAccessDenied);
    }
    this.addInnerDisposer(() => {
      if (this.provider instanceof MediasoupSource) {
        this.provider.off("availableQualities", this.handleAvailableQualities);
        this.provider.off("accessDenied", this.handleAccessDenied);
      }
    });

    this.ctx.logger.trace("constructor()", { options });
  }

  protected async handleSource(stream: MediaStream): Promise<void> {
    if (this.suspended) {
      return;
    }
    this.ctx.logger.trace("handleSource()");

    this.source = stream;
    if (this.elementSupervisor != null) {
      if (this.autoPlay) {
        await this.playingPromise;
        this.ctx.logger.trace("handleSource() -> await this.playingPromise");
        this.elementSupervisor.updateSrcObject(stream);
        await this.play();
        this.ctx.logger.trace("handleSource() -> await play()");
      } else {
        this.elementSupervisor.updateSrcObject(stream);
      }
      this.ctx.logger.debug("srcObject set");
    }

    if (this.provider instanceof MediasoupSource) {
      this.hasAudio = this.provider.hasAudio;
      this.hasVideo = this.provider.hasVideo;
      this.consumerAudioMuted = !this.hasAudio || this.provider.audioMuted;
      this.consumerVideoPaused = !this.hasVideo || this.provider.videoPaused;
      this.availableQualities = this.provider.availableQualities;
    }
  }

  private handleAccessDenied(response: { message: string }): void {
    if (this.provider instanceof MediasoupSource) {
      this.provider.peer.call?.dispose(response.message);
    }
  }

  private handleAvailableQualities(ev: BitrateSwitchingEvents["availableQualities"]): void {
    this.availableQualities = ev;
  }

  get consumerAudioEnabled(): boolean {
    const { consumerAudioMuted, hasAudio } = this;
    if (!(this.provider instanceof MediasoupSource)) {
      return false;
    }

    return hasAudio && consumerAudioMuted === false;
  }

  get consumerVideoEnabled(): boolean {
    const { consumerVideoPaused, hasVideo } = this;
    if (!(this.provider instanceof MediasoupSource)) {
      return false;
    }
    return hasVideo && consumerVideoPaused === false;
  }

  get noConsumerAudioAndVideo(): boolean {
    const { consumerAudioMuted, hasAudio, consumerVideoPaused, hasVideo } = this;
    return !(hasAudio && consumerAudioMuted === false) && !(hasVideo && consumerVideoPaused === false);
  }

  get streamName(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.streamName;
    }
    return "";
  }

  get peerId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.peerId ?? "";
    }
    return "";
  }

  get callId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.call?.id ?? "";
    }
    return "";
  }

  get userId(): string {
    if (this.provider instanceof MediasoupSource) {
      return this.provider.peer?.userId ?? "";
    }
    return "";
  }

  toJSON(): Json {
    const data = super.toJSON();
    if (!(data != null && typeof data === "object" && !Array.isArray(data) && !isSerializableObject(data))) {
      return {};
    }

    const aggregates = data.aggregates ?? {};
    if (
      !(
        aggregates != null &&
        typeof aggregates === "object" &&
        !Array.isArray(aggregates) &&
        !isSerializableObject(aggregates)
      )
    ) {
      return data;
    }

    delete data.aggregates;

    return {
      ...data,
      options: this.options,

      aggregates: {
        ...aggregates,
        ...extractAggregates(this.provider, "support"),
        support: this.ctx.support.hash,
        consumerAudioEnabled: this.consumerAudioEnabled,
        consumerVideoEnabled: this.consumerVideoEnabled,
        noConsumerAudioAndVideo: this.noConsumerAudioAndVideo,
        preferredSource: this.preferredSource,
        currentQuality: this.currentQuality,
        isManifestPlayer: this.isManifestPlayer,
        peerId: this.peerId,
        userId: this.userId,
      },
    };
  }
}
