import { Level } from "@video/log-node";

/**
 * Supported Levels
 * Logging levels are prioritized from 0-4
 */
const supportedLevels: Record<Level, number> = {
  fatal: 0,
  error: 0,
  warn: 1,
  notice: 1,
  deprecated: 1,
  info: 2,
  local: 3,
  debug: 3,
  timing: 3,
  network: 3,
  trace: 3,
};

/**
 * Check if level is supported
 * @param {string} level
 */
function supportsLevel(level: Level): boolean {
  return supportedLevels[level] !== undefined;
}

/**
 * Check if level meets maxLevel
 * @param {string} level
 * @param {string} maxLevel
 */
function meetsLevel(level: Level, maxLevel: Level): boolean {
  if (supportsLevel(level) && supportsLevel(maxLevel)) {
    return supportedLevels[level] <= supportedLevels[maxLevel];
  }

  return false;
}

export { supportsLevel, meetsLevel, supportedLevels };
