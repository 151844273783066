import { isSerializableObject } from "@video/log-node";
import { Disposable, DisposeFunction, NamedClass } from "../../api/common";
import { cancel } from "./context/context";
import { hasVcContext } from "./context/vc-context";

export function disposeObjects(obj: Disposable, innerDisposers: Array<DisposeFunction>, reason: string): void {
  const object: NamedClass = obj.constructor;
  const className: string = object?.displayName ?? object.name;

  if (obj.isDisposed) {
    return;
  }

  if (hasVcContext(obj)) {
    obj.ctx.logger.trace(`dispose() ${reason}`, {
      aggregates: {
        reason,
      },
    });
    cancel(obj.ctx, `${className} disposed: ${reason}`);
  }

  innerDisposers.forEach((d) => {
    try {
      d(`${className} inner disposer: ${reason}`);
    } catch (err) {
      if (hasVcContext(obj)) {
        obj.ctx.logger.error("error in dispose logic", {
          object: isSerializableObject(obj) ? obj : `${obj}`,
          className,
          err: `${err}`,
          reason,
        });
      }
    }
  });

  innerDisposers.splice(0, innerDisposers.length);
}
