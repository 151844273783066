import { Serializable } from "@video/log-node";
import { MediaStreamConstraints } from "./adapter";
import { PreferredLevel } from "./player/features/bitrate-switching";

export interface IVideoClientError extends Error, Serializable {
  /**
   * Contains the very last error code.
   **/
  readonly code: ErrorCode;

  /**
   * Contains all error codes from inner errors.
   */
  readonly innerCodes: Set<ErrorCode>;

  /**
   * Contains an error that caused this error.
   */
  readonly inner: AnyError | null;

  /**
   * @deprecated use disposed instead
   */
  readonly critical?: boolean;

  /**
   * Do not log this error.
   */
  mute(): void;

  isMuted: boolean;
}

export type GenericError = Record<string, never>;

export type WebrtcConsumerError = {
  peerId: string;
  consumerId?: string;
  layerId?: string | number;
};

export type PlayerError = {
  player?: string;
  format?: string;
  preferredLevel?: PreferredLevel;
};

export type NetworkError = {
  status: number;
};

export type ManifestError = {
  url: string;
};

export type DeviceError = {
  deviceId: string | null;
  deviceLabel: string | null;
  prevDeviceId: string | null;
  prevDeviceLabel: string | null;
  kind: "audio" | "video";
};

export type TrackError = {
  trackId: string;
  kind: string;
};

export type ConstraintsError = {
  constraints: MediaStreamConstraints | null;
  fallbackConstraints: MediaStreamConstraints | null;
};

export type NativeError = {
  type: string;
};

export type SourceTypeError = {
  sourceType: string;
};

export type DisposingError = {
  className: string;
  reason?: string;
};

export type AssertionError = {
  className: string;
  methodName: string;
};

export type StreamError = {
  streamName: string;
};

export type AllErrorsMap = {
  [ErrorCode.H264NotSupported]: GenericError;
  [ErrorCode.NoBackendEndpoints]: GenericError;
  [ErrorCode.NoPlayersAvailable]: GenericError;
  [ErrorCode.UnableJoinCall]: GenericError;
  [ErrorCode.UnableChangeBitrate]: WebrtcConsumerError;
  [ErrorCode.UnableSwitchQuality]: PlayerError;
  [ErrorCode.MediaStreamNotSupported]: GenericError;
  [ErrorCode.MediaDeviceNotSupported]: GenericError;
  [ErrorCode.ConsumerNotCreated]: WebrtcConsumerError;
  [ErrorCode.NetworkError]: NetworkError;
  [ErrorCode.ManifestError]: ManifestError;
  [ErrorCode.PlayerLoadingFailed]: PlayerError;
  [ErrorCode.BadManifest]: GenericError;
  [ErrorCode.MediaDeviceNotAvailable]: DeviceError;
  [ErrorCode.MediaControllerNotInitialized]: GenericError;
  [ErrorCode.MediaDeviceChangingFailed]: DeviceError;
  [ErrorCode.MediaStreamPreparingConflict]: GenericError;
  [ErrorCode.MediaTrackEnded]: TrackError;
  [ErrorCode.ApplyingConstraintsFailed]: ConstraintsError;
  [ErrorCode.NativeError]: NativeError;
  [ErrorCode.PlayerSourceNotSupported]: SourceTypeError;
  [ErrorCode.UpdateResolutionsFailed]: DeviceError;
  [ErrorCode.DisposingError]: DisposingError;
  [ErrorCode.AssertionError]: AssertionError;
  [ErrorCode.StreamNameAlreadyExists]: StreamError;
  [ErrorCode.StreamNotFound]: StreamError;
  [ErrorCode.BroadcastVideoFailed]: StreamError;
  [ErrorCode.BroadcastAudioFailed]: StreamError;

  [ErrorCode.InternalError]: GenericError;
  [ErrorCode.NotInitialized]: GenericError;
  [ErrorCode.NotSupported]: GenericError;
  [ErrorCode.ValidationError]: GenericError;
  [ErrorCode.Permission]: GenericError;
  [ErrorCode.CallError]: GenericError;
  [ErrorCode.JoinCallRejected]: GenericError;
  [ErrorCode.StartBroadcastError]: GenericError;
  [ErrorCode.UpdateBroadcastError]: GenericError;
  [ErrorCode.StreamExists]: GenericError;
  [ErrorCode.EnableVideoError]: GenericError;
  [ErrorCode.DisableVideoError]: GenericError;
  [ErrorCode.EnableAudioError]: GenericError;
  [ErrorCode.DisableAudioError]: GenericError;
  [ErrorCode.LayerNotFound]: GenericError;
  [ErrorCode.PlayingIssue]: GenericError;
  [ErrorCode.DisposedObject]: GenericError;
  [ErrorCode.RetrievingMediaStreamError]: GenericError;
  [ErrorCode.CapturableStreamError]: GenericError;
  [ErrorCode.SFUNewPeersEvent]: GenericError;
  [ErrorCode.SFUConsumerSourcesEvent]: GenericError;
  [ErrorCode.SFUNewConsumerEvent]: GenericError;
  [ErrorCode.SFUPeerClosedEvent]: GenericError;
  [ErrorCode.SFUSwitchConsumerTrackEvent]: GenericError;
  [ErrorCode.SFUConsumerClosedEvent]: GenericError;
  [ErrorCode.SFUConsumerPausedEvent]: GenericError;
  [ErrorCode.SFUConsumerResumedEvent]: GenericError;
  [ErrorCode.SFUConsumerScoreEvent]: GenericError;
  [ErrorCode.SFUConsumerLayersChangedEvent]: GenericError;
  [ErrorCode.SFUProducerClosedEvent]: GenericError;
  [ErrorCode.SFUProducerPausedEvent]: GenericError;
  [ErrorCode.SFUProducerResumedEvent]: GenericError;
  [ErrorCode.MediasoupSetupError]: GenericError;
  [ErrorCode.TransportStateError]: GenericError;
  [ErrorCode.BadInput]: GenericError;
  [ErrorCode.DriverNotSupported]: GenericError;
  [ErrorCode.ElementRequired]: GenericError;
  [ErrorCode.EmbedSWFFailed]: GenericError;
  [ErrorCode.GetUserMediaFailed]: GenericError;
  [ErrorCode.ManifestUnexpectedResponse]: GenericError;
  [ErrorCode.ManifestUnauthorized]: GenericError;
  [ErrorCode.ManifestForbidden]: GenericError;
  [ErrorCode.ManifestInternalError]: GenericError;
  [ErrorCode.ManifestNotFound]: GenericError;
  [ErrorCode.InvalidControls]: GenericError;
  [ErrorCode.InvalidMediaURL]: GenericError;
  [ErrorCode.InvalidPopoutURL]: GenericError;
  [ErrorCode.InvalidElement]: GenericError;
  [ErrorCode.WSNetworkError]: GenericError;
  [ErrorCode.NoDrivers]: GenericError;
  [ErrorCode.PlaybackError]: GenericError;
  [ErrorCode.UnknownDriver]: GenericError;
  [ErrorCode.UnknownError]: GenericError;
  [ErrorCode.UnrecognizedDriver]: GenericError;
  [ErrorCode.UserRequired]: GenericError;
  [ErrorCode.InvalidBitrate]: GenericError;
  [ErrorCode.HlsjsNotLoaded]: GenericError;
  [ErrorCode.MediaError]: GenericError;
  [ErrorCode.MeowDriverError]: GenericError;
  [ErrorCode.InternalCallError]: GenericError;
  [ErrorCode.WSRequestError]: GenericError;
  [ErrorCode.MediaRecorderError]: GenericError;
  [ErrorCode.UpdateMSCError]: GenericError;
  [ErrorCode.Mp4BufferError]: GenericError;
  [ErrorCode.HandleHlsJsError]: GenericError;
  [ErrorCode.ConsumerNotFound]: GenericError;
  [ErrorCode.SFUBusy]: GenericError;
  [ErrorCode.SimulcastDisabled]: GenericError;
  [ErrorCode.SFUBusy]: GenericError;
};

export type AnyError = AllErrorsMap[keyof AllErrorsMap] & IVideoClientError;

export enum ErrorCode {
  /**
   * The browser does not support H264 codec.
   */
  H264NotSupported = "h264-not-supported",

  /**
   * No backend endpoints or joinUrl were provided.
   */
  NoBackendEndpoints = "no-backend-endpoints",

  /**
   * No players are available.
   */
  NoPlayersAvailable = "no-players-available",

  /**
   * Unable to join the call by unknown reason.
   */
  UnableJoinCall = "unable-join-call",

  /**
   * Unable to change bitrate by some reason for specific consumer.
   */
  UnableChangeBitrate = "unable-change-bitrate",

  /**
   * Unable to switch to specific quality.
   */
  UnableSwitchQuality = "unable-switch-quality",

  /**
   * MediaStream is not supported by the browser.
   */
  MediaStreamNotSupported = "media-stream-not-supported",

  /**
   * MediaDevice is not supported by the browser.
   */
  MediaDeviceNotSupported = "media-device-not-supported",

  /**
   * The consumer was received from the server, but the player was not able to play it.
   */
  ConsumerNotCreated = "consumer-not-created",

  /**
   * Network error.
   */
  NetworkError = "network-error",

  /**
   * Manifest wa not loaded due to some reason.
   */
  ManifestNotLoaded = "manifest-not-loaded",

  /**
   * The player was not able to load the stream.
   */
  PlayerLoadingFailed = "player-loading-failed",

  /**
   * Manifest is not valid.
   */
  BadManifest = "bad-manifest",

  /**
   * Audio/video device not found or not available.
   */
  MediaDeviceNotAvailable = "media-device-not-available",

  /**
   * MediaController has not been initialized yet.
   */
  MediaControllerNotInitialized = "media-controller-not-initialized",

  /**
   * MediaDevice has not been changed due to error. See inner error for details.
   */
  MediaDeviceChangingFailed = "media-device-changing-failed",

  /**
   * Attempt to start preparing media stream while another stream is preparing.
   */
  MediaStreamPreparingConflict = "media-stream-preparing-conflict",

  /**
   * MediaTrack has ended unexpectedly.
   */
  MediaTrackEnded = "media-track-ended",

  /**
   * Constraints are not applied due to some error.
   */
  ApplyingConstraintsFailed = "constraints-not-applied",

  /**
   * The error is not handled by the VideoClient.
   */
  NativeError = "native-error",

  /**
   * The given source is not supported by the player.
   */
  PlayerSourceNotSupported = "player-source-not-supported",

  /**
   * Unable to get available resolutions for selected device.
   */
  UpdateResolutionsFailed = "update-resolutions-failed",

  /**
   * An error occurred while disposing an object.
   */
  DisposingError = "disposing-error",

  /**
   * Internal check failed. This is a VideoClient bug.
   */
  AssertionError = "assertion-error",

  /**
   * The stream with specified name already exists.
   */
  StreamNameAlreadyExists = "stream-name-already-exists",

  /**
   * The stream with specified name does not exist.
   */
  StreamNotFound = "stream-not-found",

  /**
   * Unable to broadcast video due to some error.
   */
  BroadcastVideoFailed = "broadcast-video-failed",

  /**
   * Unable to broadcast audio due to some error.
   */
  BroadcastAudioFailed = "broadcast-video-failed",

  /**
   * SFU hits the limit of concurrent connections per peer.
   */
  SFUBusy = "sfu-busy",

  InternalError = "internal-error",
  NotInitialized = "not-initialized",
  NotSupported = "not-supported",
  ValidationError = "validation-error",
  Permission = "permission-error",
  CallError = "call-error",
  JoinCallRejected = "join-call-rejected",
  StartBroadcastError = "start-broadcast-error",
  UpdateBroadcastError = "start-broadcast-error",
  StreamExists = "stream-exists",
  EnableVideoError = "enable-video-error",
  DisableVideoError = "disable-video-error",
  EnableAudioError = "enable-audio-error",
  DisableAudioError = "disable-audio-error",
  ManifestError = "manifest-error",
  LayerNotFound = "layer-not-found",
  PlayingIssue = "playing-issue",
  DisposedObject = "disposed-object",
  RetrievingMediaStreamError = "retrieving-media-stream-error",
  CapturableStreamError = "capturable-stream-error",
  SFUNewPeersEvent = "sfu-new-peers-event",
  SFUConsumerSourcesEvent = "sfu-consumer-sources-event",
  SFUNewConsumerEvent = "sfu-new-consumer-event",
  SFUPeerClosedEvent = "sfu-peer-closed-event",
  SFUSwitchConsumerTrackEvent = "sfu-switch-consumer-track-event",
  SFUConsumerClosedEvent = "sfu-consumer-closed-event",
  SFUConsumerPausedEvent = "sfu-consumer-paused-event",
  SFUConsumerResumedEvent = "sfu-consumer-resumed-event",
  SFUConsumerScoreEvent = "sfu-consumer-score-event",
  SFUConsumerLayersChangedEvent = "sfu-consumer-layers-changed-event",
  SFUProducerClosedEvent = "sfu-producer-closed-event",
  SFUProducerPausedEvent = "sfu-producer-paused-event",
  SFUProducerResumedEvent = "sfu-producer-resumed-event",
  MediasoupSetupError = "mediasoup-setup-error",
  TransportStateError = "transport-state-error",
  BadInput = "bad-input",
  DriverNotSupported = "driver-not-supported",
  ElementRequired = "element-required",
  EmbedSWFFailed = "embedding-flash-swf-failed",
  GetUserMediaFailed = "get-user-media-failed",
  ManifestUnexpectedResponse = "manifest-unexpected-response",
  ManifestUnauthorized = "manifest-unauthorized",
  ManifestForbidden = "manifest-forbidden",
  ManifestInternalError = "manifest-internal-error",
  ManifestNotFound = "manifest-not-found",
  InvalidControls = "invalid-controls-parent",
  InvalidMediaURL = "invalid-media-url",
  InvalidPopoutURL = "invalid-popout-url",
  InvalidElement = "invalid-element",
  WSNetworkError = "websocket-network-error",
  NoDrivers = "no-valid-drivers",
  PlaybackError = "playback-error",
  UnknownDriver = "unknown-driver",
  UnknownError = "unknown-error",
  UnrecognizedDriver = "unrecognized-driver",
  UserRequired = "user-required",
  InvalidBitrate = "invalid-bitrate",
  HlsjsNotLoaded = "hlsjs-not-loaded",
  MediaError = "media-error",
  MeowDriverError = "meow-driver-error",
  InternalCallError = "internal-call-error",
  WSRequestError = "ws-request-error",
  MediaRecorderError = "media-recorder-error",
  UpdateMSCError = "update-msc-error",
  Mp4BufferError = "mp4-buffer-error",
  HandleHlsJsError = "handle-hlsjs-error",
  ConsumerNotFound = "consumer-not-foud",
  Constraints = "constraints",
  DeviceNotFound = "device-not-found",
  TrackEnded = "track-ended",
  JoinCallError = "join-call-error",
  SimulcastDisabled = "simulcast-disabled",
}

export function isVideoClientError(error: any): error is AnyError & IVideoClientError {
  return error && error.code && Object.values(ErrorCode).includes(error.code);
}
