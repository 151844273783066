/**
 * Contains media streams with silence audio background for preview.
 */
const previewStreams = new WeakMap<MediaStream, MediaStream>();

/**
 * Replace the audio track with silence audio background and store in the previewStreams WeakMap.
 * If the stream is already in the WeakMap, return the stream from the WeakMap.
 */
export function withSilenceBackground(audioCtx: AudioContext, mediaStream: MediaStream | null): MediaStream | null {
  if (mediaStream == null) {
    return null;
  }

  const previewStream = previewStreams.get(mediaStream);
  if (previewStream != null) {
    return previewStream;
  }

  const videoTracks = mediaStream.getVideoTracks();
  if (videoTracks.length === 0) {
    return mediaStream;
  }

  const silentAudioStream = createSilencedBackground(audioCtx, videoTracks);
  previewStreams.set(mediaStream, silentAudioStream);
  return silentAudioStream;
}

/**
 * Connects our audioSourceNode to the audioCTX and sets its gain to be pretty much silent.
 */
function createSilencedBackground(audioCtx: AudioContext, videoTracks: MediaStreamTrack[]): MediaStream {
  const source = createConstantSource(audioCtx);
  const gainNode = audioCtx.createGain();
  // Set the audio of the gain node to almost nothing
  gainNode.gain.value = 0.001;
  source.connect(gainNode);
  // Connect our gainNode to the final destination of all of the audio in the context.
  gainNode.connect(audioCtx.destination);
  source.start();

  const audioDestinationMediaStream = audioCtx.createMediaStreamDestination();
  const audioTrack = audioDestinationMediaStream.stream.getAudioTracks()[0];

  // Check to ensure we have an audio track and the mediaStreamController has set it's videoTracks.\
  // Create our new MediaStream and set our newly created audioTrack and the mediaStreamController video track to it.
  const newStream = new MediaStream();

  videoTracks.forEach((vt) => {
    newStream.addTrack(vt);
    vt.addEventListener(
      "ended",
      () => {
        audioTrack.stop();
      },
      { once: true },
    );
  });
  newStream.addTrack(audioTrack);
  return newStream;
}

/**
 * Creates our constant source of audio, returns the audioBufferSourceNode which is playing on repeat.
 */
function createConstantSource(audioCtx: AudioContext): AudioBufferSourceNode {
  // Create our buffer source node
  const constantSourceNode = audioCtx.createBufferSource();
  const constantBuffer = audioCtx.createBuffer(1, 1, audioCtx.sampleRate);
  const bufferData = constantBuffer.getChannelData(0);

  for (let i = 0; i < bufferData.length; i++) {
    // Then filling that channel with white noise
    bufferData[i] = Math.random() * 2 - 1;
  }
  constantSourceNode.buffer = constantBuffer;
  // Loop the audio so that it will keep replaying
  constantSourceNode.loop = true;

  return constantSourceNode;
}
