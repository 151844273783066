import { TranscodeScoreLevel, SourceScoreLevel, AutoQualityLevel, types } from "@video/video-client-core";

export type BitrateScore = TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel;

export type BitrateHelper = {
  name: string;
  score: BitrateScore;
  formattedBitrate: string;
};

/**
 * Returns pretty-printed bitrate.
 */
function _formatBitrate(quality: types.Quality, defaultVal = ""): string {
  if (quality.layer == null) {
    return defaultVal;
  }

  return ((quality.layer.bitrate ?? 0) / 1_000).toFixed(2);
}

function _getUIBitrateLevel(
  availableQualities: types.Quality[],
  preferenceArray: BitrateScore[],
  name: string,
): { name: string; score: BitrateScore | null; formattedBitrate: string | null } {
  let preferredLevel: BitrateScore | null = null;
  let formattedBitrate: string | null = null;

  for (const item of preferenceArray) {
    const foundQuality = availableQualities.find((q) => (q.level as BitrateScore) === item);
    if (foundQuality != null) {
      preferredLevel = item;
      formattedBitrate = _formatBitrate(foundQuality);
      break;
    }
  }

  return { score: preferredLevel, name, formattedBitrate };
}

export const availableBitrateHelper = (availableQualities: types.Quality[] | null): BitrateHelper[] => {
  if (availableQualities == null) {
    return [];
  }

  const auto = _getUIBitrateLevel(availableQualities, [AutoQualityLevel.Auto], "Auto");

  const source = _getUIBitrateLevel(
    availableQualities,
    [SourceScoreLevel.Low, SourceScoreLevel.Medium, SourceScoreLevel.High],
    "Source",
  );

  const low = _getUIBitrateLevel(availableQualities, [TranscodeScoreLevel.Low, TranscodeScoreLevel.Lowest], "Low");

  const medium = _getUIBitrateLevel(
    availableQualities,
    [TranscodeScoreLevel.Medium, TranscodeScoreLevel.MediumHigh, TranscodeScoreLevel.MediumLow],
    "Medium",
  );

  const high = _getUIBitrateLevel(availableQualities, [TranscodeScoreLevel.High, TranscodeScoreLevel.Highest], "High");

  return [auto, source, low, medium, high].filter((qty) => qty.score != null) as BitrateHelper[];
};
