import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Camera: FC<IconProps & { outline?: boolean }> = ({ className, outline = false, ...rest }) => {
  if (outline) {
    return (
      <svg
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className != null && className}`}
        {...rest}
      >
        <circle cx="19" cy="19" r="19" fill="white" fillOpacity="0.1" />
        <path
          d="M22.75 17.5L27.47 12.78C27.5749 12.6752 27.7085 12.6039 27.8539 12.575C27.9993 12.5462 28.15 12.561 28.2869 12.6177C28.4239 12.6744 28.541 12.7705 28.6234 12.8937C28.7058 13.0169 28.7499 13.1618 28.75 13.31V24.69C28.7499 24.8382 28.7058 24.9831 28.6234 25.1063C28.541 25.2295 28.4239 25.3256 28.2869 25.3823C28.15 25.439 27.9993 25.4538 27.8539 25.425C27.7085 25.3961 27.5749 25.3248 27.47 25.22L22.75 20.5M11.5 25.75H20.5C21.0967 25.75 21.669 25.5129 22.091 25.091C22.5129 24.669 22.75 24.0967 22.75 23.5V14.5C22.75 13.9033 22.5129 13.331 22.091 12.909C21.669 12.4871 21.0967 12.25 20.5 12.25H11.5C10.9033 12.25 10.331 12.4871 9.90901 12.909C9.48705 13.331 9.25 13.9033 9.25 14.5V23.5C9.25 24.0967 9.48705 24.669 9.90901 25.091C10.331 25.5129 10.9033 25.75 11.5 25.75Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }

  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="camera"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M112.209 50.7342H39.1599C32.473 50.7342 27.0526 56.225 27.0526 62.9987V136.996C27.0526 143.77 32.473 149.261 39.1599 149.261H112.209C118.895 149.261 124.316 143.77 124.316 136.996V62.9987C124.316 56.225 118.895 50.7342 112.209 50.7342ZM160.182 60.4072L132.421 79.8046V120.19L160.182 139.562C165.551 143.308 172.947 139.485 172.947 132.942V67.027C172.947 60.5099 165.577 56.6612 160.182 60.4072Z"
      />
    </svg>
  );
};

export default Camera;
