import { RefObject, useEffect, useState } from "react";

export interface Dimensions {
  height: number;
  width: number;
}

// This hook returns the height and width of an element.
// It uses a ResizeObserver to detect changes in the size of the element.
export const useRefDimensions = (ref: RefObject<HTMLElement>): Dimensions => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref]);

  return dimensions;
};
