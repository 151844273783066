import { player } from "@video/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../store/player";
import { PlayerUiContext } from "../../../context";
import { useStyles } from "../../../styling";
import { useRefDimensions } from "../../../ui-lib/Hooks/useRefDimensions";
import VideoWrapper, { VideoWrapperProps } from "../../../ui-lib/VideoWrapper";
import { videoWrapperStyles } from "../../../ui-lib/VideoWrapper/styles";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";
import { Video } from "./Video";

const ModularVideo = observer(
  ({ classes, adaptiveBlur, adaptiveBlurValues, elementBlur, blurPixels, ...props }: Partial<VideoWrapperProps>) => {
    const componentName = "<PlayerVideo/>";
    /**
     * @todo: Not sure how we want to handle styles for this.
     */

    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    useUndefinedStoreError(ctx?.videoElement != null, componentName);

    const blurredVideo =
      adaptiveBlur || elementBlur || (ctx.player.isImplements(player.Feature.BITRATE_SWITCHING) && ctx.player.blurred);

    const { width } = useRefDimensions(ctx?.videoElement);

    // A video element width of 360 will use 9 blur pixels
    // A video element width of 1920 will use 15 blur pixels
    // Other width blur values will be calculated based on a linear regression of the above
    const adaptiveBlurPixels = (elWidth: number): number => {
      const minWidth = 360;
      const maxWidth = 1920;
      const minBlurPixels = adaptiveBlurValues?.min ?? 9;
      const maxBlurPixels = adaptiveBlurValues?.max ?? 15;
      return ((maxBlurPixels - minBlurPixels) * (elWidth - minWidth)) / (maxWidth - minWidth) + minBlurPixels;
    };

    blurPixels = adaptiveBlur ? adaptiveBlurPixels(width) : blurPixels;

    const styling = videoWrapperStyles(
      props.pillarBox ?? false,
      props.isFullScreen,
      props.mirror,
      blurredVideo,
      blurPixels,
    );

    const mergedClasses = useStyles({ source: classes ?? {}, target: styling }, "videoWrapper");

    // @TODO Needs to be added back in when this feature is fixed
    /*
  useEffect(
    () =>
      autorun(() => {
        const p = ctx?.player;
        const el = ctx?.videoElement;
        if (p == null || !(el instanceof HTMLVideoElement)) {
          return;
        }

        if (p.isImplements(player.Feature.CONSUMER)) {
          const logCtx = {
            streamName: p.streamName,
            consumerVideoEnabled: p.consumerVideoEnabled,
          };

          if (p.consumerVideoEnabled) {
            ctx?.logger?.debug("Video element is visible", logCtx);
            el.style.visibility = "";
          } else {

            //ctx?.logger?.debug("Video element is hidden", logCtx);
            //el.style.visibility = "hidden";
          }

        } else {
          el.style.visibility = "";
        }

      }),
    [ctx?.logger, ctx?.player, ctx?.videoElement, ctx?.player.format],
  );
  */
    return (
      <VideoWrapper
        isFullScreen={ctx?.isFullscreen}
        classes={classes}
        ref={ctx.videoWrapperElement}
        elementBlur={blurredVideo}
        blurPixels={blurPixels}
        {...props}
      >
        <Video
          source={ctx.player}
          autoPlay={false}
          className={mergedClasses.videoElement}
          ref={ctx.videoElement}
          id={props.id}
        />
      </VideoWrapper>
    );
  },
);

const PlayerVideoWithErrorBoundary: FC<Partial<VideoWrapperProps>> = ({ classes, ...props }) => {
  return (
    /**
     * @todo: Temporary fallback. Update for a real fallback.
     */
    <ErrorBoundary
      render={() => (
        <div style={{ height: "600px", width: "100%" }}>
          <p>Video is not working</p>
        </div>
      )}
    >
      <ModularVideo classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default PlayerVideoWithErrorBoundary;
