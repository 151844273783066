/* eslint-disable import/no-cycle */
import querystring from "qs";
import { Auth } from "../../../api";
import { NetworkError } from "../../errors-deprecated";
import type { VcContext } from "../context/vc-context";
import authRequest from "./auth";
import { device } from "../../../api/adapter";

const JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export interface RequestOptions {
  auth?: Auth;
  headers: { [key: string]: string };
  failoverUrls: string[];
  reauth: boolean;
  method: "get" | "post" | "patch" | "delete" | "head";
  body?: string;
  query?: { [key: string]: string };
  expectedStatus?: number;
  warnStatuses: number[];
  callId: string;
}

export type RequestResponse<T> = {
  authToken: string | null;
  body: T;
};

const defaultOptions: RequestOptions = {
  headers: {},
  failoverUrls: [],
  reauth: false,
  method: "get",
  warnStatuses: [],
  callId: "",
};

export default async <T>(
  ctx: VcContext,
  url: string,
  options: Partial<RequestOptions>,
): Promise<RequestResponse<T> | null> => {
  const opts = { ...defaultOptions, ...options };

  if (options.auth != null && typeof options.auth.refreshToken !== "function") {
    ctx.logger.error("auth must be a Authorization object");
    throw new Error("auth must be a Authorization object");
  }
  const auth = opts.auth ?? null;

  const optionsHeaders = opts.headers ?? {};
  const headers = {
    ...JSON_HEADERS,
    ...optionsHeaders,
  };

  let useUrl: string = url;
  let failoverUrls = opts.failoverUrls?.slice() ?? [];
  let hasNextUrl = failoverUrls.length > 0;
  const rotateUrl = (): void => {
    if (failoverUrls[0] == null) {
      throw new Error("Failover URL is undefined");
    }
    [useUrl] = failoverUrls;
    failoverUrls = failoverUrls.slice(1);
    hasNextUrl = failoverUrls.length > 0;
  };

  const request = async (noRetry = false): Promise<RequestResponse<T> | null> => {
    let token: string | null = null;
    if (auth != null) {
      if (options.reauth) {
        auth.refreshToken();
      }

      try {
        token = await authRequest(auth);
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        ctx.logger.warn("Authorization error", { err: msg });
        throw err;
      }
    }

    const req: RequestInit = {
      method: options.method ?? "get",
      headers:
        token != null
          ? {
              ...headers,
              Authorization: `bearer ${token}`,
            }
          : headers,
    };

    if (options.body != null) {
      req.body = options.body;
    }

    if (options.query != null) {
      const qs = querystring.stringify(options.query);
      if (useUrl.includes("?")) {
        useUrl = `${useUrl}&${qs}`;
      } else {
        useUrl = `${useUrl}?${qs}`;
      }
    }

    let response;
    try {
      response = await device.fetch(useUrl, req);
    } catch (err) {
      const msg = err instanceof Error ? err.message : "unknown error";
      ctx.logger.warn("Unable to reach server", {
        useUrl,
        err: msg,
      });
      if (!noRetry && hasNextUrl) {
        rotateUrl();
        return request(failoverUrls.length === 0);
      }
      throw err;
    }

    if (response.status === 204) {
      return null;
    }

    let body;
    try {
      body = await response.json();
    } catch (err) {
      let text;
      try {
        text = await response.text();
      } catch (e) {
        // ignore
      }
      ctx.logger.warn("Unexpected non-json response from server", {
        useUrl,
        body: text,
        statusCode: response.status,
      });
      if (!noRetry && hasNextUrl) {
        rotateUrl();
        return request(failoverUrls.length === 0);
      }
      throw err;
    }

    if (response.status === 401 || response.status === 403) {
      if (auth != null && !noRetry) {
        if (auth.refreshToken()) {
          return request(true);
        }
      }

      ctx.logger.warn("Authorization error from server", {
        useUrl,
        status: response.status,
        reasons: body?.reasons,
      });
      throw new NetworkError("Authorization error from server", {
        status: response.status,
      });
    }

    if ((options.expectedStatus != null && response.status !== options.expectedStatus) || response.status !== 200) {
      if (hasNextUrl || options.warnStatuses?.includes(response.status)) {
        ctx.logger.warn("Unexpected response code from server", {
          useUrl,
          status: response.status,
          reasons: body?.reasons,
        });
      } else {
        ctx.logger.error("Unexpected response code from server", {
          useUrl,
          status: response.status,
          reasons: body?.reasons,
        });
      }
      const e = new NetworkError("Unexpected response code from server", {
        status: response.status,
      });
      if (!noRetry && hasNextUrl) {
        rotateUrl();
        return request(failoverUrls.length === 0);
      }
      throw e;
    }

    return {
      authToken: token,
      body,
    };
  };

  return request();
};
