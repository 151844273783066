import type { IEventEmitter } from "@video/events-typed";
import { Json } from "@video/log-node";
import { Encoding } from "../../manifest";

export enum TranscodeScoreLevel {
  Lowest = "transcode:lowest",
  Low = "transcode:low",
  MediumLow = "transcode:medium-low",
  Medium = "transcode:medium",
  MediumHigh = "transcode:medium-high",
  High = "transcode:high",
  Highest = "transcode:highest",
}

export enum SourceScoreLevel {
  Low = "source:low",
  Medium = "source:medium",
  High = "source:high",
}

export enum AutoQualityLevel {
  Auto = "auto-quality",
}

export type PreferredLevel = TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel | null;

export type BitrateLayer = {
  readonly isSource: boolean;
  readonly bitrate: number;
  readonly appData?: Record<string, Json>;
  readonly encoding?: Encoding;

  /**
   * @deprecated Use `bitrate` instead
   */
  readonly maxBitrate?: number;

  readonly id: string | number;
};

export type Quality = {
  level: TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel;
  layer?: BitrateLayer;
  encoding?: Encoding;
};

export interface BitrateSwitchingEvents {
  /**
   * @description Is emitted when the array of availableQualities is set/changes.
   * @example player.on("availableQualities", (arr) => { // display available qualities})
   */
  availableQualities: Quality[];
  /**
   * @description Is emitted when the currentQuality is updated.
   * @example player.on("currentQuality", (val) => { // handle quality update})
   */
  currentQuality: Quality | null;

  /**
   * @deprecated Use `currentQuality` instead
   */
  activeLayer: BitrateLayer | null;

  /**
   * @deprecated Use `availableQualities` instead
   */
  layers: BitrateLayer[];

  blurred?: boolean;
}

export interface BitrateSwitchingFeature extends IEventEmitter<BitrateSwitchingEvents> {
  readonly availableQualities?: Quality[];
  readonly currentQuality: Quality | null;
  readonly blurred?: boolean;

  set preferredLevel(level: TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel | null);

  getClosestQuality(score: TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel): Quality | null;

  /**
   * @deprecated use `preferredLevel` setter instead
   */
  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void;
}
