/* eslint-disable no-useless-constructor,@typescript-eslint/no-this-alias */
import { EventEmitter, IEventEmitter } from "@video/events-typed";
import { LoggerCore } from "@video/log-client";
import { createAtom, IAtom, reaction, runInAction, untracked } from "mobx";
import { Disposable } from "../../../api";
import { isNamedClass } from "../logger";
import { emitterMethods, EventsHandler, originalTarget } from "./events-handler";

type SyncCallback = (...args: unknown[]) => Promise<unknown>;
type ResolveCallback = (value: PromiseLike<unknown> | unknown) => void;
type RejectCallback = (reason?: unknown) => void;

class SyncedMethod {
  private readonly stack: Array<[unknown, unknown[], ResolveCallback, RejectCallback]> = [];

  constructor(private readonly key: string | symbol, private _fn: SyncCallback | null) {}

  get wrapper(): SyncCallback {
    const self = this;
    const wrapper = function wrapper(this: unknown, ...args: unknown[]): Promise<unknown> {
      return self.apply(this, args);
    };
    Object.defineProperty(wrapper, "name", { value: `${String(this.key)}SyncWrapper` });
    return wrapper;
  }

  get fn(): SyncCallback | null {
    return this._fn;
  }

  set fn(fn: SyncCallback | null) {
    this._fn = fn;
    if (fn != null) {
      this.stack.forEach(([self, args, resolve, reject]) => {
        fn.apply(self, args).then(resolve, reject);
      });
      this.stack.splice(0, this.stack.length);
    }
  }

  postpone(self: unknown, args: unknown[]): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.stack.push([self, args, resolve, reject]);
    });
  }

  apply(self: unknown, args: unknown[]): Promise<unknown> {
    if (this.fn != null) {
      return this.fn.apply(self, args);
    }

    return this.postpone(self, args);
  }
}

type MetaProp = {
  val: unknown;
  method: SyncedMethod;
  atom: IAtom;
  changed: boolean;
  disposer?: () => void;
};

type MetaImpl<T> = {
  impl: T | null;
  atom: IAtom;
  sentWarn: boolean;
};

type MethodSpec = "stub" | "strict" | "optional" | "postpone";

export type SyncHandlerOptions<T> = {
  logger?: LoggerCore;
  methods?: Partial<Record<keyof T, MethodSpec>>;
};

export const implementation = Symbol("implementation");

export class SyncHandler<
    E extends object,
    T extends IEventEmitter<
      E & {
        implementation: void;
        attached: void;
        detached: void;
      }
    > &
      Disposable,
  >
  extends EventsHandler<E, T>
  implements ProxyHandler<T>
{
  private readonly implementation = new WeakMap<T, MetaImpl<T>>();

  private readonly syncProperties = new WeakMap<T, Map<string | symbol, MetaProp>>();

  private readonly logger: LoggerCore;

  constructor(private readonly options: SyncHandlerOptions<T> = {}) {
    super();
    this.logger = options.logger ?? new LoggerCore("SyncHandler");
  }

  private sync(target: T, meta: MetaProp, key: string | symbol, impl: T | null): void {
    if (impl != null && Reflect.get(this.options.methods ?? {}, key) === "postpone") {
      meta.method.fn = Reflect.get(impl, key) as SyncCallback;
      return;
    }

    if (impl != null && meta.changed) {
      // pass all accumulated values to new instance
      // if it was made by client
      Reflect.set(impl, key, meta.val);
    }

    // update mobx reaction if needed
    this.updateReaction(target, meta, key, impl);

    // change value and report that
    const newVal = impl == null ? undefined : Reflect.get(impl, key);
    if (!meta.changed && meta.val !== newVal) {
      meta.val = newVal;
      meta.atom.reportChanged();
    }
  }

  private updateReaction(target: T, meta: MetaProp, key: string | symbol, impl: T | null): void {
    // clean up
    meta.disposer?.();
    delete meta.disposer;

    if (impl != null) {
      // reaction can be not observable here actually
      // but we can't check that, so we'll try anyway
      meta.disposer = reaction(
        () => Reflect.get(impl, key),
        (val) => {
          // vvv disabled for now because it requires players refactoring vvv
          //
          // if (meta.changed) {
          //   const targetClass = isNamedClass(target.constructor)
          //     ? target.constructor.displayName
          //     : target.constructor.name;
          //   const proxyClass = isNamedClass(impl.constructor) ? impl.constructor.displayName : impl.constructor.name;
          //   this.logger.warn("Attempts to update the value which was changed by implementer", {
          //     targetClass,
          //     proxyClass,
          //     prop: String(key),
          //   });
          //   // to prevent spreading this value
          //   // to another implementations
          //   meta.changed = false;
          //   Reflect.set(impl, key, meta.val);
          //   return;
          // }
          meta.val = val;
          meta.atom.reportChanged();
        },
        { name: `proxy:${String(key)}` },
      );
    }
  }

  private propBecomeObservable(target: T, key: string | symbol): void {
    const meta = this.getOrCrateMeta(target, key);
    this.updateReaction(target, meta, key, this.implementation.get(target)?.impl ?? null);
  }

  private propBecomeUnobservable(target: T, key: string | symbol): void {
    const meta = this.getOrCrateMeta(target, key);
    meta.disposer?.();
    delete meta.disposer;
  }

  private getOrCrateMeta(target: T, key: string | symbol): MetaProp {
    if (!this.syncProperties.has(target)) {
      this.syncProperties.set(target, new Map<string | symbol, MetaProp>());
    }
    let obj = this.syncProperties.get(target)?.get(key);
    if (obj == null) {
      const impl = this.implementation.get(target)?.impl;
      const implVal = impl == null ? undefined : (untracked(() => Reflect.get(impl, key)) as SyncCallback);
      obj = {
        // use `untracked` way to get this value
        // because we don't want to trigger autorun twice
        val: implVal,
        atom: createAtom(
          `proxy:${String(key)}`,

          this.propBecomeObservable.bind(this, target, key),
          this.propBecomeUnobservable.bind(this, target, key),
        ),
        changed: false,
        method: new SyncedMethod(key, implVal ?? null),
      };
      this.syncProperties.get(target)?.set(key, obj);
    }
    return obj;
  }

  private getObservedProp(target: T, key: string | symbol): unknown {
    const meta = this.getOrCrateMeta(target, key);

    meta.atom.reportObserved();
    return meta.val;
  }

  private deleteObservedProp(target: T, key: string | symbol): unknown {
    const meta = this.getOrCrateMeta(target, key);
    const impl = this.implementation.get(target)?.impl;

    meta.val = impl == null ? undefined : Reflect.get(impl, key);
    meta.changed = false;
    meta.atom.reportObserved();
    return meta.val;
  }

  private reportObservedProp(target: T, key: string | symbol, val: unknown): void {
    const meta = this.getOrCrateMeta(target, key);

    meta.val = val;
    meta.changed = true;

    meta.atom.reportChanged();
  }

  private getObservableImplementation(target: T): T | null {
    let implMeta = this.implementation.get(target);
    if (implMeta == null) {
      implMeta = { atom: createAtom("proxy:implementation"), impl: null, sentWarn: false };
      this.implementation.set(target, implMeta);
    }

    if (
      !implMeta.atom.reportObserved() &&
      EventEmitter.listenerCount(target, "implementation") === 0 &&
      !implMeta.sentWarn
    ) {
      // vvv disabled for now because it requires players refactoring vvv
      //
      // const klass = isNamedClass(target.constructor) ? target.constructor.displayName : target.constructor.name;
      // this.logger.warn(
      //   "Warning! Attempt to use an implementation check in non-observable context and without listening `implementation`" +
      //     " event. The object may not reflect correct properties at the time. Please consider to use " +
      //     `\`autorun(() => ... )\` from MobX or \`obj.on('implementation', () => ... )\` event. Class: ${klass}`,
      // );
      implMeta.sentWarn = true;
    }

    return implMeta.impl;
  }

  private setImplementation(target: T, impl: T | null): void {
    for (const [key, meta] of this.syncProperties.get(target)?.entries() ?? []) {
      // we need to recreate reactions for all keys
      // so it will request changes from new impl after that
      // and update current values
      this.sync(target, meta, key, impl);
    }

    // notify mobx that implementationAtom has changed
    let implMeta = this.implementation.get(target);
    if (implMeta == null) {
      implMeta = {
        impl: null,
        atom: createAtom("proxy:implementation"),
        sentWarn: false,
      };
      this.implementation.set(target, implMeta);
    }
    // remove all events from old implementations and add it to new one
    if (implMeta.impl != null) {
      implMeta.impl?.emit("detached");
      super.removeProxy(target, implMeta.impl);
    }
    if (impl != null) {
      super.addProxy(target, impl);
    }
    //
    // impl?.once("disposed", () => {
    //   setTimeout(() => {
    //     if (impl === this.implementation.get(target)?.impl) {
    //       this.setImplementation(target, null);
    //     }
    //   });
    // });

    implMeta.impl = impl;
    implMeta.atom.reportChanged();
    target.emit("implementation");
    if (impl != null) {
      impl?.emit("attached");
    }
  }

  private getImplMethod(target: T, key: string | symbol): undefined | (() => void) {
    const impl = this.implementation.get(target)?.impl;
    if (impl != null) {
      const implMethod = Reflect.get(impl, key);
      if (implMethod != null && typeof implMethod === "function") {
        return implMethod as SyncCallback;
      }
    }
    return undefined;
  }

  get(target: T, key: string | symbol, receiver: unknown): unknown {
    if (key === originalTarget) {
      return target;
    }

    if (key === implementation) {
      return this.getObservableImplementation(target);
    }

    if (emitterMethods.has(key)) {
      const method = emitterMethods.get(key);
      if (method != null) {
        return this[method].bind(this, target);
      }
    }

    if (this.options.methods != null && key in this.options.methods) {
      const method = this.getImplMethod(target, key);
      switch (this.options.methods[key as keyof T]) {
        case "stub":
          return method ?? (() => undefined);
        case "optional":
          return method;
        case "strict":
          if (method == null) {
            throw new Error(`No implementations of method '${String(key)}' found`);
          }
          return method;
        case "postpone":
          return this.getOrCrateMeta(target, key).method.wrapper;
        default:
      }
    }

    // do not override existing props behaviour
    if (Reflect.has(target, key)) {
      return Reflect.get(target, key, receiver);
    }

    return this.getObservedProp(target, key);
  }

  set(target: T, prop: string | symbol, value: unknown, receiver: unknown): boolean {
    if (prop === implementation) {
      runInAction(() => {
        this.setImplementation(target, value as T);
      });
      return true;
    }

    // do not override existing props behaviour
    if (Reflect.has(target, prop)) {
      const result = Reflect.set(target, prop, value, receiver);
      if (!result) {
        const klass = isNamedClass(target.constructor) ? target.constructor.displayName : target.constructor.name;
        this.logger.warn(`Property '${String(prop)}' of class '${klass}' is not writable`);
      }
      return true;
    }

    // always store incoming value in lazy properties
    this.reportObservedProp(target, prop, value);

    // pass to target
    const implMeta = this.implementation.get(target);
    if (implMeta?.impl != null) {
      const result = Reflect.set(implMeta.impl, prop, value);
      if (!result) {
        const klass = isNamedClass(implMeta.impl.constructor)
          ? implMeta.impl.constructor.displayName
          : implMeta.impl.constructor.name;
        this.logger.warn(`Property '${String(prop)}' of class '${klass}' is not writable`);
      }
      return true;
    }

    return true;
  }

  deleteProperty(target: T, key: string | symbol): boolean {
    if (key === implementation) {
      this.setImplementation(target, null);
      return true;
    }

    // do not override existing props behaviour
    if (Reflect.has(target, key)) {
      return Reflect.deleteProperty(target, key);
    }

    this.deleteObservedProp(target, key);
    return true;
  }
}
