import { RootStyles, StylesObject } from "../typings/css";

export interface AlertStyles extends RootStyles {
  inner?: StylesObject;
  closeButton?: StylesObject;
}

const styles: AlertStyles = {
  root: {
    alignItems: "center",
    backgroundColor: "#F8FA8F",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0.5rem auto",
    width: "100%",
    position: "absolute",
    borderRadius: "4px",
    fontFamily: "sans-serif",
    fontSize: "1.5rem",
    overflow: "hidden",
    transition: "all .5s ease-in-out",
    height: "0",
    "&.open": {
      height: "auto",
      zIndex: "200",
    },
  },
  inner: {
    padding: "1rem",
  },
  closeButton: {
    width: "10px",
    position: "absolute",
    right: "0",
    top: "0",
    margin: "10px",
  },
};

export default styles;
