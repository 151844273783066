import { LoggerCore } from "@video/log-client";
import type { VideoClient } from "../../video-client";
import type { Support } from "../support";
import type { Context } from "./context";

export type VcContext = Context<{
  logger: LoggerCore;
  support: Support;
  videoClient: VideoClient;
  statsDebugLogs?: boolean;
  chain?: string;
}>;

export function hasVcContext(val: unknown): val is { ctx: VcContext } {
  const obj = val as { ctx: VideoClient };
  if (typeof obj !== "object" || !("ctx" in obj)) {
    return false;
  }
  const ctx = obj.ctx as unknown as VcContext;

  return (
    typeof ctx === "object" &&
    typeof ctx.logger === "object" &&
    typeof ctx.support === "object" &&
    typeof ctx.videoClient === "object"
  );
}
