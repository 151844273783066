import { types } from "@video/video-client-core";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext, useEffect, useRef } from "react";
import { CallState } from "../../../../../../store/call";
import { EncoderUiState } from "../../../../../../store/encoder";
import { CallContext, EncoderUiContext, VideoClientContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface JoinBroadcastButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface JoinBroadcastButtonProps extends Partial<ButtonIconProps> {
  broadcastOptions: types.BroadcastOptions;
  classes?: JoinBroadcastButtonClasses;
  setCallId?: (id: string | null) => void;
  callId?: string | null;
  onZeroBitrate?: (ev: types.BroadcastEvents["zeroBitrate"]) => void;
  setOwnerCall?: (call: types.CallAPI | null) => void;
  setCall?: (call: types.CallAPI | null) => void;
  clientReferrer?: string;
  sfuOptions?: types.SFUOptions;
  videoUserId?: string;
}

const ModularJoinBroadcastButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    screenReaderText,
    broadcastOptions,
    onZeroBitrate,
    active,
    classes = {
      root: {},
      icon: {},
    },
    label = "Stream Broadcast Toggle Button",
    setCallId,
    callId,
    setOwnerCall,
    setCall,
    clientReferrer,
    sfuOptions,
    videoUserId,
    ...props
  }: JoinBroadcastButtonProps): ReactElement => {
    const componentName = "<BroadcastButton/>";

    /**
     * Access EncoderUiContext & destructure API state
     */
    const encoderCtx = useContext<EncoderUiState | null>(EncoderUiContext);

    const callCtx = useContext<CallState | null>(CallContext);
    /**
     * Grab the videoClient supplied from the encoder.
     */
    const videoClient = useContext<types.VideoClientAPI | null>(VideoClientContext);

    const isTogglingBroadcast = useRef<boolean>(false);

    useUndefinedStoreError(
      encoderCtx?.mediaStreamController != null && videoClient != null && callCtx != null,
      componentName,
    );

    useEffect(() => {
      if (onZeroBitrate) {
        callCtx.zeroBitrateHandler.push(onZeroBitrate);
      }
      return () => {
        callCtx.zeroBitrateHandler = callCtx.zeroBitrateHandler.filter((h) => h !== onZeroBitrate);
      };
    }, [callCtx, onZeroBitrate]);

    useEffect(() => {
      reaction(
        () => callCtx.call,
        (newCall, oldCall) => {
          setCall?.(newCall);
          setCallId?.(newCall?.id ?? null);
          if (newCall?.isOwner) {
            setOwnerCall?.(newCall);
          }
        },
      );
    }, [callCtx, setCall, setCallId, setOwnerCall]);

    const activeState = active ?? callCtx.isBroadcasting;

    const callOptions = {
      userId: videoUserId,
      clientReferrer,
      sfu: sfuOptions,
    };

    async function handleJoinBroadcast(): Promise<void> {
      if (encoderCtx && videoClient && callCtx && !isTogglingBroadcast.current) {
        isTogglingBroadcast.current = true;
        await callCtx.toggleBroadcast(
          callId ?? callOptions,
          broadcastOptions,
          encoderCtx.mediaStreamController,
          videoClient,
        );
        isTogglingBroadcast.current = false;
      }
    }

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick ?? useUIEventError(handleJoinBroadcast, componentName);

    const icon = ProvidedIcon ?? <Icon iconName="broadcast" classes={classes?.icon} />;

    return (
      <ButtonIcon
        active={activeState}
        activeClass="video-button--active lv-button--active"
        classes={classes}
        inactiveClass={null}
        data-selenium="broadcast-stream-button"
        icon={icon}
        onClick={handleClick}
        disabled={
          encoderCtx.mediaStreamController.videoDeviceId == null &&
          encoderCtx.mediaStreamController.audioDeviceId == null
        }
        label={label}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Stop" : "Start"} Broadcast`}
      </ButtonIcon>
    );
  },
);

/**
 *
 * @visibleName Broadcast Button
 */
const JoinBroadcastButtonWithErrorBoundary: FC<JoinBroadcastButtonProps> = ({
  icon: ProvidedIcon,
  label = "Stream Broadcast Toggle Button",
  screenReaderText,
  broadcastOptions,
  classes,
  ...props
}) => {
  const icon = <Icon iconName="broadcast" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="broadcast-stream-button"
          disabled
          classes={classes}
          icon={ProvidedIcon ?? icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to start Broadcast"}
        </ButtonIcon>
      )}
    >
      <ModularJoinBroadcastButton
        icon={ProvidedIcon}
        classes={classes}
        label={label}
        screenReaderText={screenReaderText}
        broadcastOptions={broadcastOptions}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default JoinBroadcastButtonWithErrorBoundary;
