import { StatsObject, TimelineEvent, TrackReport, WebRTCStats } from "@video/webrtc-stats";
import { types } from "mediasoup-client";
import { device, Feature } from "../../../api/adapter";
import {
  CombinedStats,
  ConsumerStats,
  ProducerStats,
  StatsCollectorEvents,
  StatsCollectorOptions,
} from "../../../api/stats";
import { onceCanceled } from "../../utils/context/context";
import { VcContext } from "../../utils/context/vc-context";
import { ObservableEventEmitter } from "../../utils/events/event-emitter";

function findBestIdx(reports: TrackReport[]): number {
  if (reports.length < 2) {
    return 0;
  }

  let idx = 0;
  let bitrate = 0;
  reports.forEach((r, i) => {
    const b = r.bitrate ?? 0;
    if (b > bitrate) {
      idx = i;
      bitrate = b;
    }
  });
  return idx;
}

function computeConsumerFractionLost(stats: CombinedStats, kind: "video" | "audio"): number {
  const localInbound = stats.current[kind]?.inbound[0];
  const localPrevInbound = stats.previous[kind]?.inbound[0];
  if (localInbound == null || localPrevInbound == null) {
    return 0;
  }

  if (localInbound.fractionLost != null) {
    return localInbound.fractionLost;
  }

  const remoteOutbound = stats.current.remote?.[kind]?.outbound[0];
  const remotePrevOutbound = stats.previous.remote?.[kind]?.outbound[0];
  if (remoteOutbound == null || remotePrevOutbound == null) {
    return 0;
  }

  const packetsSent = remoteOutbound.packetsSent - remotePrevOutbound.packetsSent;
  let lostPackets = 0;

  if (localPrevInbound.packetsReceived == null || localInbound.packetsReceived == null) {
    return 0;
  }

  if (localInbound.packetsLost !== undefined) {
    lostPackets = localInbound.packetsLost - localPrevInbound.packetsLost;
    return packetsSent > 0 ? lostPackets / packetsSent : 0;
  }
  return 0;
}

function distinct(report: StatsObject, cb: (s: TrackReport) => string): string[] {
  const res = new Set<string>();
  const cond = (r: TrackReport): void => {
    const val = cb(r).toString();
    if (val !== "") {
      res.add(val);
    }
  };

  report.video.inbound.forEach(cond);
  report.video.outbound.forEach(cond);
  report.audio.inbound.forEach(cond);
  report.video.outbound.forEach(cond);

  return Array.from(res.values());
}

function filterReports(report: StatsObject | null, val: unknown, cb: (s: TrackReport) => string): StatsObject {
  if (report) {
    return {
      connection: report.connection,
      video: {
        outbound: report.video.outbound.filter((r: any) => cb(r) === val),
        inbound: report.video.inbound.filter((r: any) => cb(r) === val),
      },
      audio: {
        outbound: report.audio.outbound.filter((r: any) => cb(r) === val),
        inbound: report.audio.inbound.filter((r: any) => cb(r) === val),
      },
      remote: {
        video: {
          outbound: report.remote?.video.outbound.filter((r: any) => cb(r) === val) ?? [],
          inbound: report.remote?.video.inbound.filter((r: any) => cb(r) === val) ?? [],
        },
        audio: {
          outbound: report.remote?.audio.outbound.filter((r: any) => cb(r) === val) ?? [],
          inbound: report.remote?.audio.inbound.filter((r: any) => cb(r) === val) ?? [],
        },
      },
    };
  }
  return {
    connection: null,
    video: {
      outbound: [],
      inbound: [],
    },
    audio: {
      outbound: [],
      inbound: [],
    },
    remote: {
      video: {
        outbound: [],
        inbound: [],
      },
      audio: {
        outbound: [],
        inbound: [],
      },
    },
  };
}

function groupBy(
  current: StatsObject,
  previous: StatsObject | null,
  cb: (s: TrackReport) => string,
): Record<string, CombinedStats> {
  const res: Record<string, CombinedStats> = {};
  const uniq = distinct(current, cb);

  for (const val of uniq) {
    res[val] = {
      current: filterReports(current, val, cb),
      previous: filterReports(previous, val, cb),
    };
  }

  return res;
}

export class StatsCollector extends ObservableEventEmitter<StatsCollectorEvents> {
  static readonly displayName = "StatsCollector";

  private readonly webrtcStats: WebRTCStats;

  private readonly options: StatsCollectorOptions;

  private readonly ctx: VcContext;

  constructor(ctx: VcContext, options: StatsCollectorOptions) {
    super();

    onceCanceled(ctx).then(this.dispose);

    this.options = options;
    if (this.options.interval < 1000) {
      this.options.interval = 5000;
    }
    this.ctx = ctx;
    this.webrtcStats = new WebRTCStats({
      getStatsInterval: this.options.interval,
    });

    if (device.isImplements(Feature.CPU_USAGE)) {
      device.enableCpuStats();
    }

    this.addInnerDisposer(() => {
      this.webrtcStats.removeAllListeners();
    });
  }

  attachTransports(peerId: string, send: types.Transport | null, recv: types.Transport | null): void {
    if (recv != null && recv.direction !== "recv") {
      this.ctx.logger.error("Wrong transport direction for a consumer", {
        direction: recv.direction,
      });
      return;
    }

    if (send != null && send.direction !== "send") {
      this.ctx.logger.error("Wrong transport direction for a consumer", {
        direction: send.direction,
      });
      return;
    }

    this.webrtcStats.addPeer({
      sendTransport: send ?? undefined,
      recvTransport: recv ?? undefined,
      peerId,
    });

    this.webrtcStats.removeAllListeners("stats");
    this.webrtcStats.on("stats", this.collectStats);
  }

  private collectStats(ev: TimelineEvent): void {
    if (ev.peerId == null) {
      this.ctx.logger.warn("peerId not found", { peerId: ev.peerId });
      return;
    }

    const current: StatsObject = ev.data;
    const previous: StatsObject = ev.prev;

    const grouped = groupBy(current, previous, (t) => `${t.appData.peerId}:${t.appData.streamName}`);

    for (const [, stats] of Object.entries(grouped)) {
      if (stats.current.video.outbound.length > 0 || stats.current.audio.outbound.length > 0) {
        this.emitProducer(ev, stats);
      }
      if (stats.current.video.inbound.length > 0 || stats.current.audio.inbound.length > 0) {
        this.emitConsumer(ev, stats);
      }
    }
  }

  private emitProducer(ev: TimelineEvent, stats: CombinedStats): void {
    const vidx = findBestIdx(stats.current.video.outbound);
    const aidx = findBestIdx(stats.current.audio.outbound);
    // best bitrate has changed, so skip this to avoid spikes
    if (vidx !== findBestIdx(stats.previous.video.outbound) || aidx !== findBestIdx(stats.previous.audio.outbound)) {
      return;
    }

    const videoCurLoc = stats.current.video.outbound[vidx] ?? {};
    const audioCurLoc = stats.current.audio.outbound[aidx] ?? {};
    const appData = videoCurLoc.appData ?? audioCurLoc.appData ?? {};

    const videoPrevLoc = stats.previous.video.outbound[vidx] ?? {};
    const audioPrevLoc = stats.previous.audio.outbound[aidx] ?? {};

    const rtpFps = videoCurLoc.framesPerSecond ?? 0;
    const mediaFps = videoCurLoc.track?.frameRate ?? 0;
    const mediaHeight = videoCurLoc.track?.height ?? 0;
    const mediaWidth = videoCurLoc.track?.width ?? 0;
    const diffTotalEncodeTime = (videoCurLoc.totalEncodeTime ?? 0) - (videoPrevLoc.totalEncodeTime ?? 0);
    const diffTotalEncodedBytesTarget =
      (videoCurLoc.totalEncodedBytesTarget ?? 0) - (videoPrevLoc.totalEncodedBytesTarget ?? 0);
    const diffAudioPacketsSent = (audioCurLoc.packetsSent ?? 0) - (audioPrevLoc.packetsSent ?? 0);
    const diffVideoPacketsSent = (videoCurLoc.packetsSent ?? 0) - (videoPrevLoc.packetsSent ?? 0);
    const diffAudioRetransmittedPacketsSent =
      (audioCurLoc.retransmittedPacketsSent ?? 0) - (audioPrevLoc.retransmittedPacketsSent ?? 0);
    const diffVideoRetransmittedPacketsSent =
      (videoCurLoc.retransmittedPacketsSent ?? 0) - (videoPrevLoc.retransmittedPacketsSent ?? 0);

    const result: ProducerStats = {
      cpu: device.isImplements(Feature.CPU_USAGE) ? device.averageCpuUsage(this.options.interval) : 0,
      peerId: ev.peerId ?? "NA",
      callId: this.options.callId,
      userId: this.options.userId ?? "NA",
      displayName: this.options.displayName ?? "NA",
      streamName: appData.streamName,
      studioId: appData.studioId,
      kind: "producer",
      localCandidate_networkType: stats.current.connection?.local?.networkType ?? "NA",
      transport_transportStatus: stats.current.connection?.state ?? "NA",
      videoFrameHeight: videoCurLoc.frameHeight ?? 0,
      videoFrameWidth: videoCurLoc.frameWidth ?? 0,
      videoFramesPerSecond: rtpFps,
      videoBitrate: videoCurLoc.bitrate == null || videoCurLoc.bitrate < 0 ? 0 : Math.trunc(videoCurLoc.bitrate),
      audioBitrate: audioCurLoc.bitrate == null || audioCurLoc.bitrate < 0 ? 0 : Math.trunc(audioCurLoc.bitrate),
      videoPacketSendDelay: (videoCurLoc.totalPacketSendDelay ?? 0) - (videoPrevLoc.totalPacketSendDelay ?? 0),
      videoRetransmittedPacketsSent: diffVideoPacketsSent
        ? (diffVideoRetransmittedPacketsSent / diffVideoPacketsSent) * 100_000
        : 0,
      videoMediaSourceFrameHeight: videoCurLoc.track?.height ?? 0,
      videoMediaSourceFrameWidth: videoCurLoc.track?.width ?? 0,
      videoMediaSourceFramesPerSecond: mediaFps,
      videoOutboundRtpQualityLimitationReason: videoCurLoc?.qualityLimitationReason ?? "NA",
      videoOutboundRtpQualityLimitationResolutionChanges:
        (videoCurLoc.qualityLimitationResolutionChanges ?? 0) - (videoPrevLoc.qualityLimitationResolutionChanges ?? 0),
      videoCalculationFpsSourceToOutput: mediaFps > 0 ? rtpFps / mediaFps : 0,
      videoCalculationHeightSourceToOutput: mediaHeight > 0 ? (videoCurLoc.frameHeight ?? 0) / mediaHeight : 0,
      videoCalculationWidthSourceToOutput: mediaWidth > 0 ? (videoCurLoc.frameWidth ?? 0) / mediaWidth : 0,
      videoOutboundRtpEncodeTime: diffTotalEncodeTime / (this.options.interval / 1000),
      videoOutboundRtpEncodedBytesTarget: diffTotalEncodedBytesTarget / (this.options.interval / 1000),
      audioPacketSendDelay: (audioCurLoc.totalPacketSendDelay ?? 0) - (audioPrevLoc.totalPacketSendDelay ?? 0),
      audioRetransmittedPacketsSent: diffAudioPacketsSent
        ? (diffAudioRetransmittedPacketsSent / diffAudioPacketsSent) * 100_000
        : 0,
    };

    this.emit("webrtc-stats-raw", stats);
    this.emit("webrtc-stats", result);
  }

  private emitConsumer(ev: TimelineEvent, stats: CombinedStats): void {
    const vidx = findBestIdx(stats.current.video.inbound);
    const aidx = findBestIdx(stats.current.audio.inbound);
    // best bitrate has changed, so skip this to avoid spikes
    if (vidx !== findBestIdx(stats.previous.video.inbound) || aidx !== findBestIdx(stats.previous.audio.inbound)) {
      return;
    }

    const videoCurLoc = stats.current.video.inbound[vidx] ?? {};
    const audioCurLoc = stats.current.audio.inbound[aidx] ?? {};
    const videoCurRem = stats.current.remote?.video.outbound[vidx] ?? {};
    const audioCurRem = stats.current.remote?.audio.outbound[aidx] ?? {};
    const appData = videoCurLoc.appData ?? audioCurLoc.appData ?? {};

    const rtpFps = videoCurLoc.framesPerSecond ?? 0;

    const result: ConsumerStats = {
      cpu: device.isImplements(Feature.CPU_USAGE) ? device.averageCpuUsage(this.options.interval) : 0,
      peerId: ev.peerId ?? "NA",
      callId: this.options.callId,
      userId: this.options.userId ?? "NA",
      displayName: this.options.displayName ?? "NA",
      streamName: appData.streamName,
      studioId: appData.studioId,
      kind: "consumer",
      localCandidate_networkType: stats.current.connection?.local?.networkType ?? "NA",
      transport_transportStatus: stats.current.connection?.state ?? "NA",
      videoFrameHeight: videoCurLoc.frameHeight ?? 0,
      videoFrameWidth: videoCurLoc.frameWidth ?? 0,
      videoFramesPerSecond: rtpFps,
      videoBytesReceived: videoCurLoc.bytesReceived ?? 0,
      videoBitrate: videoCurLoc.bitrate == null || videoCurLoc.bitrate < 0 ? 0 : Math.trunc(videoCurLoc.bitrate),
      audioBitrate: audioCurLoc.bitrate == null || audioCurLoc.bitrate < 0 ? 0 : Math.trunc(audioCurLoc.bitrate),

      producingUserId: appData.userId,
      producingPeerId: appData.peerId,
      videoFractionLost: computeConsumerFractionLost(stats, "video"),
      videoRoundTripTime:
        stats.current.connection?.currentRoundTripTime ??
        stats.current.connection?.currentRtt ??
        videoCurRem.roundTripTime ??
        0,
      audioBytesReceived: audioCurLoc.bytesReceived ?? 0,
      audioFractionLost: computeConsumerFractionLost(stats, "audio"),
      audioRoundTripTime:
        stats.current.connection?.currentRoundTripTime ??
        stats.current.connection?.currentRtt ??
        audioCurRem.roundTripTime ??
        0,
      audioJitter: audioCurLoc.jitter ?? 0,
      audioPacketsReceived: audioCurLoc.packetsReceived ?? 0,
      audioPacketsLost: audioCurLoc.packetsLost ?? 0,
    };

    this.emit("webrtc-stats-raw", stats);
    this.emit("webrtc-stats", result);
  }
}
