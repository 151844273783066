import { LoggerCore } from "@video/log-client";
import { contextId, instanceId, player, types } from "@video/video-client-core";
import { computed, makeObservable, observable } from "mobx";
import currentPackage from "../../package-json";
import { onlineWatcher } from "../utils/online-watcher";
import BaseUiState, { BaseUiStateEvents } from "../utils/ui-state";
import { availableBitrateHelper } from "./utils";
import type { BitrateHelper } from "./utils";

export interface PlayerUIOptions {
  autoPlay?: boolean;
  muted?: boolean;
  volume?: number;
  logger?: LoggerCore;
  mutedAutoplayFallback?: boolean;
}

export interface PlayerUiStateEvents extends BaseUiStateEvents {
  /**
   * @description Is emitted when the broadcaster has muted/unmuted their audio.
   * @example playerUiState.on("encoderMuted", (bool) => { if(bool) { // show a muted icon } })
   */
  encoderMuted: boolean;
  /**
   * @deprecated Use playerUiState.player.consumerVideoEnabled
   * @description Is emitted when the broadcaster has turned off/on their video.
   * @example playerUiState.on("encoderNoVideo", (bool) => { if(bool) { // show a video poster } })
   */
  encoderNoVideo: boolean;

  /**
   * @description Is emitted when the user hovers mouse over/off the video element.
   * @example playerUiState.on("videoMouseOver", (bool) => { if(bool) { // show controls} })
   */
  videoMouseOver: boolean;

  // MobX Events
  availableQualities: PlayerUiState["availableQualities"];

  currentPlayer: PlayerUiState["currentPlayer"];
  availablePlayers: PlayerUiState["availablePlayers"];
  viewGetSound: PlayerUiState["viewGetSound"];
  qualityMouseOver: PlayerUiState["qualityMouseOver"];
}
export default class PlayerUiState extends BaseUiState<PlayerUiStateEvents> {
  static readonly displayName = "PlayerUiState";

  readonly player: types.PlayerAPI;

  constructor(basePlayer: types.PlayerAPI, options?: PlayerUIOptions) {
    super();

    makeObservable(this, {
      // observers
      viewGetSound: observable,
      qualityMouseOver: observable,

      // computed
      encoderMuted: computed,
      encoderNoVideo: computed,
      availableBitrates: computed,
    });

    this._options = options;

    this.player = basePlayer;

    if (this.player.logger != null) {
      this.logger = new LoggerCore("VDC-web")
        .extend(this.player.logger)
        .setMessageAggregate("chain", `Player:${PlayerUiState.displayName}`);
    } else {
      this.logger = new LoggerCore("VDC-web")
        .setLoggerMeta("client", "VDC")
        .setLoggerMeta("chain", "PlayerUiState")
        .setLoggerMeta("release", currentPackage.version)
        .setMessageAggregate("contextId", contextId() ?? undefined)
        .setMessageAggregate("instanceId", instanceId() ?? undefined);
    }

    // backwards compatibility
    this.player.on("consumerVideoEnabled", (val) => this.emit("encoderNoVideo", !val));
    this.player.on("consumerAudioEnabled", (val) => this.emit("encoderMuted", !val));

    this.addInnerDisposer(this.player);
    this.addInnerDisposer(onlineWatcher(this.logger));
  }

  get availableQualities(): types.Quality[] {
    if (this.player.isImplements(player.Feature.BITRATE_SWITCHING)) {
      return this.player.availableQualities ?? [];
    }
    return [];
  }

  get availableBitrates(): BitrateHelper[] {
    if (this.player.isImplements(player.Feature.BITRATE_SWITCHING) && this.player.availableQualities != null) {
      return availableBitrateHelper(this.player.availableQualities);
    }
    return [];
  }

  get availablePlayers(): types.PlayerAnySpec[] {
    if (this.player.isImplements(player.Feature.PLAYER_SELECTOR)) {
      return this.player.availablePlayers ?? [];
    }
    return [];
  }

  readonly _options: PlayerUIOptions | undefined;

  /**
   * Creating our logger core
   */
  logger: LoggerCore;

  /**
   * @deprecated.
   */
  get currentPlayer(): types.PlayerAPI | null | undefined {
    return this.player;
  }

  /**
   * Indicates whether the get sound element(s)  is/are visible.
   */
  viewGetSound = true;

  /**
   * @deprecated Use playerUiState.player.consumerAudioEnabled
   */
  get encoderMuted(): boolean | null {
    return this.player.isImplements(player.Feature.CONSUMER) ? !this.player.consumerAudioEnabled : null;
  }

  /**
   * @deprecated Use playerUiState.player.consumerVideoEnabled
   */
  get encoderNoVideo(): boolean | null {
    return this.player.isImplements(player.Feature.CONSUMER) ? !this.player.consumerVideoEnabled : null;
  }

  /**
   * Watches for mouse over on the qualitySelect modal
   */
  qualityMouseOver = false;

  get showToolbar(): boolean {
    const video = this.videoMouseOver;
    const control = this.controlMouseOver;
    const quality = this.qualityMouseOver;
    return video || control || quality;
  }
}
