/* eslint-disable import/no-cycle */
import { EventEmitter, IEventEmitter } from "@video/events-typed";
import { Disposable, Merge } from "../../../api";

type MetaEvents<T> = {
  type: keyof T;
  listener: () => void;
};

const denyList: PropertyKey[] = ["disposed"];

type ListenerMethods = "addListener" | "prependListener" | "removeListener";

export const emitterMethods = new Map<string | symbol, ListenerMethods>(
  Object.entries({
    addListener: "addListener",
    on: "addListener",
    prependListener: "prependListener",
    removeListener: "removeListener",
    off: "removeListener",
  }),
);

export const addProxy = Symbol("AddProxy");

export const removeProxy = Symbol("RemoveProxy");

export const originalTarget = Symbol("originalTarget");

export interface HasTarget<T> {
  [originalTarget]?: T | undefined;
}

function hasMaxListeners(obj: IEventEmitter<unknown>): obj is EventEmitter {
  return "setMaxListeners" in obj && "getMaxListeners" in obj;
}

export class EventsHandler<E extends object, T extends Merge<IEventEmitter<E>, Disposable> & HasTarget<T>>
  implements ProxyHandler<T>
{
  private readonly proxies = new WeakMap<T, Set<Merge<IEventEmitter<E>, Disposable>>>();

  private readonly proxyEvents = new WeakMap<T, MetaEvents<E>[]>();

  private getOrCreateEvent(target: T): MetaEvents<E>[] {
    let obj = this.proxyEvents.get(target);
    if (obj == null) {
      obj = [];
      this.proxyEvents.set(target, obj);
    }
    return obj;
  }

  protected addProxy(target: T, impl: Merge<IEventEmitter<E>, Disposable>): void {
    if (hasMaxListeners(target) && hasMaxListeners(impl)) {
      const n = target.getMaxListeners();
      if (impl.getMaxListeners() < n) {
        impl.setMaxListeners(n);
      }
    }

    for (const proxyEvent of this.proxyEvents.get(target) ?? []) {
      // remove all events from old implementations and add it to new one
      impl.addListener(proxyEvent.type, proxyEvent.listener);
    }

    impl.once("disposed", this.removeProxy.bind(this, target, impl));

    if (!this.proxies.has(target)) {
      this.proxies.set(target, new Set());
    }
    this.proxies.get(target)?.add(impl);
  }

  protected removeProxy(target: T, impl: Merge<IEventEmitter<E>, Disposable>): void {
    for (const proxyEvent of this.proxyEvents.get(target) ?? []) {
      // simply unsubscribe and remove form proxies
      impl.removeListener(proxyEvent.type, proxyEvent.listener);
    }

    const proxies = this.proxies.get(target);
    if (proxies != null) {
      proxies.delete(impl);
    }
  }

  addListener(target: T, type: keyof E, listener: () => void): void {
    const impls = this.proxies.get(target) ?? [];
    const events = this.getOrCreateEvent(target);
    events.push({ type, listener });
    target.addListener(type, listener);

    if (!denyList.includes(type)) {
      for (const impl of impls) {
        impl.addListener(type, listener);
      }
    }
  }

  prependListener(target: T, type: keyof E, listener: () => void): void {
    const impls = this.proxies.get(target) ?? [];
    const events = this.getOrCreateEvent(target);
    events.unshift({ type, listener });
    target.prependListener(type, listener);

    if (!denyList.includes(type)) {
      for (const impl of impls) {
        impl.prependListener(type, listener);
      }
    }
  }

  removeListener(target: T, type: keyof E, listener: () => void): void {
    const impls = this.proxies.get(target) ?? [];
    const events = this.getOrCreateEvent(target);
    for (let i = 0; i < events.length; i++) {
      if (type === events[i].type && listener === events[i].listener) {
        events.splice(i, 1);
      }
    }
    target.removeListener(type, listener);

    if (!denyList.includes(type)) {
      for (const impl of impls) {
        impl.removeListener(type, listener);
      }
    }
  }

  get(target: T, key: string | symbol, receiver: unknown): unknown {
    if (key === originalTarget) {
      return target;
    }

    if (key === addProxy) {
      return this.addProxy.bind(this, target);
    }

    if (key === removeProxy) {
      return this.removeProxy.bind(this, target);
    }

    if (emitterMethods.has(key)) {
      const method = emitterMethods.get(key);
      if (method != null) {
        return this[method].bind(this, target);
      }
    }

    return Reflect.get(target, key, receiver);
  }
}
