import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../store/encoder";
import { withSilenceBackground } from "../../../../store/encoder/silence-background";
import { EncoderUiContext } from "../../../context";
import { useStyles } from "../../../styling";
import PlayerOverlayButton from "../../../ui-lib/PlayerOverlayButton";
import VideoWrapper, { VideoWrapperProps } from "../../../ui-lib/VideoWrapper";
import { videoWrapperStyles } from "../../../ui-lib/VideoWrapper/styles";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";
import { TempVideo } from "./TempVideo";

const ModularVideo = observer(({ classes, backgroundAudio, ...props }: Partial<VideoWrapperProps>) => {
  const ctx = useContext<EncoderUiState | null>(EncoderUiContext);

  useUndefinedStoreError(ctx?.videoElement != null, "<EncoderVideo/>");

  const styling = videoWrapperStyles(
    props.pillarBox ?? false,
    props.isFullScreen,
    props.mirror,
    props.elementBlur,
    props.blurPixels,
  );

  const mergedClasses = useStyles({ source: classes ?? {}, target: styling }, "videoWrapper");

  const mediaStream =
    backgroundAudio && !ctx.testMic
      ? withSilenceBackground(ctx.audioCtx, ctx.mediaStreamController.source as MediaStream)
      : ctx.mediaStreamController.source;

  return (
    <VideoWrapper classes={classes} isFullScreen={ctx.isFullscreen} ref={ctx.videoWrapperElement} {...props}>
      <TempVideo
        muted={!ctx.testMic}
        ref={ctx.videoElement}
        playsInline
        source={mediaStream as MediaStream}
        className={mergedClasses.videoElement}
      />
    </VideoWrapper>
  );
});

const EncoderVideoWithErrorBoundary: FC<Partial<VideoWrapperProps>> = ({ classes, ...props }) => {
  const styling = videoWrapperStyles(
    props.pillarBox ?? false,
    props.isFullScreen,
    props.mirror,
    props.elementBlur,
    props.blurPixels,
  );

  const mergedClasses = useStyles({ source: classes ?? {}, target: styling }, "videoWrapper");

  return (
    /**
     * @todo: Temporary fallback. Update for a real fallback.
     */
    <ErrorBoundary
      render={() => (
        <VideoWrapper classes={classes} {...props}>
          <div className={mergedClasses.videoElement} style={{ display: "inline-block", aspectRatio: "16/9" }}>
            <p>Video is not working</p>
            <PlayerOverlayButton classes={classes?.playerOverlayButton} {...props} disabled />
          </div>
        </VideoWrapper>
      )}
    >
      <ModularVideo classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default EncoderVideoWithErrorBoundary;
