import { Properties } from "csstype";
import React, { FC, ReactNode } from "react";

export interface SelectProps {
  className?: string;
  children?: ReactNode;
  classes?: Properties;
}

const SelectNonNative: FC<SelectProps> = ({ className, classes, children }) => {
  return <div className={`${className}`}>{children}</div>;
};

export default SelectNonNative;
