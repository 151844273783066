import { types } from "mediasoup-client";
import { DeviceAPI } from "../../../api";

interface InternalTransportOptions {
  direction: "send" | "recv";
}

/**
 * The class accepts `DeviceAPI` and process RTP capabilities
 * before creating a sendTransport or recvTransport.
 */
export class ExtendedDevice extends types.Device {
  /**
   * Represents the current direction of the transport.
   */
  private directionContext: "recv" | "send" | null = null;

  /**
   * The original RTP capabilities.
   */
  private originalRtpCapabilities: types.RtpCapabilities;

  /**
   * The original `createTransport` method.
   */
  private readonly originalCreateTransport: types.Device["createTransport"];

  constructor(private readonly device: DeviceAPI, opts: types.DeviceOptions = {}) {
    super(opts);

    this.originalRtpCapabilities = Reflect.get(this, "_extendedRtpCapabilities");
    this.originalCreateTransport = Reflect.get(this, "createTransport");

    // replace _extendedRtpCapabilities with our getter
    Reflect.defineProperty(this, "_extendedRtpCapabilities", {
      get: this.getterRtpCapabilities.bind(this),
      set: this.setterRtpCapabilities.bind(this),
      enumerable: true,
    });

    // override _createTransport with our wrapper
    Reflect.defineProperty(this, "_createTransport", {
      value: this.wrapperCreateTransport.bind(this),
      enumerable: false,
    });
  }

  /**
   * Return the RTP capabilities of the device depending on the direction.
   */
  getterRtpCapabilities(): types.RtpCapabilities {
    if (this.directionContext == null || this.originalRtpCapabilities == null) {
      return this.originalRtpCapabilities;
    }

    return this.device.processRtpCapabilities(this.directionContext, this.originalRtpCapabilities);
  }

  /**
   * Store the RTP capabilities as is.
   */
  setterRtpCapabilities(val: types.RtpCapabilities): void {
    this.originalRtpCapabilities = val;
  }

  /**
   * Wrapper for the original `createTransport` method.
   * It will process the RTP capabilities before creating the transport.
   */
  wrapperCreateTransport(options: InternalTransportOptions): types.Transport {
    try {
      this.directionContext = options.direction;
      return this.originalCreateTransport(options);
    } finally {
      this.directionContext = null;
    }
  }
}
