import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React from "react";
import { SelectProps } from "../../../../ui-lib/Inputs/SelectNonNative";
import LowLatencyToggle from "./LowLatencyToggle";
import QualitySelect from "./QualitySelect";
import Tooltip from "../../../../ui-lib/Tooltip/Tooltip";

interface QualitySettingsClasses {
  root?: Properties;
  option?: Properties;
  activeOption?: Properties;
  disabledOption?: Properties;
}

type QualitySettingsProps = Omit<SelectProps, "classes"> & {
  classes?: QualitySettingsClasses;
  showBitrate?: boolean;
  disableToggle?: boolean;
  disableSelect?: boolean;
  active: boolean;
};

const ModularQualitySettings = observer(
  ({ classes = {}, disableToggle, disableSelect, showBitrate, active, ...props }: QualitySettingsProps) => {
    return (
      <Tooltip active={active}>
        <QualitySelect disableSelect={disableSelect} showBitrate={showBitrate} />
        <LowLatencyToggle disabledOff={disableToggle} />
      </Tooltip>
    );
  },
);

/**
 * @deprecated
 * use components directly (Tooltip, QualitySelect, LowLatencyToggle)
 */
export default ModularQualitySettings;
