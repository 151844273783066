import type Mpegts from "mpegts.js";
import { LoggerCore } from "@video/log-client";
import type { ManifestFormats, ManifestJson, PlayerEvents } from "../../api";
import { SourceProvider } from "../../api";
import { device, Feature } from "../../api/adapter";
import { BitrateLayer, BitrateSwitchingFeature, Quality } from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { DriverNotSupportedError, PlayingIssueError } from "../errors-deprecated";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer } from "./core";
import { supportsFlvHttp } from "../utils/browser-support";

export interface FlvHttpPlayerEvents extends PlayerEvents {
  activeLayer: BitrateLayer | null;
  layers: BitrateLayer[];
  availableQualities: Quality[];
  currentQuality: Quality;
  blurred?: boolean;
}

export type FlvHttpPlayerOptions = {
  autoPlay?: boolean;
  muted?: boolean;
  requestHeaders?: Record<string, string>;
};

export class FlvHttpPlayer
  extends CorePlayer<FlvHttpPlayerOptions, ManifestJson, FlvHttpPlayerEvents>
  implements BitrateSwitchingFeature
{
  static readonly displayName = "FlvHttpPlayer";

  private player: Mpegts.Player | null = null;

  private isFirstLoad = true;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsFlvHttp(logger);
  }

  async isSupported(): Promise<boolean> {
    return FlvHttpPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "flv-http";
  }

  get format(): keyof ManifestFormats {
    return FlvHttpPlayer.format;
  }

  constructor(ctx: VcContext, provider: SourceProvider<ManifestJson>, options: FlvHttpPlayerOptions) {
    super(ctx, provider, options);

    this.autorun(() => {
      const layer = this.currentQuality?.layer;
      if (layer != null && typeof layer.id === "string" && layer.id !== "") {
        this.requestUrl(layer.id);
      }
    });

    this.on("hostElementAttached", this.handleElAttached);

    this.addInnerDisposer(() => {
      this.player?.destroy();
    });
  }

  async ready(): Promise<void> {
    if (device.isImplements(Feature.MPEGTS)) {
      await device.loadMpegtsScript();
    }
    await super.ready();
  }

  protected override pauseEl(): void {
    super.pauseEl();
    // this is a third-party player, which manipulate with HTMLElement by itself
    // so we need to say it pause too
    this.player?.pause();
  }

  protected handleSource(manifest: ManifestJson | null): void {
    if (this.suspended) {
      return;
    }
    const format = manifest?.formats["flv-http"];

    if (format == null) {
      this.emitErrorDeprecated(
        new DriverNotSupportedError("manifest doesn't contains 'flv-http' format", { manifest }),
      );
    }
  }

  private requestUrl(url: string): void {
    if (!device.isImplements(Feature.MPEGTS)) {
      this.throwErrorDeprecated(new PlayingIssueError("mpegts library is not loaded", { player: this }));
    }

    if (this.player != null) {
      this.player.destroy();
    }

    const requestHeaders = this.options.requestHeaders ?? null;
    const createPlayerOptions = requestHeaders != null ? { headers: requestHeaders } : undefined;
    this.player = device.mpegts.createPlayer(
      {
        type: "flv",
        isLive: true,
        url,
      },
      createPlayerOptions,
    );

    if (this.elementSupervisor != null) {
      this.handleElAttached({ el: Reflect.get(this.elementSupervisor, "element") });
    }
  }

  private handleElAttached(ev: PlayerEvents["hostElementAttached"]): void {
    if (this.player != null) {
      this.player.attachMediaElement(ev.el);
      this.player.load();
      this.ctx.logger.debug("host element is attached and loaded");
      if (this.isFirstLoad && !this.options.autoPlay) {
        this.isFirstLoad = false;
        return;
      }
      try {
        if (this.internalPaused) {
          this.ctx.logger.debug("pause");
          this.player.pause();
        } else {
          this.ctx.logger.debug("play");
          this.player.play();
        }
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitErrorDeprecated(
          new PlayingIssueError("mpegts call failed in handleElAttached()", {
            inner,
            player: this,
          }),
        );
      }
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }
}
