import { LoggerCore } from "@video/log-client";
import WebSocketClient from "@video/hub-websocket";
import { Json } from "../../api";
import { Feature, device } from "../../api/adapter";

const globalScope =
  (globalThis as any) ??
  (function fn(this: any): any {
    return this;
  })();

export class ClientStats {
  private websocketOpen = false;

  private ws: WebSocketClient | null = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly websocketUri: string, private readonly logger: LoggerCore) {}

  openWebsocket(data: Json): void {
    // check to see if websocket is passed in
    // if not, check window and global.
    // if not, create new websocket
    // else throw and error
    if (globalScope?.livelyWebsocketV1?.readyState > 0) {
      this.ws = globalScope.livelyWebsocketV1;
      this.websocketOpen = true;
    } else if (device.isImplements(Feature.WEB_SOCKET_CLIENT)) {
      this.ws = new device.WebSocketClient(
        {
          websocketUri: this.websocketUri,
          logger: this.logger,
          plugin: "client-stats",
          version: "v2",
        },
        undefined,
        {
          maxReconnectInterval: 15000, // 15 sec
          reconnectDecay: 1.2, // 1s, 1.44s, 1.73s, 2.07s, ...
        },
      );
      this.websocketOpen = true;
    } else {
      throw new Error("No websocket client found");
    }

    this.ws?.send("'client-stats", data);
  }

  // Send event
  push(data: Record<string, Json>): void {
    // send data over websocket
    data.type = "client-stats";

    if (!this.websocketOpen) {
      this.openWebsocket(data);
    } else {
      this.ws?.send("client-stats", data);
    }
  }

  // Close websocket
  close(): void {
    if (this.ws != null) {
      this.ws.close(1005, "ClientStats is closed");
      this.ws = null;
    }
    this.websocketOpen = false;
  }
}
