/* eslint-disable react/function-component-definition */
import * as React from "react";
import { IconProps } from "./iconProps";

const Eye: React.FC<IconProps> = (props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.295}
        d="M1.622 6.046c-.088-.14-.132-.21-.157-.318a.76.76 0 0 1 0-.289c.025-.108.069-.177.157-.317C2.351 3.968 4.52 1.05 7.826 1.05c3.306 0 5.476 2.918 6.205 4.072.088.14.132.21.157.317a.761.761 0 0 1 0 .29c-.025.107-.07.177-.157.317-.73 1.154-2.899 4.071-6.205 4.071S2.351 7.2 1.622 6.046Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.295}
        d="M7.826 7.527a1.943 1.943 0 1 0 0-3.886 1.943 1.943 0 0 0 0 3.886Z"
      />
    </svg>
  );
};
export default Eye;
