import { Properties } from "csstype";
import React, { ReactElement } from "react";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";

interface SaveButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface SaveButtonProps extends Partial<ButtonIconProps> {
  classes?: SaveButtonClasses;
}

function SaveButton({
  active,
  classes = {
    root: {},
    icon: {},
  },
  label = "Save Recording Button",
  icon: ProvidedIcon,
  ...rest
}: SaveButtonProps): ReactElement {
  const proppedIcon = <Icon iconName="recordersave" classes={classes?.icon} />;

  return (
    <ButtonIcon
      active={active}
      classes={classes}
      label={label}
      icon={ProvidedIcon || proppedIcon}
      onClick={rest.onClick}
      data-selenium="save-recording-button"
      {...rest}
    >
      Click to save Recording
    </ButtonIcon>
  );
}

export default SaveButton;
