/* eslint-disable import/no-cycle */
import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { MultistreamContext } from "../../../context";
import MediaContainer from "../MediaContainer/MediaContainer";
import PlayerVideo from "../Videos/PlayerVideo";
import { PlayerPlayButton, PlayerAudioButton } from "..";
import ControlBar from "../../../ui-lib/ControlBar";
import { useStyles } from "../../../styling";
import Close from "../../../ui-lib/Icons/Close";
import { MultistreamState } from "../../../../store/multistream";
import { useUndefinedStoreError } from "../../ErrorBoundary";
import { PlayerStyleProps, getVideoElementStyles, playerStyles } from "./styles";
import Eye from "../../../ui-lib/Icons/Eye";

interface MultistreamPlayerProps {
  classes?: PlayerStyleProps;
  useDefaultStyles?: boolean;
  publicKey: string;
  reRender?: boolean;
}

const MultistreamPlayer = observer(
  ({ classes, publicKey, useDefaultStyles = true, reRender }: MultistreamPlayerProps): JSX.Element => {
    const mergedClasses = useStyles({ target: classes ?? {}, source: playerStyles }, "multistream");
    const ctx = useContext<MultistreamState | null>(MultistreamContext);

    useUndefinedStoreError(ctx != null, "<MultistreamPlayer/>");

    const { playersWrapperElement, activePublicKeys, stopPlayer, streams } = ctx;

    const stream = streams[publicKey];

    const { offsetHeight, offsetWidth } = playersWrapperElement.current ?? { offsetHeight: 0, offsetWidth: 0 };

    const defaultStyles = useMemo(
      () =>
        getVideoElementStyles({
          containerHeight: offsetHeight,
          containerWidth: offsetWidth,
          playersCount: activePublicKeys.length,
        }),
      [activePublicKeys.length, offsetHeight, offsetWidth],
    );

    const viewCount = stream?.viewCount;

    return (
      <MediaContainer
        classes={
          useDefaultStyles
            ? {
                root: {
                  ...defaultStyles.root,
                  ...classes?.root,
                },
              }
            : classes
        }
      >
        {activePublicKeys.length !== 1 && (
          <button type="button" onClick={() => stopPlayer(publicKey)} className={mergedClasses.closeButton}>
            <Close weight="normal" style={{ width: "15px", marginLeft: "3px" }} />
          </button>
        )}
        <PlayerVideo pillarBox={false} />
        <ControlBar
          variant="player"
          classes={{
            root: { bottom: "0 !important" },
            controls: { backgroundImage: "transparent", justifyContent: "flex-start" },
          }}
        >
          <PlayerPlayButton />
          <PlayerAudioButton />
        </ControlBar>
        {viewCount != null && viewCount > 0 && (
          <div className={mergedClasses.viewCount}>
            <Eye style={{ width: "15px", marginRight: "3px", height: "11px" }} />
            <span>{viewCount}</span>
          </div>
        )}
      </MediaContainer>
    );
  },
);

export default MultistreamPlayer;
