// @todo seems broken.

import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../../../store";
import { PlayerUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface NewWindowButtonClasses extends RootStyles {
  icon?: Properties;
}

interface NewWindowButtonProps extends Partial<ButtonIconProps> {
  classes?: NewWindowButtonClasses;
}

const ModularLaunchButton = observer(
  ({
    active = false,
    icon: ProvidedIcon,
    classes = {
      root: {},
      icon: {},
    },
    label = "Open in a new window Button",
    onClick,
    screenReaderText,
    ...props
  }: NewWindowButtonProps) => {
    const componentName = "<NewWindowButton/>";
    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    useUndefinedStoreError(ctx?.newWindow != null, componentName);

    // replaced with noop because is not implemented in ctx
    const handleClick = onClick ?? useUIEventError(() => undefined, componentName);

    const icon = <Icon iconName="launchopen" classes={classes?.icon} />;

    return (
      <ButtonIcon
        active={active}
        data-selenium="new-window-button"
        icon={ProvidedIcon ?? icon}
        label={label}
        classes={classes}
        onClick={handleClick}
        {...props}
      >
        {screenReaderText ?? `Click to ${active ? "Collapse from " : "Expand to "}  Fullscreen`}
      </ButtonIcon>
    );
  },
);

const LaunchButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  label = "Open in a new window Button",
  screenReaderText,
  classes,
  active,
  ...props
}: NewWindowButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="launchopen" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="new-window-button"
          disabled
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Expand to Fullscreen"}
        </ButtonIcon>
      )}
    >
      <ModularLaunchButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        active={active}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default LaunchButtonWithErrorBoundary;
