import { Json } from "@video/log-node/lib";
import { Device, parseScalabilityMode, types } from "mediasoup-client";
import { PeerProducerOptions } from "../../../api";
import { device } from "../../../api/adapter";
import { Feature } from "../../../api/adapter/features/feature";
import type { RTCPeerConnectionState, TransportDirection } from "../../../api/adapter/features/media-device";
import type { MediaStreamTrack } from "../../../api/adapter/features/media-stream";
import type { NetworkInformation } from "../../../api/adapter/features/network-information";
import { AnyError, ErrorCode } from "../../../api/error";
import type { BitrateLayer } from "../../../api/player/features/bitrate-switching";
import { createError } from "../../errors";
import {
  InternalError,
  MediasoupSetupError,
  SFUConsumerClosedEventError,
  SFUConsumerLayersChangedEventError,
  SFUConsumerPausedEventError,
  SFUConsumerResumedEventError,
  SFUConsumerScoreEventError,
  SFUConsumerSourcesEventError,
  SFUNewConsumerEventError,
  SFUNewPeersEventError,
  SFUPeerClosedEventError,
  SFUProducerClosedEventError,
  SFUProducerPausedEventError,
  SFUProducerResumedEventError,
  SFUSwitchConsumerTrackEventError,
  TransportStateError,
  WSRequestError,
} from "../../errors-deprecated";
import { sortEncodings } from "../../player/helper";
import { onceCanceled } from "../../utils/context/context";
import type { VcContext } from "../../utils/context/vc-context";
import { ObservableEventEmitter } from "../../utils/events/event-emitter";
import { extendContext } from "../../utils/logger";
import { StatsCollector } from "../stats";
import type { AnomalyEvent } from "../stats/anomalies";
import * as anomalies from "../stats/anomalies";
import type { Call } from "./call";
import type { CallEvents } from "./call.events";
import {
  AppData,
  ConsumerLayer,
  ConsumerParameters,
  PeerParameters,
  PERMISSIONS,
  WSConsumerSourcesResponse,
  WSRequest,
} from "./common";
import { ExtendedDevice } from "./device";
import { TROUBLESHOOTING } from "./messageList";

export interface Lock {
  promise: Promise<void>;
  resolve: () => void;
  reject: (err?: Error) => void;
  resolved: boolean;
  ready: () => Promise<void>;
}

interface CreateWebRTCTransportResponse extends types.TransportOptions {
  peers: PeerParameters[];
}
export interface PeerEventsMap {
  /**
   * @arg VideoClientError
   * @description Is emitted on a peer error.
   * @example interalPeer.on("error", (err) => { // handle err})
   */
  error: AnyError;
  /**
   * @arg any
   * @description Is emitted when webrtc-stats are received.
   * @example internalPeer.on("webrtc-stats", (stats) => { if(stats) { // do something } })
   */
  "webrtc-stats": any; // statsCollector.WebrtcStats;
  "webrtc-anomaly": AnomalyEvent;
  /**
   * @arg TransportDirection
   * @description Is emitted when the RTCPeerConnection state changes to connected.
   * @example videoclient.on("peerAdded", (ev) => {
   * if(ev.peer.isImplements(peer.Feature.INTERNAL_PEER)) {
   *  ev.peer.pvcCall.peer.on("webrtc-transport-connected", (val) => {
   *    // Do something
   *  });
   */
  "webrtc-transport-connected": TransportDirection;
  /**
   * @arg TransportDirection
   * @description Is emitted when the RTCPeerConnection state changes to disconnected.
   * @example videoclient.on("peerAdded", (ev) => {
   * if(ev.peer.isImplements(peer.Feature.INTERNAL_PEER)) {
   *  ev.peer.pvcCall.peer.on("webrtc-transport-disconnected", (val) => {
   *    // Do something
   *  });
   */
  "webrtc-transport-disconnected": TransportDirection;
  /**
   * @arg TransportDirection
   * @description Is emitted when the RTCPeerConnection state changes to closed.
   * @example videoclient.on("peerAdded", (ev) => {
   * if(ev.peer.isImplements(peer.Feature.INTERNAL_PEER)) {
   *  ev.peer.pvcCall.peer.on("webrtc-transport-closed", (val) => {
   *    // Do something
   *  });
   */
  "webrtc-transport-closed": TransportDirection;
  /**
   * @arg TransportDirection
   * @description Is emitted when the RTCPeerConnection state changes to failed.
   * @example videoclient.on("peerAdded", (ev) => {
   * if(ev.peer.isImplements(peer.Feature.INTERNAL_PEER)) {
   *  ev.peer.pvcCall.peer.on("webrtc-transport-failed", (val) => {
   *    // Do something
   *  });
   */
  "webrtc-transport-failed": TransportDirection;
}

// @todo: figure out the real types
type PeerRequest = types.ConsumerOptions & {
  peerId: string;
  producerPaused: boolean;
};

type PeerResponse = (arg: Record<string, unknown> | null, message?: string) => void;

type SwitchConsumerTrackRequest = PeerRequest & {
  add: Array<{
    consumerId?: string;
  }>;
  remove: Array<{
    consumerId?: string;
  }>;
};

export class Peer extends ObservableEventEmitter<PeerEventsMap> {
  static readonly displayName = "PvcPeer";

  public _closed = false;

  private readonly _iceTimeouts: Map<unknown, number> = new Map<unknown, number>();

  private _mediasoupDevice: Device | null = null;

  private _sendTransport: types.Transport | null = null;

  private _recvTransport: types.Transport | null = null;

  private readonly _producers: Map<string, types.Producer> = new Map<string, types.Producer>();

  private readonly _consumerStatIntervals: Map<string, number> = new Map<string, number>();

  private readonly _delayVideoStart: boolean = false;

  private readonly _forceRecvTcp: unknown;

  public readonly statsCollector: StatsCollector;

  public call: Call;

  public peers: Map<string, PeerParameters> = new Map<string, PeerParameters>();

  // transport locks used to determine if a transport is ready
  public sendLock: Lock | null = null;

  public recvLock: Lock | null = null;

  private readonly ctx: VcContext;

  constructor(ctx: VcContext, call: Call) {
    super();

    this.ctx = ctx;
    this.call = call;

    onceCanceled(ctx).then(this.dispose);

    const statsIntervalDefault = 5000;
    const statsInterval =
      this?.call?.options?.call?.stats?.statsInterval != null ? this?.call?.options?.call?.stats?.statsInterval : null;

    this.statsCollector = new StatsCollector(extendContext(this.ctx, StatsCollector), {
      interval: statsInterval ?? statsIntervalDefault,
      callId: this.call.call.id,
      userId: this.call.user?.userId ?? null,
      displayName: this.call.user?.displayName ?? null,
      debug: true,
    });

    if (this.call._wsTransport == null) {
      throw new Error("Call._wsTransport is null");
    }

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");

    const transport = this.call._wsTransport;
    transport.on("newPeers", this._handleNewPeers);
    transport.on("consumerSources", this._handleConsumerSources);
    transport.on("kicked", this._handleAccessDenied);

    this.addInnerDisposer(() => {
      transport.off("newPeers", this._handleNewPeers);
      transport.off("consumerSources", this._handleConsumerSources);
      transport.off("kicked", this._handleAccessDenied);
    });

    this._peerEvent("newConsumer", this._onNewConsumer.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUNewConsumerEventError("unable to handle `newConsumer` peer event", {
          peer: this,
          request: {
            id: request.id,
            kind: request.kind,
            producerPaused: request.producerPaused,
            producerId: request.producerId,
            rtpParameters: request.rtpParameters,
          },
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._peerEvent("peerClosed", this._onPeerClosed.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUPeerClosedEventError("unable to handle `peerClosed` peer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._peerEvent("switchConsumerTrack", this._onSwitchConsumerTrack.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUSwitchConsumerTrackEventError("unable to handle `switchConsumerTrack` peer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._consumerEvent("consumerClosed", this._onConsumerClosed.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUConsumerClosedEventError("unable to handle `consumerClosed` consumer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._consumerEvent("consumerPaused", this._onConsumerPaused.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUConsumerPausedEventError("unable to handle `consumerPaused` consumer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._consumerEvent("consumerResumed", this._onConsumerResumed.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUConsumerResumedEventError("unable to handle `consumerResumed` consumer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._consumerEvent("consumerScore", this._onConsumerScore.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUConsumerScoreEventError("unable to handle `consumerScore` consumer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._consumerEvent<ConsumerLayer>(
      "consumerLayersChanged",
      this._onConsumerLayersChanged.bind(this),
      (err, request) => {
        this.emitErrorDeprecated(
          new SFUConsumerLayersChangedEventError("unable to handle `consumerLayersChanged` consumer event", {
            peer: this,
            request: request as any,
            inner: err instanceof Error ? err : null,
          }),
        );
      },
    );

    this._producerEvent("producerClosed", this._onProducerClosed.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUProducerClosedEventError("unable to handle `producerClosed` producer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._producerEvent("producerPaused", this._onProducerPaused.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUProducerPausedEventError("unable to handle `producerPaused` producer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this._producerEvent("producerResumed", this._onProducerResumed.bind(this), (err, request) => {
      this.emitErrorDeprecated(
        new SFUProducerResumedEventError("unable to handle `producerResumed` producer event", {
          peer: this,
          request: request as any,
          inner: err instanceof Error ? err : null,
        }),
      );
    });

    this.addInnerDisposer(this.clear);
  }

  private _handleConsumerSources(request: WSConsumerSourcesResponse): void {
    try {
      this._onConsumerSources(request);
    } catch (err) {
      this.emitErrorDeprecated(
        new SFUConsumerSourcesEventError("unable to handle `newPeers` peer event", {
          peer: this,
          request,
          inner: err instanceof Error ? err : null,
        }),
      );
    }
  }

  async _handleAccessDenied(request: { message: string }): Promise<void> {
    this.call.emit("CALL_ACCESS_DENIED", request);
  }

  private _handleNewPeers(request: { peers: PeerParameters[] }): void {
    try {
      this._onNewPeers(request);
    } catch (err) {
      this.emitErrorDeprecated(
        new SFUNewPeersEventError("unable to handle `newPeers` peer event", {
          peer: this,
          request,
          inner: err instanceof Error ? err : null,
        }),
      );
    }
  }

  async _setup(routerRtpCapabilities: types.RtpCapabilities): Promise<void> {
    // TODO: create transports when needed - via behaviours and client action???
    try {
      this._mediasoupDevice = new ExtendedDevice(device);
      await this._mediasoupDevice.load({ routerRtpCapabilities });
      // TODO: switch to using permissions

      // create webrtc transports
      let wait: Promise<void>[] = [];
      if (Object.keys(this.call.streams).length > 0) {
        wait.push(this._createSendTransport());
      }

      // create receive transport
      wait.push(this._createReceiveTransport());
      await Promise.all(wait);

      this.call.emit("CALL_SET_MEDIA_CAPABILITIES", {
        canSendMic: this._mediasoupDevice.canProduce("audio"),
        canSendWebcam: this._mediasoupDevice.canProduce("video"),
      });

      // create producers for the streams
      wait = [];
      for (const s of this.call.streamsIterator()) {
        if (s.hasVideoStreamTrack && !this._delayVideoStart && this.call.hasPermission(PERMISSIONS.STREAM_VIDEO)) {
          wait.push(
            (async () => {
              try {
                if (this._mediasoupDevice?.canProduce("video")) {
                  await s.enableVideo();
                }
              } catch (err) {
                const msg = err instanceof Error ? err.message : "unknown error";
                this.ctx.logger.warn("Unable to set video producer", { err: msg });
                this.call._addMessage(TROUBLESHOOTING.NO_WEBCAM);
              }
            })(),
          );
        }

        if (s.hasAudioStreamTrack && this.call.hasPermission(PERMISSIONS.STREAM_AUDIO)) {
          wait.push(
            (async () => {
              try {
                if (this._mediasoupDevice?.canProduce("audio")) {
                  await s.enableAudio();
                }
              } catch (err) {
                const msg = err instanceof Error ? err.message : "unknown error";
                this.ctx.logger.warn("Unable to set audio producer", { err: msg });
                this.call._addMessage(TROUBLESHOOTING.NO_MIC);
              }
            })(),
          );
        }
      }

      await Promise.all(wait);
    } catch (err) {
      if (this.sendLock != null) {
        try {
          this.sendLock.reject();
        } catch (ignore) {
          // pass
        }
      }
      if (this.recvLock != null) {
        try {
          this.recvLock.reject();
        } catch (ignore) {
          // pass
        }
      }
      if (this._recvTransport != null) {
        try {
          this._recvTransport.close();
        } catch (ignore) {
          // pass
        }
      }
      if (this._sendTransport != null) {
        try {
          this._sendTransport.close();
        } catch (ignore) {
          // pass
        }
      }
      this.emitErrorDeprecated(
        new MediasoupSetupError("ms3._setup: error during setup", {
          critical: true,
          inner: err instanceof Error ? err : null,
        }),
      );
    }
  }

  async _createSendTransport(): Promise<void> {
    if (this._mediasoupDevice == null) {
      throw new Error("Peer._mediasoupDevice is null");
    }

    this.sendLock = this._initLock();
    let transportInfo: CreateWebRTCTransportResponse;
    const args = {
      producing: true,
      consuming: false,
      rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
      maxBitrate: this.call.maxBitrate,
    };
    try {
      transportInfo = await this.call._request<CreateWebRTCTransportResponse>("createWebRtcTransport", args);
    } catch (err) {
      const inner = err instanceof Error ? err : null;
      const vdcerr = new WSRequestError("createSendTransport: ws request error", {
        inner,
        request: "createWebRtcTransport",
        args,
        internalCall: this.call,
      });
      this.call.emit("error", vdcerr as any);
      throw vdcerr;
    }

    this._sendTransport = this._mediasoupDevice.createSendTransport({
      id: transportInfo.id,
      iceParameters: transportInfo.iceParameters,
      iceCandidates: transportInfo.iceCandidates,
      dtlsParameters: transportInfo.dtlsParameters,
      ...this._transportOptions(),
    });
    if (this.call.peerId != null) {
      this.statsCollector.attachTransports(this.call.peerId, this._sendTransport, null);
    } else {
      this.ctx.logger.error("unable to attach stats collector: peerId is null");
    }

    this.sendLock.resolve();

    this.ctx.logger.debug("ms3._createSendTransport: created", {
      transportInfo: transportInfo as any,
      deviceCapabilities: this._mediasoupDevice.rtpCapabilities,
    });

    this._handleTransportEvents(this._sendTransport, "send");
  }

  async _createReceiveTransport(): Promise<void> {
    if (this._mediasoupDevice == null) {
      throw new Error("Peer._mediasoupDevice is null");
    }

    this.recvLock = this._initLock();
    let transportInfo: CreateWebRTCTransportResponse;
    const args = {
      forceTcp: this._forceRecvTcp,
      producing: false,
      consuming: true,
      rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
    };

    // save if the call is closed/closing before entering try/catch block
    const closed = this._closed || this.call._closed;

    try {
      transportInfo = await this.call._request<CreateWebRTCTransportResponse>("createWebRtcTransport", args);
    } catch (err) {
      const currentlyClosed = this._closed || this.call._closed;

      //if the call was closed during async request sallow error
      if (!closed && currentlyClosed) {
        return;
      }

      const inner = err instanceof Error ? err : null;
      const vdcerr = new WSRequestError("createReceiveTransport: ws request error", {
        inner,
        request: "createWebRtcTransport",
        args,
        internalCall: this.call,
      });
      this.call.emit("error", vdcerr as any);
      throw vdcerr;
    }

    transportInfo = {
      ...transportInfo,
      ...this._transportOptions(),
    };
    this._recvTransport = this._mediasoupDevice.createRecvTransport(transportInfo);
    if (this.call.peerId != null) {
      this.statsCollector.attachTransports(this.call.peerId, null, this._recvTransport);
    } else {
      this.ctx.logger.error("unable to attach stats collector: peerId is null");
    }

    this.recvLock.resolve();

    this.ctx.logger.debug("ms3._createReceiveTransport: created", { transportInfo: transportInfo as any });

    this._handleTransportEvents(this._recvTransport, "recv");
  }

  // TODO: this should probably be returned from the SFU when the request to create a transport is made
  _transportOptions(): Partial<types.TransportOptions> {
    const turnOrStun =
      device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo?.name === "edge"
        ? this.call._edgeIceServers()
        : this.call._iceServers();
    this.call._iceTransportPolicyUsed =
      this.call._iceTransportPolicy ?? this.call._localSettings.iceTransportPolicy ?? "all";

    return {
      iceServers: turnOrStun,
      iceTransportPolicy: this.call._iceTransportPolicyUsed,
    };
  }

  _handleTransportEvents(transport: types.Transport, transportType: TransportDirection): void {
    // If the server is mis-configured no connection state changes occur so
    // we need to monitor manually for a change
    let failedIceTimeout: number | undefined;
    if ((transportType === "send" && this.call._requireSend) || (transportType === "recv" && this.call._requireRecv)) {
      failedIceTimeout = device.setTimeout(() => {
        if (transport.connectionState === "new") {
          this.emitErrorDeprecated(
            new TransportStateError("Transport failed to change connection state", {
              critical: true,
            }),
          );
        }
      }, 20000);
    }

    const DIR = transportType.toUpperCase();
    let prevState: string | null = null;
    transport.on("connectionstatechange", (state: RTCPeerConnectionState) => {
      device.clearTimeout(failedIceTimeout);

      if (DIR === "SEND") {
        this.call.emit("CALL_SET_SENDING_ICE_STATE", { state });
      } else {
        this.call.emit("CALL_SET_RECEIVING_ICE_STATE", { state });
      }

      this.ctx.logger.setMessageAggregate(`${transportType}TransportId`, transport.id);
      this.ctx.logger.setMessageAggregate(`${transportType}TransportState`, state);

      const connection: NetworkInformation | null = device.isImplements(Feature.NETWORK_INFORMATION)
        ? device.connection
        : null;

      this.ctx.logger.info(`${transportType} transport connection state change`, {
        state,
        transportType,
        prevState,
        connection: connection as any,
      });
      prevState = state;

      // @hack: which browser has 'completed' state?
      if ((state as string) === "completed") {
        state = "connected";
      }

      switch (state) {
        case "connected":
          transport.appData.onceConnected = true;

          this.call._removeMessage(TROUBLESHOOTING.NETWORK_FIREWALLED);
          this.call._removeMessage(
            TROUBLESHOOTING[DIR === "SEND" ? "WEBRTC_SEND_DISCONNECTED" : "WEBRTC_RECV_DISCONNECTED"],
          );

          device.clearTimeout(this._iceTimeouts.get(transportType));
          this._iceTimeouts.delete(transportType);
          this.ctx.logger.info(`${transportType} transport entered connected state`);
          this.emit("webrtc-transport-connected", transportType);
          break;
        case "disconnected":
          this.call._addMessage(
            TROUBLESHOOTING[DIR === "SEND" ? "WEBRTC_SEND_DISCONNECTED" : "WEBRTC_RECV_DISCONNECTED"],
          );

          this._restartIce(transportType);
          this.ctx.logger.warn(`${transportType} transport entered disconnected state`);
          this.emit("webrtc-transport-disconnected", transportType);
          break;
        case "failed":
          this.ctx.logger.warn(`${transportType} transport entered failed state`);
          this.emit("webrtc-transport-failed", transportType);
          if (DIR === "SEND" && this.call._requireSend) {
            this.emitErrorDeprecated(new TransportStateError("Send transport failed", { critical: true }));
          } else if (DIR === "RECV" && this.call._requireRecv) {
            const err = new Error("Receive transport failed");
            if (this.recvLock != null) {
              this.recvLock.reject(err);
            }
            this.emitErrorDeprecated(new TransportStateError("Receive transport failed", { critical: true }));
          } else {
            this.call._removeMessage(
              TROUBLESHOOTING[DIR === "SEND" ? "WEBRTC_SEND_DISCONNECTED" : "WEBRTC_RECV_DISCONNECTED"],
            );

            this._restartIce(transportType);

            if (!(transport.appData.onceConnected as boolean)) {
              this.call._addMessage(TROUBLESHOOTING.NETWORK_FIREWALLED);
            }
          }
          break;
        case "closed":
          if (DIR === "send") {
            if (this.sendLock != null) {
              this.sendLock.reject(new Error("send transport closed"));
            }
          } else if (this.recvLock != null) {
            this.recvLock.reject(new Error("recv transport closed"));
          }
          if (!this._closed) {
            this.emitErrorDeprecated(
              new TransportStateError(`${transportType} transport closed prematurely`, { critical: true }),
            );
          }
          this.ctx.logger.warn(`${transportType} transport entered closed state`);
          this.emit("webrtc-transport-closed", transportType);
          break;
        default:
          break;
      }
    });

    transport.observer.on("close", () => {
      // if (DIR === "send") {
      //   if (this.sendLock != null) {
      //     this.sendLock.reject(new Error("send transport closed"));
      //   }
      // } else if (this.recvLock != null) {
      //   this.recvLock.reject(new Error("recv transport closed"));
      // }
      // if (!this._closed) {
      //   this.emit("error", new Error(`${transportType} transport closed prematurely`));
      // }
      this.ctx.logger.info(`${transportType} transport.observer.closed state`);
      this.emit("webrtc-transport-closed", transportType);

      if (this._closed || this.disposing) {
        return;
      }

      if (!this._closed && transportType === "recv") {
        this.ctx.logger.warn(
          `${transportType} transport.observer.closed, but peer is still open. Trying to recreate recv transport`,
        );
        this._createReceiveTransport().catch((err) => {
          const inner = err instanceof Error ? err : null;
          this.throwErrorDeprecated(
            new TransportStateError("unable to re-create recv transport", { inner, critical: true }),
          );
        });
      }
    });

    // save if the call is closed/closing before entering try/catch block
    const closed = this._closed || this.call._closed;

    transport.on(
      "connect",
      (
        { dtlsParameters }: { dtlsParameters: types.DtlsParameters },
        callback: () => void,
        errback: (error: Error) => void,
      ) => {
        const args = {
          transportId: transport.id,
          dtlsParameters,
        };

        this.call
          ._request("connectWebRtcTransport", args)
          .then(() => {
            callback();
            this.recvLock?.resolve();
            this.ctx.logger.debug("ms3._handleTransportEvents connected", {
              transportType,
            });
          })
          .catch((err) => {
            const currentlyClosed = this._closed || this.call._closed;

            //if the call was closed during async request sallow error
            if (!closed && currentlyClosed) {
              return;
            }

            const inner = err instanceof Error ? err : null;
            const vdcerr = new WSRequestError("handleTransportEvents: ws request error", {
              inner,
              request: "connectWebRtcTransport",
              args,
              internalCall: this.call,
            });
            this.call.emit("error", vdcerr as any);
            errback(vdcerr);
          });
      },
    );

    transport.on(
      "produce",
      (
        { kind, rtpParameters, appData }: types.ConsumerOptions,
        callback: (data: { id: string }) => void,
        errback: (error: Error) => void,
      ) => {
        const args = {
          transportId: transport.id,
          kind,
          rtpParameters,
          appData,
        };
        this.call
          ._request<{ id: string }>("produce", args)
          .then((resp) => {
            callback(resp);
            this.ctx.logger.debug("ms3._handleTransportEvents: produce", {
              kind,
              appData: appData as Json,
            });
          })
          .catch((err) => {
            const inner = err instanceof Error ? err : null;
            const vdcerr = new WSRequestError("handleTransportEvents: ws request error", {
              inner,
              request: "produce",
              args,
              internalCall: this.call,
            });
            this.call.emit("error", vdcerr as any);
            errback(vdcerr);
          });
      },
    );
  }

  _addPeers(peers: PeerParameters[]): void {
    peers.forEach((peer) => {
      if (peer.id == null) {
        this.emitErrorDeprecated(new InternalError("peerId is undefined", {}));
        return;
      }

      this.peers.set(peer.id, peer);
      this.call.emit("CALL_ADD_PEER", { peer });
      this.call.emit("PEER_CHANGE", { peer, action: "added" });
    });
  }

  _restartIce(dir: TransportDirection): void {
    const to = this._iceTimeouts.get(dir);
    if (to != null) {
      // ice restart already in progress
      return;
    }

    const restart = async (): Promise<void> => {
      if (this._closed) {
        return;
      }

      if (this.call._wsTransport == null) {
        this.ctx.logger.error("call: restartIce error", { err: "call._wsTransport is null" });
        return;
      }

      this.ctx.logger.throttledLog("debug", 15000, "ice restart", { online: this.call._wsTransport.online });
      if (!this.call._wsTransport.online) {
        // retry until user is back online
        // clear timeout before setting in case there's one already in the map
        device.clearTimeout(this._iceTimeouts.get(dir));
        this._iceTimeouts.set(dir, device.setTimeout(restart.bind(this), 500));
        return;
      }

      try {
        const args = { dir };
        let params: {
          iceParameters: types.IceParameters;
        };
        try {
          params = await this.call._request<{
            iceParameters: types.IceParameters;
          }>("restartIce", args);
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          const vdcerr = new WSRequestError("restartIce: ws request error", {
            inner,
            request: "restartIce",
            args,
            internalCall: this.call,
          });
          this.call.emit("error", vdcerr as any);
          throw vdcerr;
        }

        if (dir === "send") {
          if (this._sendTransport == null) {
            this.ctx.logger.error("call: restartIce error", { err: "_sendTransport is null" });
            return;
          }
          await this._sendTransport.restartIce(params);
        } else {
          if (this._recvTransport == null) {
            this.ctx.logger.error("call: restartIce error", { err: "_recvTransport is null" });
            return;
          }
          await this._recvTransport.restartIce(params);
        }
        this._iceTimeouts.delete(dir);
        return;
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        this.ctx.logger.warn("call: restartIce error", { err: msg });
      }

      // timeout gets cleared once a candidate is found or call is closed.
      // clear timeout before setting in case there's one already in the map
      device.clearTimeout(this._iceTimeouts.get(dir));
      this._iceTimeouts.set(dir, device.setTimeout(restart.bind(this), 5000));
    };

    restart().catch(this.ctx.logger.error);
  }

  _onNewPeers(request: { peers: PeerParameters[] }): void {
    this._addPeers(request.peers);
  }

  _onConsumerSources(request: WSConsumerSourcesResponse): void {
    const streams: Record<string, CallEvents["CALL_CONSUMER_SOURCES"]> = {};
    for (const c of request.consumers) {
      const ev: CallEvents["CALL_CONSUMER_SOURCES"] = streams[`${c.peerId}-${c.streamName}`] ?? {
        peerId: c.peerId,
        streamName: c.streamName,
        layers: [],
      };

      for (const enc of c.encodings) {
        ev.layers.push({
          id: enc.id,
          bitrate: enc.bitrate ?? enc.maxBitrate ?? 0,
          isSource: !c.xcode,
          appData: {
            consumerId: c.consumerId,
          },
        });
      }
      streams[`${c.peerId}-${c.streamName}`] = ev;
    }

    for (const ev of Object.values(streams)) {
      this.call.emit("CALL_CONSUMER_SOURCES", ev);
    }
  }

  async getLayers(streamName: string, peerId: string): Promise<[BitrateLayer | null, BitrateLayer[]]> {
    let layers: BitrateLayer[] = [];
    let activeLayer: BitrateLayer | null = null;
    let sources: WSConsumerSourcesResponse | null = null;

    // // save if the call is closed/closing before entering try/catch block
    const closed = this._closed || this.call._closed;
    try {
      sources = await this.call.requestSources(streamName, peerId);
      this.ctx.logger.debug("request consumerSources from ws", { sources });
      // if (
      //   sources?.active?.encoding?.id != null &&
      //   sources?.active?.consumerId != null &&
      //   sources?.active?.xcode != null
      // ) {
      //   activeLayer = {
      //     id: sources.active.encoding.id,
      //     bitrate: sources.active.encoding.bitrate ?? sources.active.encoding.maxBitrate ?? 0,
      //     isSource: !sources.active.xcode,
      //     appData: {
      //       consumerId: sources.active.consumerId,
      //     },
      //   };
      // }
      for (const c of sources.consumers) {
        for (const e of c.encodings) {
          const layer = {
            id: e.id,
            bitrate: e.bitrate ?? e.maxBitrate ?? 0,
            isSource: !c.xcode,
            appData: {
              consumerId: c.consumerId,
            },
          };
          layers.push(layer);
          if (e.active) {
            activeLayer = layer;
          }
        }
      }
    } catch (err) {
      const currentlyClosed = this._closed || this.call._closed;

      //if the call was closed during async request sallow error
      if (!closed && currentlyClosed) {
        return [null, []];
      }
      this.ctx.logger.error(`unable to get consumer sources: ${err}`, { sources });
    }
    layers = sortEncodings(layers) as BitrateLayer[];
    return [activeLayer, layers];
  }

  async _onNewConsumer(
    peer: PeerParameters,
    request: types.ConsumerOptions & { producerPaused: boolean },
    response: (arg: Record<string, unknown> | null, message?: string) => void,
  ): Promise<void> {
    // TODO: check permissions
    this.ctx.logger.info("call: _onNewConsumer");

    if (this.recvLock != null) {
      await this.recvLock.ready();
    } else {
      await this._createReceiveTransport();
    }

    if (this._recvTransport == null) {
      this.ctx.logger.error("call: _onNewConsumer error", { err: "_recvTransport is null" });
      response(null, "_recvTransport is null");
      return;
    }

    if (this._recvTransport.closed) {
      await this._createReceiveTransport();
      await this.recvLock?.ready();
      response(null, "_recvTransport closed");
      return;
    }

    const consumer = await this._recvTransport.consume(request);

    // this a workaround for a bug in mediasoup when ms always create unpaused consumers
    if ((request.appData?.trackEnabled === false || request.producerPaused) && !consumer.paused) {
      consumer.pause();
    }

    peer.consumers?.push(consumer);

    consumer.on("transportclose", () => {
      this.ctx.logger.debug("consumer event", {
        event: "transportclose",
        kind: request.kind,
      });
      this._deleteConsumers(peer, [consumer.id]);
    });
    consumer.on("trackended", () => {
      this.ctx.logger.debug("consumer event", {
        event: "trackended",
        kind: request.kind,
      });
      this._deleteConsumers(peer, [consumer.id]);
    });

    // if (this.call.options.call.stats != null) {
    //   const statsIntervalDefault = 5000;
    //   statsCollector.attachTo(
    //     this.ctx.logger,
    //     consumer,
    //     this.call.options.call.stats.statsInterval || statsIntervalDefault,
    //     this.ctx.statsDebugLogs,
    //     "consumer",
    //     this.call.call.id,
    //   );
    // }
    anomalies.watchCpuSpikes(this.ctx.logger, consumer);
    // consumer.on("webrtc-stats", (stats: statsCollector.WebrtcStats) => {
    //   this.call.emit("webrtc-stats", stats);
    // });
    consumer.on("webrtc-anomaly" as any, (anomaly: AnomalyEvent) => {
      this.emit("webrtc-anomaly", anomaly);
    });

    const { encodings } = consumer.rtpParameters;
    if (encodings == null || encodings?.length === 0) {
      this.ctx.logger.error("call: _onNewConsumer error", { err: "consumer doesn't have any encodings" });
      response(null, "consumer doesn't have any encodings");
      return;
    }

    const { spatialLayers, temporalLayers } = parseScalabilityMode(encodings[0]?.scalabilityMode ?? "");
    const { streamName } = consumer.appData;
    if (typeof streamName !== "string") {
      this.ctx.logger.error("call: _onNewConsumer error", {
        err: "consumer.appData doesn't have a proper stream name",
      });
      response(null, "consumer.appData doesn't have a proper stream name");
      return;
    }

    if (peer.id == null) {
      this.ctx.logger.error("call: _onNewConsumer error", { err: "peer.id is null" });
      response(null, "peer.id is null");
      return;
    }

    const dontAdd = typeof request === "object" && (request as any).dontAdd;

    // create specific pause and resume controls so that we
    // can notify the SFU
    const pause = (): void => {
      consumer.pause();
      const args = {
        consumerId: consumer.id,
      };
      this.call._request("pauseConsumer", args).catch((err) => {
        const inner = err instanceof Error ? err : null;
        const vdcerr = new WSRequestError("pause: ws request error", {
          inner,
          request: "pauseConsumer",
          args,
          internalCall: this.call,
        });
        this.call.emit("error", vdcerr as any);
      });
    };

    const resume = (): void => {
      consumer.resume();
      const args = {
        consumerId: consumer.id,
      };
      this.call._request("resumeConsumer", args).catch((err) => {
        const inner = err instanceof Error ? err : null;
        const vdcerr = new WSRequestError("resume: ws request error", {
          inner,
          request: "resumeConsumer",
          args,
          internalCall: this.call,
        });
        this.call.emit("error", vdcerr as any);
        return vdcerr;
      });
    };
    let [activeLayer, layers]: [BitrateLayer | null, BitrateLayer[]] = [null, []];
    if (!dontAdd) {
      // @todo: move it to CALL_ADD_PEER or something like that

      // save if the call is closed/closing before entering try/catch block
      const closed = this._closed || this.call._closed;

      if (consumer.kind === "video") {
        try {
          [activeLayer, layers] = await this.getLayers(streamName, peer.id);
        } catch (err) {
          const currentlyClosed = this._closed || this.call._closed;

          //if the call was closed during async request sallow error
          if (!closed && currentlyClosed) {
            return;
          }
          throw err;
        }
      }
    }

    const ev: CallEvents["CALL_ADD_CONSUMER"] = {
      peerId: peer.id,
      streamName,
      consumer: {
        id: consumer.id,
        peerId: peer.id,
        kind: consumer.kind,
        streamName: String(consumer.appData.streamName),
        supported: true, // TODO: consumer.supported not supported - if we have a consumer it should be supported

        locallyPaused: false,
        paused: consumer.paused,

        track: consumer.track,
        codec: consumer.rtpParameters.codecs[0]?.mimeType.split("/")[1] ?? "",
        pause,
        resume,
        layers,
        activeLayer,

        preferredSpatialLayer: spatialLayers - 1,
        preferredTemporalLayer: temporalLayers - 1,
        rtpParameters: consumer.rtpParameters,
        dontAdd,
      },
    };
    this.call.emit("CALL_ADD_CONSUMER", ev);

    if (!dontAdd) {
      // TODO: this seems unnecessary since we already have the track but it's required for the external api
      this.call.emit("CALL_SET_CONSUMER_TRACK", {
        peerId: peer.id,
        streamName,
        consumerId: consumer.id,
        track: consumer.track,
      });

      this.call.emit("PEER_CHANGE", { peer, action: "consumer_added" });
    }
    response({});
  }

  hasConsumerId(consumerId: string): boolean {
    return Array.from(this.peers.values()).some((peerParam) => {
      return peerParam.consumers?.some((consumer) => consumer.id === consumerId);
    });
  }

  _peerEvent<T extends PeerRequest>(
    event: string,
    cb: (peer: PeerParameters, request: T, response: PeerResponse) => Promise<void>,
    errCb: (err: Error | null, request: T) => void,
  ): void {
    const transport = this.call._wsTransport;
    if (transport == null) {
      this.emitErrorDeprecated(new InternalError("call: peerEvent error: wsTransport is null", {}));
      return;
    }

    const listener = async (req: T, resp: PeerResponse): Promise<void> => {
      if (req.peerId == null || req.peerId === "") {
        this.ctx.logger.warn("invalid peer event request: no peerId", {
          event,
          req: req as Json,
        });
        resp(null, "invalid peer event request: no peerId");
        return;
      }

      const peer = this.peers.get(req.peerId);
      if (peer == null) {
        this.ctx.logger.warn("peer not found for peer event", {
          event,
          req: req as Json,
        });
        resp(null, "peer not found for peer event");
        return;
      }
      this.ctx.logger.debug("peer event", {
        event,
        req: req as Json,
      });
      try {
        await cb(peer, req, resp);
      } catch (err) {
        errCb(err instanceof Error ? err : null, req);
        if (typeof resp === "function") {
          if (err instanceof Error) {
            resp(null, `peer event error: ${err.message}`);
          } else {
            resp(null, "peer event error: undefined error");
          }
        } else {
          this.ctx.logger.debug(`BUG: check '${event}' event in PvcTransport, it doesn't provide 'resp' callback`, {
            aggregates: { bug: true },
          });
        }
      }
    };
    transport.on(event, listener);
    this.addInnerDisposer(() => {
      transport.off(event, listener);
    });
  }

  async _onPeerClosed(peer: PeerParameters): Promise<void> {
    if (peer.id == null) {
      this.emitErrorDeprecated(new InternalError("peerId is undefined", {}));
      return;
    }

    if (this.peers.has(peer.id)) {
      this.peers.delete(peer.id);

      const consumerIds = peer.consumers?.map((consumer) => consumer.id);
      await this._deleteConsumers(peer, consumerIds ?? []);

      if (this.call._closed) {
        return;
      }
      this.call.emit("CALL_REMOVE_PEER", { peerId: peer.id ?? "" });
      this.call.emit("PEER_CHANGE", { peer, action: "removed" });
    }
  }

  _producerEvent(
    event: string,
    cb: (producer: types.Producer, req: { peerId: string; streamName: string; producerId: string }) => Promise<void>,
    errCb: (err: Error | null, request: { peerId: string; streamName: string; producerId: string }) => void,
  ): void {
    const transport = this.call._wsTransport;
    if (transport == null) {
      this.ctx.logger.error("call: produceEvent error", { err: "call._wsTransport is null" });
      return;
    }

    const listener = async (req: { peerId: string; producerId: string; streamName: string }): Promise<void> => {
      if (req.peerId !== this.call.peerId) {
        this.ctx.logger.debug("wrong peer ID for producer event", {
          event,
          req,
        });
        return;
      }

      const producer = this._producers.get(req.producerId);
      if (producer == null) {
        console.trace("producer not found for producer event", {
          event,
          req,
        });
        return;
      }

      try {
        await cb(producer, req);
      } catch (err) {
        errCb(err instanceof Error ? err : null, req);
      }
    };
    transport.on(event, listener);
    this.addInnerDisposer(() => {
      transport.off(event, listener);
    });
  }

  _consumerEvent<T extends WSRequest>(
    event: string,
    cb: (consumer: types.Consumer, peer: PeerParameters, req: T) => Promise<void>,
    errCb: (err: Error | null, request: T) => void,
  ): void {
    const transport = this.call._wsTransport;
    if (transport == null) {
      this.ctx.logger.error("call: consumerEvent error", { err: "call._wsTransport is null" });
      return;
    }

    const listener = async (req: T): Promise<void> => {
      const peer = this.peers.get(req.peerId);
      if (peer == null) {
        this.ctx.logger.debug("peer not found for consumer event", {
          event,
          req: req as any,
        });
        return;
      }
      const consumer = peer.consumers?.find((c) => c.id === req.consumerId);
      if (consumer == null) {
        if (event !== "consumerClosed") {
          this.ctx.logger.debug("consumer not found for consumer event", {
            event,
            req: req as any,
          });
        }
        return;
      }

      this.ctx.logger.debug("consumer event", {
        event,
        req: req as any,
      });
      try {
        await cb(consumer, peer, req);
      } catch (err) {
        errCb(err instanceof Error ? err : null, req);
      }
    };
    transport.on(event, listener);
    this.addInnerDisposer(() => {
      transport.off(event, listener);
    });
  }

  _deleteConsumers(peer: PeerParameters, consumerIds: string[]): Promise<void> {
    consumerIds.forEach((id) => {
      const consumer = peer.consumers?.find((c) => c.id === id);
      if (consumer == null) {
        return;
      }
      this.call.emit("CALL_REMOVE_CONSUMER", {
        peerId: peer.id ?? "",
        streamName: String(consumer.appData.streamName),
        consumerId: consumer.id,
      });
      this.call.emit("PEER_CHANGE", { peer, action: "consumer_removed" });
      const i = peer.consumers?.indexOf(consumer);
      peer.consumers?.splice(i ?? 0, 1);
    });
    return Promise.resolve();
  }

  async _onConsumerClosed(consumer: types.Consumer, peer: PeerParameters): Promise<void> {
    consumer.close();
    const interval = this._consumerStatIntervals.get(consumer.id);
    clearInterval(interval);
    this._consumerStatIntervals.delete(consumer.id);
    await this._deleteConsumers(peer, [consumer.id]);
  }

  _onConsumerPaused(consumer: types.Consumer, peer: PeerParameters): Promise<void> {
    consumer.pause();
    this.call.emit("CALL_SET_CONSUMER_PAUSED", {
      peerId: peer.id ?? "",
      streamName: String(consumer.appData.streamName),
      consumerId: consumer.id,
      originator: "peer",
    });
    return Promise.resolve();
  }

  _onConsumerResumed(consumer: types.Consumer, peer: PeerParameters): Promise<void> {
    consumer.resume();
    this.call.emit("CALL_SET_CONSUMER_RESUMED", {
      peerId: peer.id ?? "",
      streamName: String(consumer.appData.streamName),
      consumerId: consumer.id,
      originator: "peer",
    });
    return Promise.resolve();
  }

  async _onConsumerScore(consumer: types.Consumer, peer: PeerParameters): Promise<void> {
    // TODO: Don't currently have this feature - need to create event and emit
  }

  async _onConsumerLayersChanged(consumer: types.Consumer, peer: PeerParameters, req: ConsumerLayer): Promise<void> {
    this.call.emit("CALL_CONSUMER_LAYER_CHANGED", req);
    return Promise.resolve();
  }

  async _onSwitchConsumerTrack(
    peer: PeerParameters,
    req: SwitchConsumerTrackRequest,
    resp: PeerResponse,
  ): Promise<void> {
    const peerId = peer.id;
    if (peerId == null) {
      return;
    }

    const preparedConsumers = new Map<string, { add: MediaStreamTrack[]; remove: MediaStreamTrack[] }>();

    for (const rc of req.add) {
      const consumer = peer.consumers?.find((c) => c.id === rc.consumerId);
      if (!consumer) {
        const err = new SFUSwitchConsumerTrackEventError("unable to switch tracks: consumer not found", {
          consumerId: rc.consumerId,
          peer: this,
          request: req as Json,
        });
        this.emitErrorDeprecated(err);
        return;
      }

      const streamName = String(consumer.appData.streamName);

      if (!preparedConsumers.has(streamName)) {
        preparedConsumers.set(streamName, { add: [], remove: [] });
      }

      preparedConsumers.get(streamName)?.add.push(consumer.track);
    }

    for (const rc of req.remove) {
      const consumer = peer.consumers?.find((c) => c.id === rc.consumerId);
      if (!consumer) {
        const err = new SFUSwitchConsumerTrackEventError("unable to switch tracks: consumer not found", {
          consumerId: rc.consumerId,
          peer: this,
          request: req as Json,
        });
        this.emitErrorDeprecated(err);
        return;
      }

      const streamName = String(consumer.appData.streamName);

      if (!preparedConsumers.has(streamName)) {
        preparedConsumers.set(streamName, { add: [], remove: [] });
      }
      preparedConsumers.get(streamName)?.remove.push(consumer.track);
    }

    for (const [streamName, consumers] of preparedConsumers.entries()) {
      if (consumers.add.length === 0 || consumers.remove.length === 0) {
        const err = new SFUSwitchConsumerTrackEventError(
          "unable to switch tracks: consumers have different stream names",
          {
            peer: this,
            request: req as Json,
          },
        );
        this.emitErrorDeprecated(err);
        return;
      }

      this.call.emit("CALL_SWAP_CONSUMERS", {
        peerId,
        streamName,
        add: consumers.add,
        remove: consumers.remove,
      });
    }

    resp({});
  }

  _onProducerClosed(producer: types.Producer, req: { streamName: string; producerId: string }): Promise<void> {
    if (producer.closed) {
      return Promise.resolve();
    }
    this.call.emit("CALL_REMOVE_PRODUCER", {
      streamName: req.streamName,
      producerId: req.producerId,
      originator: "client",
    });
    producer.close();
    return Promise.resolve();
  }

  _onProducerPaused(producer: types.Producer): Promise<void> {
    producer.pause();
    return Promise.resolve();
  }

  _onProducerResumed(producer: types.Producer): Promise<void> {
    producer.resume();
    return Promise.resolve();
  }

  // TODO: don't have profile change - this._emit(events.setConsumerEffectiveProfile(consumer.peer.name, consumer.appData.streamName, consumer.id, profile));
  async produce(track: MediaStreamTrack, options: PeerProducerOptions, appData: AppData): Promise<types.Producer> {
    if (this.sendLock != null) {
      try {
        await this.sendLock.ready();
      } catch (ex) {
        throw new Error("unable to acquire a lock");
      }
    } else {
      try {
        await this._createSendTransport();
      } catch (err) {
        const msg = err instanceof Error ? err.message : "unknown error";
        this.ctx.logger.error("unable to create send transport", { err: msg });
        throw err;
      }
    }

    if (this._sendTransport == null) {
      const err = new Error("_sendTransport is null");
      this.ctx.logger.error("ms3.produce error", { err: err.message });
      throw err;
    }

    // If simulcast options are present, use encodings option with maxBitrate settings
    let producer;
    const { simulcast: { maxBitrates } = {} } = options;
    // MediaStreamTrack.kind returns a string set to "video" if it is a video track. Only set simulcast options for video tracks.
    if (maxBitrates && track.kind === "video") {
      const opts = {
        ...options,
        track,
        appData,
        // We only support two maxBitrates
        encodings: [
          { maxBitrate: maxBitrates[0], dtx: true },
          { maxBitrate: maxBitrates[1], dtx: true },
        ],
        stopTracks: false,
      };

      if (options.audioOnly) {
        opts.appData.audioOnly = options.audioOnly;
      }

      if (options.videoOnly) {
        opts.appData.videoOnly = options.videoOnly;
      }

      opts.appData.trackEnabled = opts.track.enabled;
      opts.appData.userId = this.call.user?.userId;
      opts.appData.displayName = this.call.user?.displayName;

      this.ctx.logger.debug("create maxBitrates producer", {
        ...opts,
        track: null,
        appData: null,
      });

      producer = await this._sendTransport.produce(opts);
    } else {
      const opts = {
        ...options,
        track,
        appData,
        stopTracks: false,
      };

      if (options.audioOnly) {
        opts.appData.audioOnly = options.audioOnly;
      }

      if (options.videoOnly) {
        opts.appData.videoOnly = options.videoOnly;
      }

      opts.appData.trackEnabled = opts.track.enabled;
      opts.appData.userId = this.call.user?.userId;
      opts.appData.displayName = this.call.user?.displayName;

      this.ctx.logger.debug("create basic producer", {
        ...opts,
        track: null,
        appData: null,
      });

      if (
        opts.encodings &&
        opts.encodings.length > 1 &&
        device.isIosDevice &&
        device.isImplements(Feature.BROWSER_TYPE) &&
        device.browserInfo.name === "chrome"
      ) {
        opts.encodings = [{ maxBitrate: 2500000 }];
        this.emitError(
          createError(ErrorCode.SimulcastDisabled, "simulcast is disabled due to ios chrome limitations", {}),
        );
      }

      producer = await this._sendTransport.produce(opts);
    }
    this._producers.set(producer.id, producer); // not sure if we need to keep a ref

    this.ctx.logger.debug("ms3.produce", {
      appData: appData as any,
      trackLabel: track.label,
    });

    producer.on("transportclose", () => {
      this.ctx.logger.debug("ms3.produce: transport closed", {
        trackLabel: track.label,
        appData: appData as any,
      });
    });
    producer.on("trackended", () => {
      this.ctx.logger.debug("ms3.produce: track ended", {
        trackLabel: track.label,
        appData: appData as any,
      });
    });

    return producer;
  }

  private clear(debugString?: string): void {
    this.statsCollector.dispose(debugString);

    this._iceTimeouts.forEach((to) => {
      device.clearTimeout(to);
    });
    this._iceTimeouts.clear();

    if (this._recvTransport != null) {
      this._recvTransport.close();
    }
    if (this._sendTransport != null) {
      this._sendTransport.close();
    }
  }

  async close(debugString = "Implementer did not pass debugString", internal = false): Promise<void> {
    if (this._closed) {
      return;
    }

    this.clear(debugString);

    this._closed = true;

    if (this.call._wsTransport == null) {
      this.ctx.logger.debug("close error", { err: "call._wsTransport is null" });
      return;
    }

    try {
      await this.call._wsTransport.request("closePeer", { internal });
    } catch (err) {
      const msg = err instanceof Error ? err.toString() : "unknown error";
      this.ctx.logger.info("ms3.close: error closing peer on the server", {
        aggregates: { err: msg, internal, reason: debugString },
      });
    }
  }

  _initLock(): Lock {
    const lock: Lock = {} as Lock;

    lock.promise = new Promise((resolve, reject) => {
      lock.resolve = () => {
        lock.resolved = true;
        resolve();
      };
      lock.reject = (err) => {
        if (lock.resolved) {
          lock.promise = Promise.reject(err);
        } else {
          reject(err);
        }
      };
    });

    lock.ready = () => lock.promise;
    return lock;
  }

  toJSON(): Json {
    return {
      peers: Array.from(this.peers.values()).map((p) => {
        return {
          id: p.id,
          name: p.name,
          peerId: p.peerId,
          userId: p.userId,
          displayName: p.displayName,
          scope: p.scope,
          permissions: p.permissions,
          behaviours: p.behaviours,
        } as const;
      }),

      aggregates: {
        support: this.ctx.support.hash,
        localPeerId: this.call._localPeerId,
      },
    };
  }
}
