import { StylesObject } from "../ui-lib/typings/css";

/**
 * Helper function to determine if a StylesObject property is a nested StylesObject or a css property string.
 *
 * @param item Value to check
 * @returns boolean
 */
const _isObject = (item: StylesObject | string): boolean => {
  return item != null && typeof item === "object" && !Array.isArray(item);
};

/**
 * Recursive function to merge nested JSS style objects.
 *
 * @param target Base styles. These are overwritten by source styles.
 * @param source Additional styles. These take precedence over target styles.
 * @param namespace
 * @returns StylesObject
 */
export const mergeStylesObjects = (target: StylesObject, source: StylesObject): StylesObject => {
  const output = { ...target };

  Object.keys(source).forEach((key) => {
    if (_isObject(source[key])) {
      if (!(key in target)) Object.assign(output, { [key]: source[key] });
      else {
        output[key] = mergeStylesObjects(target[key], source[key]);
      }
    } else {
      Object.assign(output, { [key]: source[key] });
    }
  });

  return output;
};
