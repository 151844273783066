import React, { ReactNode } from "react";
import { useStyles } from "../../styling";
import styles from "./styles";
import { RootStyles } from "../typings/css";

export type TooltipProps = {
  children?: ReactNode;
  classes?: RootStyles;
  active: boolean;
};

function Tooltip({ classes = {}, children, active }: TooltipProps): React.ReactElement {
  const mergedClasses = useStyles({ source: classes, target: styles }, "tooltip");

  return <div className={`${mergedClasses.root} ${active ? "active" : "inactive"}`}>{children}</div>;
}

export default Tooltip;
