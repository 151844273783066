/* eslint-disable import/no-cycle */
import type { LoggerCore } from "@video/log-client";
import { Device, types } from "mediasoup-client";
import { device } from "../../../api/adapter";
import { Feature } from "../../../api/adapter/features/feature";
import { VP8_CAPABILITY, OPUS_CAPABILITY, H264_CAPABILITY } from "../browser-support";
import { MockMediasoupClientDevice } from "../../../mocks/mediasoup-client-device";

export async function checkCapabilitiesForRoom(
  capabilities: types.RtpCapabilities,
): Promise<{ audio: boolean; video: boolean }> {
  try {
    let dev;
    if (process.env.TEST != null) {
      dev = new MockMediasoupClientDevice();
    } else {
      dev = new Device();
    }

    await dev.load({ routerRtpCapabilities: capabilities });

    return {
      audio: dev.canProduce("audio"),
      video: dev.canProduce("video"),
    };
  } catch (err) {
    throw new Error(`current browser/device not supported: ${err}`);
  }
}

export const hasOpusCapability = async (log?: LoggerCore): Promise<boolean> => {
  const logInfo = device.isImplements(Feature.BROWSER_TYPE)
    ? {
        ...device.browserInfo,
        platform: device.platform,
      }
    : { platform: device.platform };

  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [OPUS_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no opus capabilities support", {
      logInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.audio) {
    log?.warn("support: no opus capabilities support", {
      logInfo,
    });
    return false;
  }
  log?.debug("support: have opus capabilities support", {
    logInfo,
  });
  return true;
};

export const hasVP8Capability = async (log?: LoggerCore): Promise<boolean> => {
  const logInfo = device.isImplements(Feature.BROWSER_TYPE)
    ? {
        ...device.browserInfo,
        platform: device.platform,
      }
    : { platform: device.platform };

  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [VP8_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no vp8 capabilities support", {
      logInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.video) {
    log?.warn("support: no vp8 capabilities support", {
      logInfo,
    });
    return false;
  }
  log?.debug("support: have vp8 capabilities support", {
    logInfo,
  });
  return true;
};

export const hasH264Capability = async (log?: LoggerCore): Promise<boolean> => {
  const logInfo = device.isImplements(Feature.BROWSER_TYPE)
    ? {
        ...device.browserInfo,
        platform: device.platform,
      }
    : { platform: device.platform };

  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [H264_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no h264 capabilities support", {
      logInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.video) {
    log?.warn("support: no h264 capabilities support", {
      logInfo,
    });
    return false;
  }
  log?.debug("support: have h264 capabilities support", {
    logInfo,
  });
  return true;
};

export const hasOpus = (): true => true;

// to mock globalThis.document in tests
export const getGlobalDocument = (): any => {
  return (globalThis as any).document;
};

export const hasVP8 = (): boolean => {
  try {
    if (getGlobalDocument() == null) {
      // if we don't have document, we're polyfilling code support anyway
      return true;
    }
  } catch (ex) {
    return true;
  }

  if (device.isImplements(Feature.BROWSER_TYPE)) {
    return (
      device.browserInfo.osname !== "ios" &&
      device.browserInfo.name !== "safari" &&
      device.browserInfo.name !== "edge" &&
      device.browserInfo.name !== "microsoft edge"
    );
  }
  return true;
};

export const hasH264 = (log?: LoggerCore): boolean => {
  try {
    if (getGlobalDocument() == null) {
      // if we don't have document, we're polyfilling code support anyway
      return true;
    }
  } catch (ex) {
    return true;
  }

  if (device.isImplements(Feature.BROWSER_TYPE)) {
    if (device.browserInfo.name === "chrome") {
      if (device.isImplements(Feature.MEDIA_RECORDER)) {
        if (device.MediaRecorder.isTypeSupported("video/webm; codecs=h264")) {
          log?.debug("support: h264 support: chrome");
          return true;
        }
        log?.warn("support: no h264 support", {
          reason: "chrome without h264",
          ...device.browserInfo,
          platform: device.platform,
        });
      } else {
        log?.flush();
        return false;
      }
    }
  }

  if (device.isAndroidDevice) {
    if (device.isImplements(Feature.BROWSER_TYPE)) {
      log?.warn("support: no h264 support", {
        reason: "android device",
        ...device.browserInfo,
        platform: device.platform,
      });
    } else {
      log?.warn("support: no h264 support", {
        reason: "android device",
        platform: device.platform,
      });
    }
    return false;
  }
  return device.isImplements(Feature.MEDIA_DEVICE) && device.isCodecSupported("h264");
};
