import { RootStyles } from "../typings/css";

const styles: RootStyles = {
  root: {
    transition: "opacity 0.3s ease-in-out, transform 0.5s ease-in-out",
    position: "absolute",
    bottom: "50px",
    "&.active": {
      opacity: 1,
    },
    "&.inactive": {
      transform: "translateY(50px)",
      opacity: 0,
      visibility: "hidden",
    },
  },
};

export default styles;
