import { Level } from "@video/log-node";

export const browserStyles: Record<Level, string> = {
  trace: "color: rgba(169,184,186,0.6)",
  debug: "color: #a9b8ba",
  network: "color: #a9b8ba",
  timing: "color: #a9b8ba",
  local: "color: #a9b8ba",
  info: "",
  warn: "color: #c0a044",
  notice: "color: #c0a044",
  deprecated: "color: #c0a044",
  error: "color: #f44336",
  fatal: "color: #f44336",
};

export const ttySyles: Record<Level, string> = {
  trace: "\x1b[38;5;239m",
  debug: "\x1b[38;5;239m",
  network: "\x1b[38;5;239m",
  timing: "\x1b[38;5;239m",
  local: "\x1b[38;5;239m",
  info: "\x1b[38;5;231m",
  warn: "\x1b[38;5;226m",
  notice: "\x1b[38;5;226m",
  deprecated: "\x1b[38;5;226m",
  error: "\x1b[38;5;196m",
  fatal: "\x1b[38;5;196m",
};
