import { ObservableEventEmitter, types } from "@video/video-client-core";
import { makeObservable, observable, reaction } from "mobx";

export type CallStateEvents = {
  call: types.CallAPI | null;
};

export default class CallState extends ObservableEventEmitter<CallStateEvents> {
  call: types.CallAPI | null = null;

  broadcast: types.BroadcastAPI | null = null;

  zeroBitrateHandler: ((ev: types.BroadcastEvents["zeroBitrate"]) => void)[] = [];

  constructor() {
    super();
    makeObservable(this, {
      // observers
      call: observable,
      broadcast: observable,
    });

    this.addInnerDisposer(
      reaction(
        () => this.call,
        (newCall, oldCall) => {
          if (oldCall != null) {
            oldCall.off("zeroBitrate", this.onZeroBitrate);
          }
          if (newCall != null) {
            newCall.on("zeroBitrate", this.onZeroBitrate);
          }
        },
      ),
    );
  }

  onZeroBitrate(ev: types.BroadcastEvents["zeroBitrate"]): void {
    this.zeroBitrateHandler.forEach((h) => h(ev));
  }

  /**
   * Check if the broadcast is active.
   */
  get isBroadcasting(): boolean {
    return this.broadcast?.state === "active";
  }

  //
  // get isOwner(): boolean {
  //   return this.call?.isOwner ?? false;
  // }
  //
  async toggleBroadcast(
    callOptionsOrId: string | types.CallOptions,
    broadcastOptions: types.BroadcastOptions,
    mediaStreamController: types.MediaStreamControllerAPI,
    videoClient: types.VideoClientAPI,
  ): Promise<void> {
    if (this.isBroadcasting) {
      return this.stopBroadcast();
    }

    if (typeof callOptionsOrId === "string") {
      return this.startBroadcastOnExistingCall(callOptionsOrId, broadcastOptions, mediaStreamController, videoClient);
    }
    return this.startBroadcastOnNewCall(callOptionsOrId, broadcastOptions, mediaStreamController, videoClient);
  }

  async startBroadcastOnNewCall(
    callOptions: types.CallOptions,
    broadcastOptions: types.BroadcastOptions,
    mediaStreamController: types.MediaStreamControllerAPI,
    videoClient: types.VideoClientAPI,
  ): Promise<void> {
    if (this.call != null) {
      throw new Error("Cannot start broadcast on a call that is already active");
    }

    this.call = await videoClient.createCall(callOptions);
    return this.startBroadcastOnExistingCall(this.call.id, broadcastOptions, mediaStreamController, videoClient);
  }

  /**
   * Joins the existing call and starts the broadcast.
   */
  async startBroadcastOnExistingCall(
    callId: string,
    options: types.BroadcastOptions,
    mediaStreamController: types.MediaStreamControllerAPI,
    videoClient: types.VideoClientAPI,
  ): Promise<void> {
    if (this.broadcast != null) {
      throw new Error("Broadcast is already active");
    }

    if (this.call == null || this.call.state !== "active") {
      this.call = await videoClient.joinCall(callId);
    }
    this.call.on("viewerKicked", () => this.stopBroadcast());
    this.broadcast = await this.call.broadcast(mediaStreamController, options);
  }

  async stopBroadcast(): Promise<void> {
    if (this.call?.isOwner) {
      this.call?.dispose("call disposed via callState stopBroadcast()");
      this.call?.close("Closing via callState stopBroadcast()");
      this.call = null;
    } else {
      this.broadcast?.dispose("broadcast disposed via callState stopBroadcast()");
    }
    this.broadcast = null;
  }
}
