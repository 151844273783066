import { WebDevice } from "../../internal/adapters/web-device";
import { DeviceAPI } from "../device";
import { Feature } from "./features/feature";

export { Feature, Features } from "./features/feature";
export * from "./features/types";

// that's intentionally mutable
// eslint-disable-next-line import/no-mutable-exports
export let device: DeviceAPI = new WebDevice();

let resolvePromise: (value: DeviceAPI | PromiseLike<DeviceAPI>) => void;
export const onceDeviceReady: Promise<DeviceAPI> = new Promise((r) => {
  resolvePromise = r;
});

export function implement(_device: DeviceAPI): void {
  device = _device;
  resolvePromise(device);
}

/**
 * @deprecated use adapter.device.isAndroidDevice
 */
export function isAndroidDevice(): boolean {
  return device.isAndroidDevice;
}

/**
 * @deprecated use adapter.device.isIosDevice
 */
export function isIosDevice(): boolean {
  return device.isIosDevice;
}

/**
 * @deprecated use adapter.device.isMobileDevice
 */
export function isMobileDevice(): boolean {
  return device.isMobileDevice;
}

/**
 * @deprecated use device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "safari"
 */
export function isSafari(): boolean {
  return device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "safari";
}
