import { MediaStream } from "../adapter/features/media-stream";

export type Listener<T> = (ev: T) => void;

export interface DomEventEmitter<M> {
  addEventListener<K extends keyof M | "newListener">(
    type: K,
    listener: Listener<(M & { newListener: keyof M })[K]>,
  ): void;
  removeEventListener<K extends keyof M | "newListener">(
    type: K,
    listener?: (ev: (M & { newListener: keyof M })[K]) => void,
  ): void;
}

export interface VideoElementEventsMap {
  play: void;
  pause: void;
  volumechange: void;
  timeupdate: void;
  progress: void;
  ended: void;
  loadedmetadata: void;
  canplay: void;
  error: Event;
  loadeddata: void;

  suspend: void;

  // can be ignored on non-DOM implementation
  webkitendfullscreen: void;

  "vdc:timeupdatePause": void;
  "vdc:timeupdateResume": void;
}

export interface TimeRanges {
  readonly length: number;
  end(index: number): number;
  start(index: number): number;
}

export function isMediaStream(value: unknown): value is MediaStream {
  if (typeof value !== "object" || value == null) {
    return false;
  }
  return "getAudioTracks" in value && "getVideoTracks" in value;
}


export interface MediaError {
  readonly code: MediaErrorCodeConstants;
  readonly message: string;
}

export enum MediaErrorCodeConstants {
  UNKNOWN = 0,
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4,
  MEDIA_ERR_ENCRYPTED = 5,
}

export function isVideoElement(el: VideoElement | unknown ): el is VideoElement {
  return  (el as VideoElement)?.src !== undefined;
}
export interface VideoElement extends DomEventEmitter<VideoElementEventsMap> {
  // mutable properties
  muted: boolean;
  volume: number;
  currentTime: number;
  srcObject: MediaStream | unknown | Blob | File;
  playbackRate: number;
  src: string;
  autoplay: boolean;

  // readonly properties
  readonly paused: boolean;
  readonly duration: number;
  readonly buffered: TimeRanges;
  readonly error: MediaError | null;
  readonly readyState: number;
  readonly ended: boolean;
  readonly videoWidth: number;
  readonly videoHeight: number;

  // this method can be ignored on non-DOM implementations
  setAttribute(key: "class", value: string): void;
  setAttribute(key: "webkit-playsinline", value: "true" | "false"): void;
  setAttribute(key: "poster", value: string): void;
  setAttribute(key: "playsinline", boolean: "true" | "false"): void;
  setAttribute(key: "type", mimetype: string): void;
  setAttribute(key: "src", value: string): void;

  getAttribute(key: "playsinline"): string|null;
  getAttribute(key: "webkit-playsinline"): string|null;
  getAttribute(key: "poster"): string|null;

  // methods
  play(): Promise<void>;
  pause(): void;
  load?(): void;
  captureStream?(): MediaStream;
}
