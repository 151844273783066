import React, { ReactNode, useRef, useState, Children, useEffect, useCallback } from "react";
import { Properties } from "csstype";
import { useStyles } from "../../styling";
import styles, {
  getButtonStyles,
  getCarouselSlideStyles,
  getCarouselSlidesWrapperStyles,
  getChevronStyles,
  getRootStyles,
} from "./styles";
import ChevronDownIcon from "../Icons/ChevronDown";

type CarouselProp = {
  classes?: Properties;
  itemsPerSlide?: number;
  gap?: number;
  children: ReactNode[];
  vertical?: boolean;
};

function Carousel({ classes, itemsPerSlide = 3, gap = 10, children, vertical = true }: CarouselProp): JSX.Element {
  const mergedClasses = useStyles({ target: classes ?? {}, source: styles }, "carousel");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselDrivingDimension, setCarouselDrivingDimension] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const lastSlideIndex = children.length - itemsPerSlide < 0 ? 0 : children.length - itemsPerSlide;
  const isLastSlide = lastSlideIndex === currentIndex;
  const isFirstSlide = currentIndex === 0;
  const shouldRenderButtons = children.length > itemsPerSlide;

  if (lastSlideIndex < currentIndex) {
    setCurrentIndex(lastSlideIndex);
  }

  const calculateCarouselDimension = useCallback(() => {
    if (!containerRef.current) return;

    const height = containerRef.current.offsetHeight;
    const width = containerRef.current.offsetWidth;

    if (vertical) {
      if (height === 0) {
        console.error("Carousel component must be placed inside a fixed height container.");
        return;
      }

      const numberOfGaps = itemsPerSlide - 1;
      setCarouselDrivingDimension((height - gap * numberOfGaps) / itemsPerSlide);
    } else {
      if (width === 0) {
        console.error("Carousel component must be placed inside a fixed width container.");
        return;
      }

      const numberOfGaps = itemsPerSlide - 1;

      setCarouselDrivingDimension((width - gap * numberOfGaps) / itemsPerSlide);
    }
  }, [gap, itemsPerSlide, vertical]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!containerRef.current) return () => {};

    calculateCarouselDimension();
    const observer = new ResizeObserver(calculateCarouselDimension);
    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [calculateCarouselDimension]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 10);
  }, [carouselDrivingDimension]);

  const handleNext = (): void => {
    setCurrentIndex((prev) => {
      const newIndex = prev + 1;
      if (newIndex > children.length - itemsPerSlide) return prev;
      return newIndex;
    });
  };

  const handlePrev = (): void => {
    setCurrentIndex((prev) => {
      const newIndex = prev - 1;
      if (newIndex < 0) return prev;
      return newIndex;
    });
  };

  return (
    <div className={mergedClasses.root} style={getRootStyles(vertical)}>
      <button
        type="button"
        className={mergedClasses.carouselButton}
        onClick={handlePrev}
        style={getButtonStyles(vertical, isFirstSlide, shouldRenderButtons)}
      >
        <ChevronDownIcon style={getChevronStyles(vertical, shouldRenderButtons, "up")} />
      </button>
      <div ref={containerRef} className={mergedClasses.slidesWrapper}>
        <div className={mergedClasses.carouselSlidesWrapper} style={getCarouselSlidesWrapperStyles(vertical, gap)}>
          {Children.map(children, (child, idx) => (
            <div
              className={mergedClasses.carouselItem}
              style={getCarouselSlideStyles({
                carouselDrivingDimension,
                currentIndex,
                isVertical: vertical,
                gap,
                isLoading,
              })}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className={mergedClasses.carouselButton}
        onClick={handleNext}
        style={getButtonStyles(vertical, isLastSlide, shouldRenderButtons)}
      >
        <ChevronDownIcon style={getChevronStyles(vertical, shouldRenderButtons, "down")} />
      </button>
    </div>
  );
}

export default Carousel;
