import { adapter } from "@video/video-client-core";
import { RootStyles, StylesObject } from "../typings/css";

export interface SidebarStyles extends RootStyles {
  closeButton?: StylesObject;
}

const styles: SidebarStyles = {
  root: {
    // Container style-related properties
    backgroundColor: "rgba(255, 255, 255, 0.90)",
    "backdrop-filter": "blur(10px)",
    boxShadow: "6px 0px 12px 0px rgba(43,43,43,0.33)",
    fontFamily: "sans-serif",
    height: "auto",
    overflow: adapter.device.isMobileDevice ? "auto" : "hidden",
    transition: "left 1s",
    transitionProperty: "opacity, left, top",
    transitionDuration: ".33s",
    transitionTimingFunction: "ease-out",
    width: "40%",
    zIndex: 140,

    // Container layout-related properties
    bottom: 0,
    left: "-100vw",
    padding: adapter.device.isMobileDevice ? "15px" : "0 1.5rem",
    position: "absolute",
    top: 0,
    display: "grid",
    gap: "10px 0px",
    gridAutoColumns: "1fr",
    gridTemplateRows: "1fr 10fr 1fr",

    "@media only screen and (max-width: 768px)": {
      width: "initial",
    },

    "@media only screen and (min-width: 1200px)": {
      top: 0,
      left: "-100vw",
      width: "40%",

      "&.open": {
        left: 0,
      },
    },

    "&.open": {
      left: 0,
    },

    "&.dark-shelf": {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
  },
  closeButton: {
    width: "15px",
  },
};

export default styles;
