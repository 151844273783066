/* eslint-disable import/no-cycle */
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { device } from "@video/video-client-core/lib/api/adapter";
import { MultistreamContext, PlayerUiContext } from "../../../context";
import { MultistreamState } from "../../../../store/multistream";
import { useUndefinedStoreError } from "../../ErrorBoundary";
import MultistreamPlayer from "./MultistreamPlayer";
import { RootStyles, useStyles } from "../../../styling";
import { playersWrapperStyles } from "./styles";
import { useRefDimensions } from "../../../ui-lib/Hooks/useRefDimensions";

interface MultistreamPlayersGridProps {
  classes?: RootStyles;
}

const MultistreamPlayersGrid = observer(({ classes }: MultistreamPlayersGridProps) => {
  const mergedClasses = useStyles({ target: classes ?? {}, source: playersWrapperStyles }, "multistream-players-grid");
  const ctx = useContext<MultistreamState | null>(MultistreamContext);
  const [render, reRender] = useState(false);

  useUndefinedStoreError(ctx != null, "<MultistreamPlayersGrid/>");

  const { width, height } = useRefDimensions(ctx.playersWrapperElement);

  useEffect(() => {
    const updateReRender = (): void => {
      reRender((prev) => !prev);
    };
    device.addEventListener("resize", updateReRender);
    updateReRender();

    return () => device.removeEventListener("resize", updateReRender);
  }, [width, height]);

  const { playersWrapperElement, activePublicKeys, streams } = ctx;

  return (
    <div ref={playersWrapperElement} className={mergedClasses.root}>
      {activePublicKeys.map((publicKey) => (
        <PlayerUiContext.Provider key={publicKey} value={streams[publicKey]?.playerUi}>
          <MultistreamPlayer publicKey={publicKey} reRender={render} />
        </PlayerUiContext.Provider>
      ))}
    </div>
  );
});

export default MultistreamPlayersGrid;
