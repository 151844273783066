/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoggerCore } from "@video/log-client";
import source from "@video/log-client/lib/source";
import { Json } from "@video/log-node";
import { diff } from "deep-object-diff";
import {
  action,
  computed,
  createAtom,
  IReactionOptions,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import {
  Capturable,
  DeepReadonly,
  DeviceAPI,
  ErrorCode,
  ExistsStreamPolicy,
  FacingMode,
  MediaControllerAPI,
  MediaControllerEvents,
  MediaControllerOptions,
  MediaStreamControllerAPI,
  MediaStreamControllerEvents,
  MediaStreamControllerOptions,
} from "../api";
import { device, Feature } from "../api/adapter";
import type { AudioContext, GainNode } from "../api/adapter/features/audio-context";
import type { MediaDeviceInfo, MediaStreamConstraints } from "../api/adapter/features/media-device";
import type {
  MediaStream,
  MediaStreamTrack,
  MediaTrackConstraints,
  MediaTrackSettings,
} from "../api/adapter/features/media-stream";
import { isErrorLike, isOverconstrainedError } from "../api/typings/dom-exception";
import packageJson from "../package-json";
import { contextId, instanceId, PACKAGE_NAME } from "../utils/common";
import { createError, wrapError } from "./errors";
import { makeBounded } from "./utils/bind";
import { ObservableEventEmitter } from "./utils/events/event-emitter";

//region Default Constraints
export const VIDEO_DEVICE_SCREENCAPTURE: Readonly<MediaDeviceInfo & { virtual: true }> = {
  deviceId: "screencapture",
  groupId: "screencapture",
  label: "Screen Capture",
  kind: "videoinput",
  virtual: true,
  // it's any in original DOM type
  // eslint-disable-next-line
  toJSON(): any {
    return {
      deviceId: this.deviceId,
      groupId: this.groupId,
      label: this.label,
      kind: this.kind,
    };
  },
};

export const VIDEO_DEVICE_CAPTURABLE: Readonly<MediaDeviceInfo & { virtual: true }> = {
  deviceId: "capturable",
  groupId: "capturable",
  label: "Capturable",
  kind: "videoinput",
  virtual: true,
  // it's any in original DOM type
  // eslint-disable-next-line
  toJSON(): any {
    return {
      deviceId: this.deviceId,
      groupId: this.groupId,
      label: this.label,
      kind: this.kind,
    };
  },
};

function isVirtualDevice(dev: MediaDeviceInfo): boolean {
  return dev != null && (dev as MediaDeviceInfo & { virtual: true }).virtual;
}

/**
 * Whether a device API is running on Android via react-native
 * @param deviceApi Device
 * @returns True if running on Android via react-native, false otherwise
 */
function isReactNativeAndroidDevice(deviceApi: DeviceAPI): boolean {
  return deviceApi.isAndroidDevice && deviceApi.platform === "ReactNative";
}

export const CONSTRAINTS_AUDIO_OPTIMAL_STRICT: DeepReadonly<MediaTrackConstraints> = {
  autoGainControl: { exact: true },
  channelCount: { min: 1, ideal: 2 },
  echoCancellation: { exact: true },
  latency: { ideal: 0 },
  noiseSuppression: { exact: true },
  sampleRate: { min: 8000, ideal: 48000, max: 48000 },
  sampleSize: { min: 16, ideal: 32 },
};

export const CONSTRAINTS_AUDIO_OPTIMAL_WEAK: DeepReadonly<MediaTrackConstraints> = {
  autoGainControl: { ideal: true },
  channelCount: { min: 1, ideal: 2 },
  echoCancellation: { ideal: true },
  latency: { ideal: 0 },
  noiseSuppression: { ideal: true },
  sampleRate: { min: 8000, ideal: 48000, max: 48000 },
  sampleSize: { min: 16, ideal: 32 },
};

export const CONSTRAINTS_VIDEO_OPTIMAL_STRICT: DeepReadonly<MediaTrackConstraints> = {
  facingMode: { ideal: "user" },
  aspectRatio: { exact: 16 / 9 },
  height: device.isAndroidDevice ? { min: 240, max: 2160 } : { min: 240, ideal: 2160, max: 2160 },
  frameRate: { ideal: 24 },
};

export const CONSTRAINTS_VIDEO_OPTIMAL_WEAK: DeepReadonly<MediaTrackConstraints> = {
  facingMode: { ideal: "user" },
  aspectRatio: { ideal: 16 / 9 },
  height: device.isAndroidDevice ? { min: 240, max: 2160 } : { min: 240, ideal: 2160, max: 2160 },
  frameRate: { ideal: 24 },
};

export const CONSTRAINTS_SCREENCAPTURE_OPTIMAL_WEAK: DeepReadonly<MediaTrackConstraints> = {
  frameRate: { ideal: 24 },
};
//endregion

//region Static Properties
const MOBX_REACT_DELAY = 10;
const mediaDevicesList = observable.array<Readonly<MediaDeviceInfo>>([], { name: "mediaDevicesList" });
const videoDevices = new Proxy({} as Record<string, Readonly<MediaDeviceInfo>>, {
  get: (target, prop) => {
    if (prop === VIDEO_DEVICE_SCREENCAPTURE.deviceId) {
      return VIDEO_DEVICE_SCREENCAPTURE;
    }
    if (prop === VIDEO_DEVICE_CAPTURABLE.deviceId) {
      return VIDEO_DEVICE_CAPTURABLE;
    }
    return mediaDevicesList.find((d) => d.deviceId === prop && d.kind === "videoinput") ?? null;
  },
});

const AUDIO_GAIN_MULTIPLIER = 10;
const audioDevices = new Proxy({} as Record<string, Readonly<MediaDeviceInfo>>, {
  get: (target, prop) => {
    return mediaDevicesList.find((d) => d.deviceId === prop && d.kind === "audioinput") ?? null;
  },
});

const allDevices = new Proxy({} as Record<string, Readonly<MediaDeviceInfo>>, {
  get: (target, prop) => {
    if (prop === VIDEO_DEVICE_SCREENCAPTURE.deviceId) {
      return VIDEO_DEVICE_SCREENCAPTURE;
    }
    if (prop === VIDEO_DEVICE_CAPTURABLE.deviceId) {
      return VIDEO_DEVICE_CAPTURABLE;
    }
    return mediaDevicesList.find((d) => d.deviceId === prop) ?? null;
  },
});

const possibleResolutions = [4320, 2160, 1440, 1080, 720, 480, 240];
const tracksMeta: WeakMap<MediaStreamTrack, MediaTrackMeta> = new WeakMap();
const deviceResolutionsCache: Record<string, ResolutionInfo> = {};
let deviceUpdateTimer = 0;
let audioCtx: AudioContext | null = null;

//endregion

//region Additional Types
type MediaTrackMeta = {
  deviceId?: string;
  originalTrack?: MediaStreamTrack;
  gainNode?: GainNode;
  internallyEnded?: boolean;
};
type ResolutionInfo = [number | null, number | null, number[]];

interface MergeConstraintsOverride {
  aspectRatio: number | [number, number] | null;
  autoGainControl: boolean | null;
  echoCancellation: boolean | null;
  facingMode: FacingMode | null;
  frameRate: number | [number, number] | null;
  noiseSuppression: boolean | null;
  resolution: number | { min: number; ideal: number; max: number } | [number, number] | null;
}

interface BatchUpdates {
  timeout: number | null;
  changes: Array<() => void>;
  videoDevice: boolean;
  audioDevice: boolean;
  gain: boolean;
  videoPaused: boolean;
  audioMuted: boolean;
  constraints: boolean;
}

const defaultMediaStreamControllerOptions: MediaStreamControllerOptions = {
  defaultConstraints: {
    audio: CONSTRAINTS_AUDIO_OPTIMAL_STRICT,
    video: CONSTRAINTS_VIDEO_OPTIMAL_STRICT,
    screencapture: CONSTRAINTS_SCREENCAPTURE_OPTIMAL_WEAK,
  },
  fallbackConstraints: {
    audio: CONSTRAINTS_AUDIO_OPTIMAL_WEAK,
    video: CONSTRAINTS_VIDEO_OPTIMAL_WEAK,
    screencapture: {},
  },
  replaceTracks: true,
  waitingDelay: 100,
  // probably can be ExistsStreamPolicy.ignore for chrome
  defaultLockPolicy: ExistsStreamPolicy.wait,
  noEchoGainAmplifier: device.isIosDevice,
  requestAudioPermission: true,
};
//endregion

//region Helper Functions
function attachGainController(logger: LoggerCore, stream: MediaStream, gain: number): MediaStream {
  if (!device.isImplements(Feature.AUDIO_CONTEXT)) {
    return stream;
  }

  if (!stream.getAudioTracks().length) {
    // no audio tracks
    return stream;
  }

  logger.debug("attach gain controller to stream");

  // // check unsupported browsers
  if (audioCtx == null) {
    audioCtx = new device.AudioContext();
  }

  // check unsupported browsers
  if (
    audioCtx.createAnalyser == null ||
    audioCtx.createGain == null ||
    audioCtx.createMediaStreamSource == null ||
    audioCtx.createMediaStreamDestination == null
  ) {
    return stream;
  }

  // will be reimplemented in separate class
  // // create reusable analyzer elements
  // if (this.analyzer == null) {
  //   this.analyzer = this.audioCtx.createAnalyser();
  //   this.analyzer.fftSize = 256;
  //   this.analyzerData = new Uint8Array(this.analyzer.frequencyBinCount);
  // }

  // connect source and destination
  const gainNode = audioCtx.createGain();
  const src = audioCtx.createMediaStreamSource(stream);
  const gainDestination = audioCtx.createMediaStreamDestination();
  const gainStream = gainDestination.stream;
  src.connect(gainNode);
  gainNode.connect(gainDestination);

  // gainNode.connect(this.analyzer);

  gainNode.gain.value = gain;
  // original audio tracks
  const originalTrack = stream.getAudioTracks()[0];
  const gainTrack = gainStream.getAudioTracks()[0];
  if (originalTrack == null || gainTrack == null) {
    throw new Error("no audio tracks");
  }

  // set the original device id for gainstream's new audio tracks
  const deviceId = findDeviceId(originalTrack);
  if (deviceId != null) {
    tracksMeta.set(gainTrack, { deviceId, originalTrack, gainNode });
  }

  // add video tracks to the new stream
  stream.getVideoTracks().forEach((t) => {
    gainStream.addTrack(t);
  });

  // clean up old tracks
  const onEnded = (): void => {
    logger.debug("modified audio track is ended. stopping original one", { originalTrack: originalTrack.id });
    originalTrack.stop();
    gainTrack.removeEventListener("ended", onEnded);
  };
  gainTrack.addEventListener("ended", onEnded);

  return gainStream;
}

function stopTracks(stream: MediaStream | null, audio = true, video = true): void {
  if (stream == null) {
    return;
  }

  for (const track of stream.getTracks()) {
    // skip audio or video tracks
    if ((track.kind === "audio" && !audio) || (track.kind === "video" && !video)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const original = tracksMeta.get(track);
    if (original != null) {
      original.originalTrack?.stop();
    }
    track.stop();
    stream.removeTrack(track);
  }
}

function getSettings(track: MediaStreamTrack): MediaTrackSettings {
  let settings: MediaTrackSettings;
  try {
    settings = track.getSettings?.();
  } catch (error) {
    settings = {};
  }
  return settings;
}

function getConstraints(track: MediaStreamTrack): MediaTrackConstraints {
  let settings;
  try {
    settings = track.getConstraints?.();
  } catch (error) {
    settings = {};
  }
  return settings;
}

function findDeviceId(track: MediaStreamTrack): string | null {
  const meta = tracksMeta.get(track);
  if (meta?.deviceId != null) {
    return meta.deviceId;
  }
  const settings = getSettings(track);
  const constraints = getConstraints(track);
  const deviceId =
    settings?.deviceId ??
    // eslint-disable-next-line no-nested-ternary
    (typeof constraints?.deviceId === "object"
      ? (constraints.deviceId as { exact: string }).exact
      : Array.isArray(constraints.deviceId)
      ? constraints.deviceId[0]
      : constraints.deviceId);
  if (deviceId != null) {
    return deviceId.toString();
  }

  return mediaDevicesList.find((d) => d.label === track.label)?.deviceId ?? null;
}

function calculateAvailableResolutions(stream: MediaStream): ResolutionInfo {
  const vt = stream.getVideoTracks()[0];
  if (vt == null) {
    return [null, null, []];
  }
  const settings = getSettings(vt);
  const width = settings?.width;
  const height = settings?.height;

  if (height == null || width == null) {
    return [null, null, []];
  }

  const availableResolutions = possibleResolutions.filter((h) => h <= height);
  return [width, height, availableResolutions];
}

function mergeConstraints(
  constraints: DeepReadonly<MediaTrackConstraints>,
  deviceId: string | undefined,
  overrides: Partial<MergeConstraintsOverride>,
): MediaTrackConstraints {
  const copy: MediaTrackConstraints = JSON.parse(JSON.stringify(constraints));

  if (overrides.aspectRatio != null) {
    if (typeof overrides.aspectRatio === "number") {
      copy.aspectRatio = { exact: overrides.aspectRatio };
    } else {
      const [min, max] = overrides.aspectRatio;
      copy.aspectRatio = { min, max };
    }
  }

  if (overrides.autoGainControl != null) {
    copy.autoGainControl = { exact: overrides.autoGainControl };
  }

  if (overrides.echoCancellation != null) {
    copy.echoCancellation = { exact: overrides.echoCancellation };
  }

  if (overrides.facingMode != null) {
    copy.facingMode = { exact: overrides.facingMode };
  }

  if (overrides.frameRate != null) {
    if (typeof overrides.frameRate === "number") {
      copy.frameRate = { exact: overrides.frameRate };
    } else {
      const [min, max] = overrides.frameRate;
      copy.frameRate = { min, max };
    }
  }

  if (overrides.resolution != null) {
    if (typeof overrides.resolution === "number") {
      copy.height = { exact: overrides.resolution };
    } else if (Array.isArray(overrides.resolution)) {
      const [min, max] = overrides.resolution;
      copy.height = { min, max };
    } else {
      copy.height = { min: overrides.resolution.min, ideal: overrides.resolution.ideal, max: overrides.resolution.max };
    }
  }

  copy.deviceId = deviceId == null || isVirtualDevice(allDevices[deviceId]) ? undefined : { exact: deviceId };

  return copy;
}

function emptyBatchUpdates(): BatchUpdates {
  return {
    timeout: null,
    changes: [],
    videoDevice: false,
    audioDevice: false,
    gain: false,
    videoPaused: false,
    audioMuted: false,
    constraints: false,
  };
}

function buildStreamConstraints(
  defaults: {
    audio: DeepReadonly<MediaTrackConstraints>;
    video: DeepReadonly<MediaTrackConstraints>;
    screencapture: DeepReadonly<MediaTrackConstraints>;
  },
  audioDeviceInfo: MediaDeviceInfo | null,
  videoDeviceInfo: MediaDeviceInfo | null,
  {
    echoCancellation,
    autoGainControl,
    noiseSuppression,
    frameRate,
    resolution,
    aspectRatio,
    facingMode,
  }: MergeConstraintsOverride,
): MediaStreamConstraints {
  const video: DeepReadonly<MediaTrackConstraints> =
    videoDeviceInfo?.deviceId === VIDEO_DEVICE_SCREENCAPTURE?.deviceId ? defaults.screencapture : defaults.video;
  const { audio } = defaults;

  return {
    audio:
      audioDeviceInfo == null
        ? false
        : mergeConstraints(audio, audioDeviceInfo?.deviceId, {
            echoCancellation,
            autoGainControl,
            noiseSuppression,
          }),
    video:
      videoDeviceInfo == null
        ? false
        : mergeConstraints(video, videoDeviceInfo?.deviceId, {
            resolution,
            frameRate,
            aspectRatio,
            facingMode,
          }),
  };
}

//endregion

/**
 * Factory for MediaStreamController and enumerator for media devices.
 * It requires to call init() before start using it
 */
class MediaController extends ObservableEventEmitter<MediaControllerEvents> implements MediaControllerAPI {
  static readonly displayName = "MediaController";

  private isInitialized = false;

  private initialized: Promise<void> | null = null;

  private mediaControllerOptions: MediaControllerOptions | undefined;

  logger: LoggerCore;

  constructor() {
    super();

    this.logger = new LoggerCore(PACKAGE_NAME)
      .setLoggerMeta("client", "VDC")
      .setLoggerMeta("release", packageJson.version)
      .appendChain(MediaController)
      .setMessageAggregate("contextId", contextId() ?? undefined)
      .setMessageAggregate("instanceId", instanceId() ?? undefined);

    makeBounded(this);
    makeObservable(this, {
      requestController: action,
    });
  }

  /**
   * Enumerate all audio devices.
   *
   * @throws NotInitializedError if called before init()
   */
  audioDevices(): Readonly<Array<Readonly<MediaDeviceInfo>>> {
    if (!this.isInitialized) {
      this.logger.warn("init() must be called on the media-controller singleton", {});
      return [];
    }
    return mediaDevicesList.filter((md) => md.kind === "audioinput");
  }

  /**
   * Enumerate all video devices.
   *
   * @emits videoDeviceChanged if the device has changed
   * @throws NotInitializedError if called before init()
   */
  videoDevices(): Readonly<Array<Readonly<MediaDeviceInfo>>> {
    if (!this.isInitialized) {
      this.logger.warn("init() must be called on the media-controller singleton", {});
      return [];
    }

    const mediaDevices = mediaDevicesList.filter((md) => md.kind === "videoinput");

    return mediaDevices;
  }

  get devices(): Readonly<Record<string, Readonly<MediaDeviceInfo>>> {
    return Object.fromEntries(mediaDevicesList.entries());
  }

  private _supportSharedDevices: boolean | null = null;

  /**
   * If the browser/platform supports sharing video/audio devices amongst
   * different MediaStreams, i.e.:
   *
   * const stream1 = await navigator.mediaDevices.getUserMedia({video: "abc123"});
   * const stream2 = await navigator.mediaDevices.getUserMedia({video: "abc123"});
   *
   */
  get supportSharedDevices(): boolean {
    if (this._supportSharedDevices == null) {
      return false;
    }
    return this._supportSharedDevices;
  }

  async checkSupportSharedDevices(hasVideoPermission: boolean, hasAudioPermission: boolean): Promise<void> {
    if (isReactNativeAndroidDevice(device)) {
      // The way we check for shared device support via [testConstraint] will throw an OS camera error most of the time.
      // this is because of too many open camera sessions. remember, this is not the only place where we use [getUserMedia].
      // practically speaking on mobile, how likely is device sharing across streams anyways?
      this._supportSharedDevices = false;
      return;
    }

    if (!device.isImplements(Feature.MEDIA_DEVICE)) {
      this._supportSharedDevices = false;
      return;
    }

    if (hasVideoPermission === false && hasAudioPermission === false) {
      this.logger.warn("video and audio device permissions denied");
      this._supportSharedDevices = false;
      return;
    }

    let stream1 = null;
    let stream2 = null;

    const testConstraint = hasVideoPermission ? { video: { deviceId: "default" } } : { audio: { deviceId: "default" } };
    try {
      stream1 = await device.mediaDevices.getUserMedia(testConstraint);
      const defaultVideoDeviceId = stream1.getVideoTracks()[0].id;
      stream2 = await device.mediaDevices.getUserMedia({ video: { deviceId: defaultVideoDeviceId } });
      const defaultVideoDeviceId2 = stream2.getVideoTracks()[0].id;
      // If the two devices Ids are the same, shared devices is possible, otherwise it's just grabbing a fallback device
      // bc the requested device is already in use/unavailable.
      this._supportSharedDevices = defaultVideoDeviceId === defaultVideoDeviceId2;
    } catch {
      this._supportSharedDevices = false;
    } finally {
      stopTracks(stream1);
      stopTracks(stream2);
    }
  }

  /**
   * Initialize media controller with default video/audio device
   * Should be called before any other methods
   * Some platforms requires to call this method on user action (like mouse click)
   *
   * @param {MediaControllerOptions} options
   * @returns {Promise<void>}
   */
  async init(options?: MediaControllerOptions): Promise<void> {
    this.mediaControllerOptions = options;

    if (this.initialized != null) {
      if (options) {
        this.setOptions(options);
      }
      return this.initialized;
    }

    this.initialized = this._init();
    if (options) {
      this.setOptions(options);
    }

    try {
      await this.initialized;
    } catch (error) {
      this.initialized = null;
      // todo: logging
      throw error;
    }

    this.isInitialized = true;
    return this.initialized;
  }

  setOptions(options: MediaControllerOptions): void {
    clearInterval(deviceUpdateTimer);
    if (options.updateByTimer != null) {
      deviceUpdateTimer = device.setInterval(this.enumerateDevices, options.updateByTimer);
    }

    if (options.logger != null) {
      this.logger?.destroy();
      this.logger = options.logger;
    }
  }

  /**
   * internal logic for locked init
   *
   * @param {MediaControllerOptions} options
   * @returns {Promise<void>}
   */
  private async _init(): Promise<void> {
    if (!device.isImplements(Feature.MEDIA_DEVICE)) {
      this.throwError(createError(ErrorCode.MediaDeviceNotSupported, "media device is not supported", {}));
    }

    device.mediaDevices.removeEventListener("devicechange", this.enumerateDevices);
    device.mediaDevices.addEventListener("devicechange", this.enumerateDevices);

    // call it second time because it changes after permission granted
    return this.enumerateDevices(true).then(this.observePerms);
  }

  async observePerms(): Promise<void> {
    if (!device.isImplements(Feature.PERMISSIONS)) {
      return;
    }

    const observer = (ev: Event): void => {
      const target = ev.target as any;
      this.enumerateDevices(target.state === "prompt");
    };

    try {
      const permCam = await device.permissions.query({ name: "camera" });
      const permMic = await device.permissions.query({ name: "microphone" });

      permCam.addEventListener("change", observer);
      permMic.addEventListener("change", observer);
    } catch (err) {
      // it's not supported by browser
    }
  }

  /**
   * MediaStreamController factory. Creates MediaStreamController
   * based on giving constraints. Array order is important
   *
   * Important! You have to call ctrl.dispose() when you finish work
   * with MediaStreamController. Otherwise it continue capture
   * audio and video devices.
   *
   * @throws {ErrorCode.MediaControllerNotInitialized} if called before init()
   * @throws {ErrorCode.MediaStreamNotSupported} if MediaStream is not supported by the current device
   **/
  requestController(
    options: Partial<MediaStreamControllerOptions> = defaultMediaStreamControllerOptions,
  ): Promise<MediaStreamControllerAPI> {
    const combinedOptions: MediaStreamControllerOptions = {
      ...defaultMediaStreamControllerOptions,
      replaceTracks: this.supportSharedDevices,
      requestAudioPermission: this.mediaControllerOptions?.requestAudioPermission ?? true,
      ...options,
    };

    this.logger.info("request new MediaStreamController", {
      options: {
        defaultConstraints: JSON.stringify(combinedOptions.defaultConstraints),
        fallbackConstraints: JSON.stringify(combinedOptions.fallbackConstraints),
        defaultLockPolicy: combinedOptions.defaultLockPolicy,
        replaceTracks: combinedOptions.replaceTracks,
        waitingDelay: combinedOptions.waitingDelay,
        displayName: combinedOptions.displayName,
        requestAudioPermission: combinedOptions.requestAudioPermission,
      },
    });

    if (!this.isInitialized) {
      this.throwError(
        createError(
          ErrorCode.MediaControllerNotInitialized,
          "init() must be called on the media-controller singleton",
          {},
        ),
      );
    }
    return Promise.resolve(new MediaStreamController(combinedOptions));
  }

  async enumerateDevices(probe = false): Promise<void> {
    if (!device.isImplements(Feature.MEDIA_DEVICE) || device.mediaDevices.enumerateDevices == null) {
      this.throwError(createError(ErrorCode.MediaDeviceNotSupported, "media device is not supported", {}));
    }

    const tmpStreams: MediaStream[] = [];
    if (probe) {
      // we need to call that to get devices labels
      // browser may ask permissions at this point
      let hasAudioPermission = false;
      let hasVideoPermission = false;
      const requestAudioPermission = this.mediaControllerOptions?.requestAudioPermission ?? true;
      try {
        tmpStreams.push(await device.mediaDevices.getUserMedia({ audio: requestAudioPermission, video: true }));
        hasAudioPermission = requestAudioPermission;
        hasVideoPermission = true;
      } catch (ex) {
        try {
          if (requestAudioPermission) {
            tmpStreams.push(await device.mediaDevices.getUserMedia({ audio: true, video: false }));
            hasAudioPermission = true;
          }
        } catch (err) {
          if (err instanceof Error) {
            if (err.name === "NotFoundError") {
              this.logger.warn("no audio devices found", { err: wrapError(err) });
              this.emit("audioNotFound");
            } else if (err.name === "NotAllowedError") {
              this.logger.warn("audio device permission denied", { err: wrapError(err) });
              this.emit("audioNotAllowed");
            } else {
              this.logger.warn("Audio device error", { err: wrapError(err) });
            }
          }
        }

        try {
          tmpStreams.push(await device.mediaDevices.getUserMedia({ audio: false, video: true }));
          hasVideoPermission = true;
        } catch (err) {
          if (err instanceof Error) {
            if (err.name === "NotFoundError") {
              this.logger.warn("no video devices found", { err: wrapError(err) });
              this.emit("videoNotFound");
            } else if (err.name === "NotAllowedError") {
              this.logger.warn("video device permission denied", { err: wrapError(err) });
              this.emit("videoNotAllowed");
            } else {
              this.logger.warn("Video device error", { err: wrapError(err) });
            }
          }
        }
      }

      // We call this after we've checked for permissions because we don't want to ask for permissions twice
      await this.checkSupportSharedDevices(hasVideoPermission, hasAudioPermission);
    }

    try {
      const devices = await device.mediaDevices.enumerateDevices();

      let uniqDevices: MediaDeviceInfo[] = [];
      for (const d of devices) {
        if (
          d.deviceId !== "" &&
          d.label !== "" &&
          !uniqDevices.some((u) => u.deviceId === d.deviceId && u.kind === d.kind)
        ) {
          uniqDevices.push(d);
        }
      }

      if (device.isSafari && device.isImplements(Feature.PERMISSIONS)) {
        // On Safari, device.mediaDevices.enumerateDevices() returns all devices
        // even if permissions are denied so we remove them from the list manually
        const camPermission = await device.permissions.query({ name: "camera" });
        const micPermission = await device.permissions.query({ name: "microphone" });
        if (camPermission.state !== "granted") {
          uniqDevices = uniqDevices.filter((d) => d.kind !== "videoinput");
        }
        if (micPermission.state !== "granted") {
          uniqDevices = uniqDevices.filter((d) => d.kind !== "audioinput");
        }
      }

      if (
        mediaDevicesList.length !== uniqDevices.length ||
        mediaDevicesList.some(
          (d, i) =>
            d.deviceId !== uniqDevices[i].deviceId ||
            d.groupId !== uniqDevices[i].groupId ||
            d.label !== uniqDevices[i].label ||
            d.kind !== uniqDevices[i].kind,
        )
      ) {
        runInAction(function updateMediaDevicesList() {
          mediaDevicesList.replace(uniqDevices);
        });
        this.emit("deviceListChanged", mediaDevicesList.slice());
      }
    } finally {
      for (const stream of tmpStreams) {
        stopTracks(stream);
      }
    }
  }
}

export const mediaController: MediaControllerAPI = new MediaController();

export const optionsKey = Symbol("options");

/**
 * The controller which allows to manipulate MediaStream
 *
 * @throws {NotInitializedError} if init() was not called on the media-controller singleton
 * @throws {NotSupportedError} if the browser does not support MediaStream API
 *
 */
export class MediaStreamController
  extends ObservableEventEmitter<MediaStreamControllerEvents>
  implements MediaStreamControllerAPI
{
  static readonly displayName = "MediaStreamController";

  //region Private Properties
  private sync: Promise<MediaStream> | null = null;

  private readonly audioCtx: AudioContext | null = null;

  private readonly options: MediaStreamControllerOptions;

  private readonly optionsAtom = createAtom("options");

  private readonly applyConstraintsAtom = createAtom("applyConstraints");

  public get [optionsKey](): MediaStreamControllerOptions {
    this.optionsAtom.reportObserved();
    return this.options;
  }

  private capturable: Capturable | null = null;

  readonly logger: LoggerCore;

  private batchUpdates: BatchUpdates = emptyBatchUpdates();
  //endregion

  //region MobX Observable Properties
  private audioDeviceChanged: Readonly<MediaDeviceInfo> | null = null;

  private videoDeviceChanged: Readonly<MediaDeviceInfo> | null = null;

  private audioDeviceChanging: Readonly<MediaDeviceInfo> | null = null;

  private videoDeviceChanging: Readonly<MediaDeviceInfo> | null = null;

  videoDeviceRemoved: string | null = null;

  audioDeviceRemoved: string | null = null;

  audioMuted = false;

  availableResolutions: number[] = [];

  maxWidth: number | null = null;

  maxHeight: number | null = null;

  videoPaused = false;

  videoDisabled = false;

  /**
   * Indicates the direction in which the camera producing the video track
   *
   * @type 'back' | 'front' | 'environment' | 'user'
   */
  facingMode: FacingMode | null = null;

  /**
   * Current volume level
   *
   * @type number
   */
  gain = 1;

  /**
   * This setting amplifies the volume  if true and the echo cancelation is disabled.
   *
   * @type number
   */
  noEchoGainAmplifier = false;

  /**
   * Current video resolution
   */
  resolution: number | { min: number; ideal: number; max: number } | [number, number] | null = null;

  /**
   * Current framerate
   */
  frameRate: number | [number, number] | null = null;

  /**
   * Current aspect ratio. E.g. 16/9
   */
  aspectRatio: number | [number, number] | null = null;

  /**
   * Indicates whether echo cancellation is enabled or not.
   * @todo
   */
  echoCancellation: boolean | null = null;

  /**
   * Indicates whether noise suppression is enabled or not.
   * @todo
   */
  noiseSuppression: boolean | null = null;

  /**
   * @todo
   */
  autoGainControl: boolean | null = null;

  get settings(): { audio?: MediaTrackSettings; video?: MediaTrackSettings; audioCtx?: unknown } {
    this.applyConstraintsAtom.reportObserved();

    const result: { audio?: MediaTrackSettings; video?: MediaTrackSettings; audioCtx?: unknown } = {};
    if (this.hasActiveAudioTrack()) {
      const track = this.source?.getAudioTracks()[0];
      const meta = tracksMeta.get(track);
      if (track != null && meta != null) {
        result.audioCtx = {
          gain: meta.gainNode?.gain.value,
          internallyEnded: meta.internallyEnded,
          track: track.getSettings(),
        };
        result.audio = meta.originalTrack?.getSettings();
      } else {
        result.audio = this.source?.getAudioTracks()[0]?.getSettings();
      }
    }
    if (this.hasActiveVideoTrack()) {
      result.video = this.source?.getVideoTracks()[0]?.getSettings();
    }
    return result;
  }

  /**
   * Returns MediaStream with current settings
   */
  source: MediaStream;

  get sourceUrl(): string {
    return URL.createObjectURL(source);
  }

  /**
   * Returns a current audio device id
   */
  get audioDeviceId(): string | null {
    return this.audioDeviceChanged?.deviceId ?? null;
  }

  /**
   * Set new audio video id
   *
   * @emits audioDeviceChanged if the device has changed
   */
  set audioDeviceId(value: string | null) {
    if (value == null) {
      this.audioDeviceChanging = null;
      return;
    }

    if (audioDevices[value] == null) {
      this.emitError(
        createError(ErrorCode.MediaDeviceNotAvailable, "audio device not found or not available", {
          deviceId: value,
          deviceLabel: null,
          prevDeviceId: this.audioDeviceId,
          prevDeviceLabel: this.audioDeviceChanged?.label ?? null,
          kind: "audio",
        }),
      );
    }

    this.audioDeviceChanging = this.options.requestAudioPermission ? audioDevices[value] : null;
  }

  /**
   * Returns whether the video device is in transition
   */
  get inVideoDeviceTransition(): boolean {
    return this.sync != null && this.videoDeviceChanging !== this.videoDeviceChanged;
  }

  /**
   * Returns whether the audio device is in transition
   */
  get inAudioDeviceTransition(): boolean {
    return this.sync != null && this.audioDeviceChanging !== this.audioDeviceChanged;
  }

  /**
   * Returns a current video device id
   */
  get videoDeviceId(): string | null {
    if (this.videoDisabled) {
      return this.videoDeviceChanging?.deviceId ?? null;
    }
    return this.videoDeviceChanged?.deviceId ?? null;
  }

  /**
   * Set new audio video id
   *
   * @emits videoDeviceChanged if the device has changed
   */
  set videoDeviceId(value: string | null) {
    if (value == null) {
      this.videoDeviceChanging = null;
      return;
    }

    if (videoDevices[value] == null) {
      this.emitError(
        createError(ErrorCode.MediaDeviceNotAvailable, "video device not found or not available", {
          deviceId: value,
          deviceLabel: null,
          prevDeviceId: this.videoDeviceId,
          prevDeviceLabel: this.videoDeviceChanged?.label ?? null,
          kind: "video",
        }),
      );
    }

    this.videoDeviceChanging = videoDevices[value];
  }

  get isScreenCaptured(): boolean {
    return this.videoDeviceChanged?.deviceId === VIDEO_DEVICE_SCREENCAPTURE.deviceId;
  }

  //endregion

  /**
   * @internal
   * @throws {ErrorCode.MediaStreamNotSupported} if MediaStream is not supported by the current device
   */
  constructor(options?: Partial<MediaStreamControllerOptions>) {
    super();

    if (!device.isImplements(Feature.MEDIA_STREAM)) {
      this.throwError(createError(ErrorCode.MediaStreamNotSupported, "MediaStream is not supported", {}));
    }

    // Amplify gain for ios devices. With certain audio constraints on ios, audio becomes very quiet.

    this.source = new device.MediaStream();

    this.logger = new LoggerCore(PACKAGE_NAME)
      .setLoggerMeta("package", "VDC-core")
      .setLoggerMeta("client", "VDC")
      .setLoggerMeta("release", packageJson.version)
      .setLoggerMeta("commitHash", packageJson.commit)
      .setLoggerMeta("contextId", contextId() ?? "")
      .setLoggerMeta("instanceId", instanceId() ?? "")
      .setMessageAggregate("displayName", options?.displayName)
      .appendChain(MediaStreamController)
      .attachObject(this);

    this.logger.trace("constructor()", this);

    this.on("error", (err) => {
      this.logger.error(err.message);
    });

    makeObservable<
      MediaStreamController,
      | "audioDeviceChanged"
      | "videoDeviceChanged"
      | "audioDeviceChanging"
      | "videoDeviceChanging"
      | "onDeviceListChanged"
    >(this, {
      // observers
      audioDeviceChanged: observable.ref,
      videoDeviceChanged: observable.ref,
      audioDeviceChanging: observable.ref,
      videoDeviceChanging: observable.ref,
      audioMuted: observable,
      availableResolutions: observable,
      maxWidth: observable,
      maxHeight: observable,
      videoPaused: observable,
      videoDisabled: observable,
      facingMode: observable,
      gain: observable,
      resolution: observable,
      frameRate: observable,
      aspectRatio: observable,
      echoCancellation: observable,
      noiseSuppression: observable,
      autoGainControl: observable,
      source: observable.ref,

      // computed
      audioDeviceId: computed,
      videoDeviceId: computed,
      isScreenCaptured: computed,
      sourceUrl: computed,
      settings: computed,

      // actions
      toggleCamera: action,
      toggleMic: action,
      onDeviceListChanged: action,
    });

    // this.prepareStream()

    // using JSON.parse(JSON.stringify()) to create a deep copy of defaultMediaStreamControllerOptions
    this.options = JSON.parse(JSON.stringify(defaultMediaStreamControllerOptions));

    if (options != null) {
      this.setOptions({ ...defaultMediaStreamControllerOptions, ...options });

      if (options?.capturable?.element != null && device.supportsMediaStreamCapture(options?.capturable?.element)) {
        this.capturable = options.capturable;
        this.videoDeviceId = VIDEO_DEVICE_CAPTURABLE.deviceId;
        this.applyNewDevices();
      }
    }
    if (this.options.noEchoGainAmplifier) {
      this.noEchoGainAmplifier = true;
    }

    this.setupMobxBatching();

    mediaController.on("deviceListChanged", this.onDeviceListChanged);

    this.addInnerDisposer(this.close);
  }

  //region MobX Actions
  /**
   * Returns whether this exists an active stream on the controller
   */
  hasActiveStream(): boolean {
    const activeAudioTrack = this.hasActiveAudioTrack();
    const activeVideoTrack = this.hasActiveAudioTrack();
    return activeAudioTrack || activeVideoTrack;
  }

  /**
   * Returns whether this exists an active video track on the controller
   */
  hasActiveVideoTrack(): boolean {
    const { videoDeviceChanged, videoDisabled } = this;
    return videoDeviceChanged != null && !videoDisabled;
  }

  /**
   * Returns whether this exists an active audio track on the controller
   */
  hasActiveAudioTrack(): boolean {
    return this.audioDeviceChanged != null;
  }

  /**
   * Toggle the video paused attribute
   */
  toggleCamera(): void {
    this.videoPaused = !this.videoPaused;
  }

  /**
   * Toggle the audio muted attribute
   */
  toggleMic(): void {
    this.audioMuted = !this.audioMuted;
  }

  //endregion

  private onDeviceListChanged(devices: MediaControllerEvents["deviceListChanged"]): void {
    if (this.videoDeviceChanged != null && !devices.some((d) => d.deviceId === this.videoDeviceChanged?.deviceId)) {
      const vd = devices.filter((d) => d.kind === "videoinput");
      if (vd.length > 0) {
        [this.videoDeviceChanging] = vd;
      } else {
        this.videoDeviceChanging = null;
      }
    }

    if (this.audioDeviceChanged != null && !devices.some((d) => d.deviceId === this.audioDeviceChanged?.deviceId)) {
      const ad = devices.filter((d) => d.kind === "audioinput");
      if (ad.length > 0) {
        [this.audioDeviceChanging] = ad;
      } else {
        this.audioDeviceChanging = null;
      }
    }
  }

  private setupMobxBatching(): void {
    // mobx watchers
    const opts: IReactionOptions<unknown, true> = {
      scheduler: this.batchUpdatesScheduler,
      name: "MediaStreamController(batch update)",
    };

    this.addInnerDisposer(
      reaction(
        () => {
          return this.videoDeviceChanging;
        },
        () => {
          this.batchUpdates.videoDevice = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.audioDeviceChanging,
        () => {
          this.batchUpdates.audioDevice = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.videoDisabled,
        () => {
          this.batchUpdates.videoDevice = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.gain,
        () => {
          this.batchUpdates.gain = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.videoPaused,
        () => {
          this.batchUpdates.videoPaused = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => this.audioMuted,
        () => {
          this.batchUpdates.audioMuted = true;
        },
        opts,
      ),
    );

    this.addInnerDisposer(
      reaction(
        () => {
          return [
            this.facingMode,
            this.aspectRatio,
            this.frameRate,
            this.resolution,
            this.noiseSuppression,
            this.autoGainControl,
            this.echoCancellation,
          ];
        },
        () => {
          this.batchUpdates.constraints = true;
        },
        opts,
      ),
    );
  }

  private batchUpdatesScheduler(run: () => void, ...args: unknown[]): void {
    this.batchUpdates.changes.push(run);
    if (this.batchUpdates.timeout == null) {
      this.batchUpdates.timeout = device.setInterval(() => {
        if (this.sync == null) {
          device.clearInterval(this.batchUpdates.timeout ?? undefined);
        } else {
          return;
        }

        this.batchUpdates.changes.forEach((u) => u());
        const updates = this.batchUpdates;
        this.batchUpdates = emptyBatchUpdates();

        if (updates.audioDevice || updates.videoDevice) {
          this.applyNewDevices();
          // no need handle other changes since changing
          // audio/video triggers `prepareStream()`
          return;
        }

        if (updates.constraints && !device.isImplements(Feature.APPLY_CONSTRAINTS)) {
          // if applyConstraints() is not implemented
          // then it triggers prepareStream() also
          this.applyConstraints();
          return;
        }

        if (updates.audioMuted) {
          this.applyAudioMute();
        }

        if (updates.videoPaused) {
          this.applyVideoPause();
        }

        if (updates.gain) {
          this.applyGain();
        }

        if (updates.constraints) {
          this.applyConstraints();
        }
      }, MOBX_REACT_DELAY);
    }
  }

  setOptions(options: Partial<MediaStreamControllerOptions>): void {
    let constraintsChanged = false;

    if (options.replaceTracks != null) {
      this.options.replaceTracks = options.replaceTracks;
    }

    if (options.requestAudioPermission != null) {
      this.options.requestAudioPermission = options.requestAudioPermission;
    }

    if (options.defaultConstraints != null) {
      this.options.defaultConstraints = { ...options.defaultConstraints };
      constraintsChanged = true;
    }

    if (options.fallbackConstraints !== undefined) {
      this.options.fallbackConstraints =
        options.fallbackConstraints == null ? null : { ...options.fallbackConstraints };
      constraintsChanged = true;
    }

    if (options.capturable !== undefined) {
      this.capturable = options.capturable;
    }

    if (constraintsChanged) {
      this.applyConstraints();
    }
    this.optionsAtom.reportChanged();
  }

  constraints(): MediaStreamConstraints {
    return buildStreamConstraints(
      this.options.defaultConstraints,
      this.options.requestAudioPermission ? this.audioDeviceChanging : null,
      this.videoDisabled ? null : this.videoDeviceChanging,
      {
        aspectRatio: this.aspectRatio,
        autoGainControl: this.autoGainControl,
        echoCancellation: this.echoCancellation,
        facingMode: this.facingMode,
        frameRate: this.frameRate,
        noiseSuppression: this.noiseSuppression,
        resolution: this.resolution,
      },
    );
  }

  fallbackConstraints(): MediaStreamConstraints | null {
    return this.options.fallbackConstraints == null
      ? null
      : buildStreamConstraints(
          this.options.fallbackConstraints,
          this.options.requestAudioPermission ? this.audioDeviceChanging : null,
          this.videoDisabled ? null : this.videoDeviceChanging,
          {
            aspectRatio: this.aspectRatio,
            autoGainControl: this.autoGainControl,
            echoCancellation: this.echoCancellation,
            facingMode: this.facingMode,
            frameRate: this.frameRate,
            noiseSuppression: this.noiseSuppression,
            resolution: this.resolution,
          },
        );
  }

  activeConstraints(): { audio?: MediaTrackConstraints; video?: MediaTrackConstraints } {
    this.applyConstraintsAtom.reportObserved();

    const res: { audio?: MediaTrackConstraints; video?: MediaTrackConstraints } = {};
    try {
      if (this.hasActiveVideoTrack()) {
        res.video = this.source?.getVideoTracks()?.[0]?.getConstraints();
      }
      if (this.hasActiveAudioTrack()) {
        const track = this.source?.getAudioTracks()?.[0];
        if (track != null) {
          const orig = tracksMeta.get(track)?.originalTrack;
          res.audio = orig != null ? orig.getConstraints() : track.getConstraints();
        }
      }
    } catch (err) {
      this.logger.warn("unable to detect active constaints");
    }
    return res;
  }

  private applyVideoPause(): void {
    const { videoPaused } = this;

    // it will be better to implement screencapture with dedicated stream
    if (this.videoDeviceChanged?.deviceId === VIDEO_DEVICE_SCREENCAPTURE.deviceId) {
      return;
    }

    this.source.getVideoTracks().forEach((t) => {
      if (videoPaused) {
        this.logger.debug("pause video track", { trackId: t.id });
      } else {
        this.logger.debug("unpause video track", { trackId: t.id });
      }
      t.enabled = !videoPaused;
    });
  }

  private applyAudioMute(): void {
    const { audioMuted } = this;
    this.source.getAudioTracks().forEach((t) => {
      if (audioMuted) {
        this.logger.debug("disable audio track", { trackId: t.id });
      } else {
        this.logger.debug("enable audio track", { trackId: t.id });
      }
      t.enabled = !audioMuted;
    });
  }

  private async applyNewDevices(): Promise<void> {
    const { replaceTracks } = this.options;
    const videoDevice = this.videoDisabled ? null : this.videoDeviceChanging;
    const audioDevice = this.audioDeviceChanging;
    const videoDeviceOld = this.videoDeviceChanged;
    const audioDeviceOld = this.audioDeviceChanged;
    const videoChanged = videoDevice !== this.videoDeviceChanged;
    const audioChanged = this.audioDeviceChanging !== this.audioDeviceChanged;

    if (videoDevice == null && audioDevice == null) {
      action("applyNewDevices", () => {
        if (device.isImplements(Feature.MEDIA_STREAM)) {
          this.audioDeviceChanged = audioDevice;
          this.videoDeviceChanged = videoDevice;
          stopTracks(this.source);
          this.source = new device.MediaStream();
        }
      })();
      return;
    }

    this.logger.debug("preparing new devices", {
      videoDevice,
      audioDevice,
      videoChanged,
      audioChanged,
      replaceTracks,
    });

    let newStream: MediaStream | null = null;
    const curStream = this.source;

    if (replaceTracks) {
      try {
        newStream = await this.lockAndPrepareStream(
          videoDevice,
          audioDevice,
          this.options.defaultLockPolicy,
          this.constraints(),
          this.fallbackConstraints(),
        );

        if (videoChanged && videoDevice !== VIDEO_DEVICE_SCREENCAPTURE && videoDevice !== VIDEO_DEVICE_CAPTURABLE) {
          // that's ok to create a separate stream
          // because replaceTracks is true

          try {
            await this.updateResolutions(videoDevice);
          } catch (err) {
            this.emitError(
              createError(
                ErrorCode.UpdateResolutionsFailed,
                "unable to update available resolutions",
                {
                  kind: "video",
                  deviceId: videoDevice?.deviceId ?? null,
                  deviceLabel: videoDevice?.label ?? null,
                  prevDeviceId: videoDeviceOld?.deviceId ?? null,
                  prevDeviceLabel: videoDeviceOld?.label ?? null,
                },
                err,
              ),
            );
          }
        }

        stopTracks(curStream, audioChanged, videoChanged);
      } catch (err) {
        stopTracks(newStream);
        if (videoChanged) {
          this.emitError(
            createError(
              ErrorCode.MediaDeviceChangingFailed,
              "unable to change video device",
              {
                deviceId: videoDevice?.deviceId ?? null,
                deviceLabel: videoDevice?.label ?? null,
                prevDeviceId: videoDeviceOld?.deviceId ?? null,
                prevDeviceLabel: videoDeviceOld?.label ?? null,
                kind: "video",
              },
              err,
            ),
          );
        }

        if (audioChanged) {
          this.emitError(
            createError(
              ErrorCode.MediaDeviceChangingFailed,
              "unable to change audio device",
              {
                deviceId: audioDevice?.deviceId ?? null,
                deviceLabel: audioDevice?.label ?? null,
                prevDeviceId: audioDeviceOld?.deviceId ?? null,
                prevDeviceLabel: audioDeviceOld?.label ?? null,
                kind: "audio",
              },
              err,
            ),
          );
        }

        // deprecated
        this.emit("changeDevicesError", {
          err: err as Error,
          audio: {
            old: audioDeviceOld,
            new: audioDevice,
          },
          video: {
            old: videoDeviceOld,
            new: videoDevice,
          },
        });

        // no other preparing streams
        if (this.sync == null) {
          action("rollbackDevices", () => {
            this.videoDeviceChanging = videoDeviceOld;
            this.audioDeviceChanging = audioDeviceOld;
          })();
        }
        return;
      }
    } else {
      try {
        // stop only tracks for changed devices
        stopTracks(curStream, audioChanged, videoChanged);

        // the only moment to calculate resolutions
        // when all old streams were stopped
        // but new ones are not started yet
        if (videoChanged && videoDevice !== VIDEO_DEVICE_SCREENCAPTURE && videoDevice !== VIDEO_DEVICE_CAPTURABLE) {
          try {
            await this.updateResolutions(videoDevice);
          } catch (err) {
            this.emitError(
              createError(
                ErrorCode.UpdateResolutionsFailed,
                "unable to update available resolutions",
                {
                  kind: "video",
                  deviceId: videoDevice?.deviceId ?? null,
                  deviceLabel: videoDevice?.label ?? null,
                  prevDeviceId: videoDeviceOld?.deviceId ?? null,
                  prevDeviceLabel: videoDeviceOld?.label ?? null,
                },
                err,
              ),
            );
          }
        }

        newStream = await this.lockAndPrepareStream(
          videoDevice,
          audioDevice,
          this.options.defaultLockPolicy,

          // generate constraints only for changed devices
          // because we stopped old tracks only for them
          this.constraints(),
          this.fallbackConstraints(),
        );
      } catch (err) {
        stopTracks(newStream);
        if (videoChanged) {
          this.emitError(
            createError(
              ErrorCode.MediaDeviceChangingFailed,
              "unable to change video device",
              {
                deviceId: videoDevice?.deviceId ?? null,
                deviceLabel: videoDevice?.label ?? null,
                prevDeviceId: videoDeviceOld?.deviceId ?? null,
                prevDeviceLabel: videoDeviceOld?.label ?? null,
                kind: "video",
              },
              err,
            ),
          );
        }

        if (audioChanged) {
          this.emitError(
            createError(
              ErrorCode.MediaDeviceChangingFailed,
              "unable to change audio device",
              {
                deviceId: audioDevice?.deviceId ?? null,
                deviceLabel: audioDevice?.label ?? null,
                prevDeviceId: audioDeviceOld?.deviceId ?? null,
                prevDeviceLabel: audioDeviceOld?.label ?? null,
                kind: "audio",
              },
              err,
            ),
          );
        }

        // deprecated
        this.emit("changeDevicesError", {
          err: err as Error,
          audio: {
            old: audioDeviceOld,
            new: audioDevice,
          },
          video: {
            old: videoDeviceOld,
            new: audioDevice,
          },
        });

        // restore previous stream if possible
        if (this.sync == null) {
          action("rollbackDevices", () => {
            this.videoDeviceChanging = videoDeviceOld;
            this.audioDeviceChanging = audioDeviceOld;
          })();
        }
        return;
      }
    }

    // move all unchanged tracks from previous stream as is
    for (const at of curStream.getTracks()) {
      curStream.removeTrack(at);
      newStream.addTrack(at);
    }

    // to makes ts feel good
    const finalStream = newStream;
    action("applyNewDevices", () => {
      this.audioDeviceChanged = audioDevice;
      this.videoDeviceChanged = videoDevice;
      this.source = finalStream;
      this.logger.info("applied new devices", { videoDevice, audioDevice });
    })();
  }

  private async updateResolutions(videoDevice: Readonly<MediaDeviceInfo> | null): Promise<void> {
    // must never used in ff/react-native with running streams

    if (!this.options.replaceTracks && this.source.getVideoTracks().some((t) => t.readyState === "live")) {
      throw new Error("attempt to get resolutions with replaceTracks enabled and running stream");
    }

    const deviceId = videoDevice?.deviceId;
    if (deviceId == null) {
      runInAction(() => {
        this.maxWidth = 0;
        this.maxHeight = 0;
        this.availableResolutions = [];
      });
      return;
    }

    let maxWidth: number | null = null;
    let maxHeight: number | null = null;
    let availableResolutions: number[] = [];

    if (deviceResolutionsCache[deviceId] == null) {
      // a new device, so we need a new stream to calculate resolution
      const bestResolutionStream = await this.lockAndPrepareStream(
        videoDevice,
        null,
        ExistsStreamPolicy.error,

        // not using constraints generation because we need
        // a best mediaStream for selected deviceId
        { video: { deviceId: { exact: deviceId } } },
      );
      deviceResolutionsCache[deviceId] = calculateAvailableResolutions(bestResolutionStream);
      stopTracks(bestResolutionStream);
    }
    [maxWidth, maxHeight, availableResolutions] = deviceResolutionsCache[deviceId];

    runInAction(() => {
      this.maxWidth = maxWidth;
      this.maxHeight = maxHeight;
      this.availableResolutions = availableResolutions;
    });
  }

  private async applyConstraintsVideo(
    vt: MediaStreamTrack,
    fallbackVideo: boolean | MediaTrackConstraints | undefined,
    defaultVideo: MediaTrackConstraints,
  ): Promise<void> {
    if (device.isImplements(Feature.APPLY_CONSTRAINTS)) {
      this.logger.debug("apply video constraints", { defaultVideo, fallbackVideo });

      //This property is not supported on safari and will throw and overconstrained error.
      if (device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "safari") {
        delete defaultVideo.deviceId;
      }

      try {
        await device.applyConstraints(vt, defaultVideo);
      } catch (err) {
        this.logger.warn("unable to apply video constraints", { defaultVideo });

        const inner = err instanceof Error || isErrorLike(err) ? err : null;

        const active = this.activeConstraints();
        const diffConstraints = diff(defaultVideo, active.video ?? {}) as Json;
        this.emit("videoConstraintsError", {
          diff: diffConstraints,
          isFallback: false,
          constraints: defaultVideo,
          err: wrapError(inner) as any,
        });

        if (fallbackVideo != null && typeof fallbackVideo !== "boolean") {
          this.logger.debug("trying fallback video constraints", { fallbackVideo });
          device.applyConstraints(vt, fallbackVideo).catch((err2) => {
            const inner2 = err2 instanceof Error || isErrorLike(err2) ? err2 : null;
            const active2 = this.activeConstraints();
            const diffConstraints2 = diff(fallbackVideo, active2.video ?? {}) as Json;
            if (inner2 != null && isOverconstrainedError(inner2) && inner2.constraint === "height") {
              this.logger.error(
                "Unable to apply selected resolution, please select a resolution supported by your current video device.",
                {
                  fallbackConstraints: fallbackVideo,
                  defaultConstraints: defaultVideo,
                  trackId: vt.id,
                  trackType: vt.kind,
                  err: wrapError(inner2) as any,
                },
              );
              this.emit("videoConstraintsError", {
                diff: diffConstraints2,
                isFallback: true,
                constraints: fallbackVideo,
                err: wrapError(inner2) as any,
              });
            } else {
              this.logger.error("Unable to apply video constraints. Try restarting the stream.", {
                fallbackConstraints: fallbackVideo,
                defaultConstraints: defaultVideo,
                trackId: vt.id,
                trackType: vt.kind,
                err: wrapError(inner2),
              });
              this.emit("videoConstraintsError", {
                diff: diffConstraints2,
                isFallback: true,
                constraints: fallbackVideo,
                err: wrapError(inner2) as any,
              });
            }
          });
        }
      }
    }
  }

  private async applyConstraintsAudio(
    at: MediaStreamTrack,
    defaultAudio: MediaTrackConstraints,
    fallbackAudio: boolean | MediaTrackConstraints | undefined,
  ): Promise<void> {
    if (device.isImplements(Feature.APPLY_CONSTRAINTS)) {
      try {
        //This property is not supported on safari and will throw and overconstrained error.
        if (device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "safari") {
          delete defaultAudio.deviceId;
        }
        await device.applyConstraints(at, defaultAudio);
      } catch (err) {
        this.logger.warn("unable to apply constraints", { defaultAudio });
        const inner = err instanceof Error || isErrorLike(err) ? err : null;
        const active = this.activeConstraints();
        const diffConstraints = diff(defaultAudio, active.audio ?? {}) as Json;
        this.emit("audioConstraintsError", {
          diff: diffConstraints,
          isFallback: false,
          constraints: defaultAudio,
          err: wrapError(inner) as any,
        });

        if (fallbackAudio != null && typeof fallbackAudio !== "boolean") {
          this.logger.debug("trying fallback apply constraints", { fallbackAudio });
          const active2 = this.activeConstraints();
          const diffConstraints2 = diff(fallbackAudio, active2.audio ?? {}) as Json;

          device.applyConstraints(at, fallbackAudio).catch((err2) => {
            const inner2 = err2 instanceof Error || isErrorLike(err2) ? err2 : null;
            this.logger.error("unable to apply audio constraints. restart stream", {
              fallbackConstraints: fallbackAudio,
              defaultConstraints: defaultAudio,
              trackId: at.id,
              trackType: at.kind,
              err: wrapError(inner2),
            });
            this.emit("audioConstraintsError", {
              diff: diffConstraints2,
              isFallback: true,
              constraints: fallbackAudio,
              err: wrapError(inner2) as any,
            });
          });
        }
      }
    }
  }

  private async applyConstraints(): Promise<void> {
    if (this.audioDeviceChanging == null && this.videoDeviceChanging == null) {
      return;
    }

    if (!device.isImplements(Feature.APPLY_CONSTRAINTS)) {
      stopTracks(this.source);
      const constraints = this.constraints();
      const fallbackConstraints = this.fallbackConstraints();

      try {
        this.source = await this.lockAndPrepareStream(
          this.videoDeviceChanging,
          this.audioDeviceChanging,
          this.options.defaultLockPolicy,
          constraints,
          fallbackConstraints,
        );
      } catch (err) {
        this.emitError(
          createError(
            ErrorCode.ApplyingConstraintsFailed,
            "applying constraints failed",
            {
              constraints,
              fallbackConstraints,
            },
            err,
          ),
        );
      }
      return;
    }

    const curStream = this.source;
    const constraints = this.constraints();
    const fallbackConstraints = this.fallbackConstraints();
    const waitGroup: Promise<void>[] = [];

    const defaultVideo = constraints.video;
    const fallbackVideo = fallbackConstraints?.video;
    const defaultAudio = constraints.audio;
    const fallbackAudio = fallbackConstraints?.audio;

    if (defaultVideo != null && typeof defaultVideo !== "boolean") {
      waitGroup.push(
        ...curStream
          .getVideoTracks()
          .filter((t) => t.readyState !== "ended")
          .map((t) => this.applyConstraintsVideo(t, fallbackVideo, defaultVideo)),
      );
    }

    if (defaultAudio != null && typeof defaultAudio !== "boolean") {
      waitGroup.push(
        ...curStream
          .getAudioTracks()
          .map((t) => (tracksMeta.get(t)?.originalTrack != null ? tracksMeta.get(t)!.originalTrack! : t))
          .filter((t) => t)
          .map((t) => this.applyConstraintsAudio(t, defaultAudio, fallbackAudio)),
      );
    }

    await Promise.all(waitGroup);
    this.applyConstraintsAtom.reportChanged();
  }

  /**
   * Check for `noiseSuppression` support.
   * @description Check if user's device supports noise suppression.
   * @example <caption>Example usage of supportsNoiseSuppression.</caption>
   * supportsNoiseSuppression();
   * // Returns true if user device supports noiseSuppression.
   *
   * @see noiseSuppression
   */
  supportsNoiseSuppression(): boolean {
    if (device.isImplements(Feature.MEDIA_DEVICE)) {
      try {
        return device.mediaDevices.getSupportedConstraints().noiseSuppression ?? false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  /**
   * Check for `echoCancellation` support.
   * @description Check if user's device supports echo cancellation.
   * @example <caption>Example usage of supportsEchoCancellation.</caption>
   * supportsEchoCancellation();
   * // Returns `true` if user device supports echoCancellation.
   *
   * @see echoCancellation
   *
   * @todo This method is throwing an error w/ @computed.
   */
  supportsEchoCancellation(): boolean {
    if (device.isImplements(Feature.MEDIA_DEVICE)) {
      try {
        return device.mediaDevices.getSupportedConstraints().echoCancellation ?? false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  private async applyGain(): Promise<void> {
    const audioTrack = this.source.getAudioTracks()[0];
    const meta = tracksMeta.get(audioTrack);
    if (meta?.gainNode != null) {
      const constraints = getConstraints(audioTrack);
      if (this.noEchoGainAmplifier && constraints.echoCancellation === false) {
        meta.gainNode.gain.value = this.gain * AUDIO_GAIN_MULTIPLIER;
      } else {
        meta.gainNode.gain.value = this.gain;
      }
      this.applyConstraintsAtom.reportChanged();
    }
  }

  /**
   * @throws {ErrorCode.MediaStreamPreparingConflict}
   */
  private async lockAndPrepareStream(
    videoDevice: Readonly<MediaDeviceInfo> | null,
    audioDevice: Readonly<MediaDeviceInfo> | null,
    policy: ExistsStreamPolicy,
    constraints: MediaStreamConstraints,
    fallbackConstraints?: MediaStreamConstraints | null,
  ): Promise<MediaStream> {
    const kind =
      // eslint-disable-next-line no-nested-ternary
      videoDevice === VIDEO_DEVICE_SCREENCAPTURE
        ? "screencapture"
        : videoDevice === VIDEO_DEVICE_CAPTURABLE
        ? "captureable"
        : "media";

    // we'll get 100% error with { audio: false, video: false} constraints
    // so just return an empty MediaStream instead
    if (constraints.audio === false && constraints.video === false && device.isImplements(Feature.MEDIA_STREAM)) {
      return new device.MediaStream();
    }

    this.logger.trace("lockAndPrepareStream()", {
      policy: ExistsStreamPolicy[policy],
      constraints,
    });
    if (this.sync != null) {
      switch (policy) {
        case ExistsStreamPolicy.error:
          this.throwError(createError(ErrorCode.MediaStreamPreparingConflict, "MediaStream is preparing already", {}));
          break;
        case ExistsStreamPolicy.stale:
          this.logger.debug("stream is preparing already, waiting");
          return this.sync;
        case ExistsStreamPolicy.wait:
          await this.sync;
          return new Promise<MediaStream>((resolve, reject) => {
            device.setTimeout(() => {
              this.lockAndPrepareStream(videoDevice, audioDevice, policy, constraints, fallbackConstraints)
                .then(resolve)
                .catch(reject);
            }, this.options.waitingDelay);
          });
        case ExistsStreamPolicy.ignore:
          this.sync.then((stream) => {
            stopTracks(stream);
          });

          break; // continue prepare stream
        default:
          throw new Error(`Unknown policy MediaStream ${policy}`);
      }
    }
    this.sync = this.prepareStream(kind, constraints).catch((err) => {
      if (fallbackConstraints != null && kind === "media") {
        this.logger.warn("constraints failed. using fallback constraints", {
          constraints,
          fallbackConstraints,
          err,
        });
        return this.prepareStream(kind, fallbackConstraints);
      }
      throw err;
    });

    return this.sync.finally(() => {
      this.sync = null;
    });
  }

  /**
   * Prepares a new stream with current constraints and gain processing
   * Throws `VideoClientError` if failed
   */
  private async prepareStream(
    kind: "media" | "screencapture" | "captureable",
    constraints: MediaStreamConstraints,
  ): Promise<MediaStream> {
    this.logger.trace("prepareStream() start", { constraints });
    let newStream: MediaStream | null = null;

    // If using screenCapture, get ScreenCaptureStream and set stream or append tracks
    // depending on whether audio is in use or not.
    if (kind === "screencapture") {
      newStream = await this.prepareScreenCaptureStream({
        audio: constraints.audio,
        video: this.options.defaultConstraints.screencapture,
      } as MediaStreamConstraints);
    } else if (kind === "captureable") {
      newStream = await this.prepareCapturableStream({
        audio: constraints.audio,
      } as MediaStreamConstraints);
    } else {
      // If not using screenCapture/capturable or if using screenCapture/capturable and audioDevices is set
      // getMediaDevices.
      newStream = await this.prepareMediaStream(constraints);
    }

    newStream.getVideoTracks().forEach((t) => {
      t.addEventListener("ended", async () => {
        if (kind === "media") {
          this.logger.warn("video track has ended unexpectedly (perm revoked?)");
          await (mediaController as MediaController).enumerateDevices();
          if (!mediaDevicesList?.some((md) => md.kind === "videoinput" && md.deviceId === this.videoDeviceId)) {
            this.logger.warn(
              "Video device no longer exists in list of available devices, check to see if device was removed",
            );
            this.emit("videoDeviceRemoved", this.videoDeviceId);
            this.videoDeviceId = null;
          }
        }
      });
    });

    newStream.getAudioTracks().forEach((t) => {
      t.addEventListener("ended", async () => {
        this.logger.warn("audio track has ended unexpectedly (perm revoked?)");
        await (mediaController as MediaController).enumerateDevices();
        if (!mediaDevicesList?.some((md) => md.kind === "audioinput" && md.deviceId === this.videoDeviceId)) {
          this.logger.warn(
            "Audio device no longer exists in list of available devices, check to see if device was removed",
          );
          this.emit("audioDeviceRemoved", this.audioDeviceId);
          this.audioDeviceId = null;
        }
      });
    });

    if (constraints.audio !== false) {
      const newGain =
        this.noEchoGainAmplifier &&
        typeof constraints.audio !== "boolean" &&
        constraints.audio?.echoCancellation === false
          ? this.gain * AUDIO_GAIN_MULTIPLIER
          : this.gain;

      newStream = attachGainController(this.logger, newStream, newGain);
    }

    for (const track of newStream?.getTracks() ?? []) {
      if (track.readyState === "ended") {
        this.throwError(
          createError(ErrorCode.MediaTrackEnded, "track ended unexpectedly", { trackId: track.id, kind: track.kind }),
        );
      }

      if (track.kind === "video") {
        track.enabled = !this.videoPaused;
      }

      if (track.kind === "audio") {
        track.enabled = !this.audioMuted;
      }
    }

    this.logger.trace("prepareStream() finished");
    return newStream;
  }

  // it is consistent
  private async prepareMediaStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    this.logger.trace("prepareMediaStream()");
    if (!device.isImplements(Feature.MEDIA_DEVICE) || device.mediaDevices.getUserMedia == null) {
      this.throwError(createError(ErrorCode.MediaDeviceNotSupported, "MediaDevices not supported", {}));
    }

    this.logger.debug("using constraints", { constraints });
    return device.mediaDevices.getUserMedia(constraints);
  }

  private async prepareScreenCaptureStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    this.logger.trace("prepareScreenCaptureStream()", { constraints });

    let captureStream = null;
    let stream: MediaStream | null = null;

    if (
      device.isImplements(Feature.MEDIA_STREAM) &&
      device.isImplements(Feature.MEDIA_DEVICE) &&
      typeof device.mediaDevices.getDisplayMedia === "function"
    ) {
      const promise = device.mediaDevices?.getDisplayMedia?.({ video: constraints.video });
      if (promise != null) {
        try {
          captureStream = await promise;
        } catch (err) {
          this.videoDeviceChanging = null;
          throw err;
        }
      }
      if (constraints.audio !== false) {
        stream = await this.prepareMediaStream({ audio: constraints.audio });
      } else {
        stream = new device.MediaStream();
      }
    }

    if (captureStream == null || stream == null) {
      throw new Error("unable to capture screen");
    }

    captureStream.getTracks().forEach((t) => {
      tracksMeta.set(t, {
        deviceId: VIDEO_DEVICE_SCREENCAPTURE.deviceId,
      });
      stream?.addTrack(t);
    });

    return stream;
  }

  private async prepareCapturableStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    this.logger.trace("prepareCapturableStream()", { constraints });
    let captureStream: MediaStream | null = null;
    let stream: MediaStream | null = null;
    // Grab canvas track
    if (
      device.isImplements(Feature.MEDIA_STREAM) &&
      this.capturable?.element != null &&
      device.supportsMediaStreamCapture(this.capturable?.element)
    ) {
      captureStream = this.capturable.element.captureStream(this.capturable.framerate);
    } else {
      this.logger.warn("unable to support stream capture");
    }

    // Create MediaStream, adding audioTrack (if there is one)
    if (device.isImplements(Feature.MEDIA_DEVICE) && device.isImplements(Feature.MEDIA_STREAM)) {
      stream =
        constraints.audio === false || constraints.audio == null
          ? new device.MediaStream()
          : await this.prepareMediaStream({ audio: constraints.audio });
    }

    if (captureStream == null || stream == null) {
      // @todo add log
      throw new Error(
        `prepareCapturableStream(): unable to generate MediaStream from provided inputs. CapturableStream is null: ${
          captureStream == null
        }. Base MediaStream is null: ${stream == null}`,
      );
    }

    captureStream.getTracks().forEach((t) => {
      tracksMeta.set(t, {
        deviceId: VIDEO_DEVICE_CAPTURABLE.deviceId,
      });
      stream?.addTrack(t);
    });

    return stream;
  }

  /**
   * Release audio and video devices
   */
  close(reason = "not provided"): void {
    try {
      stopTracks(this.source);
      this.audioDeviceChanging = null;
      this.videoDeviceChanging = null;
      this.logger.info("MediaController Class close()", { aggregates: { reason } });
    } catch (err) {
      this.emitError(
        createError(
          ErrorCode.DisposingError,
          "unable to dispose media stream controller",
          {
            className: MediaStreamController.displayName,
            reason,
          },
          err,
        ),
      );
    }

    // it's ok because audioCtx can be closed already
    this.audioCtx?.close().catch(() => {
      // pass
    });
  }

  toJSON(): Json {
    return {
      audioMuted: this.audioMuted,
      videoPaused: this.videoPaused,
      videoDisabled: this.videoDisabled,
      inAudioDeviceTransition: this.inAudioDeviceTransition,
      inVideoDeviceTransition: this.inVideoDeviceTransition,
      hasSync: this.sync != null,
      mscOptions: {
        replaceTracks: this.options?.replaceTracks,
        waitingDelay: this.options?.waitingDelay,
        defaultLockPolicy: this.options?.defaultLockPolicy,
      },

      aggregates: {
        audioDeviceId: this.audioDeviceId,
        videoDeviceId: this.videoDeviceId,
      },
    };
  }
}
