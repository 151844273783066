import { RootStyles, StylesObject } from "../../../ui-lib/typings/css";

export interface CarouselSlideStyleProps extends RootStyles {
  root: StylesObject;
  image: StylesObject;
  addButton: StylesObject;
  playingOverlay: StylesObject;
  disconnected: StylesObject;
  viewCount: StylesObject;
  disconnectedWrapper: StylesObject;
  stockImageWrapper: StylesObject;
}

export interface PlayerStyleProps extends RootStyles {
  root: StylesObject;
  closeButton: StylesObject;
}

const buttonStyles = {
  position: "absolute",
  top: 0,
  right: 0,
  width: "1.5rem",
  height: "1.5rem",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(220, 175, 15, 0.7)",
  borderBottomLeftRadius: "15px",
  border: "1px solid #fff",
  paddingBottom: "0.15rem",
  zIndex: 1,
};

const viewCountStyles = {
  position: "absolute",
  padding: "0.2rem 0.8rem",
  color: "#fff",
  display: "flex",
  gap: "0.7rem",
  justifyContent: "space-between",
  alignItems: "center",
  background: "rgba(29, 29, 29, 0.4)",
  backdropFilter: "blur(3.40008px)",
  border: "1px solid #fff",
  borderRadius: "1.36px",
};

export const VIDEO_SPACING = 16;

export const playerStyles = {
  root: {},
  closeButton: buttonStyles,
  viewCount: { ...viewCountStyles, bottom: 11, right: 10 },
};

export const playersWrapperStyles: RootStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: `${VIDEO_SPACING}px`,
    "@media only screen and (min-width: 769px)": {
      width: "100%",
      height: "inherit",
    },
    "@media only screen and (max-width: 770px)": {
      width: "inherit",
      height: "100%",
    },
  },
};

export const carouselSlideStyles: CarouselSlideStyleProps = {
  root: {
    height: "inherit",
    width: "100%",
    position: "relative",
  },
  disconnectedWrapper: {
    height: "inherit",
    width: "100%",
    position: "relative",
    background: "rgba(29, 29, 29, 0.4)",
    backdropFilter: "blur(3.40008px)",
  },
  addButton: buttonStyles,
  image: {
    height: "inherit",
    width: "100%",
    objectFit: "cover",
  },
  playingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "inherit",
    width: "100%",
    border: "solid 3px #E5B711",
  },
  disconnected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
    width: "100%",
    color: "#fff",
  },
  stockImageWrapper: {
    height: "inherit",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  viewCount: { ...viewCountStyles, bottom: 5, right: 5 },
};

export const getVideoElementStyles = ({
  containerHeight,
  containerWidth,
  playersCount,
}: {
  containerHeight: number;
  containerWidth: number;
  playersCount: number;
}): RootStyles => {
  if (containerHeight === 0 || containerWidth === 0) return { root: { width: "100%", height: "100%" } };

  if (playersCount === 1) {
    return {
      root: {
        width: "100%",
        height: `${containerHeight}px`,
        "@media only screen and (max-width: 768px)": {
          width: "100%",
          height: "auto",
        },
      },
    };
  }

  const marginOfError = 2;

  return {
    root: {
      width: `${(containerWidth - VIDEO_SPACING - marginOfError) / 2}px`,
      height: `${(containerHeight - VIDEO_SPACING - marginOfError) / 2}px`,
      "@media only screen and (max-width: 768px)": {
        width: "100%",
        height: "auto",
      },
    },
  };
};
