import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState, PlayerUiState } from "../../../../../store";
import { EncoderUiContext, PlayerUiContext } from "../../../../context";
import ButtonIcon from "../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

interface FullscreenButtonClasses extends RootStyles {
  icon?: Properties;
}

interface FullscreenButtonProps extends Partial<ButtonIconProps> {
  classes?: FullscreenButtonClasses;
}

const ModularFullscreenButton = observer(
  ({
    active,
    classes = {
      root: {},
      icon: {},
    },
    icon: ProvidedIcon,
    label = "Toggle fullscreen",
    onClick,
    screenReaderText,
    ...props
  }: FullscreenButtonProps) => {
    const componentName = "<FullscreenButton/>";

    /**
     * Determines whether we are using a player or encoder.
     */
    const ctx =
      useContext<EncoderUiState | null>(EncoderUiContext) != null
        ? useContext<EncoderUiState | null>(EncoderUiContext)
        : useContext<PlayerUiState | null>(PlayerUiContext);

    useUndefinedStoreError(ctx?.toggleFullscreen != null, componentName);

    const handleClick = onClick ?? useUIEventError(ctx.toggleFullscreen, componentName);

    const activeState: boolean = active ?? ctx.isFullscreen ?? false;

    const icon = activeState ? (
      <Icon iconName="fullscreenexit" classes={classes?.icon} />
    ) : (
      <Icon iconName="fullscreen" classes={classes?.icon} />
    );

    return (
      <ButtonIcon
        active={activeState}
        inactiveClass={null}
        classes={classes}
        data-selenium="fullscreen-button"
        icon={ProvidedIcon || icon}
        label={label}
        onClick={handleClick}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Collapse from " : "Expand to "}  Fullscreen`}
      </ButtonIcon>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const FullscreenButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  classes,
  icon: ProvidedIcon,
  label = "Toggle fullscreen",
  screenReaderText,
  style = {},
  ...props
}: FullscreenButtonProps) => {
  const icon = ProvidedIcon ?? <Icon iconName="fullscreen" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="fullscreen-button"
          disabled
          label={label}
          icon={icon}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Expand to Fullscreen"}
        </ButtonIcon>
      )}
    >
      <ModularFullscreenButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default FullscreenButtonWithErrorBoundary;
