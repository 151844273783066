import { MediaStream, VideoElement } from "../../api";
import { device, Feature } from "../../api/adapter";
import { MediaError, TimeRanges, VideoElementEventsMap } from "../../api/typings/video-element";
import { AggregateLogs, dumpVideoElement } from "../utils/debug/play-logs";
import { ObservableEventEmitter } from "../utils/events/event-emitter";
import { timeupdateWrapper, type CorePlayer } from "./core";

type HandlerNames =
  | "handleElPlay"
  | "handleElPause"
  | "handleElVolumeChange"
  | "handleElError"
  | "handleElProgress"
  | "handleElTimeupdate";

const elementsSupervisors = new WeakMap<VideoElement, MediaElementSupervisor>();

export type MediaElementSupervisorEvents = {
  loadeddata: void;
};

export class MediaElementSupervisor extends ObservableEventEmitter<MediaElementSupervisorEvents> {
  static getSupervisor(element: VideoElement): MediaElementSupervisor {
    if (!elementsSupervisors.has(element)) {
      elementsSupervisors.set(element, new MediaElementSupervisor(element));
    }
    return elementsSupervisors.get(element)!;
  }

  activePlayer: CorePlayer | null = null;

  debugSuspendTimeupdate = false;

  private constructor(private readonly element: VideoElement) {
    super();

    element.setAttribute("playsinline", "true");
    element.setAttribute("webkit-playsinline", "true");

    this.startListenEvent("play", "handleElPlay");
    this.startListenEvent("pause", "handleElPause");
    this.startListenEvent("volumechange", "handleElVolumeChange");
    this.startListenEvent("error", "handleElError");
    this.startListenEvent("progress", "handleElProgress");

    timeupdateWrapper.wrap(element, this.createEventHandler("handleElTimeupdate"));

    this.element.addEventListener("loadeddata", () => {
      this.emit("loadeddata");
    });
  }

  attachToPlayer(player: CorePlayer): this {
    this.element.src = "";
    this.element.srcObject = null;

    this.activePlayer = player;
    return this;
  }

  private createEventHandler(handlerName: HandlerNames): () => void {
    return () => {
      if (this.activePlayer == null || this.activePlayer.isDisposed) {
        return;
      }
      if (handlerName === "handleElTimeupdate" && this.debugSuspendTimeupdate) {
        return;
      }
      this.activePlayer?.[handlerName]?.();
    };
  }

  private startListenEvent(event: keyof VideoElementEventsMap, handlerName: HandlerNames): void {
    const handler = this.createEventHandler(handlerName);
    this.element.addEventListener(event, handler);
    this.addInnerDisposer(this.stopListenEvent.bind(this, event, handler));
  }

  private stopListenEvent(event: keyof VideoElementEventsMap, handler: () => void): void {
    this.element.removeEventListener(event, handler);
  }

  isVideoPlaying(): boolean {
    return this.element.currentTime > 0 && !this.element.paused && !this.element.ended && this.element.readyState > 2;
  }

  setPoster(val: string): void {
    this.element.setAttribute("poster", val);
  }

  hasPoster(): boolean {
    return this.element.getAttribute("poster") != null;
  }

  createScreenshot(): string | null {
    try {
      if (device.isImplements(Feature.CREATE_SCREENSHOT)) {
        return device.createScreenshot(this.element);
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  pause(): void {
    this.element.pause();
  }

  updateSrc(val: string): void {
    this.element.src = val;
    this.element.srcObject = null;
  }

  updateSrcObject(val: MediaStream | null): void {
    this.element.src = "";
    this.element.srcObject = val;
  }

  dumpVideoElement(): AggregateLogs {
    return dumpVideoElement(this.element);
  }

  get muted(): boolean {
    return this.element.muted;
  }

  set muted(val: boolean) {
    this.element.muted = val;
  }

  get paused(): boolean {
    return this.element.paused;
  }

  get volume(): number {
    return this.element.volume;
  }

  set volume(val: number) {
    this.element.volume = val;
  }

  get error(): MediaError | null {
    return this.element.error;
  }

  get autoplay(): boolean {
    return this.element.autoplay;
  }

  set autoplay(val: boolean) {
    this.element.autoplay = val;
  }

  get buffered(): TimeRanges {
    return this.element.buffered;
  }

  get ended(): boolean {
    return this.element.ended;
  }

  get readyState(): number {
    return this.element.readyState;
  }

  get currentTime(): number {
    return this.element.currentTime;
  }

  set currentTime(val: number) {
    this.element.currentTime = val;
  }

  get playbackRate(): number {
    return this.element.playbackRate;
  }

  set playbackRate(val: number) {
    this.element.playbackRate = val;
  }

  get sourceKind(): "src" | "srcObject" | null {
    const currentLocation = device.isImplements(Feature.URL_LOCATION) ? device.location : "";
    if (this.element.srcObject != null) {
      return "srcObject";
    }
    if (this.element.src != null && this.element.src !== "" && this.element.src !== currentLocation) {
      return "src";
    }
    return null;
  }

  isSourceSet(): boolean {
    const currentLocation = device.isImplements(Feature.URL_LOCATION) ? device.location : "";
    return (
      (this.element.src != null && this.element.src !== "" && this.element.src !== currentLocation) ||
      this.element.srcObject != null
    );
  }

  get srcObject(): unknown {
    return this.element.srcObject;
  }

  play(): Promise<void> {
    return this.element.play();
  }
}

//
// export type MediaElementProxyHandlerEvents = {
//   getter: { property: string, value: any };
//   setter: { property: string, value: any };
//   callStarted: { method: string; args: any[] };
//   callEnded: { method: string; args: any[]; result: any };
//   callResolved: { method: string; args: any[]; result: any };
// };

//
// class MediaElementProxyHandler extends ObservableEventEmitter<MediaElementProxyHandlerEvents> implements ProxyHandler<VideoElement> {
//   denyListProperties = new Set<string>(["getAttribute", "setAttribute", "addEventListener", "removeEventListener"]);
//
//   constructor(private debug = false) {
//     super();
//   }
//
//   get(target: VideoElement, p: string | symbol, receiver: any): any {
//     if (!this.debug) {
//       return Reflect.get(target, p);
//     }
//
//     const value = Reflect.get(target, p);
//     if (typeof value === 'function') {
//       return (...args: any[]) => {
//         if (!this.denyListProperties.has(String(p))) {
//           this.emit("callStarted", { method: String(p), args });
//         }
//
//         const result = value.apply(target, args);
//         if (!this.denyListProperties.has(String(p))) {
//           this.emit("callEnded", { method: String(p), args, result });
//         }
//
//         if ("then" in value && typeof value.then === "function") {
//           value.then((result: any) => {
//             this.emit("callResolved", { method: String(p), args, result });
//           });
//         }
//         return result
//       }
//     } else {
//       this.emit("getter", { property: String(p), value });
//     }
//     return value;
//   }
//
//   set(target: VideoElement, p: string | symbol, newValue: any, receiver: any): boolean {
//     if (!this.debug) {
//       return Reflect.set(target, p, newValue);
//     }
//
//     this.emit("setter", { property: String(p), value: newValue });
//
//     return Reflect.set(target, p, newValue);
//   }
// }
