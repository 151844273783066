import React, { forwardRef, ReactNode } from "react";
import { useUID } from "react-uid";
import { useStyles } from "../../styling/videoStyles";
import styles, { StyleProps } from "./selectStyles";

export interface SelectProps {
  ariaLabel?: string;
  className?: string;
  children?: ReactNode;
  value?: string;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  classes?: StyleProps;
  tag?: string;
  fallbackText?: string;
  maxResolutionHeight?: number | null;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { ariaLabel, classes = { root: {}, select: {}, label: {} }, label, children, onChange, value, tag, ...rest },
    ref,
  ) => {
    const {
      root: rootClass,
      select: selectClass,
      label: labelClass,
    } = useStyles({ source: classes, target: styles }, "select");
    const uid = useUID();

    return (
      <div className={rootClass}>
        {label != null ? (
          <label htmlFor={uid} className={labelClass}>
            {label}
          </label>
        ) : null}
        <select
          ref={ref}
          id={uid}
          className={selectClass}
          aria-label={label ?? "Default select"}
          data-selenium={tag ?? "select-input"}
          onChange={onChange}
          value={value}
        >
          {children}
        </select>
      </div>
    );
  },
);

export default Select;
