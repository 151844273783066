import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../store/encoder";
import { PlayerUiState } from "../../../../store/player";
import { EncoderUiContext, PlayerUiContext } from "../../../context";
import SlideSidebar, { SlidingShelfProps } from "../../../ui-lib/SlidingShelf";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularSlideSidebar = observer(({ children, open, onCloseButtonClick, ...props }: Partial<SlidingShelfProps>) => {
  const componentName = "<SettingsSidebar/>";

  const ctx =
    useContext<EncoderUiState | null>(EncoderUiContext) != null
      ? useContext<EncoderUiState | null>(EncoderUiContext)
      : useContext<PlayerUiState | null>(PlayerUiContext);

  useUndefinedStoreError(ctx != null, componentName);

  const toggleSettings = (): void => {
    ctx.viewSettings = !ctx.viewSettings;
  };

  const handleClick = useUIEventError(toggleSettings, componentName);

  return (
    <SlideSidebar {...props} open={open ?? ctx.viewSettings} onCloseButtonClick={onCloseButtonClick ?? handleClick}>
      {children}
    </SlideSidebar>
  );
});

const SlideSidebarWithErrorBoundary: FC<Partial<SlidingShelfProps>> = ({ children, ...props }) => {
  return (
    <ErrorBoundary render={() => <SlideSidebar {...props} />}>
      <ModularSlideSidebar {...props}>{children}</ModularSlideSidebar>
    </ErrorBoundary>
  );
};

export default SlideSidebarWithErrorBoundary;
export { SlideSidebar as StatelessSettingsSidebar };
