import { EventEmitter } from "@video/events-typed";
import { device } from "../../../api/adapter";
import { AnalyserNode, AudioContext, MediaStreamAudioSourceNode } from "../../../api/adapter/features/audio-context";
import { Feature } from "../../../api/adapter/features/feature";
import { MediaStream, MediaStreamTrack } from "../../../api/adapter/features/media-stream";

// reduce fft size since we're looking at time-domain
const FFT_SIZE = 64;
// analysis happens every second but look at the last 2
const HISTORY_SECONDS = 2;
// a lower tolerance provides less "stable" values
const TOLERANCE = 0.09;
const THRESHOLD_RATIO = 0.4;

interface DataResult {
  average: number;
  total: number;
  sum: number;
  highest: number | null;
  lowest: number | null;
}

// do we need it? if so it should be moved to constructor or so
// device.globals.set("printOutput", false);

const log = (...msg: unknown[]): void => {
  const printOutput = device.globals.get("printOutput") === true;
  if (printOutput && device.isImplements(Feature.DEBUGGING)) {
    device.console.log(...msg);
  }
};

interface EchoDetectorEventsMap {
  echo: void;
  noecho: void;
}

export default class EchoDetector extends EventEmitter<EchoDetectorEventsMap> {
  static readonly displayName = "EchoDetector";

  public total = 0;

  private echo = false;

  private readonly _audioContext: AudioContext;

  private readonly _analyserNode: AnalyserNode;

  private _history: Array<DataResult[]> = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _processorNode: any;

  private readonly _dataArray: Uint8Array;

  private _currBucket = 0;

  private readonly _mediaStream: MediaStream;

  private readonly _mediaStreamSource: MediaStreamAudioSourceNode;

  constructor(track: MediaStreamTrack) {
    super();

    if (!device.isImplements(Feature.AUDIO_CONTEXT)) {
      throw new Error("Audio management is not supported");
    }

    this._audioContext = new device.AudioContext();
    this._analyserNode = this._audioContext.createAnalyser();
    this._processorNode = this._audioContext.createScriptProcessor();
    this._dataArray = new device.Uint8Array(this._analyserNode.fftSize);

    // reduce smoothing a bit
    this._analyserNode.smoothingTimeConstant = 0.4;
    this._analyserNode.fftSize = FFT_SIZE;

    this._processorNode.onaudioprocess = () => {
      this._analyserNode.getByteTimeDomainData(this._dataArray);

      const currTime = Date.now();
      const currBucket = Math.floor(currTime / 1000);
      if (this._currBucket !== currBucket) {
        if (this._history.length > HISTORY_SECONDS) {
          this._history = this._history.slice(1, this._history.length);
          this.runAnalysis();
        }
        this._history.push([]);
        this._currBucket = currBucket;
      }

      this._history[this._history.length - 1]?.push(this.dataResult());
      this.total += 1;
    };

    if (!device.isImplements(Feature.MEDIA_STREAM)) {
      throw new Error("media stream is not implemented");
    }

    this._mediaStream = new device.MediaStream();
    this._mediaStream.addTrack(track);

    this._mediaStreamSource = this._audioContext.createMediaStreamSource(this._mediaStream);
    this._mediaStreamSource.connect(this._analyserNode);
    this._analyserNode.connect(this._processorNode);
  }

  close(): void {
    this._audioContext.close();
  }

  dataResult(): DataResult {
    let lowest = null;
    let highest = null;
    let sum = 0;
    for (let i = 0; i < this._dataArray.length; i += 1) {
      const value = this._dataArray[i];
      if (lowest == null || value < lowest) {
        lowest = value;
      }
      if (highest == null || value > highest) {
        highest = value;
      }
      sum += value;
    }

    return {
      lowest,
      highest,
      total: this._dataArray.length,
      sum,
      average: sum / this._dataArray.length,
    };
  }

  runAnalysis(): void {
    let increases = 0;
    let decreases = 0;
    let stables = 0;

    const chart = [];
    for (const [x, bin] of this._history.entries()) {
      for (const [y, value] of bin.entries()) {
        const prev = this._history[x - 1];
        if (prev != null) {
          const previous = bin[y - 1] ?? prev[prev.length - 1];

          if (previous != null) {
            const highest = value.highest ?? 0;
            const prevHighest = previous.highest ?? 0;
            const compareDecrease = prevHighest - Math.abs(prevHighest) * TOLERANCE;

            if (highest >= compareDecrease) {
              const compareIncrease = prevHighest + Math.abs(prevHighest) * TOLERANCE;

              if (highest <= compareIncrease) {
                stables += 1;
                chart.push("s");
              } else {
                increases += 1;
                chart.push("i");
              }
            } else {
              decreases += 1;
              chart.push("d");
            }
          }
        }
      }
    }

    const variations = increases + decreases;
    const total = variations + stables;
    if (variations / total > THRESHOLD_RATIO) {
      if (!this.echo) {
        this.echo = true;
        this.emit("echo");
      }
    } else if (this.echo) {
      this.echo = false;
      this.emit("noecho");
    }

    log(`${chart.join("")} - (${increases}/${decreases}/${stables}) (${variations})/${total})`);
  }
}
