import { VideoElement } from "../../api/typings/video-element";

export class TimeupdateWrapper {
  paused = false;

  handlersMap = new WeakMap<VideoElement, Array<() => void>>();

  wrap(el: VideoElement, callback: () => void): void {
    if (this.handlersMap.get(el) == null) {
      this.handlersMap.set(el, []);
    }
    const handler = this.handle.bind(this, callback);
    this.handlersMap.get(el)?.push(handler);
    el.addEventListener("timeupdate", handler);
    el.addEventListener("vdc:timeupdatePause", this.pauseUpdates);
    el.addEventListener("vdc:timeupdateResume", this.resumeUpdates);
  }

  handle(cb: () => void): void {
    if (!this.paused) {
      cb();
    }
  }

  pauseUpdates = (): void => {
    this.paused = true;
  };

  resumeUpdates = (): void => {
    this.paused = false;
  };

  unwrap(el: VideoElement): void {
    for (const handler of this.handlersMap.get(el) ?? []) {
      el.removeEventListener("timeupdate", handler);
      el.removeEventListener("vdc:timeupdatePause", this.pauseUpdates);
      el.removeEventListener("vdc:timeupdateResume", this.resumeUpdates);
    }
  }
}
