import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { MultistreamContext } from "../../../context";
import { MultistreamState } from "../../../../store/multistream";
import Carousel from "../../../ui-lib/Carousel";
import { useUndefinedStoreError } from "../../ErrorBoundary";
import MultistreamCarouselSlide from "./MultistreamCarouselSlide";
import useMediaQuery from "../../../ui-lib/Hooks/useMediaQuery";

interface MultistreamCarouselProps {
  itemsPerSlide?: number;
}

const MultistreamCarousel = observer(({ itemsPerSlide = 6 }: MultistreamCarouselProps) => {
  const isTablet = useMediaQuery("screen and (max-width: 1023px)");
  const isMobile = useMediaQuery("screen and (max-width: 768px)");
  const ctx = useContext<MultistreamState | null>(MultistreamContext);

  useUndefinedStoreError(ctx != null, "<MultistreamCarousel/>");

  const { isInitialLoadDone, publicKeys } = ctx;

  return (
    <div>
      {isInitialLoadDone && (
        <Carousel itemsPerSlide={isTablet ? itemsPerSlide / 2 : itemsPerSlide} gap={10} vertical={!isMobile}>
          {publicKeys.map((publicKey) => (
            <MultistreamCarouselSlide key={publicKey} publicKey={publicKey} isMobile={isMobile} />
          ))}
        </Carousel>
      )}
    </div>
  );
});

export default MultistreamCarousel;
