import { player as pl, SourceScoreLevel, TranscodeScoreLevel, AutoQualityLevel } from "@video/video-client-core";
import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { PlayerUiState } from "../../../../../store";
import { PlayerUiContext } from "../../../../context";
import { useStyles } from "../../../../styling/videoStyles";
import SelectNonNative, { SelectProps } from "../../../../ui-lib/Inputs/SelectNonNative";
import { ErrorBoundary, useUndefinedStoreError } from "../../../ErrorBoundary";
import styles from "./styles";

interface QualitySettingsClasses {
  root?: Properties;
  option?: Properties;
  activeOption?: Properties;
  disabledOption?: Properties;
}

type QualitySettingsProps = Omit<SelectProps, "classes"> & {
  classes?: QualitySettingsClasses;
  showBitrate?: boolean;
  disableSelect?: boolean;
};

const ModularQualitySelect = observer(
  ({ classes = {}, disableSelect, showBitrate, ...props }: Partial<QualitySettingsProps>) => {
    const componentName = "<QualitySelect/>";

    /**
     * Access PlayerUiContext.
     */
    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    const mergedClasses = useStyles({ source: classes, target: styles }, "quality-select");

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.player != null, componentName);

    /**
     * Returns true if the given score matches the current quality.
     */
    function isSelected(score: SourceScoreLevel | TranscodeScoreLevel | AutoQualityLevel): boolean {
      if (!ctx?.player.isImplements(pl.Feature.BITRATE_SWITCHING)) {
        return false;
      }
      return score === ctx.player.currentQuality?.level;
    }

    /**
     * Select the closest quality to the given score if player supports bitrate switching.
     * Otherwise, it does nothing.
     */
    function selectQuality(score: SourceScoreLevel | TranscodeScoreLevel | AutoQualityLevel): void {
      if (ctx?.player.isImplements(pl.Feature.BITRATE_SWITCHING)) {
        ctx.player.preferredLevel = score;
      }
    }

    function showBitrateText(formattedBitrate: string): string {
      if (!showBitrate || formattedBitrate == null || formattedBitrate === "") {
        return "";
      }

      return formattedBitrate;
    }

    if (!ctx?.player?.isImplements(pl.Feature.BITRATE_SWITCHING)) {
      return null;
    }

    return (
      <SelectNonNative {...props} className={mergedClasses.root}>
        {ctx.availableBitrates.map((layer) => (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && layer.score !== undefined && selectQuality(layer.score)}
            key={layer.score}
            id={layer.score}
            onClick={() => selectQuality(layer.score)}
            className={isSelected(layer.score) ? mergedClasses.activeOption : mergedClasses.option}
          >
            <label htmlFor="layer.score">
              {layer.name} {showBitrateText(layer.formattedBitrate)}
            </label>
          </div>
        ))}
      </SelectNonNative>
    );
  },
);

function QualitySelectWithErrorBoundary({ classes, ...props }: QualitySettingsProps): React.ReactElement {
  const mergedClasses = useStyles({ source: classes ?? {}, target: styles }, "select");

  return (
    <ErrorBoundary
      render={() => (
        <SelectNonNative {...props} className={mergedClasses.root}>
          <div key="" id="disabled-select" className={mergedClasses.disabledOption}>
            Qualities Unavailable
          </div>
        </SelectNonNative>
      )}
    >
      <ModularQualitySelect {...props} />
    </ErrorBoundary>
  );
}

export default QualitySelectWithErrorBoundary;
