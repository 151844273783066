import { player as corePlayer, types } from "@video/video-client-core";
import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../../../store";
import { PlayerUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface GetSoundButtonClasses extends RootStyles {
  icon?: Properties;
}

interface GetSoundButtonProps extends Partial<ButtonIconProps> {
  classes?: GetSoundButtonClasses;
}

const ModularTestSoundButton = observer(
  ({
    active = false,
    icon: ProvidedIcon,
    label = "Get sound",
    onClick,
    screenReaderText,
    classes = {
      root: {},
      icon: {},
    },
    ...props
  }: GetSoundButtonProps) => {
    const componentName = "<GetSoundButton/>";

    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    useUndefinedStoreError(ctx?.player != null, componentName);

    const resumeAudio = (): void => {
      ctx.player.localAudioMuted = false;
      (ctx.player as types.PlayerAPI & types.MutedAutoplayFeature).forcedMute = false;
    };

    const handleClick = onClick ?? useUIEventError(resumeAudio, componentName);

    const icon = active ? (
      <Icon iconName="sound" classes={classes?.icon} />
    ) : (
      <Icon iconName="soundoff" classes={classes?.icon} />
    );

    if (
      ctx.player.isImplements(corePlayer.Feature.MUTED_AUTOPLAY) &&
      ctx.player.forcedMute &&
      ctx.player.localAudioMuted
    ) {
      return (
        <ButtonIcon
          classes={classes}
          data-selenium="audio-stream-button"
          classNames="video-button--get-sound lv-button--get-sound"
          icon={ProvidedIcon || icon}
          label={label}
          active={active ?? false}
          onClick={handleClick}
          {...props}
        >
          {screenReaderText ?? `Click to ${active ? "Stop" : "Start"} Audio Stream`}
        </ButtonIcon>
      );
    }
    return null;
  },
);

// eslint-disable-next-line react/function-component-definition
const TestSoundButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  label = "Get sound",
  screenReaderText,
  active,
  classes,
  ...props
}: GetSoundButtonProps) => {
  const icon = ProvidedIcon ?? <Icon iconName="soundoff" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="audio-stream-button"
          disabled
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Start Audio Stream"}
        </ButtonIcon>
      )}
    >
      <ModularTestSoundButton
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        active={active}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default TestSoundButtonWithErrorBoundary;
