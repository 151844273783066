import { RootStyles, StylesObject } from "../typings/css";

interface StyleProps extends RootStyles {
  carouselItem: StylesObject;
  carouselButton: StylesObject;
  slidesWrapper: StylesObject;
  carouselSlidesWrapper: StylesObject;
}

const styles: StyleProps = {
  root: {
    display: "grid",
    height: "100%",
  },
  slidesWrapper: { position: "relative", overflow: "hidden", width: "100%" },
  carouselSlidesWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
  },
  carouselItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  carouselButton: {
    zIndex: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0, 0.3)",
    color: "#fff",
    backdropFilter: "blur(15px)",
    height: "1.5rem",
  },
};

export const getRootStyles = (isVertical: boolean): StylesObject => ({
  ...(isVertical && { gridTemplateRows: "auto 1fr auto" }),
  ...(!isVertical && { gridTemplateColumns: "auto 1fr auto" }),
});

export const getButtonStyles = (
  isVertical: boolean,
  disabled: boolean,
  shouldRenderButtons: boolean,
): StylesObject => ({
  ...(disabled && {
    backgroundColor: "rgba(0,0,0, 0.6)",
    pointerEvents: "none",
    color: "#aeafaf",
  }),
  ...(!isVertical && { height: "100%", width: "1.5rem" }),
  ...(!shouldRenderButtons && { height: "0px", width: "0px" }),
});

export const getChevronStyles = (
  isVertical: boolean,
  shouldRender: boolean,
  direction: "up" | "down",
): StylesObject => ({
  ...(isVertical && direction === "up" && { transform: "rotateZ(180deg)" }),
  ...(!isVertical && direction === "up" && { transform: "rotateZ(90deg)" }),
  ...(!isVertical && direction === "down" && { transform: "rotateZ(-90deg)" }),
  ...(!shouldRender && { display: "none" }),
});

export const getCarouselSlidesWrapperStyles = (isVertical: boolean, gap: number): StylesObject => ({
  gap: `${gap}px`,
  ...(isVertical && { width: "100%", flexDirection: "column" }),
  ...(!isVertical && { height: "100%", flexDirection: "row" }),
});

export const getCarouselSlideStyles = ({
  isVertical,
  isLoading,
  carouselDrivingDimension,
  gap,
  currentIndex,
}: {
  isVertical: boolean;
  isLoading: boolean;
  carouselDrivingDimension: number;
  gap: number;
  currentIndex: number;
}): StylesObject => ({
  position: "relative",
  ...(!isLoading && { transition: "0.5s ease-in-out" }),
  ...(isVertical && {
    transform: `translate3d(0px, -${currentIndex * carouselDrivingDimension + gap * currentIndex}px, 0px)`,
    height: `${carouselDrivingDimension}px`,
  }),
  ...(!isVertical && {
    transform: `translate3d(-${currentIndex * carouselDrivingDimension + gap * currentIndex}px, 0px, 0px)`,
    width: `${carouselDrivingDimension}px`,
    height: "100%",
  }),
});

export default styles;
