import React, { FC, ReactChild } from "react";
import { useStyles } from "../../styling/videoStyles";
import Clock from "../Icons/Clock";
import { RootStyles } from "../typings/css";
import styles from "./styles";

export interface DurationLabelProps {
  children: ReactChild;
  classes?: RootStyles;
}

const DurationLabel: FC<DurationLabelProps> = ({ children, classes = {}, ...rest }) => {
  const mergedClasses = useStyles({ source: classes, target: styles }, "durationLabel");

  return (
    <div className={mergedClasses.root}>
      {/* <span className={`${mergedClasses.icon} video-icon lv-icon`}> */}
      <Clock />
      {/* </span> */}
      {/* <span className={`${mergedClasses.time} video-time lv-icon`}>{children}</span> */}
    </div>
  );
};

export default DurationLabel;
