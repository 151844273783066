import { LoggerCore } from "@video/log-client";
import { types } from "mediasoup-client";
import { device } from "../../../api/adapter";
import { Feature } from "../../../api/adapter/features/feature";
import { ConsumerStats, ProducerStats } from "../../../api/stats";

const durationSpike = 30000;
const errorsFrequency = 30000;

export interface AnomalyEvent {
  message: string;
  cpuUsage: number;
  cpuUsageDuration: number;
}

export async function watchCpuSpikes(
  log: LoggerCore,
  peer: types.Consumer | types.Producer,
  percentDrops = 0.25,
  cpuPeak = 0.9,
  durationMs = 15000,
): Promise<void> {
  const dev = device;
  if (!dev.isImplements(Feature.CPU_USAGE)) {
    return;
  }

  let lastStats = { bitrate: 0 };
  let cpuSpikeStart: number | null = null;

  const watcher = (stats: ConsumerStats | ProducerStats): void => {
    const cpuUsage = dev.averageCpuUsage(durationMs);

    if (cpuSpikeStart != null && Date.now() - cpuSpikeStart > durationSpike) {
      const message = "CPU usage is too high for long time";
      const params = {
        cpuUsage,
        cpuUsageDuration: Date.now() - cpuSpikeStart,
      };

      log.throttledLog("error", errorsFrequency, message, {
        ...params,
        aggregates: { messageGroup: "webrtc anomaly" },
      });
      const ev: AnomalyEvent = { message, ...params };
      (peer as any).emit("webrtc-anomaly", ev);
    }

    if (dev.averageCpuUsage(1000) >= cpuPeak) {
      if (cpuSpikeStart == null) {
        cpuSpikeStart = Date.now();
      }
    } else {
      cpuSpikeStart = null;
    }

    const drop = (lastStats.bitrate - (stats.videoBitrate ?? 0)) / lastStats.bitrate;
    if (drop > percentDrops) {
      if (cpuUsage >= cpuPeak) {
        const now = Date.now();
        const message = "Bitrate has been dropped with high CPU usage";
        const params = {
          bitrateDrop: drop,
          cpuUsage,
          cpuUsageDuration: now - (cpuSpikeStart ?? now),
        };

        log.throttledLog("error", errorsFrequency, message, {
          ...params,
          aggregates: { messageGroup: "webrtc anomaly" },
        });
        const ev: AnomalyEvent = { message, ...params };
        (peer as any).emit("webrtc-anomaly", ev);
      }
    }
    lastStats = { bitrate: stats.videoBitrate ?? 0 };
  };

  (peer as any).on("webrtc-stats", watcher);
}
