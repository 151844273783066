import React, { ReactElement, ReactPortal } from "react";
import ReactDOM from "react-dom";
import CallControlUI, { CallControlProps } from "../../../ui-lib/CallControl/CallControlUI";

interface CallControlRefProps extends Partial<CallControlProps> {
  attachToRef: Element | null;
  isCallInProgress: boolean;
}

function CallControls({
  attachToRef,
  isCallInProgress,
  renderCustomControls,
  renderCallMetaDisplay,
  classes,
}: CallControlRefProps): ReactPortal | null {
  if (attachToRef == null || !isCallInProgress) return null;

  const renderUI = (): ReactElement => (
    <CallControlUI
      classes={classes}
      renderCustomControls={renderCustomControls}
      renderCallMetaDisplay={renderCallMetaDisplay}
    />
  );
  return ReactDOM.createPortal(renderUI(), attachToRef);
}

export default CallControls;
