import { Properties } from "csstype";
import React, { FC, useEffect } from "react";
import { useStyles } from "../../../styling/videoStyles";
import Icon, { IconStyleProps } from "../../Icons";
import { EventFunction } from "../../typings/clickevents";
import { RootStyles } from "../../typings/css";
import styles from "./styles";

interface BroadcastButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

type CloseButtonProps = {
  onClick: undefined | EventFunction;
  active?: string | null;
  classes: BroadcastButtonClasses;
  icon?: boolean;
  open?: boolean;
  iconColor?: Properties;
  label?: string;
  react?: boolean;
};

const CloseButton: FC<CloseButtonProps> = ({
  onClick,
  active = null,
  classes = {},
  icon,
  open,
  iconColor,
  label,
  ...rest
}) => {
  const mergedClasses = useStyles({ source: classes, target: styles }, "closeButton");

  const proppedIcon = icon || <Icon iconName="close" classes={classes.icon} style={{ height: "16px" }} />;

  useEffect(() => {
    const escKeyPressed = (event: KeyboardEvent): void => {
      if (event.key === "Escape" && open === true) {
        if (onClick != null) {
          onClick();
        }
      }
    };
    window.addEventListener("keydown", escKeyPressed, false);

    return () => {
      window.removeEventListener("keydown", escKeyPressed, false);
    };
  }, [onClick, open]);

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      title={label ?? "close button"}
      className={`${mergedClasses.root} close`}
      onClick={onClick ?? undefined}
      data-selenium="close-button"
      {...rest}
    >
      {proppedIcon}
    </button>
  );
};

export default CloseButton;
