import { ObservableEventEmitter, types, adapter } from "@video/video-client-core";
import { action, makeObservable, observable } from "mobx";
import { createRef } from "react";
import { exitFullscreen, fullscreenElement, requestFullscreen } from "./fullscreen";

export interface BaseUiStateEvents {
  newWindow: null;

  newWindowElement: types.VideoElement | null;

  portal: boolean;

  portalElement: HTMLDivElement | null;

  viewSettings: boolean;

  viewVideoCallSlider: boolean;

  controlMouseOver: boolean;

  videoMouseOver: boolean;
  /**
   * @description Is emitted when the player video is/is not in fullscreen mode.
   * @example playerUiState.on("isFullscreen", (bool) => { if(bool) { // use fullscreen styles} });
   * encoderUiState.on("isFullscreen", (bool) => { if(bool) { // use fullscreen styles} })
   */
  isFullscreen: boolean;
  // videoPoster: types.VideoElement | null;
  // newWindow: Window | null;
  // newWindowElement: HTMLDivElement | null;
  // portal: boolean;
  // portalElement: HTMLDivElement | null;
  /**
   * @description Is emitted when screenshare component is supported.
   * @example playerUiState.on("screenShareSupported", (bool) => { if(bool) { // render screenshare button} })
   */
  screenShareSupported: boolean;
  /**
   * @description Is emitted when volumeRange component is supported.
   * @example playerUiState.on("volumeRangeSupported", (bool) => { if(bool) { // render volumeRange component} })
   */
  volumeRangeSupported: boolean;
}

export default class UiState<T = unknown> extends ObservableEventEmitter<BaseUiStateEvents & T> {
  constructor() {
    super();

    makeObservable(this, {
      // observers
      videoWrapperElement: observable.ref,
      isFullscreen: observable,
      newWindow: observable,
      newWindowElement: observable.ref,
      portal: observable,
      portalElement: observable.ref,
      viewSettings: observable,
      viewVideoCallSlider: observable,
      controlMouseOver: observable,
      videoMouseOver: observable,

      // actions
      toggleFullscreen: action,
      onFullscreenChange: action,
    });

    document.addEventListener("fullscreenchange", this.onFullscreenChange);
    document.addEventListener("webkitfullscreenchange", this.onFullscreenChange);

    this.addInnerDisposer(() => {
      document.removeEventListener("fullscreenchange", this.onFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", this.onFullscreenChange);
    });
  }

  onFullscreenChange(): void {
    this.isFullscreen = fullscreenElement() != null;
  }

  /**
   * The reference to the HTMLVideoElement.
   */
  videoElement = createRef<HTMLVideoElement>();

  /**
   * Video Container wrapper element.
   *
   */
  videoWrapperElement = createRef<HTMLDivElement>();

  /**
   * Indicates whether application is fullscreen.
   */
  isFullscreen = false;

  /**
   * Indicates whether the application has been opened in a new window.
   */
  newWindow = null;

  /**
   * New window DOM element
   */
  newWindowElement: types.VideoElement | null = null;

  /**
   * Indicates whether the application has launched in a React portal.
   */
  portal = false;

  /**
   * React Portal DOM element
   */
  // portalElement: document.createElement('div');
  portalElement: HTMLDivElement | null = null;

  /**
   * Indicates whether the encoder settings are visible.
   */
  viewSettings = false;

  /**
   * Indicates whether the call settings are visible.
   */
  viewVideoCallSlider = false;

  /**
   * Watches for mouse over on VideoWrapper
   */
  videoMouseOver = adapter.device.isMobileDevice;

  /**
   * Watches for mouse over on ControlBar
   */
  controlMouseOver = adapter.device.isMobileDevice;

  /**
   * Indicates if we are using a mobile device
   */
  readonly mobileDevice = adapter.device.isMobileDevice;

  /**
   * Indicates if we are using a mobile device
   */
  readonly iosDevice = adapter.device.isIosDevice;

  /**
   * Indicates if screenShare is supported
   */
  readonly screenShareSupported = !adapter.device.isMobileDevice;

  /**
   * Indicates if screenShare is supported
   */
  readonly volumeRangeSupported = !adapter.device.isIosDevice;

  /**
   * Toggle `isFullscreen`
   * @async
   * @description Toggles the encoder to/from fullscreen and sets uiState.isFullscreen
   * to true/false.
   * @returns Promise
   * @example <caption>Example usage of toggleFullscreen.</caption>
   * toggleFullscreen();
   * // Sets encoder to/from fullscreen and updates uiState.isFullscreen = true|false;
   */
  async toggleFullscreen(): Promise<void> {
    if (this.videoWrapperElement.current == null) {
      return;
    }

    if (this.isFullscreen) {
      await exitFullscreen();
    } else {
      await requestFullscreen(this.videoWrapperElement.current);
    }
  }
}
