import React, {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  EncoderAudioDeviceSelect as AudioDeviceSelect,
  EncoderVideo,
  EncoderVideoDeviceSelect as VideoDeviceSelect,
} from "..";
import { useStyles } from "../../../styling";
import { mergeStylesObjects } from "../../../styling/utils";
import CloseButton from "../../../ui-lib/Buttons/Close";
import SlideSidebar, { SlidingShelfProps } from "../../../ui-lib/SlidingShelf";
import { RootStyles } from "../../../ui-lib/typings/css";
import SendCallRequestButton from "../Buttons/Encoder/SendCallRequest";
import styles from "./callRequestSidebarStyles";

/**
 * Renders a sidebar containing title, user video, audio and device selection settings, and a request call button.
 */

interface CallRequestSidebarClasses {
  slideSidebar?: RootStyles;
  audioDeviceSelect?: RootStyles;
  callRequestContainer?: RootStyles;
  encoderContainer?: RootStyles;
}

type CallRequestSidebarProps = Omit<SlidingShelfProps, "classes"> & {
  classes?: CallRequestSidebarClasses;
  attachToRef?: Element | null;
  ref?: Ref<HTMLButtonElement>;
  open?: boolean;
  renderTitle?: (props: unknown) => ReactNode;
  renderButton?: (props: unknown) => ReactElement;
  buttonRequestText?: string;
  onRequestButtonClick?: () => MouseEventHandler<HTMLButtonElement> | KeyboardEventHandler<HTMLButtonElement> | void;
  title: string;
};

const CallRequestSidebar: FC<Partial<CallRequestSidebarProps>> = ({
  attachToRef,
  classes = {},
  ref,
  children,
  open = false,
  renderTitle,
  title,
  onRequestButtonClick,
  buttonRequestText,
  onCloseButtonClick,
  renderButton,
  ...props
}) => {
  if (!attachToRef) {
    // eslint-disable-next-line no-console
    console.warn(
      "VideoCallSidebar was not provided the attachToRef prop. This is used to attach to a DOM element and display the sidebar.",
    );
    return null;
  }

  const [localOpen, setLocalOpen] = useState(open);

  useEffect(() => {
    setLocalOpen((previousOpenState) => {
      if (previousOpenState != open) {
        return open;
      }
      return previousOpenState;
    });
  }, [open]);

  const mergedClasses = useStyles({ source: classes, target: styles }, "callRequestSidebar");
  const mergedStyles = mergeStylesObjects(classes, {});

  const closeHandler = () => {
    setLocalOpen(false);
    onCloseButtonClick ? onCloseButtonClick() : null;
  };

  const renderSidebarButton: FC<undefined> | any = () =>
    renderButton ? (
      renderButton(props)
    ) : (
      <div className={mergedClasses.buttonContainer}>
        <SendCallRequestButton
          ref={ref}
          buttonRequestText={buttonRequestText ?? undefined}
          onRequestButtonClick={onRequestButtonClick}
          active={false}
          disabled={false}
          isFirstAttempt
        />
      </div>
    );

  const renderModularSlide = (): ReactElement => (
    <SlideSidebar classes={classes?.slideSidebar} open={localOpen}>
      <div className={mergedClasses.headerContainer}>
        <CloseButton onClick={closeHandler} open={localOpen} classes={mergedStyles.closeButton} />
        {renderTitle ? renderTitle(props) : <span className={mergedClasses.renderTitleSpan}>{title}</span>}
      </div>
      <div className={mergedClasses.callRequestContainer}>
        <div className={mergedClasses.encoderContainer}>
          <EncoderVideo classes={mergedStyles.videoWrapper} />
        </div>
        <div className={mergedClasses.deviceSelectContainer}>
          <p className={mergedClasses.settingsText}>Settings</p>
          <VideoDeviceSelect ariaLabel="Video device select" classes={mergedStyles.videoDeviceSelect} />
          <AudioDeviceSelect ariaLabel="Audio device select" classes={mergedStyles.audioDeviceSelect} />
        </div>
        {renderSidebarButton()}
      </div>
    </SlideSidebar>
  );

  return ReactDOM.createPortal(renderModularSlide(), attachToRef);
};

export default CallRequestSidebar;
