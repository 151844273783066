import type { LoggerCore } from "@video/log-client";
import type { Json } from "@video/log-node";
import type { Fragment, LoaderStats } from "hls.js";

import type Hls from "hls.js";
import { device, Feature } from "../../api/adapter";
import { Feature as AdapterFeature } from "../../api/adapter/features/feature";
import type { HlsJsFeature } from "../../api/adapter/features/hlsjs";
import { DeviceAPI } from "../../api/device";
import { isVideoClientError } from "../../api/error";
import type { Encoding, FormatMp4Hls, ManifestFormats, ManifestJson } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import {
  BitrateSwitchingEvents,
  BitrateSwitchingFeature,
  SourceScoreLevel,
  TranscodeScoreLevel,
} from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { isVideoElement, MediaErrorCodeConstants, VideoElement } from "../../api/typings/video-element";
import { createError } from "../errors";
import {
  DriverNotSupportedError,
  ElementRequiredError,
  ErrorCode,
  HandleHlsJsError,
  ManifestNotFoundError,
  NetworkError,
  PlaybackError,
  PlayingIssueError,
  UnknownError,
} from "../errors-deprecated";
import MediaLoader from "../media-loader";
import { supportsHlsjs } from "../utils/browser-support";
import type { VcContext } from "../utils/context/vc-context";
import { dumpVideoElement } from "../utils/debug/play-logs";
import { stats, STATS_EVENTS, TimingStat } from "../utils/stats";
import { CorePlayer, CorePlayerOptions, timeupdateWrapper } from "./core";
import { dataProperties, ERRORS } from "./helper";

const SKIP_FORWARD_THRESHOLD = 25;
const NOT_LOADED_ERROR = "Hls.js is not loaded";
const noop = (...args: unknown[]): void => undefined;

export interface HlsJsPlayerEvents extends PlayerEvents, BitrateSwitchingEvents {
  /**
   * @description Is emitted on the native video element's "ended" event.
   * @example player.on("ended", () => { // handle ended})
   */
  ended: void;
  /**
   * @description Is emitted when the bitrate is switched.
   * @example player.on("bitrate-switch", () => { // handle bitrate switch})
   */
  "bitrate-switch": void;
  /**
   * @description Is emitted when the HlsJs player initializes.
   * @example player.on("hlsJsInit", (stat) => {console.log(stat)})
   */
  hlsJsInit: TimingStat;
  /**
   * @description Is emitted when the m3u8 manifest loads for the HlsJs player.
   * @example player.on("m3u8ManifestLoad", () => {console.log(stat)})
   */
  m3u8ManifestLoad: TimingStat;
  /**
   * @description Is emitted when the index manifest loads for the HlsJs player.
   * @example player.on("indexManifestLoad", () => {console.log(stat)})
   */
  indexManifestLoad: TimingStat;
  initialFragmentLoad: TimingStat;
}

export type HlsJsPlayerOptions = {
  estimatedKbps?: number;
  maxBufferSize?: number;
  maxBufferLength?: number;
  debug?: boolean;
  hlsjsPath?: string;
  hlsjsConfig?: Record<string, Json>;
  requestHeaders?: Record<string, string>;
} & CorePlayerOptions;

export class HlsJsPlayer
  extends CorePlayer<HlsJsPlayerOptions, ManifestJson, HlsJsPlayerEvents>
  implements BitrateSwitchingFeature
{
  static readonly displayName = "HlsJsPlayer";

  private lastProgress = 0;

  private manifestJson: ManifestJson | null = null;

  private destroyed = false;

  private readonly hlsJsInitId;

  private m3u8ManifestLoadId = 0;

  private indexManifestLoadId = 0;

  private initialFragmentLoadId = 0;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsHlsjs(logger);
  }

  get hostEl(): VideoElement | null {
    // temporary hack to get the host element for HlsJs
    // since it works with the video element directly
    if (this.elementSupervisor == null) {
      return null;
    }
    return Reflect.get(this.elementSupervisor, "element");
  }

  async isSupported(): Promise<boolean> {
    return HlsJsPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "mp4-hls";
  }

  get format(): keyof ManifestFormats {
    return HlsJsPlayer.format;
  }

  async ready(): Promise<void> {
    await this.device.loadHlsScript(this.options.hlsjsPath);
    await super.ready();
  }

  private _firstSourceEv = true;

  protected async handleSource(manifest: ManifestJson | null): Promise<void> {
    if (this.suspended) {
      return;
    }
    const hadSubstitute: boolean = this.manifestJson?.formats["mp4-hls"]?.substitute != null;

    this.manifestJson = manifest;
    const format = manifest?.formats["mp4-hls"];
    if (format == null) {
      this.emitErrorDeprecated(
        new DriverNotSupportedError("Manifest did not receive a 'mp4-hls' format, the driver was not provided.", {
          manifest,
        }),
      );
      return;
    }

    this.source = this.manifest;

    const hasSubstitute: boolean = manifest?.formats["mp4-hls"]?.substitute != null;

    /**
     * Check if manifest changed from blurredStream to standardStream (presence of `substitute` property),
     * and that it's not the player's first source event.
     */
    if (hasSubstitute !== hadSubstitute && !this._firstSourceEv) {
      this.ctx.logger.debug("Changing stream to/from blurredStream. Restart hlsjs.");
      this.restart(true);
      return;
    }

    if (this._firstSourceEv) {
      this._firstSourceEv = false;
    }
    if (
      this.hostEl != null &&
      this.source != null &&
      (!this.hostEl.src ||
        this.hostEl.srcObject != null ||
        this.qualityHasChanged ||
        (device.isImplements(Feature.URL_LOCATION) && this.hostEl.src === device.location))
    ) {
      const currentTime = this.hostEl.currentTime;
      this.qualityHasChanged = false;

      // make sure there are no other sources left
      this.hostEl.srcObject = null;

      this.hostEl.setAttribute("src", this.source);

      // keep current time for recorded videos
      if (this.manifestJson?.type === "recorded" && currentTime > 0) {
        this.hostEl.currentTime = currentTime;
      }

      this.ctx.logger.debug("hlsjs: await play()", { aggregates: dumpVideoElement(this.hostEl) });
      await this.play();
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }

  // -----

  private lastFrag: (Fragment & LoaderStats) | null = null;

  private readonly loadingHls = false;

  private loadFailure: Error | null = null;

  private recoverDecodingErrorDate = 0;

  private recoverSwapAudioCodecDate = 0;

  private hls: Hls | null = null;

  private isAttached = false;

  private _device: (DeviceAPI & HlsJsFeature) | null = null;

  private get device(): DeviceAPI & HlsJsFeature {
    if (this._device != null) {
      return this._device;
    }

    if (!device.isImplements(AdapterFeature.HLSJS)) {
      throw new Error("Device is not supported");
    }

    this._device = device;
    return this._device;
  }

  constructor(ctx: VcContext, provider: MediaLoader, options: CorePlayerOptions) {
    super(ctx, provider, options);

    this.hlsJsInitId = stats.start(STATS_EVENTS.HLSJS_INIT);

    if (!device.isImplements(AdapterFeature.HLSJS)) {
      throw new Error("Device is not supported");
    }

    this.loadHls();

    this.addInnerDisposer(this.destroy);

    // avoids unhandled error from events lib
    /* eslint-disable @typescript-eslint/no-empty-function */
    this.on("error", () => {});
  }

  get data(): FormatMp4Hls | null {
    return this.manifestJson?.formats["mp4-hls"] ?? null;
  }

  get mediaLoader(): MediaLoader {
    return this.provider as MediaLoader;
  }

  /** returns the manifest string
   * @return {string}
   */
  get manifest(): string | null {
    const format = this.data;
    if (format == null) {
      return null;
    }

    let uri;
    if (format.substitute) {
      uri = format.substitute.location;
    } else if (
      (this.currentQuality?.level === SourceScoreLevel.High ||
        this.currentQuality?.level === SourceScoreLevel.Medium ||
        this.currentQuality?.level === SourceScoreLevel.Low) &&
      format.origin?.location != null
    ) {
      uri = format.origin?.location;
    } else if (this.currentQuality?.layer?.id != null && this.currentQuality.layer.id !== "") {
      uri = this.currentQuality.layer.id.toString();
    } else {
      uri = format.manifest;
    }

    if (uri == null) {
      this.ctx.logger.info("Returning null in manifest getter. Data: ", { format });
      return null;
    }

    const separator = !uri.includes("?") ? "?" : "&";

    uri += separator;

    const lastAutoKbps = this.device.globals.get("lv_auto_last_kbps");
    if (lastAutoKbps != null) {
      const nextSeparator = !uri.includes("?") ? "?" : "&";
      uri += `${nextSeparator}kbps=${lastAutoKbps}`;
    }

    return uri;
  }

  /**
   * @throws {ErrorCode.ManifestError} if manifest cannot be loaded
   */
  async reloadAndRestart(): Promise<void> {
    if (this.isDisposed) {
      return;
    }
    await this.mediaLoader.load();
    if (this.mediaLoader.currentState === "online") {
      await this.restart(false);
    }
  }

  loadHls(inputCb?: (error?: Error) => void): void {
    const cb = inputCb ?? noop;
    this.device
      .loadHlsScript(this.options.hlsjsPath)
      .then(() => cb())
      .catch((err) => {
        this.ctx.logger.error("hlsjs not loaded", { path: this.options.hlsjsPath, err });
        cb(err);
      });
  }

  async playNow(): Promise<boolean> {
    const m = this.manifest;

    if (!this.device.isHlsLoaded()) {
      throw new Error(ERRORS.HLSJS_NOT_LOADED);
    }

    if (m == null && this.mediaLoader.currentState === "online") {
      this.emitErrorDeprecated(
        new DriverNotSupportedError("manifest doesn't contains 'flv-http' format", {
          manifest: m,
          loader: this.mediaLoader,
        }),
      );
      return false;
    }

    if (this.hostEl == null) {
      this.emitErrorDeprecated(new ElementRequiredError("cannot play, no video element", {}));
      return false;
    }

    if (this.hls != null) {
      // bug: probably a bug
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.mediaLoader.vod && m === (this.hls as any).url) {
        try {
          await super.play();
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitErrorDeprecated(
            new PlayingIssueError("vod play failed in playNow()", {
              inner,
              player: this,
            }),
          );
        }
        return true;
      }

      this.destroyHls();
    }

    if (m == null) {
      this.emitErrorDeprecated(new ManifestNotFoundError("cannot play, manifest is null", {}));
      return false;
    }

    this.hostEl.setAttribute("src", m);
    const startIt = (): void => {
      if (this.isDisposed) {
        return;
      }

      if (!this.device.isHlsLoaded()) {
        throw new Error(NOT_LOADED_ERROR);
      }

      this.device.removeEventListener("visibilitychange", startIt);
      const hlsjsConfig: Record<string, unknown> = {
        // abrController: AbrController,
        debug: this.options.debug,
        maxBufferLength: this.options.maxBufferLength,
        maxBufferSize: this.options.maxBufferSize,
        fragLoadingTimeOut: 3900,
        maxBufferHole: 2,
        backBufferLength: 90,
        progressive: false,
        autoLevelEnabled: true,
      };

      const requestHeaders = this.options.requestHeaders ?? null;

      // eslint-disable-next-line no-multi-assign
      const hls = (this.hls = new this.device.Hls({
        ...hlsjsConfig,
        ...this.options.hlsjsConfig,
        xhrSetup(xhr, url) {
          if (requestHeaders != null) {
            Object.entries(requestHeaders).forEach(([key, value]) => {
              xhr.setRequestHeader(key, value);
            });
          }
        },
      }));

      this.listen();

      // logger.setPlayer((this.mediaLoader as any).host, (this.mediaLoader as any).publicId, this.format, m, this);

      hls.loadSource(m);
      hls.attachMedia(this.hostEl);

      if (this.hostEl != null && !this.localVideoPaused) {
        try {
          super.play(true);
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitErrorDeprecated(
            new PlayingIssueError("play failed in startIt() method", {
              inner,
              player: this,
            }),
          );
        }
      }
      if (!this.mediaLoader.vod) {
        hls.on(this.device.Hls.Events.BUFFER_APPENDED, () => {
          if (this.hostEl == null || this.hostEl.buffered.length === 0 || this.hostEl.paused) {
            return;
          }
          if (
            this.hostEl.buffered.end(this.hostEl.buffered.length - 1) - this.hostEl.currentTime >
            SKIP_FORWARD_THRESHOLD
          ) {
            this.ctx.logger.warn(`player fell behind more than ${SKIP_FORWARD_THRESHOLD}s, restarting`);
            this.reloadAndRestart();
          }
        });
      }

      hls.on(this.device.Hls.Events.MEDIA_ATTACHED, () => {
        this.isAttached = true;
      });

      hls.on(this.device.Hls.Events.MEDIA_DETACHED, () => {
        this.isAttached = false;
      });

      hls.on(this.device.Hls.Events.MANIFEST_PARSED, () => {
        this.counters.lastProgress = Date.now();
      });
    };

    if (this.device.hidden) {
      this.device.addEventListener("visibilitychange", startIt);
    } else {
      startIt();
    }

    return !this.hostEl.paused;
  }

  /**
   * Attaches the hls video player and plays the video
   * @param {function} [cb] called on play
   * @return {void}
   */
  async play(): Promise<boolean> {
    this.ctx.logger.debug("play()");

    if (this.hostEl == null) {
      return false;
    }

    if (this.hls != null && !this.localVideoPaused) {
      try {
        await this.playNow();
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitErrorDeprecated(
          new PlayingIssueError("playNow() failed", {
            inner,
            player: this,
          }),
        );
      }
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.hostEl as any).onprogress = null;

    await this.ready();
    try {
      await this.playNow();
    } catch (err) {
      const inner = err instanceof Error ? err : null;
      this.emitErrorDeprecated(
        new PlayingIssueError("play failed", {
          inner,
          player: this,
        }),
      );
    }
    return true;
  }

  get autoLevelEnabled(): boolean {
    return this.hls?.autoLevelEnabled ?? false;
  }

  /**
   * @param {number} bitrate
   * @return {void}
   */
  set bitrate(bitrate: number) {
    if (this.hostEl == null) {
      return;
    }

    if (
      (device.isImplements(AdapterFeature.BROWSER_TYPE) && device.browserInfo.name === "firefox") ||
      this.mediaLoader.vod
    ) {
      const ct = this.hostEl.currentTime;
      this.stop();

      if (this.mediaLoader.vod) {
        const { hostEl } = this;
        const onLoadMetadata = (): void => {
          this.hostEl?.removeEventListener("loadedmetadata", onLoadMetadata);
          hostEl.currentTime = ct;
        };
        this.hostEl.addEventListener("loadedmetadata", onLoadMetadata);
      }

      this.play().catch((err) => {
        const inner = err instanceof Error ? err : null;
        this.emitErrorDeprecated(
          new PlayingIssueError("play failed after changing bitrate", {
            inner,
            player: this,
          }),
        );
      });
    }
  }

  /**
   * stops playback
   * @return {void}
   */
  stop(): void {
    if (this.hls == null) {
      return;
    }

    this.destroyHls();
  }

  /**
   * stops playback
   * @return {void}
   */
  pause(): void {
    this.hostEl?.pause();
  }

  /**
   * Destroys driver
   * @return {void}
   */
  destroy(): void {
    this.destroyed = true;
    // this.driverReady = false;
    this.destroyHls();
  }

  /**
   * Destroys HLS Driver
   */
  destroyHls(): void {
    const { hls } = this;
    if (hls == null) {
      return;
    }

    if (this.hostEl != null) {
      this.hostEl.removeEventListener("error", this._handleElError);
    }

    hls?.detachMedia();
    hls?.destroy();
    this.hls = null;

    this.loadFailure = null;
  }

  async recoverFromBufferSeekOverHole(): Promise<void> {
    if (this.data == null) {
      return;
    }

    this.stop();
    try {
      await this.play();
    } catch (err) {
      const inner = err instanceof Error ? err : null;
      this.emitErrorDeprecated(
        new PlayingIssueError("play failed after recoverFromBufferSeekOverHole()", {
          inner,
          player: this,
        }),
      );
    }
  }

  chosenLevel: number | null = null;

  /**
   * @param {number} [bitrate] bitrate, defaults to options.bitrate || options.estimatedKbps
   * @return {object} encoding object
   */
  pickEncoding(bitrate?: number): Encoding | null {
    if (this.data == null) {
      return null;
    }

    if (bitrate != null) {
      this.options.bitrate = bitrate;
    }

    if (this.options.bitrate == null) {
      return null;
    }

    const { encodings } = this.data;
    if (encodings.length === 0 || this.chosenLevel == null) {
      return null;
    }

    return encodings[this.chosenLevel];
  }

  ifNoProgress(cb: () => void): void {
    const el = this.hostEl;
    if (el == null) {
      return;
    }

    const ct = el.currentTime;
    device.setTimeout(() => {
      if (el.currentTime <= ct) {
        cb();
      }
    }, 250);
  }

  emitProgress(): void {
    if (device.isImplements(AdapterFeature.DEBUGGING)) {
      this.lastProgress = device.performance.now();
    } else {
      this.lastProgress = Date.now();
    }
    this.emit("progress");
  }

  /**
   * Listen for important events and record or signal
   */
  listen(): void {
    let ct = 0;
    const { hls } = this;
    const el = this.hostEl;
    let curLevel: TranscodeScoreLevel | SourceScoreLevel;

    if (hls == null) {
      throw new Error(ERRORS.HLSJS_NOT_LOADED);
    }
    if (el == null) {
      throw new Error(ERRORS.ELEMENT_REQUIRED);
    }

    hls.on(this.device.Hls.Events.ERROR, (type: unknown, data: any) => {
      try {
        switch (data.details) {
          case this.device.Hls.ErrorDetails.LEVEL_LOAD_ERROR:
          case this.device.Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
            if (this.mediaLoader.vod) {
              const getData = dataProperties(data);
              this.emitErrorDeprecated(
                new PlaybackError("hlsjs playback error", { ...getData, loader: this.mediaLoader }),
              );
              return;
            }

            if (this.mediaLoader.currentState === "offline") {
              this.stop();
              return;
            }
            this.ctx.logger.warn("level load reload and restart", { type: String(type) });
            this.reloadAndRestart().catch((err) => {
              const msg =
                isVideoClientError(err) && err.code === ErrorCode.ManifestNotLoaded
                  ? "hlsjs restart failed because manifest has not loaded"
                  : "hlsjs restart failed";
              this.emitError(
                createError(
                  ErrorCode.PlayerLoadingFailed,
                  msg,
                  { player: HlsJsPlayer.displayName, format: this.format },
                  err,
                ),
              );
            });

            ct = el.currentTime;
            device.setTimeout(() => {
              if (el.currentTime <= ct) {
                this.ctx.logger.warn("level load reload and restart", { type: String(type) });
                this.reloadAndRestart().catch((err) => {
                  const msg =
                    isVideoClientError(err) && err.code === ErrorCode.ManifestNotLoaded
                      ? "hlsjs restart failed because manifest has not loaded"
                      : "hlsjs restart failed";
                  this.emitError(
                    createError(
                      ErrorCode.PlayerLoadingFailed,
                      msg,
                      { player: HlsJsPlayer.displayName, format: this.format },
                      err,
                    ),
                  );
                });
              }
            }, 100);
            return;
          case this.device.Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE:
            this.ifNoProgress(() => {
              this.recoverFromBufferSeekOverHole();
            });
            return;
          case this.device.Hls.ErrorDetails.BUFFER_APPEND_ERROR:
          case this.device.Hls.ErrorDetails.BUFFER_FULL_ERROR:
            this.counters.bufferOverflowCount++;
            break;
          case this.device.Hls.ErrorDetails.FRAG_LOAD_ERROR:
          case this.device.Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
          case this.device.Hls.ErrorDetails.FRAG_PARSING_ERROR:
          case this.device.Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
          case this.device.Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            this.counters.bufferUnderflowCount++;
            break;
          case this.device.Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
            this.ctx.logger.error("unable to load m3u8 manifest", { level: curLevel, url: data.url });

            // it'll restart the player
            this.setPreferredLevel(TranscodeScoreLevel.Highest);
            return;
          default:
            break;
        }

        switch (data.type) {
          case this.device.Hls.ErrorTypes.NETWORK_ERROR:
            // Everything but frag timeout errors, manifest errors, etc
            // the player is busted and needs to retry
            // either rely on the retry protocol of the hls driver,
            // or just hard restart it
            // for live it seems the same either way
            if (data.details !== this.device.Hls.ErrorDetails.FRAG_LOAD_TIMEOUT) {
              this.counters.currentErrorCount++;
            } else {
              this.counters.currentErrorCount = this.options.recoverErrorCount ?? 0;
            }

            this.emitErrorDeprecated(new NetworkError("hlsjs playback error", { data: dataProperties(data) }));
            break;
          case this.device.Hls.ErrorTypes.MEDIA_ERROR:
            if (data.fatal) {
              this.handleMediaError();
              return;
            }
            // don't add to current error count for buffer stalls
            if (data.details === this.device.Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
              this.emitErrorDeprecated(
                new PlaybackError("hlsjs media error", { data: dataProperties(data), loader: this.mediaLoader }),
              );
              return;
            }
            if (data.details === this.device.Hls.ErrorDetails.FRAG_LOAD_ERROR) {
              // Its caused when the client sees the same segment multiple times
              // when the manifest file is being loaded several times,
              // each seem to spawn segment loads
              // many segment loads then compete and cause these errors
              // its probably just an indicator of a screwed state
              // Tracking it and reloading after a recovery count
              this.counters.currentErrorCount++;
            }
            this.emitErrorDeprecated(
              new PlaybackError("hlsjs media error", { data: dataProperties(data), loader: this.mediaLoader }),
            );
            break;
          case this.device.Hls.ErrorTypes.OTHER_ERROR:
            this.counters.currentErrorCount++;
            this.ctx.logger.warn("hlsjs other error", dataProperties(data));
            break;
          default:
            this.ctx.logger.error("unhandled hlsjs error", dataProperties(data));
            return;
        }

        this.checkRestart(data as { fatal: boolean });
        const getData = dataProperties(data);
        this.ctx.logger.warn(`${type}`, getData);
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitErrorDeprecated(new HandleHlsJsError("error handling error from hlsjs library", { inner }));
      }
    });

    el.addEventListener("progress", () => {
      this.emitProgress();
    });

    el.addEventListener("ended", () => {
      this.emit("ended");
    });

    const statInfo = {
      driver: this.format,
      abr: this.manifestJson?.abr,
      aor: this.manifestJson?.aor,
      atr: this.manifestJson?.atr,
      rep: this.manifestJson?.rep,
    };

    timeupdateWrapper.wrap(el, () => {
      this.emit("timeupdate");
      if (this.initialFragmentLoadId !== -1) {
        const isVideoPlaying = el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2;
        if (isVideoPlaying) {
          this.emit("initialFragmentLoad", stats.stop(this.initialFragmentLoadId, statInfo));
          this.initialFragmentLoadId = -1;
        }
      }
    });

    el.addEventListener("error", this._handleElError);

    hls.on(this.device.Hls.Events.MANIFEST_LOADING, (ev: unknown, data: { url: string }) => {
      this.emit("hlsJsInit", stats.stop(this.hlsJsInitId, statInfo));
      this.m3u8ManifestLoadId = stats.start(STATS_EVENTS.M3U8_MANIFEST_LOAD);
    });

    hls.on(this.device.Hls.Events.MANIFEST_LOADED, (ev: unknown, data: { url: string; stats: LoaderStats }) => {
      this.emit("m3u8ManifestLoad", stats.stop(this.m3u8ManifestLoadId, statInfo));
      this.indexManifestLoadId = stats.start(STATS_EVENTS.INDEX_MANIFEST_LOAD);
    });

    hls.once(this.device.Hls.Events.LEVEL_UPDATED, (ev: unknown, data: { level: number }) => {
      this.emit("indexManifestLoad", stats.stop(this.indexManifestLoadId, statInfo));
    });

    hls.once(this.device.Hls.Events.FRAG_LOADING, (ev: unknown) => {
      this.initialFragmentLoadId = stats.start(STATS_EVENTS.INITIAL_FRAGMENT_LOAD);
    });

    hls.on(this.device.Hls.Events.FRAG_LOADED, (ev: unknown, data: any) => {
      this.loadedFrag(data.frag);
    });
  }

  _handleElError(e: Event): void {
    if (!isVideoElement(e.target) || e.target.error == null) {
      return;
    }
    switch (e.target.error.code) {
      case MediaErrorCodeConstants.MEDIA_ERR_DECODE:
        this.handleMediaError();
        break;
      case MediaErrorCodeConstants.MEDIA_ERR_SRC_NOT_SUPPORTED:
        this.emitErrorDeprecated(
          new DriverNotSupportedError("hlsjs src not supported", {
            manifest: this.manifest,
            loader: this.mediaLoader,
          }),
        );
        break;
      default:
        break;
    }

    if (this.autoPlay) {
      this.hostEl?.play();
    }
  }

  handleMediaError(): void {
    const now = this.device.isImplements(AdapterFeature.DEBUGGING) ? this.device.performance.now() : Date.now();
    const { hls } = this;
    if (hls == null) {
      if (this.destroyed || this.isDisposed) {
        return;
      }
      throw new Error(ERRORS.HLSJS_NOT_LOADED);
    }

    if (this.recoverDecodingErrorDate != null || now - this.recoverDecodingErrorDate > 3000) {
      this.recoverDecodingErrorDate = now;
      hls.recoverMediaError();
    } else if (this.recoverSwapAudioCodecDate != null || now - this.recoverSwapAudioCodecDate > 3000) {
      this.recoverSwapAudioCodecDate = now;
      hls.swapAudioCodec();
      hls.recoverMediaError();
    }

    this.ifNoProgress(() => {
      this.recoverFromBufferSeekOverHole();
    });
  }

  get currentLevel(): number | null {
    const { hls } = this;
    if (hls == null) {
      return null;
    }
    if ((hls as any).abrController.fragCurrent == null) {
      return null;
    }

    return (hls as any).abrController.fragCurrent.level;
  }

  get nextLevel(): number {
    const { hls } = this;
    if (hls == null) {
      throw new Error(ERRORS.HLSJS_NOT_LOADED);
    }

    return (hls as any).abrController.nextAutoLevel;
  }

  bitrateSwitch(level: number): void {
    if (this.hls == null) {
      return;
    }

    if (level > (this.currentLevel ?? 0)) {
      this.counters.upshift++;
    } else {
      this.counters.downshift++;
    }

    this.emit("bitrate-switch");
  }

  loadedFrag(inputFrag: Fragment & LoaderStats & { request: number }): void {
    const frag = inputFrag;
    this.counters.fragCounts++;
    // bug: suspicious line. Fragment request doesn't exists and isn't using anywhere else
    frag.request =
      this.lastFrag != null ? (frag as any).trequest - ((this.lastFrag as any)?.trequest ?? 0) : (frag as any).trequest;
    this.lastFrag = frag;
    this.counters.fragSize += frag.loaded;
    this.counters.fragDuration += frag.duration;
    this.counters.fragDownloadTime += frag.request;
    this.counters.fragMaxTime = Math.max(this.counters.fragMaxTime, frag.request);
    this.counters.fragMinTime = Math.min(this.counters.fragMinTime ?? frag.request, frag.request);
  }
}
