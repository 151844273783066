import React, { forwardRef, ReactElement } from "react";
import { useStyles } from "../../styling/videoStyles";
import { RootStyles } from "../typings/css";
// Styles
import styles from "./styles";

export interface MediaWrapperProps {
  children?: ReactElement[] | ReactElement | (boolean | ReactElement)[];
  classes?: RootStyles;
  isFullscreen?: boolean;
}

const MediaWrapper = forwardRef<HTMLDivElement, MediaWrapperProps>(({ classes, ...props }, ref) => {
  const { children, isFullscreen } = props;
  const mergedClasses = useStyles({ source: classes ?? {}, target: styles }, "mediaWrapper");
  return (
    <div
      className={`
        ${mergedClasses.root ? mergedClasses.root : ""}
        ${isFullscreen ? "video-fullscreen-mode lv-fullscreen-mode" : ""}
      `}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default MediaWrapper;
