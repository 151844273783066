import EventEmitter from "events";
import merge from "deepmerge";
import { LoggerCore } from "@video/log-client";
import type { Json } from "@video/log-node";
import packageJson from "../../../package-json";
import { device } from "../../../api/adapter";
import type { AuthorizationOptions, AuthAPI } from "../../../api/auth";
import { contextId, instanceId } from "../../../utils/common";

export const defaultOptions = {
  timeout: 30000,
  headers: {
    Accept: "application/json",
  },
};

interface FetchResponse {
  token: string;
  expire: string;
}

function isFetchResponse(
  resp: Record<string, string> | Promise<Record<string, string>> | FetchResponse,
): resp is FetchResponse {
  return (resp as FetchResponse).token !== undefined || (resp as FetchResponse).expire !== undefined;
}

/**
 * @constructs Authorization
 * @param {Config} [options] - Configurable options for the Authorization class
 * @param {string} [options.timeout=30000] - Authenticated request timeout in milliseconds. Timed out errors will return an Error('authorization: timeout')
 * @param {Object} [options.headers] - Additional headers to use for the request
 * @param {Object} [options.endpoint] - Configuration to retrieve tokens from a company's authorization endpoint
 * @param {string} options.endpoint.scope - Scope of the authorization
 * @param {string} options.endpoint.uri - Url of the company's authorization endpoint. The expected response must contain {'token': 'unique token', 'expire': 'RFC3339 expiration time'}
 * @param {string} [options.bootstrap] - Optional token to use immediately instead of requesting from the auth url
 * @param {string} [options.bootstrap.token] - The bootstrap token itself
 * @param {function} [options.bootstrap.refreshToken] - function returning a new token or a Promise of a new token
 * @param {Date} [options.bootstrap.expire] - Expiration time of the boostrap token
 * @throws Will throw an error on invalid options
 */

export type EndpointOptions = {
  uri: string;
  scope: string;
};

export type BootstrapOptions = {
  token?: string;
  refreshToken?: () => string | Promise<string>;
  expire?: Date;
};

/**
 * @class Video authorization client
 */
class Authorization extends EventEmitter implements AuthAPI {
  static readonly displayName = "Authorization";

  options;

  logger: LoggerCore;

  _stopped = false;

  _fatalError: Error | null = null;

  _token: string | null = null;

  _retry = 0;

  _retries: Array<number> = [
    0, // immediate
    200, // 200ms
    1000, // 1s
    3000, // 3s
  ];

  _headers: any;

  _autorefresh: null | number = null;

  _url: string | null = null;

  constructor(options: AuthorizationOptions) {
    super();

    const logger: LoggerCore = options?.logger ?? new LoggerCore("VDC-core");
    delete options?.logger;

    const client = options?.loggerConfig?.clientName ?? "VDC";
    const prevClient = logger.getLoggerMeta("client");
    const prevRelease = logger.getLoggerMeta("release");
    const prevPackage = logger.getLoggerMeta("package");

    // const logContext = {
    //   timeout: this.options.timeout,
    //   headers: this.options.headers,
    //   endpointUri: this.options.endpoint.uri,
    //   bootstrapToken: this.options.bootstrap.token,
    //   bootstrapRefresh: this.options?.bootstrap?.refreshToken,
    // };

    // this.logger = new Logger({
    //   registerPackage: {
    //     name: packageName,
    //     id: uuid().slice(0, 8),
    //     context: logContext,
    //   },
    // });

    logger
      .setLoggerMeta(
        "package",
        prevPackage != null && prevPackage !== "VDC-core" ? `${prevPackage}/VDC-core` : "VDC-core",
      )
      .setLoggerMeta("client", prevClient != null ? `${prevClient}/${client}` : client)
      .setLoggerMeta("release", prevRelease != null ? `${prevRelease}/${packageJson.version}` : packageJson.version)
      .setLoggerMeta("commitHash", packageJson.commit)
      .setLoggerMeta("contextId", contextId() ?? "")
      .setLoggerMeta("instanceId", instanceId() ?? "")
      .appendChain(Authorization);

    // Do we need this??
    // const support = new Support(new LoggerCore("VDC-core").extend(logger).appendChain(Support));

    this.logger = logger;

    this.options = merge(defaultOptions, options);

    if (options.endpoint == null && options?.bootstrap == null) {
      this.validationError("endpoint or bootstrap must be configured for auth");
      return;
    }

    if (this.options.bootstrap?.token != null) {
      this._token = this.options.bootstrap.token;
      this.logger.setMessageAggregate("token", this._token ?? "undefined");
      this.logger.info("auth token set from bootstrap value");
    }

    this._headers = this.options.headers;

    if (this._token == null) {
      this._retryRequest();
    } else if (this.options.bootstrap?.expire != null) {
      const retryAfter = this.options.bootstrap.expire.getTime() - Date.now() - 60 * 1000;
      this._autorefresh = device.setTimeout(() => {
        this._autorefresh = null;
        if (!this._stopped) {
          this._retryRequest();
        }
      }, retryAfter);
    }
  }

  validationError(msg: string): void {
    const e = new Error(msg);
    this._fatalError = e;
    this._stopped = true;
    this.logger.error(msg, {
      errName: e?.name,
      errStack: e?.stack,
    });
  }

  destroy(): void {
    this._stopped = true;
  }

  /**
   * This callback will execute a request which requires the auth token. The token will be available as the second argument if no error was returned.
   * @callback cbRequest
   * @param {Error} e - Returns a timeout error (Error('authorization: timeout')) or a fatal error
   * @param {string} token - The authorization bearer token to use in Video API requests
   */

  /**
   * Execute a request which requires an authorization token when the token is available
   * @param {cbRequest} cb - The callback that executes the request.
   */
  request(cb: (err?: Error | null, token?: string | null) => void): void {
    if (this._fatalError) {
      cb(this._fatalError);
      return;
    }

    if (this._token != null) {
      this.logger.debug("existing token returned on token request");
      cb(null, this._token);
      return;
    }

    const tokenPass = (): void => {
      this.removeListener("fatal", tokenFatal);
      device.clearTimeout(requestTimeout);
      this.logger.debug("new token returned on token request");
      cb(null, this._token);
    };

    const tokenFatal = (): void => {
      this.removeListener("token", tokenPass);
      device.clearTimeout(requestTimeout);
      this.logger.debug("new token returned on token request");
      cb(this._fatalError);
    };

    const requestTimeout = device.setTimeout(() => {
      this.removeListener("token", tokenPass);
      this.removeListener("fatal", tokenFatal);
      this.logger.info("token request timeout");
      cb(new Error("authorization: timeout"));
    }, this.options.timeout);

    this.once("token", tokenPass);
    this.once("fatal", tokenFatal);
  }

  /**
   * Should be called if a Video endpoint returned an 401 error. A new request will be issued to the authorization server's endpoint to get a new token.
   */
  refreshToken(token?: string): boolean {
    this.logger.debug("refreshToken called", {
      token,
    });

    if (token == null && this._token == null) {
      this.logger.debug("no token to refresh");
      return false;
    }

    if (token != null) {
      this._token = token;
      this.logger.setMessageAggregate("token", this._token ?? "undefined");
      this.logger.info("token manually set from refreshToken");
      this._fatalError = null;
      if (this._autorefresh != null) {
        device.clearTimeout(this._autorefresh);
        this._autorefresh = null;
      }
      return true;
    }

    if (this._fatalError) {
      return false;
    }

    if (this._token != null) {
      if (this._autorefresh != null) {
        device.clearTimeout(this._autorefresh);
        this._autorefresh = null;
      }
      this._token = null;
      this.logger.removeMessageAggregate("token");
      (() => {
        this._retryRequest();
      })();
    }
    return true;
  }

  /**
   * Check if token has been recieved by Auth
   */
  ensureToken(cb: () => void): void {
    if (this._token == null) {
      this.once("token", () => {
        cb();
      });
      return;
    }

    cb();
  }

  /**
   * Custom parse json
   * "node-fetch" version different than client fetch
   */
  private _parseJSON(response: Response): Record<string, string> | Promise<Record<string, string>> {
    return response.text().then((text) => {
      if (text) {
        try {
          return JSON.parse(text);
        } catch (e) {
          // @todo  what do we want to do here?
          // e.fatal = true;
          // throw e;
        }
      }

      return {};
    });
  }

  /**
   * Make a request or retry after the current retry number's delay
   * @ignore
   */
  _retryRequest(): void {
    const retry = this._retries[this._retry];
    if (retry) {
      setTimeout(() => {
        this._makeRequest();
      }, retry);
      return;
    }
    this._makeRequest();
  }

  /**
   * Not sure what this is supposed to do.
   * */
  toJSON(): Json {
    return {};
  }

  _tokenSuccess(token: string): void {
    this._token = token;
    this.logger.setMessageAggregate("token", this._token);
    this._retry = 0;
    setTimeout(() => {
      this.emit("token", {
        token,
      });
    });
  }

  /**
   * Make a request to the authorization server.
   * @ignore
   */
  async _makeRequest(): Promise<void> {
    if (this.options?.bootstrap?.refreshToken != null) {
      try {
        const token = await this.options.bootstrap.refreshToken();
        this._tokenSuccess(token);
        this.logger.info("token set from async bootstrap refreshToken");
        return;
      } catch (err) {
        if (err instanceof Error) {
          this._fatalError = err;
          this.logger.warn("unable to refresh token from bootstrap function", {
            errName: err?.name,
            errStack: err?.stack,
          });
          this.emit("fatal");
        }
        return;
      }
    }

    if (this.options?.endpoint == null) {
      throw new Error("authorization: no ability to refresh token (boostrap or endpoint)");
    }

    if (this.options.endpoint.uri.indexOf("?") === -1) {
      this._url = `${this.options.endpoint.uri}?scope=${this.options.endpoint.scope}`;
    } else {
      this._url = `${this.options.endpoint.uri}&scope=${this.options.endpoint.scope}`;
    }

    this._handleFetch();
  }

  async _handleFetch(): Promise<void> {
    if (this._url == null) {
      return;
    }

    try {
      const response = await device.fetch(this._url, {
        credentials: "same-origin",
        headers: this._headers,
      });

      this._handleFetchResponse(response);
    } catch (err) {
      this._token = null;
      this.logger.removeMessageAggregate("token");

      if (err instanceof TypeError) {
        this._fatalError = err;
        this.logger.error(err.message, {
          errName: err?.name,
          errStack: err?.stack,
        });
        this.emit("fatal");
        return;
      }

      this.logger.network("unable to refresh token from endpoint", {
        // err,
        // Parse error correctly
      });

      this._retryRequest();
      if (this._retry < this._retries.length - 1) {
        this._retry++;
      }
    }
  }

  _handleFetchResponse(response: Response): void {
    if (response.status >= 200 && response.status < 300) {
      const json = this._parseJSON(response);
      if (isFetchResponse(json)) {
        if (json?.token != null) {
          this._tokenSuccess(json.token);
          this.logger.info("token set from request response");
        }

        if (json?.expire != null) {
          // re-request a minute before expiration
          const retryAfter = new Date(json.expire).getTime() - Date.now() - 60 * 1000;
          this._autorefresh = device.setTimeout(() => {
            this._autorefresh = null;
            if (!this._stopped) {
              this.logger.info("refreshing token before expiration");
              this._retryRequest();
            }
          }, retryAfter);
        }
      }

      return;
    }

    if (response.status === 401) {
      const e = new Error("authorization: unauthorized");
      // e.fatal = true;
      throw e;
    }
    if (response.status === 403) {
      const e = new Error("authorization: forbidden");
      // e.fatal = true;
      throw e;
    }
    throw new Error("authorization: non-200 response code from auth server");
  }
}
export default Authorization;
