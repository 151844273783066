import { Properties } from "csstype";
import { RootStyles } from "../typings/css";

interface DurationLabelStyles extends RootStyles {
  "svg:not(:root)"?: Properties;
}

const styles: DurationLabelStyles = {
  // SVGs and icon resets
  "svg:not(:root)": {
    overflow: "hidden",
  },

  root: {
    borderRadius: "10rem",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    left: "50%",
    top: "3rem",
    backgroundColor: "rgba(0, 0, 0, 0.66)",
    padding: ".375rem .625rem",
    transform: "translate(-50%, -50%)",
    zIndex: 130,

    "&.video-icon": {
      display: "block",
      width: "14px",
      height: "14px",
      marginRight: "0.5rem",
    },

    "&.video-time": {
      lineHeight: 1.1,
      fontSize: ".75rem",
    },

    "&.lv-icon": {
      display: "block",
      width: "14px",
      height: "14px",
      marginRight: "0.5rem",
    },

    "&.lv-time": {
      lineHeight: 1.1,
      fontSize: ".75rem",
    },
  },
};

export default styles;
