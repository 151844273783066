import type { BitrateSwitchingFeature } from "./bitrate-switching";
import type { BroadcastingFeature } from "./broadcast";
import type { ConsumerFeature } from "./consumer";
import type { DurationFeature } from "./duration";
import type { MutedAutoplayFeature } from "./muted-autoplay";
import type { PlayerSelectorFeature } from "./player-selector";

export enum Feature {
  DURATION,
  CONSUMER,
  MUTED_AUTOPLAY,
  BITRATE_SWITCHING,
  PLAYER_SELECTOR,
  BROADCAST,
}

export interface Features {
  [Feature.DURATION]: DurationFeature;
  [Feature.CONSUMER]: ConsumerFeature;
  [Feature.MUTED_AUTOPLAY]: MutedAutoplayFeature;
  [Feature.BITRATE_SWITCHING]: BitrateSwitchingFeature;
  [Feature.PLAYER_SELECTOR]: PlayerSelectorFeature;
  [Feature.BROADCAST]: BroadcastingFeature;
}
