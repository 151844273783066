import { mediaController, types } from "@video/video-client-core";
import { action, computed, makeObservable, observable } from "mobx";
// @todo: replace all console.log calls with logger
import { LoggerCore } from "@video/log-client";
import { onlineWatcher } from "../utils/online-watcher";
import BaseUiState from "../utils/ui-state";

export type EncoderUiOptions = {
  videoDevice?: string | null;
  audioDevice?: string | null;
  videoElement?: types.VideoElement;
  logger?: LoggerCore;
};

export type EncoderUiStateEvents = {
  // MobX Events
  /**
   * @arg MediaStreamController | null
   * @description Is emitted when the mediaStreamController has been set.
   * @example encoderUiState.on("mediaStreamController", (ctrl) => { if(ctrl) { // do something with mediaStreamController})
   */
  mediaStreamController: EncoderUiState["mediaStreamController"];
  /**
   * @arg string | null
   * @description Is emitted when the previous videoDeviceId changes (e.g. when a user changes their video devices, this will keep track of the previous state).
   * @example encoderUiState.on("prevVideoDeviceId", (val) => { if(val === "screencapture") { // handle exiting screencapture mode} })
   */
  prevVideoDeviceId: EncoderUiState["prevVideoDeviceId"];
  /**
   * @arg boolean
   * @description Is emitted when the user tests/stops testing their microphone.
   * @example encoderUiState.on("testMic", (bool) => { if(bool) { // show correct "test mic" button state} })
   *
   */
  testMic: EncoderUiState["testMic"];
};

export default class EncoderUiState extends BaseUiState<EncoderUiStateEvents> {
  static readonly displayName = "EncoderUiState";

  mediaStreamController: types.MediaStreamController;

  /*
   *  AudioCtx for silent audio track.
   */
  readonly audioCtx: AudioContext = new AudioContext();

  /**
   * Creating our logger core
   */
  readonly logger: LoggerCore;

  constructor(ctrl: types.MediaStreamControllerAPI, options: EncoderUiOptions = {}) {
    super();

    this.mediaStreamController = ctrl;
    this.logger = options.logger ?? this.mediaStreamController.logger;
    makeObservable(this, {
      // observers
      mediaStreamController: observable.ref,
      prevVideoDeviceId: observable,
      testMic: observable,

      // computed
      aspectRatioPadding: computed,

      // actions
      handleScreenCapture: action,
      toggleVideoCallSlider: action,
    });

    if (!ctrl.inVideoDeviceTransition) {
      if (options.videoDevice === undefined) {
        if (mediaController.videoDevices().length > 0) {
          const [first] = mediaController.videoDevices();
          ctrl.videoDeviceId = first.deviceId;
        }
      } else {
        ctrl.videoDeviceId = options.videoDevice;
      }
    }

    if (!ctrl.inAudioDeviceTransition) {
      if (options.audioDevice === undefined) {
        if (mediaController.audioDevices().length > 0) {
          const [first] = mediaController.audioDevices();
          ctrl.audioDeviceId = first.deviceId;
        }
      } else {
        ctrl.audioDeviceId = options.audioDevice;
      }
    }

    this.addInnerDisposer(this.mediaStreamController);
    this.addInnerDisposer(() => this.audioCtx.close());
    this.addInnerDisposer(onlineWatcher(this.logger));
  }

  /**
   * Indicates whether the video is muted or not.
   */
  get muted(): boolean | null {
    return this.videoElement.current?.muted ?? null;
  }

  /**
   * Keeps track of previous state for videoDeviceId
   */
  prevVideoDeviceId: string | null = null;

  /**
   * Indicates whether we are testing the mic or not.
   */
  testMic = false;

  /**
   * Current aspect ratio. E.g. 16/9
   */
  get aspectRatioPadding(): string | null {
    const ratio = this.mediaStreamController?.settings?.video?.aspectRatio;
    if (ratio == null) {
      return null;
    }

    return `${((1 / ratio) * 100).toFixed(2)}%`;
  }

  /**
   * Toggle mute.
   * @description Toggles the `muted` property on the &lt;video&gt;.
   * @example <caption>Example usage of toggleMute.</caption>
   * toggleMute();
   * // Sets &lt;video&gt; muted property to true|false and updates uiState.muted = true|false;
   */
  toggleMute(): void {
    if (this.videoElement.current != null) {
      this.videoElement.current.muted = !this.videoElement.current.muted;
    }
  }

  /**
   * @todo: Add logging for mediaController in case of mediaStreamController being null.
   * Toggle `videoDeviceId`.
   * @description Toggles the encoder to/from screenCapture and sets uiState.videoDeviceId to
   * either the previous videoDeviceId or screencapture.
   * @example <caption>Example usage of handleScreenCapture.</caption>
   * handleScreenCapture();
   */
  handleScreenCapture(): void {
    if (this.mediaStreamController?.videoDeviceId != null) {
      if (this.mediaStreamController?.videoDeviceId !== "screencapture") {
        this.prevVideoDeviceId = this.mediaStreamController.videoDeviceId;
        this.mediaStreamController.videoDeviceId = "screencapture";
      } else {
        this.mediaStreamController.videoDeviceId = this.prevVideoDeviceId;
      }
    }
  }

  /**
   * Toggle `viewVideoCallSlider`.
   * @description Toggles the video call settings slider to/from visible and sets uiState.viewVideoCallSlider
   * to true/false.
   * @example <caption>Example usage of toggleVideoCallSlider.</caption>
   * toggleVideoCallSlider();
   * // Sets video call settings slider to/from visible and updates uiState.viewVideoCallSlider = true|false;
   */
  toggleVideoCallSlider(): void {
    this.viewVideoCallSlider = !this.viewVideoCallSlider;
  }
}
