import { AuthAPI } from "../../../api/auth";

export default (auth: AuthAPI): Promise<string> =>
  new Promise((resolve, reject) => {
    auth.request((err, token) => {
      if (err != null || token == null) {
        reject(err);
        return;
      }
      resolve(token);
    });
  });
