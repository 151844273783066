import { Properties } from "csstype";
import React, { ReactElement } from "react";
import { useStyles } from "../../styling/videoStyles";
import Icon, { IconStyleProps } from "../Icons";
import { RootStyles } from "../typings/css";
import styles from "./styles";

interface PeerMutedBadgeClasses extends RootStyles {
  icon?: IconStyleProps;
}

export interface PeerMutedBadgeProps {
  classes?: PeerMutedBadgeClasses;
  muted: boolean;
  icon?: ReactElement;
  classNames?: string;
  render?: (muted?: boolean) => ReactElement;
  style?: Properties;
  color?: string;
  height?: number;
  width?: number;
  activeColor?: string;
}

function PeerMutedBadge({
  classes = { root: {}, icon: {} },
  muted,
  icon = muted ? (
    <Icon iconName="microphoneoff" title="Muted" classes={classes.icon} />
  ) : (
    <Icon iconName="microphone" title="Unmuted" classes={classes.icon} />
  ),
  classNames,
  render,
  style,
  color,
  height,
  width,
  activeColor,
}: PeerMutedBadgeProps): React.ReactElement {
  classes?.root
    ? (classes.root["& svg"] = {
        color: muted ? activeColor : color ?? "",
        height: height ?? "2rem",
        width: width ?? "2rem",
      })
    : null;

  classes?.root
    ? (classes.root["&.muted & svg"] = {
        color: activeColor,
      })
    : null;

  const mergedClasses = useStyles({ source: classes, target: styles }, "peerMutedBadge");

  return (
    <span className={`${mergedClasses.root} ${muted && "muted"} ${classNames}`} style={style}>
      {render ? render(muted) : icon}
    </span>
  );
}

PeerMutedBadge.defaultProps = {
  color: "black",
  activeColor: "red",
};

export default PeerMutedBadge;
