/**
 * Executes the given function and retries it up `retryMax` attempts
 * with `retryDelay` between each attempt and returns last successful result or last error.
 */
import type { Logger } from "@video/log-node";
import { device } from "../../api/adapter";
import { wrapNativeError } from "../errors-deprecated";

export function retry<T>(
  retryMax: number,
  retryDelay: number,
  fn: (retryCount: number) => Promise<T>,
  logger?: Logger,
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    let retryCount = 0;
    const retryFn = (): void => {
      fn(retryCount)
        .then(resolve)
        .catch((err) => {
          if (++retryCount < retryMax) {
            logger?.warn(`retrying ${retryCount} time(s)`, { err: wrapNativeError(err) });
            device.setTimeout(retryFn, retryDelay);
          } else {
            reject(err);
          }
        });
    };
    retryFn();
  });
}
