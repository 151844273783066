/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoggerCore } from "@video/log-client";
import { detectDevice } from "mediasoup-client";
import { device } from "../../../api/adapter";
import { Feature } from "../../../api/adapter/features/feature";
import * as codecs from "./codecs";

export interface Tags {
  h264: boolean;
  opus: boolean;
  vp8: boolean;
}

// todo: nonstandard check
export const iosVersion = (): number[] | null => {
  if (device.platform != null && /iP(hone|od|ad)/.test(device.platform) && device.appVersion != null) {
    const version = device.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (version) {
      return [parseInt(version[1], 10), parseInt(version[2], 10)];
    }
  }
  return null;
};

export function supportsChromeiOSCheck(): boolean {
  const iosV = iosVersion();
  if (
    device.isImplements(Feature.BROWSER_TYPE) &&
    device.browserInfo.version != null &&
    device.browserInfo.osname === "ios" &&
    device.browserInfo.name === "chrome"
  ) {
    return iosV != null && (iosV[0] > 14 || (iosV[0] === 14 && iosV[1] >= 3));
  }

  return false;
}

export class Support {
  public static displayName = "Support";

  private readonly supportTags!: Tags;

  private readonly log: LoggerCore;

  public readonly ready: Promise<void>;

  constructor(logger: LoggerCore, create?: string) {
    this.log = logger;

    if (create != null) {
      this.log.setMessageAggregate("create", create);
    }

    this.supportTags = {
      opus: false,
      vp8: false,
      h264: false,
    };

    const supportHash = create ?? this.buildLocalSupportHashSync();

    if (supportHash.includes("o")) {
      this.supportTags.opus = true;
    }

    if (supportHash.includes("v")) {
      this.supportTags.vp8 = true;
    }

    if (supportHash.includes("h")) {
      this.supportTags.h264 = true;
    }

    this.ready = this.buildLocalSupportHashAsync().then((s) => {
      if (s.includes("o")) {
        this.supportTags.opus = true;
      }

      if (s.includes("v")) {
        this.supportTags.vp8 = true;
      }

      if (s.includes("h")) {
        this.supportTags.h264 = true;
      }
    });
  }

  supports(tag: keyof Tags): boolean {
    return !!this.supportTags[tag];
  }

  get any(): boolean {
    const tagKeys = Object.keys(this.supportTags) as Array<keyof Tags>;
    return tagKeys.some((x) => this.supportTags[x]);
  }

  get audio(): boolean {
    return this.supportTags.opus;
  }

  get video(): boolean {
    return this.supportTags.vp8 || this.supportTags.h264;
  }

  get hash(): string {
    let hash = "";
    if (this.supportTags.opus) {
      hash += "o";
    }
    if (this.supportTags.vp8) {
      hash += "v";
    }
    if (this.supportTags.h264) {
      hash += "h";
    }
    return hash;
  }

  shared(compare: Support): Support {
    if (!this.any) {
      return this;
    }

    if (!compare.any) {
      return compare;
    }

    const localHash = this.hash;
    let newHash = "";
    compare.hash.split("").forEach((v) => {
      if (localHash.includes(v)) {
        newHash += v;
      }
    });

    return new Support(this.log, newHash);
  }

  private basicSupport(): boolean {
    if (device.platform === "ReactNative") {
      return true;
    }
    const logInfo = device.isImplements(Feature.BROWSER_TYPE)
      ? {
          ...device.browserInfo,
          platform: device.platform,
        }
      : { platform: device.platform };

    if (detectDevice() == null) {
      this.log.warn("support: mediasoups says device is unsupported", {
        logInfo,
      });
      this.log.flush();
      return false;
    }

    if (!device.isImplements(Feature.MEDIA_DEVICE)) {
      this.log.warn("support: no RTCPeerConnection", {
        logInfo,
      });
      this.log.flush();
      return false;
    }

    if (device.isImplements(Feature.BROWSER_TYPE)) {
      const supportsChromeiOS = supportsChromeiOSCheck();

      if (
        device.browserInfo.osname === "ios" &&
        !["safari", "ios"].includes(device.browserInfo.name ?? "") &&
        !supportsChromeiOS
      ) {
        if (device.browserInfo.name === "chrome") {
          this.log.warn("support: ios chrome support is only available on versions >= 14.3", {
            ...device.browserInfo,
          });
        } else {
          this.log.warn("support: non-safari ios", {
            ...device.browserInfo,
          });
        }

        this.log.flush();
        return false;
      }

      if (
        device.browserInfo.name != null &&
        /edge/.test(device.browserInfo.name) &&
        !device.isImplements(Feature.WEBRTC)
      ) {
        this.log.warn("support: msedge with missing webrtc api", {
          hasRTCRtpReceiver: (device as any)?.RTCRtpReceiver == null ? "false" : "true",
          hasRTCRtpSender: (device as any)?.RTCRtpSender == null ? "false" : "true",
          hasRTCRtpReceiverGetCapabilities: (device as any)?.RTCRtpReceiver?.getCapabilities != null ? "true" : "false",
          ...device.browserInfo,
        });
        this.log.flush();
        return false;
      }
    }

    return device.isImplements(Feature.MEDIA_DEVICE);
  }

  private buildLocalSupportHashSync(): string {
    const logInfo = device.isImplements(Feature.BROWSER_TYPE)
      ? {
          ...device.browserInfo,
          platform: device.platform,
        }
      : { platform: device.platform };

    if (!this.basicSupport()) {
      return "";
    }

    let localSupportHash = "";
    if (codecs.hasOpus()) {
      localSupportHash = localSupportHash.concat("o");
    }

    if (codecs.hasVP8()) {
      localSupportHash = localSupportHash.concat("v");
    }

    if (codecs.hasH264(this.log)) {
      localSupportHash = localSupportHash.concat("h");
    }

    this.log.setLoggerAggregate("supportHash", localSupportHash);
    this.log.debug("support: support hash generated", {
      logInfo,
    });
    return localSupportHash;
  }

  private async buildLocalSupportHashAsync(): Promise<string> {
    const logInfo = device.isImplements(Feature.BROWSER_TYPE)
      ? {
          ...device.browserInfo,
          platform: device.platform,
        }
      : { platform: device.platform };

    if (!this.basicSupport()) {
      return "";
    }

    let localSupportHash = "";
    if (await codecs.hasOpusCapability(this.log)) {
      localSupportHash = localSupportHash.concat("o");
    }

    if (await codecs.hasVP8Capability(this.log)) {
      localSupportHash = localSupportHash.concat("v");
    }

    if (await codecs.hasH264Capability(this.log)) {
      localSupportHash = localSupportHash.concat("h");
    }

    if (localSupportHash !== "") {
      this.log.setLoggerAggregate("supportHash", localSupportHash);
    }
    this.log.debug("support: support hash generated", {
      logInfo,
    });
    return localSupportHash;
  }
}

export function videoSupported(): boolean {
  return device.isImplements(Feature.MEDIA_DEVICE);
}
