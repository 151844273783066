import React from "react";
import { IconProps } from "./iconProps";

function ChevronDownIcon(props: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={9} fill="none" {...props}>
      <path
        fill="currentColor"
        d="M7.293 8.707a1 1 0 0 0 1.414 0l6.364-6.364A1 1 0 0 0 13.657.93L8 6.586 2.343.929A1 1 0 0 0 .93 2.343l6.364 6.364ZM7 7.5V8h2v-.5H7Z"
      />
    </svg>
  );
}
export default ChevronDownIcon;
