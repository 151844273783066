import { observer } from "mobx-react-lite";
import { player as pl } from "@video/video-client-core";
import React, { useContext } from "react";
import { PlayerUiState } from "../../../../../store";
import { PlayerUiContext } from "../../../../context";
import PillToggle, { PillToggleProps } from "../../../../ui-lib/Inputs/PillToggle";
import { ErrorBoundary, useUndefinedStoreError } from "../../../ErrorBoundary";

type LowLatencyToggleProps = Partial<PillToggleProps> & {
  disableToggle?: boolean;
  active?: boolean;
};

const LowLatencyToggle = observer(
  ({ classes = {}, disableToggle, active, ...props }: Partial<LowLatencyToggleProps>) => {
    const componentName = "<LowLatencyToggle/>";

    /**
     * Access PlayerUiContext.
     */
    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(ctx?.player != null, componentName);

    const handleClick = (): void => {
      if (ctx.player.isImplements(pl.Feature.PLAYER_SELECTOR)) {
        ctx.player.lowLatency = !ctx.player.lowLatency;
      }
    };

    if (!ctx.player.isImplements(pl.Feature.PLAYER_SELECTOR) || !ctx.player?.supportsLowLatency) {
      return null;
    }

    return (
      <PillToggle
        // disabledOff={disableToggle} // Idk what disabledOn/disabledOff is for specifically, should prob be refactored to just disabled
        isActive={ctx.player.lowLatency}
        label="Low-latency:"
        handleClick={handleClick}
        {...props}
      />
    );
  },
);

function LowLatencyToggleWithErrorBoundary({ classes, ...props }: LowLatencyToggleProps): React.ReactElement {
  return (
    <ErrorBoundary render={() => <PillToggle isActive={false} disabled {...props} />}>
      <LowLatencyToggle {...props} />
    </ErrorBoundary>
  );
}

export default LowLatencyToggleWithErrorBoundary;
