/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Generic video client error
 * All other video client errors are inherited
 * from this class
 */
import type { LoggerCore } from "@video/log-client";
import { Aggregates } from "@video/log-node";
import { ErrorCode, IVideoClientError, Json } from "../api";
import { AllErrorsMap, AnyError, isVideoClientError } from "../api/error";

export { ErrorCode } from "../api";

const excludeFields = new Set<PropertyKey>(["toJSON", "stack", "detailed", "handled", "inner", "aggregates"]);

function serialize(err: Error | null | undefined): Json {
  if (err == null) {
    return null;
  }

  if ("toJSON" in err && typeof err.toJSON === "function") {
    return err as Json;
  }

  return {
    err: err.name ?? err.constructor.name,
    message: err.message,
    stack: err.stack,
  };
}

export function wrapError(err: unknown): AnyError | null {
  if (err == null) {
    return null;
  }

  if (isVideoClientError(err)) {
    return err;
  }

  if (err instanceof Error) {
    return createError(ErrorCode.NativeError, err.message, { type: err.name });
  }

  if (err instanceof ErrorEvent) {
    return createError(ErrorCode.NativeError, err.message, { type: err.type });
  }

  return createError(ErrorCode.NativeError, String(err), { type: "unknown" });
}

function concatMessages(code: string, message: string, inner: AnyError | null): string {
  let result = `[${code}] ${message}`;
  while (inner != null) {
    result += `; [${inner.code}] ${inner.message}`;
    inner = inner.inner;
  }
  return result;
}

export function createError<C extends keyof AllErrorsMap>(
  code: C,
  message: string,
  data: AllErrorsMap[C],
  inner: unknown | null = null,
): AllErrorsMap[C] & IVideoClientError {
  return new VideoClientError(code, message, data, inner) as any;
}

/**
 * Generic VideoClient error. All errors VDC errors inherits this class
 */
export class VideoClientError extends Error implements IVideoClientError {
  readonly code: ErrorCode;

  readonly inner: AnyError | null = null;

  readonly critical?: boolean;

  readonly innerCodes: Set<ErrorCode> = new Set();

  private muted = false;

  constructor(code: ErrorCode, message: string, data: unknown = {}, inner: unknown = null) {
    super(concatMessages(code, message, wrapError(inner)));

    this.code = code;
    this.inner = wrapError(inner);

    for (const [key, val] of Object.entries(data as any) as Array<[keyof this, any]>) {
      this[key] = val;
    }
  }

  static log(err: IVideoClientError, logger: LoggerCore): void {
    logger.error(err.message, { err });
  }

  get isMuted(): boolean {
    return this.muted;
  }

  toJSON(): Json {
    const json: { [key: string]: Json } = {
      message: this.message,
      stack: this.stack ?? null,
      inner: serialize(this.inner),
      aggregates: this.aggregates(),
    };

    for (const k of Object.keys(this) as Array<keyof this>) {
      if (!excludeFields.has(k)) {
        json[k.toString()] = this[k] as Json;
      }
    }

    return json;
  }

  aggregates(): Aggregates {
    if (this.inner instanceof VideoClientError) {
      return {
        ...this.inner.aggregates(),
        errCode: this.code,
      };
    }

    return {
      errCode: this.code,
    };
  }

  mute(): void {
    this.muted = true;
  }
}
