export interface DOMException {
    readonly message: string;
    readonly name: string;
}
export interface OverconstrainedError extends DOMException {
    readonly constraint: string;
}
export function isErrorLike(err: Error | DOMException | unknown): err is DOMException {
  if (err == null || typeof err !== "object") {
    return false; 
  }
  return (err as DOMException).message !== undefined && (err as DOMException).name !== undefined;
}
export function isOverconstrainedError(err: Error | DOMException): err is OverconstrainedError {
  return (err as OverconstrainedError).constraint !== undefined && (err as OverconstrainedError).name !== undefined && (err as OverconstrainedError).message !== undefined;
}
  