import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../store/encoder";
import { EncoderUiContext } from "../../../context";
import SlideSidebar, { SlidingShelfProps } from "../../../ui-lib/SlidingShelf";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularSlideSidebar = observer(({ children, onCloseButtonClick, open, ...props }: Partial<SlidingShelfProps>) => {
  const componentName = "<VideoSidebar/>";
  const ctx = useContext<EncoderUiState | null>(EncoderUiContext);

  useUndefinedStoreError(ctx != null, componentName);

  const toggleVideoCallSlider = (): void => {
    ctx.viewVideoCallSlider = !ctx.viewVideoCallSlider;
  };

  const handleClick = useUIEventError(toggleVideoCallSlider, componentName);

  return (
    <SlideSidebar
      {...props}
      onCloseButtonClick={onCloseButtonClick ?? handleClick}
      open={open ?? ctx?.viewVideoCallSlider}
      variant="dark"
    >
      {children}
    </SlideSidebar>
  );
});

// eslint-disable-next-line react/function-component-definition
const SlideSidebarWithErrorBoundary: FC<Partial<SlidingShelfProps>> = ({ children, ...props }) => {
  return (
    <ErrorBoundary render={() => <SlideSidebar {...props} />}>
      <ModularSlideSidebar {...props}>{children}</ModularSlideSidebar>
    </ErrorBoundary>
  );
};

export default SlideSidebarWithErrorBoundary;
