import { Properties } from "csstype";
import React, { ReactElement, ReactNode } from "react";
import { useStyles } from "../../styling";
import { mergeStylesObjects } from "../../styling/utils";
import CloseButton from "../Buttons/Close";
import { EventFunction } from "../typings/clickevents";
import styles, { SidebarStyles } from "./styles";

export interface SlidingShelfProps {
  children?: ReactNode;
  className?: string;
  classes?: SidebarStyles;
  height?: number;
  onCloseButtonClick?: EventFunction;
  open?: boolean;
  style?: Properties;
  variant?: string;
}

function SlidingShelf({
  className = "",
  classes = { root: {} },
  onCloseButtonClick,
  open,
  children,
  variant = "light",
  height,
  style,
  ...rest
}: SlidingShelfProps): ReactElement {
  const mergedClasses = useStyles({ source: classes, target: styles }, "slidingShelf");
  const mergedStyles = mergeStylesObjects(classes, styles);

  return (
    <div
      className={`${mergedClasses.root} ${variant === "dark" && "dark-shelf"} ${open && "open"} ${className}`}
      {...rest}
    >
      <CloseButton onClick={onCloseButtonClick} open={open} classes={{ root: mergedStyles.closeButton }} />
      {children}
    </div>
  );
}

export default SlidingShelf;
