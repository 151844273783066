import React, { forwardRef } from "react";
import { useStyles } from "../../../styling/videoStyles";
import ButtonTextProps from "./propTypes";
import styles from "./styles";

const ButtonText = forwardRef<HTMLButtonElement, ButtonTextProps>(
  (
    {
      active,
      buttonText = "",
      children,
      classes = {},
      classNames = "",
      disabled = false,
      label = "Default Button",
      onClick,
      style = {},
      ...props
    },
    ref,
  ) => {
    const mergedClasses = useStyles({ source: classes, target: styles }, "button-text");
    let loweredLabel;
    if (typeof label !== "undefined") {
      loweredLabel = label.replace(/\s+/g, "-").toLowerCase();
    }

    return (
      <button
        title={label}
        aria-label={label}
        className={`${mergedClasses.root} ${active || ""} ${classNames && classNames}`}
        data-selenium={`${label && loweredLabel}-button`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        style={style}
        type="button"
        {...props}
      >
        {children}
      </button>
    );
  },
);

ButtonText.displayName = "ButtonText";

export default ButtonText;
