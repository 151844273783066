import { VideoClient } from "@video/video-client-core";
import { createContext } from "react";
import { EncoderUiState } from "../../store/encoder";
import { PlayerUiState } from "../../store/player";
import { RecorderUiState } from "../../store/recorder";
import { CallState } from "../../store/call";
import { MultistreamState } from "../../store/multistream";

const EncoderUiContext = createContext<EncoderUiState | null>(null);
const PlayerUiContext = createContext<PlayerUiState | null>(null);
const RecorderUiContext = createContext<RecorderUiState | null>(null);
const VideoClientContext = createContext<VideoClient | null>(null);
const CallContext = createContext<CallState | null>(null);
const MultistreamContext = createContext<MultistreamState | null>(null);

export { EncoderUiContext, CallContext, PlayerUiContext, RecorderUiContext, VideoClientContext, MultistreamContext };
