import { LoggerCore } from "@video/log-client";
import { device } from "../../api/adapter";
import { AnyFormat, Encoding } from "../../api/manifest";
import {
  AutoQualityLevel,
  BitrateLayer,
  Quality,
  SourceScoreLevel,
  TranscodeScoreLevel,
} from "../../api/player/features/bitrate-switching";
import { VideoElement } from "../../api/typings/video-element";
import { CorePlayer } from "./core";
import { HlsJsPlayer } from "./hlsjs";
import type { MediaElementSupervisor } from "./media-element-supervisor";
import { NativeHlsPlayer } from "./native-hls";

// disabled because causes the bug with black screen
//
// type VideoMetadata = {
//   timeout: number;
// };
//
// const meta = new WeakMap<VideoElement, VideoMetadata>();
// a workaround for mediasoup3 bug when
// ms3 creates consumer in an invalid statexw
// if remote track was disabled at start
// function fixMS3ServerBug(el: VideoElement): void {
//   const src = el.srcObject;
//   if (typeof src === "object" && src != null && "getTracks" in src) {
//     const stream = el.srcObject as MediaStream;
//     stream.getTracks().forEach((tr) => {
//       // if muted and enabled are true then it means
//       // this track is enabled but it didn't get any frames yet
//       // so if we didn't get any frames for 5000 ms that means
//       // it was disabled in remote producer
//       if (tr.muted && tr.enabled) {
//         // so disable it (as it should be at start)
//         // and trigger loading again
//         tr.enabled = false;
//       }
//     });
//   }
//
//   if (el.load != null) {
//     el.load();
//   } else {
//     el.play();
//   }
// }

export function playOncePossible(el: MediaElementSupervisor, logger: LoggerCore): Promise<void> {
  logger.debug("playOncePossible()", { aggregates: el.dumpVideoElement() });

  if (!el.paused && !el.ended) {
    return Promise.resolve();
  }

  if (el.readyState > 2) {
    return el.play();
  }

  // device.clearTimeout(meta.get(el)?.timeout);
  // const fixMS3Timeout = device.setTimeout(fixMS3ServerBug.bind(null, el), 5000);
  // meta.set(el, { timeout: fixMS3Timeout });

  return new Promise<void>((resolve, reject) => {
    logger.debug("playOncePossible() returned Promise", { aggregates: el.dumpVideoElement() });
    const onReady = (): void => {
      if (el.readyState > 2) {
        // device.clearTimeout(fixMS3Timeout);
        el.removeListener("loadeddata", onReady);

        el.play()
          .catch(reject)
          .then(() => {
            if (!el.paused) {
              resolve();
              return;
            }

            // if it's still paused then we'll check
            // this status for ~1sec every 50ms
            let attempts = 20;
            const n = device.setInterval(() => {
              if (!el.paused) {
                device.clearInterval(n);
                resolve();
              }
              if (--attempts === 0) {
                device.clearInterval(n);
                reject(new Error("Video element remains paused for 1s after play() call"));
              }
            }, 50);
          });
      }
    };

    el.addListener("loadeddata", onReady);
  });
}

export function isBitrateLayer(obj: Encoding | BitrateLayer): obj is BitrateLayer {
  return "id" in obj && "bitrate" in obj && "isSource" in obj;
}

export function getBitrateLayersFromQualities(qualities: Quality[]): BitrateLayer[] {
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value != null && value !== undefined;
  }
  return qualities.map((q) => q.layer).filter(notEmpty);
}

export function sortEncodings(encodings: (Encoding | BitrateLayer)[]): (Encoding | BitrateLayer)[] {
  return [...encodings].sort((a, b) => {
    const aBitrate = isBitrateLayer(a) ? a.bitrate ?? a.maxBitrate : (a.audioKbps ?? 0) + (a.videoKbps ?? 0);
    const bBitrate = isBitrateLayer(b) ? b.bitrate ?? b.maxBitrate : (b.audioKbps ?? 0) + (b.videoKbps ?? 0);

    // that means we don't know bitrate so assume
    // it's the source with the highest bitrate
    if (aBitrate === 0) {
      return -1;
    }

    return bBitrate - aBitrate;
  });
}

export function encodingToLayer(encoding: Encoding | BitrateLayer): BitrateLayer {
  if (isBitrateLayer(encoding)) {
    return {
      id: encoding.id,
      bitrate: encoding.bitrate ?? encoding.maxBitrate,
      isSource: encoding.isSource ?? false,
      appData: encoding.appData ?? {},
    };
  }

  const layer: BitrateLayer = {
    id: encoding.location,
    bitrate: (encoding.audioKbps ?? 0) + (encoding.videoKbps ?? 0),
    isSource: false,
    appData: {},
    encoding,
  };

  if (layer.appData != null && encoding.videoWidth && encoding.videoHeight) {
    layer.appData.videoWidth = encoding.videoWidth;
    layer.appData.videoHeight = encoding.videoHeight;
  }

  return layer;
}

export function fetchManifestQualities(
  format: AnyFormat | BitrateLayer[],
  origin: string | null,
  player?: CorePlayer,
): Quality[] {
  let encodings: (Encoding | BitrateLayer)[];
  if (Array.isArray(format)) {
    // that comes from SFU
    encodings = format;
  } else if (format.substitute != null) {
    // that comes from manifest
    // if it has substitute then the stream is blurred
    // so we just won't provide any real encodings
    encodings = [format.substitute];
  } else {
    encodings = format.encodings;
  }

  const enc = sortEncodings(encodings ?? []);
  const qty: Quality[] = [];

  if (origin != null) {
    qty.push({
      level: SourceScoreLevel.High,
      layer: { bitrate: 0, isSource: true, id: origin },
    });
  } else {
    const sourceLayers = enc.map((e) => encodingToLayer(e)).filter((e) => e.isSource);

    sourceLayers.sort((a, b) => (a.bitrate > b.bitrate ? 1 : -1));

    for (let i = 0; i < sourceLayers.length; i++) {
      switch (i) {
        case 0:
          qty.push({ level: SourceScoreLevel.Low, layer: sourceLayers[i], encoding: sourceLayers[i].encoding });
          break;
        case 1:
          qty.push({ level: SourceScoreLevel.Medium, layer: sourceLayers[i], encoding: sourceLayers[i].encoding });
          break;
        case 2:
          qty.push({ level: SourceScoreLevel.High, layer: sourceLayers[i], encoding: sourceLayers[i].encoding });
          break;
        default:
      }
    }
  }

  const xcodeLayers = enc.map((e) => encodingToLayer(e)).filter((e) => !e.isSource);

  switch (xcodeLayers.length) {
    case 0:
      break;
    case 1:
      qty.push({
        level: TranscodeScoreLevel.Medium,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    case 2:
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    case 3:
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[2]),
        encoding: xcodeLayers[2].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Medium,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    case 4:
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[3]),
        encoding: xcodeLayers[3].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumLow,
        layer: encodingToLayer(xcodeLayers[2]),
        encoding: xcodeLayers[2].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumHigh,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    case 5:
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[4]),
        encoding: xcodeLayers[4].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumLow,
        layer: encodingToLayer(xcodeLayers[3]),
        encoding: xcodeLayers[3].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Medium,
        layer: encodingToLayer(xcodeLayers[2]),
        encoding: xcodeLayers[2].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumHigh,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    case 6:
      qty.push({
        level: TranscodeScoreLevel.Lowest,
        layer: encodingToLayer(xcodeLayers[5]),
        encoding: xcodeLayers[5].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[4]),
        encoding: xcodeLayers[4].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumLow,
        layer: encodingToLayer(xcodeLayers[3]),
        encoding: xcodeLayers[3].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumHigh,
        layer: encodingToLayer(xcodeLayers[2]),
        encoding: xcodeLayers[2].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Highest,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
    default:
      qty.push({
        level: TranscodeScoreLevel.Lowest,
        layer: encodingToLayer(xcodeLayers[6]),
        encoding: xcodeLayers[6].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Low,
        layer: encodingToLayer(xcodeLayers[5]),
        encoding: xcodeLayers[5].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumLow,
        layer: encodingToLayer(xcodeLayers[4]),
        encoding: xcodeLayers[4].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Medium,
        layer: encodingToLayer(xcodeLayers[3]),
        encoding: xcodeLayers[3].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.MediumHigh,
        layer: encodingToLayer(xcodeLayers[2]),
        encoding: xcodeLayers[2].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.High,
        layer: encodingToLayer(xcodeLayers[1]),
        encoding: xcodeLayers[1].encoding,
      });
      qty.push({
        level: TranscodeScoreLevel.Highest,
        layer: encodingToLayer(xcodeLayers[0]),
        encoding: xcodeLayers[0].encoding,
      });
      break;
  }

  if (player != null && (player instanceof HlsJsPlayer || player instanceof NativeHlsPlayer)) {
    qty.push({
      level: AutoQualityLevel.Auto,
    });
  }

  return qty;
}

export const ERRORS = {
  BAD_INPUT: "bad-input",
  DRIVER_NOT_SUPPORTED: "driver-not-supported",
  ELEMENT_REQUIRED: "element-required",
  EMBED_SWF_FAILED: "embedding-flash-swf-failed",
  GET_USER_MEDIA_FAILED: "get-user-media-failed",
  HTTP_SERVER_UNEXPECTED_RESPONSE: "http-server-unexpected-response",
  HTTP_SERVER_UNAUTHORIZED: "http-server-unauthorized",
  HTTP_SERVER_FORBIDDEN: "http-server-forbidden",
  HTTP_SERVER_INTERNAL_ERROR: "http-server-internal-error",
  HTTP_SERVER_NOT_FOUND: "http-server-not-found",
  MANIFEST: "http-response",
  INVALID_CONTROLS: "invalid-controls-parent",
  INVALID_MEDIA_URL: "invalid-media-url",
  INVALID_POPOUT_URL: "invalid-popout-url",
  INVALID_EL: "invalid-element",
  WS_NETWORK_ERROR: "websocket-network-error",
  NETWORK_ERROR: "network-error",
  NO_DRIVERS: "no-valid-drivers",
  PLAYBACK_ERROR: "playback-error",
  UNKNOWN_DRIVER: "unknown-driver",
  UNKNOWN_ERROR: "unknown-error",
  UNRECOGNIZED_DRIVER: "unrecognized-driver",
  USER_REQUIRED: "user-required",
  INVALD_BITRATE: "invalid-bitrate",
  HLSJS_NOT_LOADED: "hlsjs-not-loaded",
};

export function isFatalError(err: unknown): boolean {
  if (err && typeof err === "object" && "fatal" in err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (err as any).fatal === true;
  }
  return false;
}

function nextScore(score: TranscodeScoreLevel | SourceScoreLevel): TranscodeScoreLevel | SourceScoreLevel | null {
  switch (score) {
    case SourceScoreLevel.Low:
      return SourceScoreLevel.Medium;
    case SourceScoreLevel.Medium:
      return SourceScoreLevel.High;
    case SourceScoreLevel.High:
      return null;

    case TranscodeScoreLevel.Lowest:
      return TranscodeScoreLevel.Low;
    case TranscodeScoreLevel.Low:
      return TranscodeScoreLevel.MediumLow;
    case TranscodeScoreLevel.MediumLow:
      return TranscodeScoreLevel.Medium;
    case TranscodeScoreLevel.Medium:
      return TranscodeScoreLevel.MediumHigh;
    case TranscodeScoreLevel.MediumHigh:
      return TranscodeScoreLevel.High;
    case TranscodeScoreLevel.High:
      return TranscodeScoreLevel.Highest;
    default:
      return null;
  }
}

function prevScore(score: TranscodeScoreLevel | SourceScoreLevel): TranscodeScoreLevel | SourceScoreLevel | null {
  switch (score) {
    case SourceScoreLevel.High:
      return SourceScoreLevel.Medium;
    case SourceScoreLevel.Medium:
      return SourceScoreLevel.Low;
    case SourceScoreLevel.Low:
      return null;

    case TranscodeScoreLevel.Highest:
      return TranscodeScoreLevel.High;
    case TranscodeScoreLevel.High:
      return TranscodeScoreLevel.MediumHigh;
    case TranscodeScoreLevel.MediumHigh:
      return TranscodeScoreLevel.Medium;
    case TranscodeScoreLevel.Medium:
      return TranscodeScoreLevel.MediumLow;
    case TranscodeScoreLevel.MediumLow:
      return TranscodeScoreLevel.Low;
    case TranscodeScoreLevel.Low:
      return TranscodeScoreLevel.Lowest;
    default:
      return null;
  }
}

/**
 * Find any closes to `score` quality. Always return some quality for non-empty `qualities` array
 */
export function findClosestQuality(
  score: TranscodeScoreLevel | SourceScoreLevel | AutoQualityLevel,
  qualities: Quality[],
): Quality | null {
  if (qualities.length === 0) {
    return null;
  }

  if (score === AutoQualityLevel.Auto) {
    return { level: score };
  }

  let closestScore: TranscodeScoreLevel | SourceScoreLevel | null;
  const mapQualities: Record<string, Quality> = Object.fromEntries(qualities.map((q) => [q.level, q]));

  // go down from better quality to lower
  closestScore = score;
  while (closestScore != null && mapQualities[closestScore] == null) {
    closestScore = prevScore(closestScore);
  }

  if (closestScore == null) {
    // if still didn't find anything then go up
    closestScore = score;
    while (closestScore != null && mapQualities[closestScore] == null) {
      closestScore = nextScore(closestScore);
    }
  }

  if (closestScore != null) {
    return mapQualities[closestScore];
  }
  return null;
}

export function equalQualities(a: Quality[], b: Quality[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i].level !== b[i].level) {
      return false;
    }
  }

  return true;
}

export function dataProperties(data: any): any {
  return { details: data.details, fatal: data.fatal, type: data.type, url: data.url };
}

export const lowPowerModeMsg =
  "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.";

export function isInLowPowerMode(err: Error): boolean {
  return (
    device.isIosDevice && err instanceof Error && err.name === "NotAllowedError" && err.message === lowPowerModeMsg
  );
}

export function attachVideoElement(el: VideoElement): VideoElement {
  // return new Proxy(el, new MediaElementSupervisor());
  return el;
}
