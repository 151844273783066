import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CarouselSlideStyleProps, carouselSlideStyles } from "./styles";
import { useStyles } from "../../../styling";
import Close from "../../../ui-lib/Icons/Close";
import { MultistreamState } from "../../../../store/multistream";
import { MultistreamContext } from "../../../context";
import { useUndefinedStoreError } from "../../ErrorBoundary";
import Eye from "../../../ui-lib/Icons/Eye";
import Camera from "../../../ui-lib/Icons/Camera";

interface MultistreamCarouselSlideProps {
  classes?: CarouselSlideStyleProps;
  isMobile: boolean;
  publicKey: string;
}

const MultistreamCarouselSlide = observer(
  ({ publicKey, classes, isMobile }: MultistreamCarouselSlideProps): JSX.Element => {
    const [isImageError, setIsImageError] = useState(false);
    const mergedClasses = useStyles(
      { target: classes ?? {}, source: carouselSlideStyles },
      "multistream-carousel-slide",
    );
    const ctx = useContext<MultistreamState | null>(MultistreamContext);

    useUndefinedStoreError(ctx != null, "<MultistreamCarouselSlide/>");

    const { activePublicKeys, startPlayer, streams } = ctx;

    const posterUrl = streams[publicKey]?.posterUrl;
    const disconnected = streams[publicKey]?.disconnected;
    const viewCount = streams[publicKey]?.viewCount;
    const isStreamPlaying = activePublicKeys.includes(publicKey);

    useEffect(() => {
      let interval: ReturnType<typeof setInterval> | null = null;

      if (isImageError) {
        interval = setInterval(() => {
          const img = new Image();

          img.onload = () => {
            setIsImageError(false);
            document.body.removeChild(img);
            if (interval != null) clearInterval(interval);
          };

          img.src = posterUrl ?? "";
          img.style.display = "none";

          document.body.appendChild(img);
        }, 2000);
      }

      return () => {
        if (interval) clearInterval(interval);
      };
    }, [isImageError, posterUrl]);

    if (disconnected) {
      return (
        <div className={mergedClasses.disconnectedWrapper}>
          <h2 className={mergedClasses.disconnected}>OFFLINE</h2>
        </div>
      );
    }

    const shouldDisplayPosterImage = posterUrl != null && !isImageError;

    return (
      <div
        className={shouldDisplayPosterImage ? mergedClasses.root : mergedClasses.disconnectedWrapper}
        onClick={() => isMobile && startPlayer(publicKey)}
        onKeyDown={(e) => e.key === "Enter" && isMobile && startPlayer(publicKey)}
        tabIndex={0}
        role="button"
        style={{ cursor: isMobile ? "pointer" : "default" }}
      >
        {!isStreamPlaying === true && (
          <button type="button" onClick={() => startPlayer(publicKey)} className={mergedClasses.addButton}>
            <Close weight="normal" style={{ width: "15px", transform: "rotateZ(45deg)", marginLeft: "3px" }} />
          </button>
        )}
        {shouldDisplayPosterImage ? (
          <img
            className={mergedClasses.image}
            src={posterUrl}
            alt="video-poster"
            onError={() => setIsImageError(true)}
          />
        ) : (
          <div className={mergedClasses.stockImageWrapper}>
            <Camera style={{ width: "30%" }} outline />
          </div>
        )}
        {isStreamPlaying && <div className={mergedClasses.playingOverlay} />}
        {viewCount != null && viewCount > 0 && (
          <div className={mergedClasses.viewCount}>
            <Eye style={{ width: "15px", marginRight: "3px", height: "11px" }} />
            <span>{viewCount}</span>
          </div>
        )}
      </div>
    );
  },
);
export default MultistreamCarouselSlide;
