import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import type { errors } from "@video/video-client-core";
import AlertBase, { AlertBaseProps } from "../../../ui-lib/Alerts/AlertBase";

type ErrorAlertProps = AlertBaseProps & {
  error?: errors.VideoClientError | null;
};

const errorAlertClasses = {
  root: {
    backgroundColor: "#f54248",
    color: "#00000",
  },
};

const ModularErrorAlert = observer(
  ({ error, children, classes, ...props }: Partial<ErrorAlertProps>): React.ReactElement | null => {
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
      if (error != null) {
        setActive(true);
      }
    }, [error]);

    if (error == null) {
      return null;
    }

    function DefaultMessage(): React.ReactElement {
      return <p style={{ margin: 0 }}> Error: {error?.code} </p>;
    }

    return (
      <AlertBase classes={classes || errorAlertClasses} {...props} active={active} setActive={setActive}>
        {children ?? <DefaultMessage />}
      </AlertBase>
    );
  },
);

export default ModularErrorAlert;
