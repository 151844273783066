import React from "react";
import { useStyles } from "../../styling";
import { mergeStylesObjects } from "../../styling/utils";
import { EventFunction } from "../typings/clickevents";
import { RootStyles } from "../typings/css";
import styles from "./styles";
import Icon from "../Icons";

export interface PlayerOverlayButtonProps {
  classes?: RootStyles;
  onClick?: EventFunction;
  // it seems React types are broken for current version of React
  // @todo can be replaced with proper type when it's fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  disabled?: boolean;
  onMouseEnter?: EventFunction;
  onMouseLeave?: EventFunction;
}

function PlayerOverlayButton({ classes = { root: {} }, children, ...rest }: PlayerOverlayButtonProps) {
  const mergedClasses = useStyles({ source: classes, target: styles }, "playerOverlayButton");
  const mergedStyles = mergeStylesObjects(classes, styles);

  return (
    <div {...rest} className={`${rest.disabled ? mergedClasses.rootDisabled : ""} ${mergedClasses.root}`}>
      {children ?? <Icon iconName="play" classes={mergedStyles.overlayPlayIcon} />}
    </div>
  );
}

export default PlayerOverlayButton;
