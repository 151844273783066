import type { LoggerCore } from "@video/log-client";
import type { ManifestFormats, ManifestJson } from "../../api/manifest";
import type { PlayerEvents } from "../../api/player";
import { BitrateSwitchingEvents, BitrateSwitchingFeature } from "../../api/player/features/bitrate-switching";
import { device, Feature } from "../../api/adapter";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { MutedAutoplayFeature } from "../../api/player/features/muted-autoplay";
import { VideoElement } from "../../api/typings/video-element";
import { DriverNotSupportedError, PlayingIssueError } from "../errors-deprecated";
import MediaLoader from "../media-loader";
import { supportsNativeHls } from "../utils/browser-support/browser";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer, CorePlayerOptions } from "./core";

export type NativeHlsPlayerEvents = PlayerEvents & BitrateSwitchingEvents;

export type NativeHlsPlayerOptions = {
  autoPlay?: boolean;
  muted?: boolean;
};
export class NativeHlsPlayer
  extends CorePlayer<NativeHlsPlayerOptions, ManifestJson, NativeHlsPlayerEvents>
  implements BitrateSwitchingFeature, MutedAutoplayFeature
{
  static readonly displayName = "NativeHlsPlayer";

  constructor(ctx: VcContext, provider: MediaLoader, options: CorePlayerOptions) {
    super(ctx, provider, options);

    this.on("currentQuality", this.handleCurrentQuality);

    this.addInnerDisposer(() => {
      this.off("currentQuality", this.handleCurrentQuality);
    });
  }

  handleCurrentQuality(): void {
    this.srcReset = false;
    this.handleSource(this.manifestJson);
  }

  srcReset = false;

  // temporary variable remove when HC is merged
  manifestJson: ManifestJson | null = null;

  static async isSupported(logger?: LoggerCore): Promise<boolean> {
    return supportsNativeHls(logger);
  }

  async isSupported(): Promise<boolean> {
    return NativeHlsPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "mp4-hls";
  }

  get format(): keyof ManifestFormats {
    return NativeHlsPlayer.format;
  }

  protected async handleSource(manifest: ManifestJson | null): Promise<void> {
    if (this.suspended) {
      return;
    }

    this.ctx.logger.debug("native-hls: handleSource()");
    const format = manifest?.formats["mp4-hls"];

    if (format == null) {
      this.emitErrorDeprecated(
        new DriverNotSupportedError("manifest doesn't contains 'mp4-hls' format", {
          manifest,
          loader: this.provider as MediaLoader,
        }),
      );
      return;
    }

    if (manifest != null) {
      this.manifestJson = manifest;
    }

    if (this.currentQuality?.layer?.id != null && this.currentQuality.layer.id !== "") {
      this.source = this.currentQuality.layer.id.toString();
    } else if (format?.substitute?.location != null) {
      this.source = format.substitute.location;
    } else {
      this.source = format.manifest;
    }

    // !this.hostEl.src won't work here because browser default the src to the window.location. Why? IDK.
    if (device.isImplements(Feature.URL_LOCATION) && this.elementSupervisor != null) {
      if (this.elementSupervisor.sourceKind !== "src") {
        this.srcReset = true;
      }
    }

    if (
      (this.elementSupervisor != null && this.elementSupervisor.sourceKind !== "src") ||
      (this.elementSupervisor != null && !this.srcReset)
    ) {
      const currentTime = this.elementSupervisor.currentTime;
      this.srcReset = true;
      this.elementSupervisor?.updateSrc(this.source);

      // keep current time for recorded videos
      if (currentTime > 0) {
        this.elementSupervisor.currentTime = currentTime;
      }

      if (this.options.autoPlay) {
        try {
          await this.play(true);
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitErrorDeprecated(
            new PlayingIssueError("autoplay call failed after handleSource()", {
              inner,
              player: this,
            }),
          );
        }
      }
    }
  }

  async attachTo(el: VideoElement): Promise<void> {
    await super.attachTo(el);
    if (this.options.autoPlay) {
      try {
        await this.play(true);
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitErrorDeprecated(
          new PlayingIssueError("autoplay call failed after attachTo()", {
            inner,
            player: this,
          }),
        );
      }
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }
}
