import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { EncoderUiContext } from "../../../../context";
import Checkbox, { CheckboxProps, CheckboxStyles } from "../../../../ui-lib/Inputs/Checkbox";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

interface EchoCancellationClasses {
  echoCancellationCheckbox?: CheckboxStyles;
}

type EchoCancellationCheckboxProps = Omit<CheckboxProps, "classes"> & { classes?: EchoCancellationClasses };

const ModularEchoCancellationCheckbox = observer(({ classes, ...props }: Partial<EchoCancellationCheckboxProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<EchoCancellationCheckbox/>";

  /**
   * Access EncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(EncoderUiContext);

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(ctx?.mediaStreamController != null, componentName);

  const toggleEchoCancellation = (): void => {
    ctx.mediaStreamController.echoCancellation = !ctx.mediaStreamController.echoCancellation;
  };

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(toggleEchoCancellation, componentName);

  return (
    <Checkbox
      {...props}
      classes={classes?.echoCancellationCheckbox}
      label="Echo Cancellation"
      disabled={!ctx.mediaStreamController.supportsEchoCancellation()}
      checked={ctx.mediaStreamController.echoCancellation === true}
      onChange={handleInputChange}
      title={
        !ctx.mediaStreamController.supportsEchoCancellation()
          ? "Your browser does not support echo cancellation"
          : undefined
      }
    />
  );
});

const EchoCancellationCheckboxWithErrorBoundary: FC<Partial<EchoCancellationCheckboxProps>> = ({
  classes,
  ...props
}) => {
  return (
    <ErrorBoundary
      render={() => (
        <Checkbox
          {...props}
          disabled
          classes={classes?.echoCancellationCheckbox}
          label="Echo Cancellation Unavailable"
        />
      )}
    >
      <ModularEchoCancellationCheckbox classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default EchoCancellationCheckboxWithErrorBoundary;
