import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Start: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg className={`${className != null && className}`} height="28" width="28" viewBox="0 0 48 48" {...rest}>
      <path d="M-838-2232H562v3600H-838z" fill="none" />
      <path d="M16 10v28l22-14z" />
      <path d="M0 0h48v48H0z" fill="none" />
    </svg>
  );
};

export default Start;
