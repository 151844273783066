import type { types } from "@video/video-client-core";

/*
 * DEPRECATED. This should be removed once the Encoder is converted to use player from core.
 */
export const createVideoElement = (autoplay = true): types.VideoElement => {
  // logger.debug("web/utils: createVideoElement() called");

  const video = document.createElement("video");
  video.autoplay = autoplay;
  video.muted = true;
  const fallbackText = document.createTextNode("Sorry, your browser doesn't support embedded videos.");
  video.appendChild(fallbackText);

  return video;
};

export const getDevices = (deviceArray: Array<MediaDeviceInfo>, inputType: string): Array<MediaDeviceInfo> | null => {
  return Array.isArray(deviceArray) && (inputType === "audio" || inputType === "video")
    ? deviceArray.filter((device) => device.kind === `${inputType}input`)
    : null;
};

export interface TokenRequest {
  scopes: string[];
  userId: string;
  data: Record<string, unknown>;
}
export type { BaseUiStateEvents } from "./ui-state";

export const videoStylesNamespace = "video";
