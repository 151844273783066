/* eslint-disable import/no-cycle */
import { LoggerCore } from "@video/log-client";
import { Device, types } from "mediasoup-client";
import { device, Feature, NetworkInformation, BrowserInfo } from "../../../api/adapter";
import type { VideoElement } from "../../../api/typings/video-element";
import { Support } from "../support";
import { MockMediasoupClientDevice } from "../../../mocks/mediasoup-client-device";

export const VP8_CAPABILITY: types.RtpCodecCapability = {
  clockRate: 90000,
  kind: "video",
  mimeType: "video/VP8",
};

export const OPUS_CAPABILITY: types.RtpCodecCapability = {
  channels: 2,
  clockRate: 48000,
  kind: "audio",
  mimeType: "audio/opus",
};

export const H264_CAPABILITY: types.RtpCodecCapability = {
  clockRate: 90000,
  kind: "video",
  mimeType: "video/H264",
  parameters: {
    "packetization-mode": 1,
  },
};

export interface SafariVideoElement {
  canPlayType: (codec: string) => "probably" | "maybe";
}

const canCheckPlayType = (el: VideoElement | SafariVideoElement): el is VideoElement & SafariVideoElement => {
  return "canPlayType" in el && typeof el.canPlayType === "function";
};

export const supportsHlsjs = (logger?: LoggerCore): boolean => {
  if (!device.isImplements(Feature.HLSJS)) {
    logger?.info("HlsJsPlayer is NOT supported");
    return false;
  }

  if (device.isImplements(Feature.BROWSER_TYPE)) {
    if (isSafari(device.browserInfo)) {
      logger?.info("HlsJsPlayer is NOT supported in Safari, use Native Hls player instead", {
        ...device.browserInfo,
        platform: device.platform,
      });
      return false;
    }

    // win chrome 51 and below have a bug in mse causing black screens on bitrate switches
    if (
      device.browserInfo.osname === "windows" &&
      device.browserInfo.name === "chrome" &&
      parseInt(device.browserInfo.version ?? "0", 10) < 52
    ) {
      logger?.info("HlsJsPlayer is NOT supported", {
        ...device.browserInfo,
        platform: device.platform,
      });
      return false;
    }

    // This is directly copied from the hls library so that the library can be dynamically loaded in
    if (
      !device.isImplements(Feature.MEDIA_SOURCE) ||
      !device.MediaSource?.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"')
    ) {
      logger?.info("HlsJsPlayer is NOT supported", {
        ...device.browserInfo,
        platform: device.platform,
      });
      return false;
    }

    logger?.debug("HlsJsPlayer is supported", {
      ...device.browserInfo,
      platform: device.platform,
    });
  }

  return true;
};

export const supportsMp4 = (logger?: LoggerCore): boolean => {
  if (!device.isImplements(Feature.BROWSER_TYPE)) {
    logger?.debug("MP4Player is NOT supported.");
    return false;
  }

  if (device.browserInfo.name === "safari" && parseInt(device.browserInfo.version ?? "0", 10) < 10) {
    logger?.info("MP4Player is NOT supported", {
      ...device.browserInfo,
      platform: device.platform,
    });
    return false;
  }

  if (device.browserInfo.name === "ie") {
    logger?.info("MP4Player is NOT supported", {
      ...device.browserInfo,
      platform: device.platform,
    });
    return false;
  }

  if (device.browserInfo.name === "chrome" && !(parseInt(device.browserInfo.version ?? "0", 10) > 49)) {
    logger?.info("MP4Player is NOT supported", {
      ...device.browserInfo,
      platform: device.platform,
    });
    return false;
  }

  if (
    !(
      device.isImplements(Feature.WEB_SOCKET) &&
      device.isImplements(Feature.MEDIA_SOURCE) &&
      device.isImplements(Feature.MEDIA_DEVICE) &&
      device.isImplements(Feature.LOCAL_STORAGE) &&
      device.isImplements(Feature.DEBUGGING) &&
      device.isImplements(Feature.BROWSER_TYPE) &&
      device.MediaSource?.isTypeSupported('video/mp4; codecs="avc1.4d4028,mp4a.40.2"')
    )
  ) {
    logger?.info("MP4Player is NOT supported", {
      ...device.browserInfo,
      platform: device.platform,
    });
    return false;
  }

  logger?.debug("MP4Player is supported", {
    ...device.browserInfo,
    platform: device.platform,
  });

  return true;
};

export const supportsNativeHls = (logger?: LoggerCore): boolean => {
  if (!device.isImplements(Feature.BROWSER_TYPE)) {
    logger?.debug("NativeHlsPlayer is NOT supported.");
    return false;
  }

  if (!device.isIosDevice && !device.isSafari) {
    logger?.debug("NativeHlsPlayer is NOT supported on non Safari browsers outside of iOS");
    return false;
  }

  if (!device.isImplements(Feature.CREATE_VIDEO_ELEMENT)) {
    logger?.info("NativeHlsPlayer is NOT supported");
    return false;
  }

  const el = device.createVideoElement();

  if (
    !(
      canCheckPlayType(el) &&
      (el.canPlayType("application/vnd.apple.mpegURL") === "probably" ||
        el.canPlayType("application/vnd.apple.mpegURL") === "maybe")
    )
  ) {
    logger?.info("NativeHlsPlayer is NOT supported", {
      ...device.browserInfo,
      platform: device.platform,
    });

    return false;
  }

  logger?.debug("NativeHlsPlayer is supported", {
    ...device.browserInfo,
    platform: device.platform,
  });

  return true;
};

export const supportsFlvHttp = async (logger?: LoggerCore): Promise<boolean> => {
  if (!device.isImplements(Feature.BROWSER_TYPE)) {
    logger?.debug("FlvHttpPlayer is NOT supported.");
    return false;
  }

  if (device.isSafari) {
    logger?.debug("FlvHttpPlayer is NOT supported on non Safari browsers");
    return false;
  }

  if (device.isImplements(Feature.MPEGTS)) {
    await device.loadMpegtsScript();
    const supported = device.mpegts?.isSupported() ?? false;

    if (!supported) {
      logger?.info("FlvHttpPlayer is NOT supported");
    }
    return supported;
  }

  logger?.debug("NativeHlsPlayer is supported", {
    ...device.browserInfo,
    platform: device.platform,
  });

  return true;
};

export const supportsMediasoupWebrtc = async (player: string, logger?: LoggerCore): Promise<boolean> => {
  const support = new Support(logger ? new LoggerCore("VDC-core").extend(logger) : new LoggerCore("VDC-core"));

  await support.ready;
  const isSupported = support.supports("h264");

  if (!isSupported) {
    return false;
  }
  if (!device.isImplements(Feature.MEDIA_STREAM)) {
    logger?.info(`${player} is NOT supported`);
    return false;
  }

  const codecs: Array<types.RtpCodecCapability> = [OPUS_CAPABILITY];

  if (device.isAndroidDevice) {
    codecs.push(VP8_CAPABILITY);
  } else {
    codecs.push(H264_CAPABILITY);
  }

  try {
    let dev;
    if (process.env.TEST != null) {
      dev = new MockMediasoupClientDevice();
    } else {
      dev = new Device();
    }

    await dev.load({ routerRtpCapabilities: { codecs } });

    const avSupport = {
      audio: dev.canProduce("audio"),
      video: dev.canProduce("video"),
    };

    if (device.isImplements(Feature.BROWSER_TYPE)) {
      if (!dev.canProduce("audio") || !dev.canProduce("video")) {
        logger?.info(`${player} is NOT supported`, {
          ...{ browserInfo: device.browserInfo as any, avSupport, platform: device.platform },
        });
        return false;
      }

      logger?.debug(`${player} is supported`, {
        ...{ browserInfo: device.browserInfo as any, avSupport, platform: device.platform },
      });
    }

    return true;
  } catch (err) {
    if (device.isImplements(Feature.BROWSER_TYPE)) {
      logger?.info(`${player} is NOT supported`, {
        ...device.browserInfo,
        platform: device.platform,
      });
    } else {
      logger?.info(`${player} is NOT supported`, {
        platform: device.platform,
      });
    }
    return false;
  }
};

/**
 * @deprecated
 */
export const isFirefox = (browserInfo?: BrowserInfo): boolean => {
  return device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "firefox";
};

/**
 * @deprecated
 */
export const isSafari = (browserInfo?: BrowserInfo): boolean => {
  return device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "safari";
};

/**
 * @deprecated
 */
export const isChrome = (browserInfo?: BrowserInfo): boolean => {
  return device.isImplements(Feature.BROWSER_TYPE) && device.browserInfo.name === "chrome";
};

/**
 * @deprecated
 * use `device.isIosDevice` instead
 */
export const isIosDevice = (): boolean => {
  return device.isIosDevice;
};

/**
 * @deprecated
 */
export const isMobileDevice = (): boolean => {
  return device.isMobileDevice;
};

type InformationUnavailable = "information unavailable";
export function getNetworkConnectionType(): [
  NetworkInformation["type"] | "information unavailable",
  NetworkInformation["effectiveType"] | "information unavailable",
] {
  let connectionType: InformationUnavailable | NetworkInformation["type"] = "information unavailable";
  let connectionEffectiveType: InformationUnavailable | NetworkInformation["effectiveType"] = "information unavailable";
  if (device.isImplements(Feature.NETWORK_INFORMATION)) {
    if (device?.connection?.type) {
      connectionType = device.connection.type;
    }
    if (device?.connection?.effectiveType) {
      connectionEffectiveType = device.connection.effectiveType;
    }
  }

  return [connectionType, connectionEffectiveType];
}
