import { EventEmitter, IEventEmitter, Listener } from "@video/events-typed";
import { autorun, IObservable, IReactionDisposer, isComputedProp, isObservableProp, reaction } from "mobx";
import { device, Feature } from "../../../api/adapter";
import type { Disposable, DisposeFunction, Merge } from "../../../api/common";
import type { IVideoClientError } from "../../../api/error";
import { VideoClientErrorDeprecated, wrapNativeError } from "../../errors-deprecated";
import { makeBounded } from "../bind";
import { cancel } from "../context/context";
import { hasVcContext } from "../context/vc-context";
import { disposeObjects } from "../dispose";

function isReactive<T>(obj: T, prop: any): prop is keyof T {
  return isObservableProp(obj, prop) || isComputedProp(obj, prop);
}

function getter<T>(obj: T, prop: keyof T): () => IObservable {
  return () => obj[prop] as any;
}

const Emitter = Symbol("Emitter");
const Observers = Symbol("Observers");

// eslint-disable-next-line @typescript-eslint/ban-types
export class ObservableEventEmitter<E = {}> implements Merge<IEventEmitter<E>, Disposable> {
  [Emitter] = new EventEmitter();

  [Observers] = new Map<string, IReactionDisposer>();

  constructor(bounded = true) {
    if (bounded) {
      makeBounded(this, [], false);
    }
  }

  private startEmitting(prop: keyof this): void {
    if (this[Observers].has(prop as string)) {
      return;
    }

    const handler = this[Emitter].emit.bind(this[Emitter], prop);
    const disposer = reaction(getter(this, prop), handler, { name: `event:${this.constructor.name}.${String(prop)}` });
    this[Observers].set(prop as string, disposer);
  }

  private stopEmitting(prop: string): void {
    this[Observers].get(prop)?.();
    this[Observers].delete(prop);
  }

  setMaxListeners(n: number): this {
    this[Emitter].setMaxListeners(n);
    return this;
  }

  getMaxListeners(): number {
    return this[Emitter].getMaxListeners();
  }

  addListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].addListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  emit<K extends keyof E | "disposed">(type: K, ev?: (E & { disposed: void })[K], ...args: any[]): boolean {
    return this[Emitter].emit(type, ev, ...args);
  }

  off<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].off(type, listener);
    if (EventEmitter.listenerCount(this[Emitter], type as string) === 0) {
      this.stopEmitting(type as string);
    }
    return this;
  }

  on<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].on(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  once<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].once(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  prependListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].prependListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  prependOnceListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this[Emitter].prependOnceListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  removeAllListeners<K extends keyof E | "disposed">(type?: K): this {
    this[Emitter].removeAllListeners(type);
    for (const key of this[Observers].keys()) {
      this.stopEmitting(key);
    }
    return this;
  }

  removeListener<K extends keyof E | "disposed">(type: K, listener: (ev: (E & { disposed: void })[K]) => void): this {
    this[Emitter].removeListener(type, listener);
    if (EventEmitter.listenerCount(this[Emitter], type as string) === 0) {
      this.stopEmitting(type as string);
    }
    return this;
  }

  private readonly disposers: Array<DisposeFunction> = [];

  private disposed = false;

  protected disposing = false;

  public addInnerDisposer(...disposableObjects: Array<DisposeFunction | Disposable>): void {
    for (const obj of disposableObjects) {
      if (typeof obj === "function") {
        this.disposers.unshift(obj);
      } else {
        this.disposers.unshift(obj.dispose.bind(obj));
      }
    }
  }

  protected autorun(view: () => void): void {
    this.addInnerDisposer(autorun(view));
  }

  get isDisposed(): boolean {
    return this.disposed;
  }

  protected emitError(err: IVideoClientError): void {
    this[Emitter].emit("error", err);

    if (!err.isMuted && hasVcContext(this)) {
      VideoClientErrorDeprecated.log(err, this.ctx.logger);
    }
  }

  protected throwError(err: IVideoClientError): never {
    this.emitError(err);
    throw err;
  }

  protected throwErrorDeprecated(err: VideoClientErrorDeprecated): never {
    this.emitErrorDeprecated(err);
    throw err;
  }

  protected emitErrorDeprecated(err: VideoClientErrorDeprecated): void {
    try {
      this[Emitter].emit("error", err);
      if (err.critical) {
        this.dispose(`due error: ${err.code}`);
      }
    } catch (handlerErr) {
      if (hasVcContext(this)) {
        this.ctx.logger.error("error handler throws another error", { err, handlerErr: wrapNativeError(handlerErr) });
      } else if (device.isImplements(Feature.DEBUGGING)) {
        device.console.error("error handler throws another error", { err, handlerErr });
      }
    }
  }

  dispose(reason = "not provided"): void {
    if (this.disposed || this.disposing) {
      return;
    }
    this.disposing = true;

    disposeObjects(this, this.disposers, reason);
    this.removeAllListeners();

    if (hasVcContext(this)) {
      // at this point the context should be canceled
      // but still call it explicitly to make sure
      // the context clear all refs to another objects
      cancel(this.ctx);
    }

    this.disposed = true;
    this.emit("disposed");
  }
}
