import { create } from "jss";
import camelCase from "jss-plugin-camel-case";
import defaultUnit from "jss-plugin-default-unit";
import nested from "jss-plugin-nested";
import { useLayoutEffect } from "react";
import { videoStylesNamespace } from "../../store/utils";
import { StylesObject } from "../ui-lib/typings/css";
import { mergeStylesObjects } from "./utils";

interface ClassesObject<T extends StylesObject, C extends StylesObject> {
  source: T;
  target: C;
}

const jss = create({ plugins: [camelCase(), nested(), defaultUnit()] });

export const useStyles = <T extends StylesObject, C extends StylesObject>(
  { target, source }: ClassesObject<T, C>,
  componentNamespace: string,
): Record<keyof C | keyof T, string> => {
  const styles = mergeStylesObjects(target ?? {}, source ?? {});
  const sheet = jss.createStyleSheet(styles, { classNamePrefix: `${videoStylesNamespace}-${componentNamespace}-` });
  useLayoutEffect(() => {
    sheet.attach();
    return () => {
      sheet.detach();
    };
  }, [sheet]);

  // return sheet.classes;
  // Is this overkill now?
  return Object.keys(sheet.classes).reduce(
    (accumulator, curr) => ({
      ...accumulator,
      [curr]: `${sheet.classes[curr]}`,
    }),
    {} as Record<keyof T | keyof C, string>,
  );
};

/**
 * @deprecated use useStyles() instead
 */
export const mergeStyles = useStyles;

/**
 * @deprecated
 */
type MakeStylesNamespace = {
  classNamePrefix: string;
  name: string;
};

/**
 * @deprecated use useStyles() instead
 */
export const makeStyles = (styles: any, namespace: MakeStylesNamespace): any =>
  useStyles({ target: styles, source: {} }, namespace.classNamePrefix);
