import { Properties } from "csstype";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { PlayerUiState } from "../../../../../../store";
import { PlayerUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUndefinedStoreError } from "../../../../ErrorBoundary";
import LowLatencyToggle from "../../../Inputs/Player/LowLatencyToggle";
import QualitySelect from "../../../Inputs/Player/QualitySelect";
import Tooltip from "../../../../../ui-lib/Tooltip/Tooltip";

interface BitrateButtonClasses extends RootStyles {
  icon?: Properties;
  selectWrapper?: Properties;
  option?: Properties;
  activeOption?: Properties;
  disabledOption?: Properties;
}

interface BitrateButtonProps extends Partial<ButtonIconProps> {
  classes?: BitrateButtonClasses;
  showBitrate?: boolean;
  disableToggle?: boolean;
  disableSelect?: boolean;
}

const ModularBitrateButton = observer(
  ({
    active,
    classNames,
    label = "Quality",
    icon: ProvidedIcon,
    screenReaderText,
    classes = {
      root: {},
      icon: {},
    },
    disableToggle,
    disableSelect,
    showBitrate,
    ...props
  }: BitrateButtonProps) => {
    const ctx = useContext<PlayerUiState | null>(PlayerUiContext);

    const [activeState, setActiveState] = useState<boolean>(false);

    const icon = <Icon iconName="settings" classes={classes?.icon} />;

    useUndefinedStoreError(ctx?.player != null, "<BitrateButton/>");

    useEffect(
      () =>
        reaction(
          () => ctx.showToolbar,
          (val) => {
            if (!val) {
              setActiveState(false);
            }
          },
        ),
      [ctx],
    );

    const handleClick = (): void => {
      setActiveState(!activeState);
    };

    return (
      <div>
        <Tooltip active={activeState}>
          <QualitySelect disableSelect={disableSelect} showBitrate={showBitrate} />
          <LowLatencyToggle disabledOff={disableToggle} />
        </Tooltip>
        <ButtonIcon
          active={activeState}
          inactiveClass={null}
          data-selenium="settings-button"
          icon={ProvidedIcon || icon}
          onClick={handleClick}
          classes={classes}
          label={label}
          {...props}
        >
          {screenReaderText ?? `Click to ${active ? "Hide" : "Show"} Settings`}
        </ButtonIcon>
      </div>
    );
  },
);

// eslint-disable-next-line react/function-component-definition
const BitrateButtonWithErrorBoundary: FC<BitrateButtonProps> = ({
  classes,
  icon: ProvidedIcon,
  label = "Quality",
  screenReaderText,
  active,
  disableToggle,
  disableSelect,
  showBitrate,
  ...props
}: BitrateButtonProps) => {
  const icon = ProvidedIcon ?? <Icon iconName="settings" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="bitrate-button"
          disabled
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Show Settings"}
        </ButtonIcon>
      )}
    >
      <ModularBitrateButton
        data-selenium="bitrate-button"
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        active={active}
        showBitrate={showBitrate}
        disableSelect={disableSelect}
        disableToggle={disableToggle}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default BitrateButtonWithErrorBoundary;
