import { Json } from "@video/log-node/lib";
import { makeObservable, observable } from "mobx";

import type { Encoding, FormatState, Manifest, ManifestFormats, ManifestJson, Serializable, State } from "../api";
import { ErrorCode, SourceProvider } from "../api";
import { device } from "../api/adapter";
import { AnyError } from "../api/error";
import { createError } from "./errors";
import { onceCanceled } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";
import { removeSearchParameterFromUrl } from "../utils/common";

const MEOW_SUPPORTED_AUDIO_CODECS = ["aac"];

export type MediaLoaderOptions = {
  pollingInterval: number;
  unauthorizedPollingInterval: number;
  notFoundPollingInterval: number;
  unauthorizedRecoveryDuration: number;
  host?: string;
  urlParams?: {
    vdc?: boolean;
    substitute?: boolean;
  };
  requestHeaders?: Record<string, string>;
};

export interface MediaLoaderEvents {
  source: ManifestJson;
  online: void;
  offline: void;
  viewers: number;
  manifest: { state: State; code?: number; formats: ManifestFormats; viewCount?: number };
  error: AnyError;
}

/**
 * Interacts with the api to fetch video data
 */
class MediaLoader
  extends ObservableEventEmitter<MediaLoaderEvents>
  implements SourceProvider<ManifestJson>, Serializable
{
  static readonly displayName = "MediaLoader";

  source: ManifestJson | null = null;

  currentState: State = "offline";

  formats: ManifestFormats = {};

  uri: string | null = null;

  originalAuthToken: string | null = null;

  viewCount = 0;

  private pingUri: unknown | null = null;

  private destroyed = false;

  public readonly options: Readonly<MediaLoaderOptions>;

  private shouldBePolling = false;

  private manifest: Manifest | null = null;

  private type: string | null = null;

  private pollingTimeout: number | null = null;

  private noLongerUnauthorizedPolling = false;

  private unauthorizedRecoveryTimout: number | null = null;

  private readonly ctx: VcContext;

  /**
   * Creates MediaLoader for loading manifest by url.
   * Or creates MediaLoader with already loaded manifest
   */
  constructor(ctx: VcContext, manifest: Manifest, options: MediaLoaderOptions) {
    super();

    this.ctx = ctx;
    onceCanceled(ctx).then((reason) => this.dispose(`MediaLoader Class Context Closed: ${reason}`));

    makeObservable(this, {
      source: observable.ref,
    });

    this.currentState = "offline";
    this.formats = {};
    this.pingUri = null;
    this.destroyed = false;
    this.options = options;
    this.setManifest(manifest);
    this.setUri(manifest);
    this.shouldBePolling = false;

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()", { manifest });

    this.addInnerDisposer(() => {
      this.destroyed = true;
      clearInterval(this.pollingTimeout ?? 0);
    });
  }

  private static modifyFormats(formats: ManifestFormats): ManifestFormats {
    for (const formatName of Object.keys(formats) as Array<keyof ManifestFormats>) {
      if (formatName === "mp4-ws") {
        const format = formats[formatName];
        if (format?.origin != null && MEOW_SUPPORTED_AUDIO_CODECS.includes(format.audioCodec)) {
          format.origin = undefined;
        }
      }
    }

    return formats;
  }

  /**
   * Loads the media data
   *
   * @throws {ErrorCode.ManifestError} if the manifest cannot be loaded
   */
  async load(previewImg = false): Promise<void> {
    if (this.manifest != null || this.vod) {
      this.emit("manifest", { state: this.currentState, formats: this.formats });
      if (this.manifest != null && typeof this.manifest !== "string") {
        this.source = this.manifest;
      }
      return;
    }

    if (this.uri == null) {
      return;
    }

    this.ctx.logger.debug("loading manifest", { uri: this.uri });

    let response: Response | null = null;
    let code: number;
    let err: AnyError | null = null;
    let json;

    const urlSplit = this.uri.split("?");
    let newParams;

    newParams = urlSplit[1] ?? "";
    newParams = removeSearchParameterFromUrl(newParams, "img");
    newParams = `${newParams}&img=${previewImg.toString()}`;

    newParams = removeSearchParameterFromUrl(newParams, "vdc");
    // Used to help the backend identify that Video Client V2 is in usage.
    // eslint-disable-next-line eqeqeq
    if (this.options?.urlParams?.vdc != undefined ? this.options?.urlParams?.vdc : true) {
      newParams = `${newParams}&vdc=true`;
    }

    newParams = removeSearchParameterFromUrl(newParams, "sbp");
    // Used to tell the backend to provide a substitute stream or not in order to control the blur feature
    // eslint-disable-next-line eqeqeq
    if (this.options?.urlParams?.substitute != undefined ? this.options?.urlParams?.substitute : true) {
      newParams = `${newParams}&sbp=true`;
    }
    const newUrl = `${urlSplit[0]}?${newParams}`;

    try {
      response = await device.fetch(newUrl, {
        method: "GET",
        ...(this.options.requestHeaders != null && { headers: this.options.requestHeaders }),
      });
      code = response.status;
    } catch (ex) {
      code = response?.status ?? 0;
      err = createError(ErrorCode.NetworkError, "Internal Error", { status: code });
    }

    if (this.isDisposed) {
      return;
    }

    if (code > 499) {
      err = createError(ErrorCode.NetworkError, "Internal Error", { status: code });
    } else if (code === 404) {
      err = createError(ErrorCode.NetworkError, "Not Found", { status: code });
    } else if (code === 403) {
      err = createError(ErrorCode.NetworkError, "Forbidden", { status: code });
    } else if (code === 401) {
      err = createError(ErrorCode.NetworkError, "Unauthorized", { status: code });
    }

    let body: ManifestJson | null = null;
    if (!err && response != null) {
      json = await response.json();
      body = this.validateResponse(json) ? json : null;
    }

    if (!err && body == null) {
      err = createError(ErrorCode.NetworkError, "Unexpected Response", { status: code });
    }

    if (err != null) {
      if (code === 404) {
        this.ctx.logger.debug("manifest not found");
      } else {
        this.emitError(err);
      }
    }
    this.ctx.logger.debug("manifest received", {
      body,
      code,
      uri: this.uri,
    });

    let state: State | null = null;
    if (code === 404 || body == null || body.formats == null) {
      state = "offline";
    } else if (code === 200 && body.self != null) {
      // todo: probably that's a bug on server side and should be fixed
      // there instead of using this workaround
      let { self } = body;
      if (!self.includes("://") && this.uri.includes("://")) {
        const path = /(\w*:\/\/[^/]+)/.exec(this.uri);
        if ((path?.length ?? 0) > 1) {
          self = `${path?.[1]}/${self}`;
        }
      }
      this.uri = self;
    }

    if (state == null && body?.formats != null) {
      this.formats = MediaLoader.modifyFormats(body.formats);
      state = Object.keys(this.formats).length === 0 ? "offline" : "online";
    }

    if (state === "online" && body?.viewCount != null) {
      this.viewCount = body.viewCount;
    }

    if (state == null) {
      const msg = code >= 400 ? "Network error while retrieving manifest" : "Invalid manifest";
      this.throwError(createError(ErrorCode.ManifestError, msg, { url: this.uri }, err));
    }

    if (state !== this.currentState) {
      this.emit(state);
    }

    this.currentState = state;
    this.pingUri = body?.ping != null ? body.ping : null;
    this.type = body?.type != null ? body.type : "live";

    this.emit("manifest", { state: this.currentState, code, formats: this.formats, viewCount: this.viewCount });
    if (body != null) {
      this.source = body;
    }

    if (body?.type !== "recorded") {
      this.setNextPoll(code);
    }
  }

  private setNextPoll(statusCode: number): void {
    if (this.vod) {
      return;
    }

    device.clearTimeout(this.pollingTimeout ?? 0);
    let to = this.options.pollingInterval;
    switch (statusCode) {
      case 401:
      case 403:
        if (this.noLongerUnauthorizedPolling) {
          this.currentState = "forbidden";
          this.emit("manifest", { state: this.currentState, code: statusCode, formats: this.formats });
          return;
        }
        to = this.options.unauthorizedPollingInterval;

        if (this.unauthorizedRecoveryTimout == null) {
          this.unauthorizedRecoveryTimout = device.setTimeout(() => {
            this.noLongerUnauthorizedPolling = true;
          }, this.options.unauthorizedRecoveryDuration);
        }

        break;
      case 404:
        to = this.options.notFoundPollingInterval;
        break;
      default:
        this.noLongerUnauthorizedPolling = false;
    }

    this.pollingTimeout = device.setTimeout(() => {
      this.load().catch((ex) => this.emit("error", ex));
    }, to);
  }

  /**
   * Sets the manifest
   */
  private setManifest(manifest: Manifest): void {
    this.type = null;
    this.formats = {};

    if (typeof manifest === "string") {
      this.uri = manifest;
    } else if (this.validateResponse(manifest)) {
      this.uri = manifest.self ?? null;
      this.manifest = manifest;
      this.currentState = "online";
      this.type = this.manifest.type ?? null;
      this.formats = this.manifest.formats ?? {};
    }

    if (this.uri == null) {
      return;
    }

    // parse original auth token from manifest url
    const accessTokenIndex = this.uri.indexOf("accessToken=");
    if (accessTokenIndex > -1) {
      const at = this.uri.slice(accessTokenIndex + 12);
      const qpIndex = at.indexOf("&") ?? -1;
      if (qpIndex > -1) {
        this.originalAuthToken = at.slice(0, qpIndex);
      } else {
        this.originalAuthToken = at;
      }
    }
  }

  get hasPlayableMedia(): boolean {
    return this.uri != null || this.manifest != null;
  }

  private validateResponse(body: unknown): body is ManifestJson {
    try {
      const manifestBody = body as ManifestJson;

      // TODO: that's a bug on server-side. remove it when it's fixed
      const webrtc = manifestBody.formats?.webrtc;
      if (webrtc != null) {
        if (typeof webrtc.origin?.location === "string") {
          webrtc.origin.location = webrtc.origin?.location.replace(/https?:\/\/(https?):?\/\//, "$1://");
        }
        if (typeof webrtc.origin.rsrc === "string") {
          webrtc.origin.rsrc = webrtc.origin.rsrc.replace(/:3000:3000/, ":3000");
        }
      }
    } catch (ex) {
      this.emitError(createError(ErrorCode.BadManifest, "bad manifest", {}, ex));
      return false;
    }
    return true;
  }

  private setUri(manifest?: Manifest): boolean {
    if (manifest == null) {
      return this.uri != null;
    }

    if (typeof manifest === "string") {
      this.uri = manifest;
      return true;
    }

    if (this.validateResponse(manifest)) {
      this.manifest = manifest;
      if (manifest.self != null) {
        this.uri = manifest.self;
      }
      return true;
    }

    this.emitError(createError(ErrorCode.BadManifest, "Invalid Media URL", {}));
    return false;
  }

  /**
   * Returns the state and formats
   */
  get(formatName: keyof ManifestFormats): FormatState {
    const format = this.formats[formatName];
    if (format == null) {
      return {
        encodings: [],
        state: "online",
        auto: false,
        driver: "",
      };
    }

    let encodings: Encoding[] = [];
    let origin: (typeof format)["origin"] | undefined;
    if ("origin" in format && format.origin != null) {
      origin = format.origin;
      origin.origin = true;
    }

    if ("encodings" in format && format.encodings != null) {
      encodings = format.encodings.sort((a, b) => {
        if ((a.videoKbps ?? 0) + (a.audioKbps ?? 0) > (b.videoKbps ?? 0) + (b.audioKbps ?? 0)) {
          return 1;
        }
        return -1;
      });
    }

    return {
      type: this.type ?? undefined,
      origin: origin ?? undefined,
      encodings,
      audioCodec: "audioCodec" in format ? format.audioCodec : undefined,
      videoCodec: "videoCodec" in format ? format.videoCodec : undefined,
      manifest: "manifest" in format ? format.manifest : undefined,
      state: "online",
      auto: false,
      driver: "",
    };
  }

  get vod(): boolean {
    return this.type === "recorded";
  }

  /**
   * Starts a polling interval
   */
  private startInterval(): void {
    if (this.options.pollingInterval == null || this.manifest != null || this.vod) {
      this.shouldBePolling = false;
      return;
    }
    this.shouldBePolling = true;
  }

  toJSON(): Json {
    return {
      uri: this.uri,
      options: this.options,

      aggregates: {
        support: this.ctx.support.hash,
        state: this.currentState,
      },
    };
  }
}

export default MediaLoader;
