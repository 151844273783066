import { Feature, device } from "@video/video-client-core/lib/api/adapter";
import { MediaQueryListEvent } from "@video/video-client-core/lib/api/adapter/features/match-media";
import { useState, useEffect } from "react";

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(() => false);

  useEffect(() => {
    if (device.isImplements(Feature.MATCH_MEDIA)) {
      const mediaQueryList = device.matchMedia(query);

      const handleMediaQueryChange = (event: MediaQueryListEvent): void => {
        setMatches(event.matches);
      };

      mediaQueryList.addEventListener("change", handleMediaQueryChange);

      // Update the state once at the beginning to ensure it reflects the correct initial state
      setMatches(mediaQueryList.matches);

      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        mediaQueryList.removeEventListener("change", handleMediaQueryChange);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [query]);

  return matches;
};

export default useMediaQuery;
